import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  CaseInterfaceProfile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  CaseInterfaceProfile__exit__root: {
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: 21,
    borderWidth: 2,
    height: 43,
    width: 87,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 30,
      minWidth: 50,
    },
  },
  CaseInterfaceProfile__avatar: {
    backgroundColor: theme.palette.primary.main,
    width: 80,
    height: 80,
    overflow: 'initial',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: 55,
      height: 55,
    },
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
    },
  },
  CaseInterfaceProfile__avatar__name: {
    fontSize: 33,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  CaseInterfaceProfile__name: {
    fontSize: 18,
    color: theme.palette.text.main,
    fontWeight: 500,
    marginLeft: 20,
    textTransform: 'capitalize',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  CaseInterfaceProfile__avatar__crown: {
    position: 'absolute',
    right: -10,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      right: -10,
      bottom: 0,
      width: 25,
      height: 25,
    },
  },
}));

export default useStyles;
