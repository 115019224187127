import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const ConfirmButton = ({ style, className, children, onClick, disabled, ...buttonProps }) => (
  <button
    type="button"
    style={style}
    className={classnames(className, 'button__confirm')}
    onClick={onClick}
    disabled={disabled}
    {...buttonProps}
  >
    {children}
  </button>
);

ConfirmButton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttonProps: PropTypes.array,
};

export default ConfirmButton;
