import produce from 'immer';
import {
  USER_LOADED,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  AUTH_ERROR,
  CLEAR_AUTH_ERRORS,
  SET_REFERRAL_CODE,
  SET_RESTORE_EMAIL_SENT,
  SET_VERIFIED,
  UPDATE_PROFILE,
  UPDATE_AUTH_USER,
  TOKEN_EXPIRED,
  SKIP_VERIFY,
  SESSIONS_ADDED,
  NOTIFICATION_SET,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: false,
  error: '',
  user: {
    email: '',
    profile: {
      is_verified: false,
      available_session: 0,
    },
    billing: {
      active_plan: {
        end_date: null,
        display_name: '',
      },
    },
    groups: [],
  },
  refCode: null,
  restorePasswordEmailSent: false,
  isVerified: true,
  samlUrlRedirect: '',
  isVerifySkiped: false,
  notification: [],
};

const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case USER_LOADING:
        return {
          ...state,
          isLoading: true,
        };
      case SET_RESTORE_EMAIL_SENT:
        return {
          ...state,
          restorePasswordEmailSent: action.value,
        };
      case USER_LOADED:
        draft.isAuthenticated = true;
        draft.isTrial = action.user.is_trial;
        draft.isLoading = false;
        draft.user = action.user;
        break;

      case LOGIN_SUCCESS:
      case REGISTER_SUCCESS:
        return {
          ...state,
          token: action.payload.token,
        };

      case LOGOUT_SUCCESS:
        return {
          ...state,
          token: null,
          user: initialState.user,
          isAuthenticated: false,
          isLoading: false,
        };

      case TOKEN_EXPIRED:
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          isLoading: false,
        };

      case AUTH_ERROR:
        return {
          ...state,
          error: action.error,
        };

      case CLEAR_AUTH_ERRORS:
        return {
          ...state,
          error: '',
        };

      case SET_REFERRAL_CODE:
        // this is user's code to share. not the one user was invited with.
        draft.refCode = action.code;
        break;

      case SET_VERIFIED:
        return {
          ...state,
          user: {
            ...state.user,
            profile: {
              ...state.user.profile,
              is_verified: action.value,
            },
          },
          isVerified: action.value,
        };

      case UPDATE_PROFILE:
        return {
          ...state,
          user: {
            ...state.user,
            profile: {
              ...state.user.profile,
              ...action.value,
            },
          },
        };

      case UPDATE_AUTH_USER: {
        return {
          ...state,
          user: {
            ...action.value,
          },
        };
      }

      case SKIP_VERIFY:
        return {
          ...state,
          isVerifySkiped: true,
        };
      case NOTIFICATION_SET:
        return {
          ...state,
          notification: action.notification,
        };

      case SESSIONS_ADDED:
        draft.user.profile.available_session = state.user.profile.available_session + action.addedSessions;
        break;

      default:
        return state;
    }
  });

export default authReducer;
