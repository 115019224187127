import React from 'react';
import PropTypes from 'prop-types';
import System from '../../icons/System';
import useStyles from './style';

const SystemBadge = ({ system }) => {
  const classes = useStyles();
  return (
    <div className={classes.SystemBadge}>
      <System system={system} size={32} className={classes.SystemBadge__icon} />
    </div>
  );
};

SystemBadge.propTypes = {
  system: PropTypes.string,
};

export default SystemBadge;
