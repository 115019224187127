import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Modal } from '../../../../components/common';
import imgUpgrade from '../../../../assets/payment/prime-offer-img.svg';
import imgUpgrade2 from '../../../../assets/payment/prime-offer-2-cases-img.svg';
import imgUpgrade3 from '../../../../assets/payment/prime-offer-4-cases-img.svg';
import imgUpgradeExplorer from '../../../../assets/payment/new-feature-condition-explorer.svg';
import useStyles from './style';

/**
 * @desc Mode selection modal
 * @param {*} param0
 */
const UpgradeModal = (props) => {
  const classes = useStyles();
  const { isToggle, toggle, type = 'default' } = props;

  // Handle upgrade modal text
  let contentObj = {};
  switch (type) {
    case 'default':
      contentObj = {
        title: 'Get Prime to unlock all our cases',
        subtitle: `This is 1 of over 100 Prime cases. They cover every system in adult medicine and every condition you can
        imagine...with Prime access the whole library can be yours.`,
        image: imgUpgrade,
        imageStyle: classes.UpgradeModal__img,
      };
      break;
    case '2-cases':
      contentObj = {
        title: `Awesome! You've done 2 of 4 free cases`,
        subtitle: `Unlock all of Oscer's cases when you go Prime. Imagine the freedom! 100+ patients covering all the systems and conditions in adult medicine.`,
        image: imgUpgrade2,
        imageStyle: classes.UpgradeModal__img__2,
      };
      break;
    case '4-cases':
      contentObj = {
        title: 'Your free cases are done. Continue with Prime!',
        subtitle: `Ace your clinical exams with 100s of patients waiting for you diagnosis. Gain the freedom to practice anywhere with instant feedback. Get access to everything with Prime.`,
        image: imgUpgrade3,
        imageStyle: classes.UpgradeModal__img__3,
      };
      break;
    case 'end':
      contentObj = {
        title: 'Your Prime subscription has ended',
        subtitle: `To regain access to Prime and the whole Oscer case library you’ll need to upgrade. Check out our Prime deals curated especially for you…`,
        image: imgUpgrade,
        imageStyle: classes.UpgradeModal__img,
        button: 'Renew Now',
      };
      break;
    default:
      contentObj = {
        title: 'Get Prime to unlock all our cases',
        subtitle: `This is 1 of over 100 Prime cases. They cover every system in adult medicine and every condition you can
        imagine...with Prime access the whole library can be yours.`,
        image: imgUpgrade,
        imageStyle: classes.UpgradeModal__img,
      };
      break;
  }

  const location = useLocation();

  return (
    <Modal isToggle={isToggle} toggle={() => toggle(false, type)} centered zIndex={9999} closable>
      <div className={classes.UpgradeModal}>
        <div className={classes.UpgradeModal__cancel} onClick={() => toggle(false, type)} aria-hidden="true">
          Exit
        </div>
        <div className={classes.UpgradeModal__content}>
          <div className={classes.UpgradeModal__wrapper}>
            <img src={contentObj.image} alt="upgrade_image" className={contentObj.imageStyle} />
            <div className={classes.UpgradeModal__title}>{contentObj.title}</div>
            <div className={classes.UpgradeModal__subtitle}>{contentObj.subtitle}</div>
          </div>
          <div className={classes.UpgradeModal__btns}>
            <Button
              variant="contained"
              classes={{ root: classes.UpgradeModal__btn }}
              component={Link}
              to={{
                pathname: '/compare',
                state: { from: location.pathname, search: location.search },
              }}
              onClick={() => toggle(false, type)}
            >
              {contentObj.button || 'Learn More'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UpgradeModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  type: PropTypes.string,
};

export default UpgradeModal;
