import React, { useEffect } from 'react';
import cx from 'classnames';
import { animateScroll } from 'react-scroll';
import { Typography, Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import WistiaPlayer from 'components/WistiaPlayer';
import { useWidth } from '../../helpers/customHooks';
import useStyles from './style';
import { trackPage } from '../../service/analytics';
import OscerMissionIcon from '../../assets/home/about-oscer-character-mission.svg';
import Overlay1 from '../../assets/home/InstantGraphication/instant-gratification-overlay.svg';
import Overlay2 from '../../assets/home/untiring-patients-overlay.svg';
import Overlay3 from '../../assets/home/shape-2.svg';
import OverlayShape7 from '../../assets/home/shape-7.svg';
import OverlayShape6 from '../../assets/home/shape-6.svg';
import aboutHeroImg from '../../assets/home/about-hero-img.svg';
import TeamCard from '../../components/Card/TeamCard';
import mapImg from '../../assets/home/about-location-map.svg';
import mobileMap from '../../assets/home/about-location-map-mobile.png';
import ValueCard from '../../components/Card/ValueCard';
import imgValuePositive from '../../assets/home/jobs-value-relentlessly-positive.svg';
import imgValueJoy from '../../assets/home/jobs-value-design-for-joy.svg';
import imgValueDeliver from '../../assets/home/jobs-value-deliver-fast.svg';
import imgValueTruth from '../../assets/home/jobs-value-pursue-the-truth.svg';
import imgValueBack from '../../assets/home/jobs-value-back-ourselves.svg';
import Teams from './Teams';

const About = (props) => {
  const classes = useStyles();
  const screenSize = useWidth();

  useEffect(() => {
    trackPage('/about');
  }, []);

  const handleScrollTop = () => {
    animateScroll.scrollToTop();
  };

  const values = [
    {
      title: 'We Deliver Fast',
      detail:
        'Achieving our mission will include hundreds of incremental ideas, designs, demos, lines of code. The faster we deliver, the more our product takes the shape of what our customers want.',
      image: imgValueDeliver,
    },
    {
      title: 'We Pursue the Truth',
      detail:
        'A relentless pursuit to objectively guide our decision making. We face the brutal facts early and chart our course, no spin, just the truth.',
      image: imgValueTruth,
    },
    {
      title: 'We Design for Joy',
      detail:
        'When we make decisions in our products we overweight user joy. We create products that are so damn delightful that people want to use them strictly because of their design.',
      image: imgValueJoy,
    },
    {
      title: 'We Back Ourselves',
      detail: `We only hire people that can become world class in their role, we'll give you what you need to succeed and the freedom to execute. Own your domain and back yourself.`,
      image: imgValueBack,
    },
    {
      title: `We're Warm and Open`,
      detail:
        'In our teams, with our students, doctors and patients we are warm, caring and transparent, approaching every situation with optimism and positivity.',
      image: imgValuePositive,
    },
  ];

  return (
    <main className={classes.About__section__main}>
      <section className={cx(classes.About__section, classes.About__oddSection)}>
        <Container className={cx(classes.About__container, classes.About__firstContainer)} maxWidth="lg">
          <p className={classes.About__tag}>ABOUT US</p>
          <Typography variant="h1" className={classes.About__title}>
            About Oscer
          </Typography>
          <Typography className={classes.About__subtitle}>
            We’re on a mission to eliminate missed diagnoses in the world and that starts with training the world’s best
            clinicians… that’s you 👩‍⚕️👨‍⚕️.
          </Typography>
          <div className={classes.About__firstContainer__bottom}>
            <img className={classes.About__img} src={aboutHeroImg} alt="aboutHeroImg" />
          </div>
        </Container>
        <img className={classes.About__overylay1} src={Overlay1} alt="Oscer overlay 1" />
        <img className={classes.About__overylay2} src={Overlay2} alt="Oscer overlay 2" />
      </section>
      <section className={classes.About__section}>
        <Container className={classes.About__container} maxWidth="lg">
          <Typography variant="h2" className={classes.About__mission__subTitle}>
            Bringing the art back to medicine
          </Typography>
          <div className={classes.About__mission__wrapper}>
            <Typography variant="subtitle1" className={classes.About__mission__description}>
              We’re changing the way students learn clinical reasoning with innovative tools like our AI virtual
              patients. Every student that uses Oscer is helping us build our medical knowledge graph that will help
              patients around the world 🌎.
            </Typography>
            <img className={classes.About__mission__icon__mobile} src={OscerMissionIcon} alt="oscer" />
          </div>
          <div className={classes.About__video__player}>
            <WistiaPlayer videoLink="https://oscerai.wistia.com/medias/vdbbn9vlot" />
            <img className={classes.About__mission__oscerCharacter} src={OscerMissionIcon} alt="oscer" />
          </div>
        </Container>
        <img className={classes.About__overylay3} src={Overlay3} alt="Oscer overlay shape 3" />
      </section>
      <section className={classes.About__section}>
        <Container className={cx(classes.About__container)} maxWidth="lg">
          <Typography variant="h2" className={classes.About__mission__subTitle}>
            What we value
          </Typography>
          <Typography variant="subtitle1" className={classes.About__mission__description}>
            We live by these values in everything that we do
          </Typography>
          <div className={classes.About__values__main}>
            {values.map((value) => (
              <ValueCard key={value.title} image={value.image} title={value.title} detail={value.detail} />
            ))}
          </div>
        </Container>
      </section>
      <section className={cx(classes.About__section, classes.About__section__team)}>
        <Container className={classes.About__container__team} maxWidth="lg">
          <Typography variant="h2" className={classes.About__mission__subTitle}>
            Meet the team
          </Typography>
          <Typography variant="subtitle1" className={classes.About__mission__description}>
            We’re a small group of students 👩‍🎓, a doctor 👨‍⚕️, software engineers 👨‍💻, designers 👩‍🎨, marketers 🍄 and
            operationalists 💼 that love 🏀 basketball, ☕ great cafes and 🕺 hip hop based in Melbourne, Australia.
          </Typography>
          <Teams />
          <Button
            variant="contained"
            component={Link}
            to="/careers"
            onClick={handleScrollTop}
            classes={{ root: classes.About__team__btn }}
          >
            Join The Team
          </Button>
        </Container>
        <img className={classes.About__overylay3} src={Overlay3} alt="Oscer overlay shape 3" />
      </section>
      <section className={cx(classes.About__section, classes.About__lastSection)} id="contact">
        <Container className={cx(classes.About__container, classes.About__contact)} maxWidth="lg">
          <Typography variant="h2" gutterBottom>
            Find us
          </Typography>
          <Typography variant="subtitle1" className={classes.About__mission__description}>
            Our office is located in the trendy and foodie Fitzroy, Melbourne, Australia
          </Typography>
          <div className={classes.About__contact__wrapper}>
            <img
              className={classes.About__contact__image}
              src={screenSize === 'xs' ? mobileMap : mapImg}
              alt="Touch base with Oscer"
            />
            <div className={classes.About__info__wrapper}>
              <div className={classes.About__map__info}>
                <div className={classes.About__map__title}>Oscer address</div>
                <a
                  href="https://www.google.com/maps/place/10%2F49+Smith+St,+Fitzroy+VIC+3065/@-37.807237,144.9804943,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad642dd8486f0e7:0x244961c7e7b3d6d2!8m2!3d-37.807237!4d144.982683"
                  target="_blank"
                  className={classes.About__map__address}
                >
                  10/49 Smith Street, Fitzroy, VIC 3065
                </a>
                <div className={classes.About__map__title}>Have a question?</div>
                <a href="mailto:tom@oscer.ai" className={classes.About__map__qs}>
                  tom@oscer.ai
                </a>
                <div className={classes.About__map__title}>Want to partner with us?</div>
                <a href="mailto:hello@oscer.ai" className={classes.About__map__partner}>
                  hello@oscer.ai
                </a>
              </div>
            </div>
          </div>
        </Container>

        <img className={classes.About__overylay4} src={OverlayShape7} alt="OverlayShape7" />
        <img className={classes.About__overylay5} src={OverlayShape6} alt="OverlayShape6" />
      </section>
    </main>
  );
};

About.propTypes = {};

export default About;
