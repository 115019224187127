import React from 'react';
import PropTypes from 'prop-types';

const IconChat = props => {
  const { color = 'var(--secondary-color-1)', size = 20, ...restProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 50 35" version="1.1" {...restProps}>
      <g  transform="translate(-619.000000, -23.000000)">
        <g id="live-chat-icon-dark" transform="translate(619.000000, 23.000000)">
          <path d="M34.0024,19.5044 C32.6214,19.5044 31.5024,18.3854 31.5024,17.0044 C31.5024,15.6234 32.6214,14.5044 34.0024,14.5044 C35.3834,14.5044 36.5024,15.6234 36.5024,17.0044 C36.5024,18.3854 35.3834,19.5044 34.0024,19.5044 M22.0024,19.5044 C20.6214,19.5044 19.5024,18.3854 19.5024,17.0044 C19.5024,15.6234 20.6214,14.5044 22.0024,14.5044 C23.3834,14.5044 24.5024,15.6234 24.5024,17.0044 C24.5024,18.3854 23.3834,19.5044 22.0024,19.5044 M10.0024,19.5044 C8.6214,19.5044 7.5024,18.3854 7.5024,17.0044 C7.5024,15.6234 8.6214,14.5044 10.0024,14.5044 C11.3834,14.5044 12.5024,15.6234 12.5024,17.0044 C12.5024,18.3854 11.3834,19.5044 10.0024,19.5044 M35.0003977,0.0004 L9.00039768,0.0004 C4.0294,0.0004 0.000397678582,4.0294 0.000397678582,9.0004 L0.000397678582,25.0004 C-0.0026,29.2014 2.9024,32.8464 7.00039768,33.7794 L7.00039768,37.0004 C6.9984,38.1834 7.6924,39.2574 8.7734,39.7414 C9.8544,40.2254 11.1174,40.0294 12.0003977,39.2394 L17.8994,34.0004 L35.0003977,34.0004 C39.9714,34.0004 44.0003977,29.9704 44.0003977,25.0004 L44.0003977,9.0004 C44.0003977,4.0294 39.9714,0.0004 35.0003977,0.0004" id="Fill-1" fill="#4A84FF" mask="url(#mask-2)"></path>
        </g>
      </g>    </svg>
  );
};

IconChat.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconChat;
