import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Signup: {
    position: 'relative',
    width: 510,
    backgroundColor: 'var(--neutral-color-2)',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 var(--black-3-color)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '45px 60px',
    borderBottom: '19px solid var(--primary-color-2)',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: 'auto',
      minHeight: '90vh',
      borderRadius: 0,
      padding: '30px 45px',
    },
  },
  Signup__wrapper: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'var(--background-color)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  Signup__icon__signup: {
    position: 'absolute',
    zIndex: 100,
    top: -69,
    right: -215,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  Signup__title: {
    fontSize: 30,
    fontWeight: 500,
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.palette.text.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  Signup__icon__logo: {
    marginBottom: 40,
    maxWidth: 150,
  },
  Signup__social__login: {
    marginTop: 25,
  },
  Signup__form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: 25,
  },
  Signup__textField__root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'var(--secondary-color-2-o1)',
    },
    '& .MuiInputBase-root': {
      padding: '0 0 5px 0',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 34,
    },
  },
  Signup__textField: {
    flex: 1,
    width: '100%',
    marginBottom: 25,
  },
  Signup__texfField__error__offset: {
    marginBottom: 3,
  },
  Signup__textField__icon: {
    maxWidth: 25,
    maxHeight: 25,
  },
  Signup__textField__input: {
    marginLeft: 10,
  },
  Signup__checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: -10,
    },
  },
  Signup__FormControlLabel__root: {
    margin: 0,
    marginRight: 5,
    marginLeft: -11,
    color: theme.palette.text.main,
    '& .MuiIconButton-label': {
      color: theme.palette.primary.light,
      borderRadius: 5,
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  Signup__freeSession__icon: {
    display: 'none',
  },
  Signup__freeSession__icon__show: {
    display: 'flex',
    marginRight: '45%',
    [theme.breakpoints.down('xs')]: {
      marginRight: '30%',
    },
  },
  Signup__buttom__root: {
    width: '100%',
  },
  Signup__button__contained: {
    padding: '13px 45px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  Signup__button__label: {
    fontWeight: 'bold',
  },
  Signup__button__disabled: {
    opacity: 0.2,
  },
  Signup__button__isLoading: {
    opacity: 0.9,
  },
  Signup__terms: {
    fontSize: 14,
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      textShadow: '0px 0px 0.2px #83d0f0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  Signup__signin: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  Signup__have__account: {
    fontSize: 14,
    color: theme.palette.text.main,
    marginTop: 25,
  },
  Signup__error: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 5,
  },
  Email__error: {
    display: 'flex',
    flex: 1,
    width: '100%',
    marginTop: '-15px',
    marginBottom: '10px',
    borderRadius: '25px',
    border: '2px solid #83d0f0',
    background: 'rgba(131, 208, 240, 0.1)',
    minHeight: '40px',
    maxHeight: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      letterSpacing: '-0.5px',
    },
  },
  Password__error: {
    flex: 1,
    width: '100%',
    marginTop: '-15px',
    marginBottom: '10px',
    borderRadius: '25px',
    border: '2px solid #e67f7f',
    background: 'rgba(230, 127, 127, 0.1)',
    minHeight: '40px',
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      letterSpacing: '-0.5px',
    },
  },
  Left__side__message: {
    display: 'flex',
    alignITems: 'center',
    justifyContent: 'center',
  },
  imageIcon: {
    height: '24px',
    width: '24px',
    [theme.breakpoints.down('xs')]: {
      height: '20px',
      width: '20px',
    },
  },
  iconRoot: {
    textAlign: 'center',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    width: '40px',
    [theme.breakpoints.down('xs')]: {
      width: '32px',
    },
  },
  icon__wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  info__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Signup__message: {
    marginRight: '10px',
    fontWeight: 'bolder',
    color: '#83d0f0',
    textDecoration: 'underline',
    [theme.breakpoints.down('xs')]: {
      marginRight: '5px',
    },
  },
  Link__message: {
    color: '#83d0f0',
    '&:hover': {
      color: '#5388db',
    },
  },
}));

export default useStyles;
