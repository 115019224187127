import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import Button from '@material-ui/core/Button';
import { Modal } from '../../common';
import { trackEvent } from '../../../service/analytics';
import { getReferralCode } from '../../../actions/auth';
import { patchRewardClaim } from '../../../actions/task';
import imgOscerRef from '../../../assets/character/character-share.svg';
import iconTwitter from '../../../assets/common/twitter-icon-light.svg';
import iconFacebook from '../../../assets/common/facebook-icon-white.svg';
import useStyles from './style';

const ReferralModal = (props) => {
  const { isToggle = false, toggle, caseID } = props;
  const classes = useStyles();

  const handleExit = () => {
    toggle(!isToggle);
  };

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!props.refCode) {
      props.getReferralCode();
    }
  }, []);

  const copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  const sharedUrl = caseID ? `https://oscer.ai/dashboard/home?id=${caseID}` : `https://oscer.ai/ref/${props.refCode}`;

  const copyClickHandler = () => {
    copyToClipboard(sharedUrl);
    setCopied(true);
    trackEvent({
      GAAction: 'generate_referral_code',
      FBAction: 'Generate Referral Code',
      category: 'Share',
      label: `User ${props.userID} generated referral code ${props.refCode}`,
    });
  };

  const facebookShare = () => {
    if (window !== 'undefined' && window.FB) {
      window.FB.ui(
        {
          method: 'share',
          href: sharedUrl,
        },
        function (response) {
          if (response && !response.error_code) {
            // Facebook share and get 1 month free
            // if (FBShareTask) {
            //   props.patchRewardClaim(FBShareTask);
            // }
            trackEvent({
              GAAction: 'share_by_facebook',
              FBAction: 'Share By Facebook',
              category: 'Share',
              label: `User ${props.userID} share by Facebook`,
            });
          } else {
            // console.log('facebook failed');
          }
        }
      );
    }
  };

  // Check if FB share task valid, if true return task id, false return boolean
  let FBShareTask = false;
  if (!props.isPrime) {
    for (let i = 0; i < props.allTasks.length; i += 1) {
      if (props.allTasks[i].name === 'Facebook Share' && !props.allTasks[i].is_completed) {
        FBShareTask = props.allTasks[i].id;
        break;
      }
    }
  }

  return (
    <Modal isToggle={isToggle} toggle={toggle} centered closable>
      <div className={classes.ReferralModal}>
        <div className={classes.ReferralModal__exit} onClick={handleExit}>
          Maybe Later
        </div>
        <CloseIcon className={classes.ReferralModal__exit__icon} onClick={handleExit} />
        <div className={classes.ReferralModal__main}>
          <div className={classes.ReferralModal__wrapper}>
            <img src={imgOscerRef} alt="img_ref" className={classes.ReferralModal__img} />
            <div className={classes.ReferralModal__title}>
              {caseID ? 'Loved This Case?….Why Not Share It!' : 'Share Oscer With Other Medical Students'}
            </div>
            <div className={classes.ReferralModal__subtitle}>Get 10 Free Coins per Medical Student</div>
            <div className={classes.ReferralModal__share}>SHARE YOUR LINK</div>
            <div className={classes.ReferralModal__btn}>
              <div className={classes.ReferralModal__code}>{props.refCode}</div>
              <div
                className={classes.ReferralModal__copy}
                role="button"
                onKeyDown={copyClickHandler}
                onClick={copyClickHandler}
                tabIndex={0}
              >
                {copied ? 'COPIED' : 'COPY'}
              </div>
            </div>
            <div className={classes.ReferralModal__share}>OR Share with your friends easily using…</div>
            <div className={classes.ReferralModal__share__btns}>
              <Button
                variant="contained"
                color="primary"
                href={`mailto:?subject=Your friend invites you to join Oscer, &body=Join Oscer with me and get 20 coins for free! ${sharedUrl}`}
                classes={{ root: classes.ReferralModal__share__email }}
                onClick={() =>
                  trackEvent({
                    GAAction: 'share_by_email',
                    FBAction: 'Share By Email',
                    category: 'Share',
                    label: `User ${props.userID} share by Email`,
                  })
                }
              >
                <EmailIcon className={classes.ReferralModal__share__icon} />
                <span>Email</span>
              </Button>
              <div className={classes.ReferralModal__share__fb__wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={facebookShare}
                  classes={{ root: classes.ReferralModal__share__facebook }}
                >
                  <img src={iconFacebook} alt="facebook" className={classes.ReferralModal__share__icon} />
                  <span>Share</span>
                </Button>
                {FBShareTask && (
                  <div className={classes.ReferralModal__fb__text__wrapper}>
                    <span>+1 MONTH FREE PRIME</span>
                    <span className={classes.ReferralModal__fb__text}>ONE TIME ONLY</span>
                  </div>
                )}
              </div>
              <Button
                variant="contained"
                color="primary"
                classes={{ root: classes.ReferralModal__share__twitter }}
                href={`https://twitter.com/intent/tweet?text=Join Oscer with me and get 20 coins for free! ${sharedUrl}`}
                target="_blank"
                onClick={() =>
                  trackEvent({
                    GAAction: 'share_by_twitter',
                    FBAction: 'Share By Twitter',
                    category: 'Share',
                    label: `User ${props.userID} share by Twitter`,
                  })
                }
              >
                <img src={iconTwitter} alt="twitter" className={classes.ReferralModal__share__icon} />
                <span>Share</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  refCode: state.auth.refCode,
  userID: state.auth.user.id,
  allTasks: state.task.tasks.all_tasks,
  isPrime: state.auth.user.prime_status,
});

ReferralModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  userID: PropTypes.number,
  refCode: PropTypes.string,
  getReferralCode: PropTypes.func,
  caseID: PropTypes.number,
  allTasks: PropTypes.array,
  patchRewardClaim: PropTypes.func,
  isPrime: PropTypes.bool,
};

export default connect(mapStateToProps, { getReferralCode, patchRewardClaim })(ReferralModal);
