import * as Sentry from '@sentry/react';
import {
  SHOW_CHECKOUT_MODAL,
  HIDE_CHECKOUT_MODAL,
  CHECKOUT_SET_PLANS,
  SET_SELECTED_PLAN,
  SET_CHECKOUT_COMPLETE,
  UPDATE_AUTH_USER,
  SHOW_BILLING_EDIT_MODAL,
  DEFAULT_PAYMENT_SET,
  PLAN_UPDATED,
} from './types';
import * as Api from '../service/api';
import { APIClient } from '../settings';

export const showCheckoutModal = () => ({
  type: SHOW_CHECKOUT_MODAL,
});

export const hideCheckoutModal = () => ({
  type: HIDE_CHECKOUT_MODAL,
});

export const setCheckoutComplete = (value) => ({
  type: SET_CHECKOUT_COMPLETE,
  value,
});

export const updatePlan = (plan) => ({
  type: PLAN_UPDATED,
  plan,
});

// Action to fetch pricing plans
export const fetchPlans = (region, voucher) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { token } = getState().auth;
    Api.getPlans({ token, voucher, region })
      .then((res) => {
        dispatch(setPlans(res.data));
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });

export const setPlans = (plans) => (dispatch, getState) => {
  dispatch({ type: CHECKOUT_SET_PLANS, plans });
};

export const setSelectedPlan = (plan) => ({
  type: SET_SELECTED_PLAN,
  plan,
});

// Action to post paypal data
export const sendPayPalPostCheckoutData = (orderID, subscriptionID) => (dispatch, getState) => {
  const { token } = getState().auth;
  Api.payPalPostCheckout(token, orderID, subscriptionID).then((r) => {
    dispatch({ type: SET_CHECKOUT_COMPLETE, value: true });
  });
};

// Post Stripe token to the BE (to create subscription)
export const postStripeCheckout = (args) => (dispatch) =>
  APIClient.post('api/v1/payment/stripe_checkout/', args).then((r) => {
    dispatch({ type: SET_CHECKOUT_COMPLETE, value: true });
    dispatch({
      type: UPDATE_AUTH_USER,
      value: r.data,
    });
  });

// Todo: temperally set this modal toggle, should not use redux because it only used in profile
export const showBillingEditModal = (value = true) => ({
  type: SHOW_BILLING_EDIT_MODAL,
  value,
});

export const postMailchimpJourney = (args) => {
  APIClient.post('/api/v1/mailchimp/journey/', args).catch((err) => {
    Sentry.captureException(err, {
      tags: {
        section: '/mailchimp/journey',
      },
    });
  });
};

export const getVoucher = (voucher, plan_id) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    const params = {
      plan_id,
    };
    APIClient.get(`/api/v1/payment/promo-code/${voucher}/`, { params })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });

export const setDefaultPayment = (payload) => ({
  type: DEFAULT_PAYMENT_SET,
  payload,
});

export const getDefaultPayment = () => (dispatch) =>
  new Promise((resolve, reject) => {
    APIClient.get(`api/v1/payment/stripe/payment-method/list/`)
      .then((res) => {
        dispatch(setDefaultPayment(res.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
