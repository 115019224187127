import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  CaseCard: {
    overflow: 'hidden',
    borderRadius: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 35, 72, 0.3)',
    background: '#ffffff',
    [theme.breakpoints.down('xs')]: {
      transform: 'none',
    },
  },
  CaseCard__borderWrapper: {
    position: 'relative',
    padding: 24,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderBottom: 'solid 8px',
    [theme.breakpoints.down('xs')]: {
      padding: 10,
      borderBottom: 'solid 6px',
    },
  },
  CaseCard__badge: {
    position: 'absolute',
    top: 15,
    right: 15,
    transform: 'scale(1.2)',
  },

  CaseCard__presentationImageWrapper: {
    background: '#f5fafe',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 6,
    width: 142,
    height: 142,
    [theme.breakpoints.down('md')]: {
      width: 110,
      height: 110,
    },
    [theme.breakpoints.down('xs')]: {
      width: 120,
      height: 120,
    },
  },
  CaseCard__presetationImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 102,
  },
  CaseCard__patientName: {
    fontSize: 20,
    color: theme.palette.text.main,
    fontWeight: 500,
    margin: '10px 0',
    lineHeight: 'normal',
    textAlign: 'center',
  },
  CaseCard__patientAge: {
    fontSize: 13,
    color: theme.palette.text.main,
    lineHeight: 'normal',
    marginTop: 3,
  },
  CaseCard__presentation: {
    fontSize: 14,
    opacity: 0.6,
    color: theme.palette.text.main,
    fontWeight: 500,
    lineHeight: 'normal',
    marginTop: 10,
  },
  CaseCard__presentationName: {
    fontSize: 20,
    color: theme.palette.primary.light,
    fontWeight: 500,
    marginTop: 5,
    marginBottom: 5,
    lineHeight: 'normal',
    textAlign: 'center',
  },
}));

export default useStyles;
