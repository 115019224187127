import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { trackEvent } from '../../../service/analytics';
import { logout, setShowLogoutModal } from '../../../actions/auth';
import CharacterExit from '../../../assets/character/character-exit.svg';
import useStyles from './style';

const Logout = (props) => {
  const classes = useStyles();

  const handleLogout = () => {
    trackEvent({
      GAAction: 'logout',
      FBAction: 'Logout',
      category: 'Logout',
      label: `User log out`,
    });
    props.logout();
  };

  const handleModalClose = () => {
    props.setShowLogoutModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.Logout}
      open={props.showLogoutModal}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.showLogoutModal}>
        <div className={classes.Logout__wrapper}>
          <img src={CharacterExit} alt="Character_image" className={classes.Logout__img} />
          <div className={classes.Logout__title}>Are you sure want to log out?</div>
          <div className={classes.Logout__subtitle}>Don’t leave Oscer behind…</div>
          <div className={classes.Logout__btns}>
            <Button
              color="primary"
              variant="outlined"
              classes={{ root: classes.Logout__btn__logout }}
              onClick={handleLogout}
            >
              Log Out
            </Button>
            <Button
              variant="contained"
              classes={{ root: classes.Logout__btn__cancel }}
              color="primary"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showLogoutModal: state.account.showLogoutModal,
});

Logout.propTypes = {
  logout: PropTypes.func,
  showLogoutModal: PropTypes.bool,
  setShowLogoutModal: PropTypes.func,
};

export default connect(mapStateToProps, { logout, setShowLogoutModal })(Logout);
