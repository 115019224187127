import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@material-ui/core';
import { FaForward } from 'react-icons/fa';
import { range } from 'lodash';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import useStyles from './style';

const MobileTourSlide = (props) => {
  const { img, tag, title, subtitle, handleNext, index, carLength, onRequestClose } = props;
  const classes = useStyles();

  return (
    <div className={classes.MobileTourSlide}>
      <img src={img} alt="img" className={classes.MobileTourSlide__img} />
      <span className={classes.MobileTourSlide__tag}>{tag}</span>
      <div className={classes.MobileTourSlide__wrapper}>
        <div className={classes.MobileTourSlide__title}>{title}</div>
        <div className={classes.MobileTourSlide__text}>{subtitle}</div>
        <Button
          variant="contained"
          color="primary"
          classes={{ root: classes.MobileTourSlide__btn }}
          onClick={carLength === index + 1 ? onRequestClose : handleNext}
        >
          {carLength === index + 1 ? (
            <>
              Get Started
              <FaForward className={classes.MobileTourSlide__btn__icon} />
            </>
          ) : (
            'Next'
          )}
        </Button>
        <div className={classes.MobileTourSlide__dots}>
          {range(carLength).map((noti, dotIndex) => (
            <FiberManualRecordIcon
              key={noti}
              classes={{
                root: cx(classes.MobileTourSlide__dot, {
                  [classes.MobileTourSlide__dot__light]: dotIndex !== index,
                }),
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MobileTourSlide.propTypes = {
  img: PropTypes.string,
  tag: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleNext: PropTypes.func,
  index: PropTypes.number,
  carLength: PropTypes.number,
  onRequestClose: PropTypes.func,
};

export default MobileTourSlide;
