import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useStyles from './style';

const MenuPopover = (props) => {
  const classes = useStyles();

  const {
    children,
    menuIcon,
    handleMouseOver,
    mouseOverElement,
    placement = 'bottom',
    styles = {},
    keepOpen = false,
  } = props;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const prevMenuOpen = useRef(isMenuOpen);

  const handlePopoverOpen = (event) => {
    if (isMenuOpen !== event.currentTarget) {
      setMenuOpen(true);
    }
  };

  const handlePopoverClose = (event) => {
    // Do not close colse popup if mouse moves to menu
    if (menuRef.current && menuRef.current.contains(event.target)) {
      return;
    }
    setMenuOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevMenuOpen.current === true && isMenuOpen === false) {
      menuRef.current.focus();
    }
    prevMenuOpen.current = isMenuOpen;
  }, [isMenuOpen]);

  // Close menu if other menu is opened
  useEffect(() => {
    if (mouseOverElement && menuRef.current) {
      if (!menuRef.current.contains(mouseOverElement)) {
        setMenuOpen(false);
      }
    }
    if (!mouseOverElement) {
      setMenuOpen(false);
    }
  }, [mouseOverElement]);

  return (
    <>
      <div
        className={cx(classes.MenuPopover__menu__icon, styles.menuIcon)}
        ref={menuRef}
        aria-controls={isMenuOpen || keepOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        onMouseOver={(e) => {
          handlePopoverOpen(e);
          handleMouseOver(e);
        }}
        onFocus={() => {}}
        aria-hidden="true"
      >
        {menuIcon}
        <ArrowDropUpIcon
          color="primary"
          fontSize="large"
          classes={{
            root: cx(classes.MenuPopover__arrow, styles.arrow, {
              [classes.MenuPopover__arrow__active]: isMenuOpen || keepOpen,
            }),
          }}
        />
      </div>
      <Popper
        open={isMenuOpen || keepOpen}
        anchorEl={menuRef.current}
        role={undefined}
        transition
        disablePortal
        onMouseLeave={handlePopoverClose}
        placement={placement}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center' }}>
            <div className={cx(classes.MenuPopover__wrapper, styles.wrapper)}>
              <Paper square classes={{ root: cx(classes.MenuPopover__Paper, styles.paper) }}>
                <ClickAwayListener onClickAway={handlePopoverClose}>{children}</ClickAwayListener>
              </Paper>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};

MenuPopover.propTypes = {
  children: PropTypes.node,
  menuIcon: PropTypes.node,
  styles: PropTypes.object,
  handleMouseOver: PropTypes.func,
  mouseOverElement: PropTypes.object,
  placement: PropTypes.string,
};

export default MenuPopover;
