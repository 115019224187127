import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withResizeDetector } from 'react-resize-detector';
import { Typography } from '@material-ui/core';
import HomeContainer from '../../../components/Container/HomeContainer';
import ImageCard from '../../../components/Card/ImageCard';
import imgAlfred from '../../../assets/home/Institutions/institution-alfred.png';
// import imgOsmosis from '../../../assets/home/Institutions/institution-osmosis.png';
import imgUmelb from '../../../assets/home/Institutions/institution-umelb.png';
import imgUsyd from '../../../assets/home/Institutions/institution-usyd.png';
import institutionOverlayImg from '../../../assets/home/section-shape.svg';

import useStyles from './style';

const Institutions = ({ width }) => {
  const classes = useStyles();

  // Set slides per row
  const [slides, setSlides] = useState(4);

  useEffect(() => {
    const slidesRow = Math.floor(width / 270);
    const maxSlides = institutionLists.length;

    // Check width not undefined, and slides number is valid
    if (width) {
      setSlides(slidesRow <= maxSlides ? slidesRow : maxSlides);
    }
  }, [width]);

  const institutionLists = [
    {
      image: imgUmelb,
      name: 'University of Melbourne',
    },
    {
      image: imgUsyd,
      name: 'University of  Sydney',
    },
    // {
    //   image: imgAlfred,
    //   name: 'The Alfred Hospital',
    // },
    // {
    //   image: imgOsmosis,
    //   name: 'Osmosis',
    // },
  ];

  const settings = {
    dots: slides === 1,
    infinite: true,
    speed: 1000,
    slidesToShow: slides,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <HomeContainer innerStyles={classes.Institutions__container__inner}>
      <Typography variant="h2" className={classes.Institutions__title} gutterBottom>
        Some of the institutions that love Oscer
      </Typography>
      <Typography variant="subtitle1" className={classes.Institutions__subtitle} gutterBottom>
        Educators love partnering with Oscer
      </Typography>
      <div className={classes.Institutions__slider}>
        <Slider {...settings}>
          {institutionLists.map((institution) => (
            <div key={institution.name}>
              <ImageCard name={institution.name} image={institution.image} />
            </div>
          ))}
        </Slider>
      </div>
      <img src={institutionOverlayImg} alt="institution_overlay" className={classes.Institutions__overlay} />
    </HomeContainer>
  );
};

Institutions.propTypes = {
  width: PropTypes.number,
};

export default withResizeDetector(Institutions);
