import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ProfilePanel: {
    backgroundColor: '#fbfbfb',
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderRadius: 16,
    width: '100%',
    marginBottom: 10,
    height: 108,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  ProfilePanel__CollapseButton: {
    position: 'absolute',
    left: 0,
    width: '32px',
    height: '62px',
    padding: '23px 8px 23px 0',
    boxShadow: '2px 1px 4px 0 rgba(0, 35, 72, 0.15)',
    backgroundColor: `#deeaff`,
    borderTopRightRadius: '100px',
    borderBottomRightRadius: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.92,
      cursor: 'pointer',
      '& $Tooltip': {
        visibility: 'visible',
      },
      '& $Tooltip__arrow': {
        visibility: 'visible',
      },
    },
  },
  ProfilePanel__exit__root: {
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: 21,
    borderWidth: 2,
    height: 43,
    width: 87,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 30,
      minWidth: 50,
    },
  },
  ProfilePanel__avatar: {
    position: 'relative',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  ProfilePanel__prime: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      right: -10,
      transform: 'scale(0.8)',
    },
  },
  ProfilePanel__name: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.main,
  },
  ProfilePanel__progress: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ProfilePanel__progress__wrapper: {
    position: 'relative',
  },
  ProfilePanel__timer: {
    position: 'absolute',
    top: 20,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 100,
    height: 100,
    backgroundColor: theme.palette.text.main,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ProfilePanel__timer__text: {
    color: theme.palette.text.main,
    lineHeight: 1,
    fontSize: 42,
    fontWeight: 500,
  },
  ProfilePanel__details__text: {
    fontSize: 12,
    color: `${theme.palette.text.main}99`,
  },
  ProfilePanel__timer__name: {
    position: 'absolute',
    bottom: -20,
    left: '50%',
    transform: 'translate(-50%, 0)',
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.main,
  },
  ProfilePanel__CircularProgress__root: {
    position: 'absolute',
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: theme.palette.primary.light,
    strokeLinecap: 'round',
  },
  ProfilePanel__CircularProgress__color__1: {
    color: '#FBA799',
  },
  ProfilePanel__CircularProgress__color__2: {
    color: '#FBA799',
  },
  ProfilePanel__CircularProgress__color__3: {
    color: '#EDBD66',
  },
  ProfilePanel__CircularProgress__color__4: {
    color: '#83D0F0',
  },
  ProfilePanel__CircularProgress__color__5: {
    color: '#4A84FF',
  },
  ProfilePanel__CircularProgress: {
    color: `${theme.palette.text.main}1A`,
    strokeLinecap: 'round',
  },
  ProfilePanel__details: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 14,
  },
  ProfilePanel__details__reminder: {
    color: '#fba799',
    fontSize: 12,
    letterSpacing: 0.18,
    textTransform: 'uppercase',
  },
  Tooltip: {
    visibility: 'hidden',
    width: '147px',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    textAlign: 'center',
    transition: 'fadeIn 0.5s ease-in',
    borderRadius: '16px',
    height: '45px',
    fontSize: '15px',
    position: 'absolute',
    top: '115%',
    boxShadow: '0 2px 30px 0 rgb(27 28 29 / 9%)',
    overflow: 'hidden',
  },
  Tooltip__borderTop: {
    width: '100%',
    background: theme.palette.primary.light,
    height: '3px',
    margin: '0 auto',
  },
  Tooltip__arrow: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: '100%',
    transition: 'fadeIn 0.5s ease-in',
    top: '100%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: `transparent transparent ${theme.palette.primary.light} transparent`,
  },
  Tooltip__text: {
    position: 'relative',
    top: '8px',
  },
}));

export default useStyles;
