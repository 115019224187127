import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ImageShowcase: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  ImageShowcase__reverse: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  ImageShowcase__left: {
    maxWidth: 520,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 30,
      maxWidth: '100%',
      textAlign: 'center',
    },
  },
  ImageShowcase__right: {
    maxWidth: 520,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 320,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 520,
    },
  },
  ImageShowcase__chatWindow: {
    maxWidth: '100%',
  },
}));

export default useStyles;
