import React from 'react';
import PropTypes from 'prop-types';
import LevelBadge from '../../../badge/LevelBadge';
import SystemBadge from '../../../badge/systemBadge';
import PresentationImg from '../../../icons/PresentationImg';
import useStyles from './style';

// The wrapper of mode selector modal
const Slide = ({
  children,
  handleCancel,
  title,
  subtitle,
  caseObj: { system = 'respiratory', case_level = 1, presentation = 'Chest_Pain' },
  modeSelectError = '',
  handlePrevious,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.ModeSelectionSlide}>
        <div className={classes.ModeSelectionSlide__header}>
          <div className={classes.ModeSelectionSlide__header__badges}>
            <div className={classes.ModeSelectionSlide__badge__level}>
              <LevelBadge level={Number(case_level)} />
            </div>
            <SystemBadge system={system} />
          </div>
          <div className={classes.ModeSelectionSlide__cancel} onClick={handleCancel} aria-hidden="true">
            Cancel
          </div>
        </div>
        <div className={classes.ModeSelectionSlide__content}>
          <div className={classes.ModeSelectionSlide__wrapper}>
            <PresentationImg presentation={presentation} size={200} className={classes.ModeSelectionSlide__pres} />
            <div className={classes.ModeSelectionSlide__title}>{title}</div>
            <div className={classes.ModeSelectionSlide__subtitle}>{subtitle}</div>
          </div>
          <div className={classes.ModeSelectionSlide__btns}>{children}</div>
          <div className={classes.ModeSelectionSlide__error}>{modeSelectError}</div>
        </div>
        {!!handlePrevious && (
          <div className={classes.ModeSelectionSlide__back} onClick={handlePrevious} aria-hidden="true">
            Back
          </div>
        )}
      </div>
    </div>
  );
};

Slide.propTypes = {
  handleCancel: PropTypes.func,
  caseObj: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  modeSelectError: PropTypes.string,
  handlePrevious: PropTypes.func,
};

export default Slide;
