import React from 'react';
import PropTypes from 'prop-types';
import AbdominalPain from '../../../assets/cases/presentation_icons/oscer-abdominal-pain.svg';
import BackPain from '../../../assets/cases/presentation_icons/oscer-back-pain.svg';
import ChestPain from '../../../assets/cases/presentation_icons/oscer-chest-pain.svg';
import Cough from '../../../assets/cases/presentation_icons/oscer-cough.svg';
import FeverTravel from '../../../assets/cases/presentation_icons/oscer-fever-travel.svg';
import Fever from '../../../assets/cases/presentation_icons/oscer-pres-fever.svg';
import Headache from '../../../assets/cases/presentation_icons/oscer-headache.svg';
import HeadingLoss from '../../../assets/cases/presentation_icons/oscer-hearing-loss.svg';
import JointPain from '../../../assets/cases/presentation_icons/oscer-joint-pain.svg';
import LegPain from '../../../assets/cases/presentation_icons/oscer-leg-pain.svg';
import Rash from '../../../assets/cases/presentation_icons/oscer-rash.svg';
import VisionLoss from '../../../assets/cases/presentation_icons/oscer-pres-vision-loss.svg';
import WeightLoss from '../../../assets/cases/presentation_icons/oscer-weight-loss.svg';
import UrinaryIncontinence from '../../../assets/cases/presentation_icons/oscer-pres-urinary-incontinence.svg';
import MuscleWeakness from '../../../assets/cases/presentation_icons/oscer-pres-muscle-weakness.svg';
import ShortnessOfBreath from '../../../assets/cases/presentation_icons/oscer-pres-shortness-of-breath.svg';
import Hint_1 from '../../../assets/cases/presentation_icons/character-1-hint.svg';
import Hint_2 from '../../../assets/cases/presentation_icons/character-2-hints.svg';
import Hint_3 from '../../../assets/cases/presentation_icons/character-3-hints.svg';
import Hint_4 from '../../../assets/cases/presentation_icons/character-4-hints.svg';
import Hint_5 from '../../../assets/cases/presentation_icons/character-5-hints.svg';
import empathy from '../../../assets/fundamental/empathy-fundamental-icon.svg';
import functional from '../../../assets/fundamental/functional-fundamental-icon.svg';
import introduction from '../../../assets/fundamental/introduction-fundamental-icon.svg';
import medication from '../../../assets/fundamental/medication-fundamental-icon.svg';
import occupational from '../../../assets/fundamental/occupational-fundamental-icon.svg';
import pastmedical from '../../../assets/fundamental/past-medical-history-fundamental-icon.svg';
import social from '../../../assets/fundamental/social-fundamental-icon.svg';
import constitutional from '../../../assets/fundamental/constitutional-fundamental-icon.svg';
import Chitchat from '../../../assets/cases/presentation_icons/oscer-chitchat.svg';

import abdominal_distension from '../../../assets/cases/presentation_icons/oscer-pres-abdominal-distension.svg';
import abdominal_pain from '../../../assets/cases/presentation_icons/oscer-pres-abdominal-pain.svg';
import alcohol_abuse from '../../../assets/cases/presentation_icons/oscer-pres-alcohol-abuse.svg';
import altered_bowel_habits from '../../../assets/cases/presentation_icons/oscer-pres-altered-bowel-habits.svg';
import amenorrhoea from '../../../assets/cases/presentation_icons/oscer-pres-amenorrhoea.svg';
import anorexia from '../../../assets/cases/presentation_icons/oscer-pres-anorexia.svg';
import anuria from '../../../assets/cases/presentation_icons/oscer-pres-anuria.svg';
import back_pain from '../../../assets/cases/presentation_icons/oscer-pres-back-pain.svg';
import bone_pain from '../../../assets/cases/presentation_icons/oscer-pres-bone-pain.svg';
import bruising from '../../../assets/cases/presentation_icons/oscer-pres-bruising.svg';
import calf_pain from '../../../assets/cases/presentation_icons/oscer-pres-calf-pain.svg';
import chest_pain from '../../../assets/cases/presentation_icons/oscer-pres-chest-pain.svg';
import constipation from '../../../assets/cases/presentation_icons/oscer-pres-constipation.svg';
import cough from '../../../assets/cases/presentation_icons/oscer-pres-cough.svg';
import dizziness from '../../../assets/cases/presentation_icons/oscer-pres-dizziness.svg';
import dysphagia from '../../../assets/cases/presentation_icons/oscer-pres-dysphagia.svg';
import dysuria from '../../../assets/cases/presentation_icons/oscer-pres-dysuria.svg';
import ear_pain from '../../../assets/cases/presentation_icons/oscer-pres-ear-pain.svg';
import eye_pain from '../../../assets/cases/presentation_icons/oscer-pres-eye-pain.svg';
import faecal_incontinence from '../../../assets/cases/presentation_icons/oscer-pres-faecal-incontinence.svg';
import falls from '../../../assets/cases/presentation_icons/oscer-pres-falls.svg';
import fatigue from '../../../assets/cases/presentation_icons/oscer-pres-fatigue.svg';
import haematuria from '../../../assets/cases/presentation_icons/oscer-pres-haematuria.svg';
import haemoptysis from '../../../assets/cases/presentation_icons/oscer-pres-haemoptysis.svg';
import headache from '../../../assets/cases/presentation_icons/oscer-pres-headache.svg';
import inguinoscrotal_pain from '../../../assets/cases/presentation_icons/oscer-pres-inguinoscrotal-pain.svg';
import itch from '../../../assets/cases/presentation_icons/oscer-pres-itch.svg';
import jaw_pain from '../../../assets/cases/presentation_icons/oscer-pres-jaw-pain.svg';
import joint_swelling from '../../../assets/cases/presentation_icons/oscer-pres-joint-swelling.svg';
import laceration from '../../../assets/cases/presentation_icons/oscer-pres-laceration.svg';
import leg_pain from '../../../assets/cases/presentation_icons/oscer-pres-leg-pain.svg';
import loin_pain from '../../../assets/cases/presentation_icons/oscer-pres-loin-pain.svg';
import loss_hearing from '../../../assets/cases/presentation_icons/oscer-pres-loss-hearing.svg';
import melaena from '../../../assets/cases/presentation_icons/oscer-pres-melaena.svg';
import muscle_cramps from '../../../assets/cases/presentation_icons/oscer-pres-muscle-cramps.svg';
import nausea from '../../../assets/cases/presentation_icons/oscer-pres-nausea.svg';
import neck_lump from '../../../assets/cases/presentation_icons/oscer-pres-neck-lump.svg';
import night_sweats from '../../../assets/cases/presentation_icons/oscer-pres-night-sweats.svg';
import nocturia from '../../../assets/cases/presentation_icons/oscer-pres-nocturia.svg';
import pallor from '../../../assets/cases/presentation_icons/oscer-pres-pallor.svg';
import palpitations from '../../../assets/cases/presentation_icons/oscer-pres-palpitations.svg';
import perianal_pain from '../../../assets/cases/presentation_icons/oscer-pres-perianal-pain.svg';
import peripheral_oedema from '../../../assets/cases/presentation_icons/oscer-pres-peripheral-oedema.svg';
import polydipsia from '../../../assets/cases/presentation_icons/oscer-pres-polydipsia.svg';
import polyuria from '../../../assets/cases/presentation_icons/oscer-pres-polyuria.svg';
import pr_bleeding from '../../../assets/cases/presentation_icons/oscer-pres-pr-bleeding.svg';
import rash from '../../../assets/cases/presentation_icons/oscer-pres-rash.svg';
import red_eye from '../../../assets/cases/presentation_icons/oscer-pres-red-eye.svg';
import returned_traveller from '../../../assets/cases/presentation_icons/oscer-pres-returned-traveller.svg';
import seizure from '../../../assets/cases/presentation_icons/oscer-pres-seizure.svg';
import sensory_disturbance from '../../../assets/cases/presentation_icons/oscer-pres-sensory-disturbance.svg';
import shoulder_pain from '../../../assets/cases/presentation_icons/oscer-pres-shoulder-pain.svg';
import skin_lumps_ulcers from '../../../assets/cases/presentation_icons/oscer-pres-skin-lumps-ulcers.svg';
import sore_throat from '../../../assets/cases/presentation_icons/oscer-pres-sore-throat.svg';
import sweating from '../../../assets/cases/presentation_icons/oscer-pres-sweating.svg';
import syncope from '../../../assets/cases/presentation_icons/oscer-pres-syncope.svg';
import tinnitus from '../../../assets/cases/presentation_icons/oscer-pres-tinnitus.svg';
import tremor from '../../../assets/cases/presentation_icons/oscer-pres-tremor.svg';
import urinary_frequency from '../../../assets/cases/presentation_icons/oscer-pres-urinary-frequency.svg';
import vomiting from '../../../assets/cases/presentation_icons/oscer-pres-vomiting.svg';
import wheeze from '../../../assets/cases/presentation_icons/oscer-pres-wheeze.svg';
import time_up from '../../../assets/cases/presentation_icons/oscer-timeup.svg';
import exit from '../../../assets/cases/presentation_icons/oscer-exit-case.svg';
import defaultPresentation from '../../../assets/cases/presentation_icons/oscer-pres-general.svg';
import elbowPain from '../../../assets/cases/presentation_icons/oscer-pres-elbow-pain.svg';
import toePain from '../../../assets/cases/presentation_icons/oscer-pres-toe-pain.svg';
import heamatemesis from '../../../assets/cases/presentation_icons/oscer-pres-haematemesis.svg';
import jaundice from '../../../assets/cases/presentation_icons/oscer-pres-jaundice.svg';
import kneePain from '../../../assets/cases/presentation_icons/oscer-pres-knee-pain.svg';
import hipPain from '../../../assets/cases/presentation_icons/oscer-pres-hip-pain.svg';
import odynophagia from '../../../assets/cases/presentation_icons/oscer-pres-odynophagia.svg';
import facialSwelling from '../../../assets/cases/presentation_icons/oscer-pres-facial-swelling.svg';
import vaginalDischarge from '../../../assets/cases/presentation_icons/oscer-pres-vaginal-discharge.svg';
import mouthUcler from '../../../assets/cases/presentation_icons/oscer-pres-mouth-ucler.svg';
import neckStiffness from '../../../assets/cases/presentation_icons/oscer-pres-neck-stiffness.svg';
import presPhoto from '../../../assets/cases/presentation_icons/oscer-pres-photophobia.svg';
import difficultSleeping from '../../../assets/cases/presentation_icons/oscer-pres-difficulty-sleeping.svg';
import breastLump from '../../../assets/cases/presentation_icons/oscer-pres-breast-lump.svg';
import breastPain from '../../../assets/cases/presentation_icons/oscer-pres-breast-pain.svg';
import nippleDischarge from '../../../assets/cases/presentation_icons/oscer-pres-nipple-discharge.svg';
import alteredMenstrualBleeding from '../../../assets/cases/presentation_icons/oscer-pres-altered-menstrual-bleeding.svg';
import bleeding from '../../../assets/cases/presentation_icons/oscer-pres-bleeding-generalised.svg';
import paleStools from '../../../assets/cases/presentation_icons/oscer-pres-epistaxis-nose-bleeds.svg';
import epistaxis from '../../../assets/cases/presentation_icons/oscer-pres-pale-stools.svg';
import erectileDysfunction from '../../../assets/cases/presentation_icons/oscer-pres-erectile-dysfunction.svg';
import weightGain from '../../../assets/cases/presentation_icons/oscer-pres-weight-gain.svg';
import darkUrine from '../../../assets/cases/presentation_icons/oscer-pres-dark-urine.svg';
import handPain from '../../../assets/cases/presentation_icons/oscer-pres-hand-pain.svg';
import hotFlushes from '../../../assets/cases/presentation_icons/oscer-pres-hot-flushes.svg';
import randomCharacter from '../../../assets/cases/presentation_icons/oscer-character-randomise-case.svg';
import anxiety from '../../../assets/cases/presentation_icons/oscer-pres-anxiety.svg';
import dyspareunia from '../../../assets/cases/presentation_icons/oscer-pres-dyspareunia.svg';
import lowMood from '../../../assets/cases/presentation_icons/oscer-pres-low-mood.svg';
import sexualMenstrualHistory from '../../../assets/cases/presentation_icons/oscer-pres-sexual-and-menstrual-history.svg';
import paediatrics from '../../../assets/cases/presentation_icons/oscer-pres-paediatrics.svg';
import hoarseness from '../../../assets/cases/presentation_icons/oscer-pres-hoarseness.svg';
import groinSwelling from '../../../assets/cases/presentation_icons/oscer-pres-groin-swelling.svg';
import mouthBleeding from '../../../assets/cases/presentation_icons/oscer-pres-mouth-bleeding.svg';
import rigors from '../../../assets/cases/presentation_icons/oscer-pres-rigors.svg';

import * as constants from './constants';

const presentationImages = {
  [constants.PRESENTATION_ABDOMINAL_PAIN]: AbdominalPain,
  [constants.PRESENTATION_BACK_PAIN]: BackPain,
  [constants.PRESENTATION_CHEST_PAIN]: ChestPain,
  [constants.PRESENTATION_COUGH]: Cough,
  [constants.PRESENTATION_COUGH_F]: Cough,
  [constants.PRESENTATION_FEVER_TRAVEL]: FeverTravel,
  [constants.PRESENTATION_FEVER]: Fever,
  [constants.PRESENTATION_HEADACHE]: Headache,
  [constants.PRESENTATION_HEARING_LOSS]: HeadingLoss,
  [constants.PRESENTATION_JOINT_PAIN]: JointPain,
  [constants.PRESENTATION_LEG_PAIN]: LegPain,
  [constants.PRESENTATION_RASH]: Rash,
  [constants.PRESENTATION_VISION_LOSS]: VisionLoss,
  [constants.PRESENTATION_WEIGHT_LOSS]: WeightLoss,
  [constants.PRESENTATION_RETURNED_TRAVELLER]: returned_traveller,
  [constants.PRESENTATION_URINARY_INCONTINENCE]: UrinaryIncontinence,
  // Use exact images for following cases later
  [constants.PRESENTATION_MUSCLE_WEAKNESS]: MuscleWeakness,
  [constants.PRESENTATION_SHORTNESS_BREATH]: ShortnessOfBreath,
  [constants.HINT_1]: Hint_1,
  [constants.HINT_2]: Hint_2,
  [constants.HINT_3]: Hint_3,
  [constants.HINT_4]: Hint_4,
  [constants.HINT_5]: Hint_5,
  [constants.empathy]: empathy,
  [constants.functional]: functional,
  functional_history: functional,
  [constants.introduction]: introduction,
  [constants.medication]: medication,
  medications: medication,
  occupational_history: occupational,
  [constants.past_medical_history]: pastmedical,
  social_history: social,

  'abdominal distension': abdominal_distension,
  Abnormal_Bleeding_and_Bruising: bruising,
  'scrotal pain': inguinoscrotal_pain,
  sensory_disturbance_hyposthesia_numbness: sensory_disturbance,
  abdominal_distension,
  abdominal_pain,
  alcohol_abuse,
  altered_bowel_habits,
  amenorrhoea,
  anorexia,
  anuria,
  back_pain,
  bone_pain,
  bruising,
  Bruising: bruising,
  calf_pain,
  chest_pain,
  constipation,
  cough,
  dizziness,
  dysphagia,
  dysuria,
  ear_pain,
  eye_pain,
  faecal_incontinence,
  diarrhoea: faecal_incontinence,
  falls,
  fatigue,
  fever: Fever,
  haematuria,
  haemoptysis,
  headache,
  inguinoscrotal_pain,
  itch,
  jaw_pain,
  joint_swelling,
  laceration,
  leg_pain,
  loin_pain,
  loss_hearing,
  melaena,
  muscle_cramps,
  muscle_weakness: MuscleWeakness,
  nausea,
  neck_lump,
  night_sweats,
  nocturia,
  pallor,
  palpitations,
  perianal_pain,
  peripheral_oedema,
  polydipsia,
  polyuria,
  pr_bleeding,
  rectal_bleeding: pr_bleeding,
  rash,
  red_eye,
  returned_traveller,
  seizure,
  sensory_disturbance,
  shortness_of_breath: ShortnessOfBreath,
  shoulder_pain,
  skin_lumps_ulcers,
  sore_throat,
  sweating,
  syncope,
  tinnitus,
  tremor,
  urinary_frequency,
  urinary_incontinence: UrinaryIncontinence,
  vomiting,
  wheeze,
  constitutional_symptoms: constitutional,
  time_up,
  exit,
  chitchat: Chitchat,
  'amenorrhoea/oligomenorrhea': amenorrhoea,
  dark_stools: melaena,
  faecal_urgency: altered_bowel_habits,
  flank_pain: loin_pain,
  groin_pain: inguinoscrotal_pain,
  increased_urination: polyuria,
  joint_pain: JointPain,
  leg_sensory_disturbance: sensory_disturbance,
  leg_swelling: joint_swelling,
  leg_weakness: MuscleWeakness,
  loss_of_appetite: anorexia,
  myalgia: muscle_cramps,
  noisy_breathing: wheeze,
  oliguria_anuria: anuria,
  overflow_incontinence: urinary_frequency,
  scrotal_pain: inguinoscrotal_pain,
  'skin_lumps_(general)': skin_lumps_ulcers,
  skin_wound: skin_lumps_ulcers,
  stress_incontinence: UrinaryIncontinence,
  transient_loss_of_consciousness: syncope,
  urge_incontinence: UrinaryIncontinence,
  urinary_urgency: urinary_frequency,
  elbow_pain: elbowPain,
  toe_pain: toePain,
  stiffness: joint_swelling,
  haematemesis: heamatemesis,
  jaundice,
  knee_pain: kneePain,
  hip_pain: hipPain,
  'Sensory Disturbance': sensory_disturbance,
  difficulty_balancing: dizziness,
  'Difficulty Balancing': dizziness,
  face_weakness: MuscleWeakness,
  'Face Weakness': MuscleWeakness,
  upper_limb_muscle_weakness: MuscleWeakness,
  'Upper Limb Muscle Weakness': MuscleWeakness,
  lower_limb_muscle_weakness: MuscleWeakness,
  'Lower Limb Muscle Weakness': MuscleWeakness,
  arm_weakness: MuscleWeakness,
  'Arm Weakness': MuscleWeakness,
  forearm_weakness: MuscleWeakness,
  'Forearm Weakness': MuscleWeakness,
  hand_weakness: MuscleWeakness,
  'Hand Weakness': MuscleWeakness,
  thigh_weakness: MuscleWeakness,
  'Thigh Weakness': MuscleWeakness,
  foot_weakness: MuscleWeakness,
  'Foot Weakness': MuscleWeakness,
  face_sensory_disturbance: sensory_disturbance,
  'Face Sensory Disturbance': sensory_disturbance,
  hand_sensory_disturbance: sensory_disturbance,
  'Hand Sensory Disturbance': sensory_disturbance,
  forearm_sensory_disturbance: sensory_disturbance,
  'Forearm Sensory Disturbance': sensory_disturbance,
  arm_sensory_disturbance: sensory_disturbance,
  'Arm Sensory Disturbance': sensory_disturbance,
  thigh_sensory_disturbance: sensory_disturbance,
  'Thigh Sensory Disturbance': sensory_disturbance,
  foot_sensory_disturbance: sensory_disturbance,
  'Foot Sensory Disturbance': sensory_disturbance,
  upper_limb_sensory_disturbance: sensory_disturbance,
  'Upper Limb Sensory Disturbance': sensory_disturbance,
  lower_limb_sensory_disturbance: sensory_disturbance,
  'Lower Limb Sensory Disturbance': sensory_disturbance,
  'Leg Sensory Disturbance': sensory_disturbance,
  'Leg Sensory Disturbance (sensory changes)': sensory_disturbance,
  'Leg Sensory Disturbance (sensation)': sensory_disturbance,
  Odynophagia: odynophagia,
  odynophagia: odynophagia,
  facial_swelling: facialSwelling,
  'Facial Swelling': facialSwelling,
  mouth_ucler: mouthUcler,
  'Mouth Ucler': mouthUcler,
  neck_stiffness: neckStiffness,
  'Neck Stiffness': neckStiffness,
  Photophobia: presPhoto,
  photophobia: presPhoto,
  coryzal_symptoms: sore_throat,
  'Coryzal symptoms': sore_throat,
  difficult_sleeping: difficultSleeping,
  'Difficult Sleeping': difficultSleeping,
  breast_lump: breastLump,
  'Breast Lump': breastLump,
  breast_pain: breastPain,
  'Breast Pain': breastPain,
  nipple_discharge: nippleDischarge,
  'Nipple discharge': nippleDischarge,
  altered_menstural_bleeding: alteredMenstrualBleeding,
  'Altered Menstrual Bleeding': alteredMenstrualBleeding,
  Bleeding: bleeding,
  'Pale Stools': paleStools,
  Epistaxis: epistaxis,
  epistaxis: epistaxis,
  erectile_dysfunction: erectileDysfunction,
  'Erectile Dysfunction': erectileDysfunction,
  weight_gain: weightGain,
  'Weight Gain': weightGain,
  dark_urine: darkUrine,
  'Dark Urine': darkUrine,
  muscle_pain: muscle_cramps,
  'Muscle Pain': muscle_cramps,
  hot_flushes: hotFlushes,
  'Hot Flushes': hotFlushes,
  hand_pain: handPain,
  'Hand Pain': handPain,
  Random: randomCharacter,
  random: randomCharacter,
  vaginal_discharge: vaginalDischarge,
  'Vaginal Discharge': vaginalDischarge,
  heavy_menstrual_bleeding: alteredMenstrualBleeding,
  'Heavy Menstrual Bleeding': alteredMenstrualBleeding,
  post_coital_bleeding: alteredMenstrualBleeding,
  'Post Coital Bleeding': alteredMenstrualBleeding,
  intermenstrual_bleeding: alteredMenstrualBleeding,
  'Intermenstrual Bleeding': alteredMenstrualBleeding,
  postmenopausal_bleeding: alteredMenstrualBleeding,
  'Postmenopausal Bleeding': alteredMenstrualBleeding,
  sexual_menstrual_history: sexualMenstrualHistory,
  'Sexual and Menstrual History': sexualMenstrualHistory,
  low_mood: lowMood,
  'Low Mood': lowMood,
  dyspareunia: dyspareunia,
  Dyspareunia: dyspareunia,
  anxiety: anxiety,
  Anxiety: anxiety,
  Hoarseness: hoarseness,
  hoarseness: hoarseness,
  Paediatrics: paediatrics,
  paediatrics: paediatrics,
  'Groin Swelling': groinSwelling,
  groin_swelling: groinSwelling,
  'Scrotal Swelling': groinSwelling,
  scrotal_swelling: groinSwelling,
  Rigors: rigors,
  rigors: rigors,
  Shivers: rigors,
  shivers: rigors,
  Chills: rigors,
  chills: rigors,
  'Mouth Bleeding': mouthBleeding,
  mouth_bleeding: mouthBleeding,
  'Gum Bleeding': mouthBleeding,
  gum_bleeding: mouthBleeding,
};

const PresentationImage = (props) => {
  const { presentation = 'shortness_of_breath', className, size } = props;
  // If presentation image not in the list, them use defaultPresentation as default
  const formalisePresentation = presentation.replace(/ /g, '_').toLowerCase();
  return presentationImages[formalisePresentation] ? (
    <img src={presentationImages[formalisePresentation]} className={className} alt={presentation} width={size} />
  ) : (
    <img src={defaultPresentation} className={className} alt={presentation} width={size} />
  );
};

PresentationImage.propTypes = {
  presentation: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};

export default PresentationImage;
