import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { scroller, animateScroll, Element } from 'react-scroll';
import { useWidth } from 'helpers/customHooks';

import { createMessage } from '../../../actions/messages';
import { requestResponse } from '../../../actions/cases';

import MessageInput from './MessageInput';
import MessageList from '../../../components/MessageList';

import './styles.scss';
import MessageRecommendation from './MessageRecommendation';

/**
 * @desc Main area which include patient info, chatting and input
 * @param {*} param
 */
const MainArea = (props) => {
  const {
    currentCase,
    createMessage,
    messages,
    panelHidden,
    requestResponse,
    timerComplete,
    user,
    sessionId,
    beginModalToggle,
    audioBlockedToggle,
  } = props;
  const screenSize = useWidth();

  const scrollToBottom = () => {
    if (screenSize === 'xs' || screenSize === 'sm') {
      animateScroll.scrollToBottom();
    } else {
      scroller.scrollTo('bottomPosition', {
        containerId: 'message-box-wrapper-id',
        duration: 1000,
        smooth: true,
      });
    }
  };

  useEffect(() => {
    const handleTimeout = setTimeout(() => {
      scrollToBottom();
    }, 400);
    return function cleanUp() {
      clearTimeout(handleTimeout);
    };
  }, [props.messages]);

  return (
    <div className="main">
      <div className="main-area">
        <div className="message-box full">
          <div className="message-box-wrapper" id="message-box-wrapper-id">
            <MessageList messages={messages} currentCase={currentCase} user={user} />
            <Element name="bottomPosition"></Element>
          </div>
          {messages.length === 1 && (
            <MessageRecommendation
              panelHidden={panelHidden}
              createMessage={createMessage}
              requestResponse={requestResponse}
              caseSession={currentCase}
            />
          )}
          <MessageInput
            panelHidden={panelHidden}
            createMessage={createMessage}
            requestResponse={requestResponse}
            timerComplete={timerComplete}
            caseId={currentCase.id}
            userId={user.id}
            sessionId={sessionId}
            beginModalToggle={beginModalToggle}
            audioBlockedToggle={audioBlockedToggle}
            messages={messages}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  timerComplete: state.cases.timerComplete,
  currentCase: state.cases.currentCase,
  messages: state.messages.messages,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  createMessage: (type, text, date) => dispatch(createMessage(type, text, date)),
  requestResponse: (text) => dispatch(requestResponse(text)),
});

MainArea.propTypes = {
  currentCase: PropTypes.object.isRequired,
  createMessage: PropTypes.func.isRequired,
  messages: PropTypes.any.isRequired,
  requestResponse: PropTypes.func.isRequired,
  timerComplete: PropTypes.bool,
  user: PropTypes.object,
  beginModalToggle: PropTypes.bool,
  audioBlockedToggle: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainArea));
