import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

/**
 * Color opacity hex https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
 * 95% — F2
 * 90% — E6
 * 85% — D9
 * 80% — CC
 * 75% — BF
 * 70% — B3
 * 65% — A6
 * 60% — 99
 * 55% — 8C
 * 50% — 80
 * 45% — 73
 * 40% — 66
 * 35% — 59
 * 30% — 4D
 * 25% — 40
 * 20% — 33
 * 15% — 26
 * 10% — 1A
 * 5% —  0D
 */
const DARK = '#002348';
const GREY = '#919aa3';

const defaultTheme = createTheme({});
const colorTheme = {
  ...defaultTheme,
  palette: {
    primary: {
      dark: DARK,
      main: '#4A84FF',
      light: '#83d0f0',
      lightDark: '#3fb6e8',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#fcb9ad',
      light: 'rgba(251, 167, 153, 0.2)',
    },
    inherit: {
      main: '#ecf2ff',
      light: '#f6f9fc',
    },
    text: {
      main: DARK,
      light: GREY,
    },
    info: {
      main: '#4A84FF',
    },
    warning: {
      light: '#fbd899',
      main: '#ff9800',
    },
    error: {
      light: '#fba799',
      main: '#f44336',
    },
    grey: {
      light: '#ecf2ff',
    },
    prime: {
      main: '#f6c89f',
      dark: '#F2AD70',
    },
    overflow: {
      grey: '#eceef1',
    },
  },
};

const theme = responsiveFontSizes(
  createTheme({
    ...colorTheme,

    typography: {
      color: DARK,
      htmlFontSize: 16,
      text: {
        main: DARK,
        light: 'rgba(0, 35, 72, 0.6)',
      },
      button: {
        fontSize: 16,
        fontWeight: 600,
      },
      h1: {
        fontSize: 56,
        fontWeight: 'normal',
        letterSpacing: 'normal',
        color: DARK,
        lineHeight: 1.11,
      },
      h2: {
        fontSize: 44,
        fontWeight: 'normal',
        color: DARK,
        [colorTheme.breakpoints.down('xs')]: {
          fontSize: 25,
        },
      },
      h3: {
        fontSize: 30,
        color: DARK,
        lineHeight: 1.2,
      },
      subtitle1: {
        fontSize: 30,
        fontWeight: 'normal',
        color: GREY,
        lineHeight: 1.5,
        letterSpacing: 'normal',
        [colorTheme.breakpoints.down('xs')]: {
          fontSize: 17,
        },
      },
    },
    overrides: {
      // Style sheet name ⚛️
      MuiTypography: {
        gutterBottom: {
          marginBottom: 40,
          [colorTheme.breakpoints.down('xs')]: {
            marginBottom: 20,
          },
        },
      },
      MuiButton: {
        // Name of the rule
        root: {
          textTransform: 'initial',
          lineHeight: 1.14,
          letterSpacing: 0.2,
          transition: 'all 0.3s',
          fontWeight: 500,
          borderRadius: 21.5,
          fontSize: 14,
        },
        text: {
          boxShadow: 'none',
          padding: '10px 8px',
        },
        textPrimary: {
          color: colorTheme.palette.primary.main,
          '&:hover': {
            backgroundColor: `${colorTheme.palette.primary.main}0A`,
          },
        },
        textSecondary: {
          color: colorTheme.palette.text.main,
          '&:hover': {
            backgroundColor: `${colorTheme.palette.text.main}0A`,
          },
        },
        outlined: {
          padding: '10px 16px',
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: 'white',
            borderWidth: 0,
          },
        },
        outlinedPrimary: {
          border: `2px solid ${colorTheme.palette.primary.main}`,
          '&:hover': {
            backgroundColor: `${colorTheme.palette.primary.main}0A`,
            border: `2px solid ${colorTheme.palette.primary.main}`,
          },
          '&.Mui-disabled': {
            backgroundColor: `${colorTheme.palette.primary.main}61`,
          },
        },
        outlinedSecondary: {
          color: colorTheme.palette.text.main,
          border: `2px solid ${colorTheme.palette.text.main}`,
          '&:hover': {
            backgroundColor: `${colorTheme.palette.text.main}0A`,
            border: `2px solid ${colorTheme.palette.text.main}`,
          },
          '&.Mui-disabled': {
            backgroundColor: `${colorTheme.palette.text.main}61`,
          },
        },
        contained: {
          padding: '10px 16px',
          boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
          color: 'white',
          '&:hover': {
            boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
          },
          '&.Mui-disabled': {
            color: 'white',
            borderWidth: 0,
          },
        },
        containedPrimary: {
          color: 'white',
          // border: `2px solid ${colorTheme.palette.primary.main}`,
          backgroundColor: colorTheme.palette.primary.main,
          '&:hover': {
            color: 'white',
            backgroundColor: `${colorTheme.palette.primary.main}EB`,
            // border: `2px solid ${colorTheme.palette.primary.main}`,
          },
          '&.Mui-disabled': {
            backgroundColor: `${colorTheme.palette.primary.main}61`,
          },
        },
        containedSecondary: {
          color: 'white',
          border: `2px solid ${colorTheme.palette.text.main}`,
          backgroundColor: colorTheme.palette.text.main,
          '&:hover': {
            color: 'white',
            backgroundColor: `${colorTheme.palette.text.main}EB`,
            border: `2px solid ${colorTheme.palette.text.main}`,
          },
          '&.Mui-disabled': {
            backgroundColor: `${colorTheme.palette.text.main}61`,
          },
        },
        colorInherit: {
          color: colorTheme.palette.primary.light,
        },
        label: {
          fontWeight: 'inherit',
          fontSize: 'inherit',
          letterSpacing: 'inherit',
        },
        iconSizeMedium: {
          '& > *:first-child': {
            fontSize: 20,
          },
        },
      },
      MuiTab: {
        root: { textTransform: 'inherit', fontSize: 18 },
        wrapper: {
          fontSize: 'inherit',
        },
      },
    },
  })
);

export default theme;
