import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  HoverCard: {
    overflow: 'hidden',
    position: 'relative',
    margin: 5,
    borderRadius: 16,
    boxShadow: '0 2px 4px 0 rgba(0, 35, 72, 0.3)',
    backgroundColor: 'white',
    '&:hover .overlay': {
      transform: ' translateY(0)',
    },
  },
  HoverCard__front: {
    borderBottom: 'solid 8px',
    padding: 24,
  },
  HoverCard__overlay: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translateY(-101%)',
    transition: 'transform 0.6s ease-out',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
