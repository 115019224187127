import React from 'react';
import ImageShowcase from '../../../components/Showcase/ImageShowcase';
import HomeContainer from '../../../components/Container/HomeContainer';
import imgFeature1 from '../../../assets/home/hp-context-snippet-img.svg';
import imgFeature2 from '../../../assets/home/hp-personality-snippet-img.svg';
import imgFeature3 from '../../../assets/home/hp-learning-snippet-img.svg';
import imgOverflow1 from '../../../assets/home/shape-artificial-2.svg';
import imgOverflow2 from '../../../assets/home/section-hero-overlay-1.svg';
import imgOverflow3 from '../../../assets/home/shape-7.svg';
import useStyles from './style';

const TechnologyFeatures = () => {
  const classes = useStyles();

  const features = [
    {
      title: 'Oscer Gets Context',
      subtitle: 'Just like a real person, our context systems have memory so you can ask your questions naturally.',
      image: imgFeature1,
      isReverse: false,
    },
    {
      title: 'Oscer Has Personality',
      subtitle: `This isn't simple question mapping or intent searching. We take the latest research and take it into production ready code.`,
      image: imgFeature2,
      isReverse: true,
    },
    {
      title: 'Oscer Is Learning',
      subtitle: 'Oscer only makes a mistake once and never again. Accuracy is inevitable.',
      image: imgFeature3,
      isReverse: false,
    },
  ];

  return (
    <HomeContainer
      outerStyles={classes.TechnologyFeatures__container__outer}
      innerStyles={classes.TechnologyFeatures__container__inner}
    >
      {features.map((feat) => (
        <ImageShowcase
          key={feat.title}
          title={feat.title}
          subtitle={feat.subtitle}
          image={feat.image}
          isReverse={feat.isReverse}
          styles={{ root: classes.TechnologyFeatures__imageShowcase }}
        />
      ))}
      <img src={imgOverflow1} alt="hero_overlay_1" className={classes.TechnologyFeatures__overlay__1} />
      <img src={imgOverflow2} alt="hero_overlay_2" className={classes.TechnologyFeatures__overlay__2} />
      <img src={imgOverflow3} alt="hero_overlay_3" className={classes.TechnologyFeatures__overlay__3} />
    </HomeContainer>
  );
};

export default TechnologyFeatures;
