export const GET_ERRORS = 'GET_ERRORS';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const SET_SEND_EMAIL_TO_BE_REMINDED = 'SET_SEND_EMAIL_TO_BE_REMINDED';
export const SET_RESTORE_EMAIL_SENT = 'SET_RESTORE_EMAIL_SENT';
export const SET_SHOW_REFERRAL_MODAL = 'SET_SHOW_REFERRAL_MODAL';
export const INIT_USER_DETAILS = 'INIT_USER_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const UPDATE_PASSWORD_DETAILS = 'UPDATE_PASSWORD_DETAILS';
export const SET_USER_DETAIL_ERRORS = 'SET_USER_DETAIL_ERRORS';
export const SET_PASSWORD_ERRORS = 'SET_PASSWORD_ERRORS';
export const RESET_PASSWORD_DETAILS = 'RESET_PASSWORD_DETAILS';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const SAML_LOGIN_REDIRECT_SET = 'AUTH/SAML_LOGIN_REDIRECT_SET';
export const NOTIFICATION_SET = 'AUTH/NOTIFICATION_SET';
export const SET_EXPLORER_UPGRADE_MODAL = 'SET_EXPLORER_UPGRADE_MODAL';

export const CASES_START = 'CASES_START';
export const CASES_SESSION_START = 'CASES_SESSION_START';
export const CASES_LOADING = 'CASES_LOADING';
export const CASES_LOADED = 'CASES_LOADED';
export const CASES_CONFIRMED_LOADED = 'CASES_CONFIRMED_LOADED';
export const CASES_LOADING_ERROR = 'CASES_LOADING_ERROR';
export const CASES_CREATE_SESSION_ID = 'CASES_CREATE_SESSION_ID';
export const CASES_SET_RUBRIC_STATS = 'CASES_SET_RUBRIC_STATS';
export const CASES_SET_CURRENT = 'CASES_SET_CURRENT';
export const CASES_SET_SCORE = 'CASES_SET_SCORE';
export const CASES_SET_HINTS = 'CASES_SET_HINTS';
export const CASES_SET_HINT_VISIBLE = 'CASES_SET_HINT_VISIBLE';
export const CASES_SET_HINT_REVEALED = 'CASES_SET_HINT_REVEALED';
export const CASES_SET_FOLLOW_UP_QUESTIONS = 'CASES_SET_FOLLOW_UP_QUESTIONS';
export const CASES_SET_RUBRICS = 'CASES_SET_RUBRICS';
export const TIMER_COMPLETE = 'TIMER_COMPLETE';
export const CASES_SET_POINTS_LABEL = 'CASES_SET_POINTS_LABEL';
export const CASES_INITIALIZE = 'CASES_INITIALIZE';
export const CASES_SET_FILTERED = 'CASES_SET_FILTERED';
export const ALL_CASES_SET_FILTERED = 'ALL_CASES_SET_FILTERED';
export const CASES_QUESTIONS_INFO = 'CASES_QUESTIONS_INFO';
export const CASES_SET_CASES = 'CASES_SET_CASES';
export const CASES_SET_ALL = 'CASES_SET_ALL';
export const ALL_CASES_INITIALIZE = 'ALL_CASES_INITIALIZE';
export const SEARCH_CASES_SET = 'SEARCH_CASES_SET';
export const PWF_CASES_SET = 'PWF_CASES_SET';
export const PWF_CASES_FILTERED_SET = 'PWF_CASES_FILTERED_SET';
export const CASES_SET_STATS = 'CASES_SET_STATS';
export const CASES_SET_PERCENTILE = 'CASES_SET_PERCENTILE';
export const WANT_TO_NAVIGATE_TO = 'WANT_TO_NAVIGATE_TO';
export const SET_FETCHING_STATE = 'SET_FETCHING_STATE';
export const DELETE_LOADING_MESSAGE = 'DELETE_LOADING_MESSAGE';
export const QUESTION_ANSWER_UPDATED = 'QUESTION_ANSWER_UPDATED';
export const CASE_JOURNEY_SET = 'CASE/CASE_JOURNEY_SET';
export const CASE_JOURNEY_ID_SET = 'CASE/CASE_JOURNEY_ID_SET';
export const CASE_SCORE_SET = 'CASE/CASE_SCORE_SET';

// message
export const CREATE_MESSAGE = 'MESSAGE/CREATE_MESSAGE';
export const CLEAR_MESSAGES = 'MESSAGE/CLEAR_MESSAGES';
export const MESSAGES_SET = 'MESSAGE/MESSAGES_SET';
export const DELETE_COMPOUND_MESSAGE = 'MESSAGES/DELETE_COMPOUND_MESSAGE';
export const COMPOUND_SELECTED_OPT_SET = 'MESSAGES/COMPOUND_SELECTED_OPT_SET';
export const MESSAGES_READ = 'MESSAGES/MESSAGES_READ';

// case
export const SET_RECOMMENDED_CASES = 'SET_RECOMMENDED_CASES';
export const RECOMMENDED_CASES = 'RECOMMENDED_CASES';
export const SET_MODE_SELECTION_MODAL = 'SET_MODE_SELECTION_MODAL';
export const SET_SELECTED_CASE = 'SET_SELECTED_CASE';
export const COMPLETED_CASES_LOADED = 'CASE/COMPLETED_CASES_LOADED';
export const SESSION_EXPIRED = 'CASE/SESSION_EXPIRED';
export const GUIDE_MODE_SET = 'GUIDE_MODE_SET';
export const AUTO_PLAY_AUDIO = 'AUTO_PALY_AUDIO';
export const FINISHED_CASE_SET = 'CASE/FINISHED_CASE_SET';
export const SESSIONS_ADDED = 'CASES/SESSIONS_ADDED';
export const CASE_UNLOCKED = 'CASES/CASE_UNLOCKED';
export const CHRONO_MARKED_SET = 'CASES/CHRONO_MARKED_SET';
export const UNLOCKED_CASE_SET = 'CASES/UNLOCKED_CASE_SET';
export const DIAGNOSIS_SCORE_SET = 'CASES/DIAGNOSIS_SCORE_SET';
export const CASES_COLLECTIONS_SET = 'CASES/CASES_COLLECTIONS_SET';
export const FILTERED_CASES_COLLECTIONS_SET = 'FILTERED_CASES_COLLECTIONS_SET';

// checkout
export const SHOW_CHECKOUT_MODAL = 'SHOW_CHECKOUT_MODAL';
export const HIDE_CHECKOUT_MODAL = 'HIDE_CHECKOUT_MODAL';
export const CHECKOUT_SET_PLANS = 'CHECKOUT_SET_PLAN';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const SET_CHECKOUT_BUTTON_STATE = 'SET_CHECKOUT_BUTTON_STATE';
export const SET_CHECKOUT_COMPLETE = 'SET_CHECKOUT_COMPLETE';
export const DEFAULT_PAYMENT_SET = 'CHECKOUT/DEFAULT_PAYMENT_SET';
export const PLAN_UPDATED = 'CHECKOUT/PLAN_UPDATED';

// dashboard
export const SET_PRESENTATION_FILTER = 'SET_PRESENTATION_FILTER';
export const SET_SYSTEM_FILTER = 'SET_SYSTEM_FILTER';
export const SET_LEVEL_FILTER = 'SET_LEVEL_FILTER';
export const SET_SYSTEM_AVERAGE_SELECTED = 'SET_SYSTEM_AVERAGE_SELECTED';
export const SET_SYSTEM_AVERAGE_FILTER = 'SET_SYSTEM_AVERAGE_FILTER';
export const SET_MODAL_TO_SHOW = 'SET_MODAL_TO_SHOW';
export const SET_SELECTED_CASE_INDEX = 'SET_SELECTED_CASE_INDEX';
export const SET_SELECTED_CASE_SECTION = 'SET_SELECTED_CASE_SECTION';
export const SET_INSENTIVE_MODAL = 'SET_INSENTIVE_MODAL';
export const SET_UPGRADE_MODAL = 'DASHBOARD/_SET_UPGRADE_MODAL';
export const UPGRADE_MODAL_STEP_FLAG_SET = 'DASHBOARD/UPGRADE_MODAL_STEP_FLAG_SET';

// account
export const SHOW_BILLING_EDIT_MODAL = 'SHOW_BILLING_EDIT_MODAL';
export const HIDE_BILLING_EDIT_MODAL = 'HIDE_BILLING_EDIT_MODAL';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_SHOW_LOGOUT_MODAL = 'SET_SHOW_LOGOUT_MODAL';

// referral
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';
export const SET_INVITED_USERS = 'SET_INVITED_USERS';
export const SET_PETITION = 'SET_PETITION';

// home
export const SET_INVITED_REFERRAL_CODE = 'SET_INVITED_REFERRAL_CODE';
export const SET_INVITED_REFERRAL_CODE_VALID = 'SET_INVITED_REFERRAL_CODE_VALID';

// verify
export const SET_VERIFIED = 'SET_VERIFIED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const SKIP_VERIFY = 'AUTH/SKIP_VERIFY';

// Practice with Friends
export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE';
export const SET_MODE = 'SET_MODE';
export const SET_EXIT_MODAL = 'SET_EXIT_MODAL';
export const SET_CASE = 'SET_CASE';
export const SELECTED_ROLE_SET = 'SELECTED_ROLE_SET';
export const OCCUPIED_ROLES_SET = 'OCCUPIED_ROLES_SET';
export const PWF_INFO_SET = 'PWF_INFO_SET';
export const MARKING_RUBRIC_SET = 'MARKING_RUBRIC_SET';
export const CASE_INFO_SET = 'CASE_INFO_SET';
export const MARKING_RUBRIC_UPDATED = 'MARKING_RUBRIC_UPDATED';
export const MARKER_STEP_UPDATED = 'MARKER_STEP_UPDATED';
export const MARKER_QUESTION_UPDATED = 'MARKER_QUESTION_UPDATED';
export const MARKER_OVERALL_UPDATED = 'MARKER_OVERALL_UPDATED';
export const MARKER_OVERALL_LOADED = 'MARKER_OVERALL_LOADED';
export const SET_SUBMIT_CASE_MODAL = 'SET_SUBMIT_CASE_MODAL';
export const SET_MARKER_NOT_SUBMIT_MODAL = 'SET_MARKER_NOT_SUBMIT_MODAL';
export const SET_HOST_NOT_START_MODAL = 'SET_HOST_NOT_START_MODAL';
export const SET_ERROR_MODAL = 'SET_ERROR_MODAL';
export const MISSING_SWITCH_SET = 'MISSING_SWITCH_SET';
export const SESSION_CHRONO_SET = 'SESSION_CHRONO_SET';

// Conditions
export const CONDTIION_SELECTED = 'CONDITION/CONDTIION_SELECTED';
export const ALL_QUIZZES_SET = 'CONDITION/ALL_QUIZZES_SET';
export const RECOMMENDED_QUIZZES_SET = 'CONDITION/RECOMMENDED_QUIZZES_SET';
export const RECOMMENDED_QUIZ_SELECTED = 'CONDITION/RECOMMENDED_QUIZ_SELECTED';
export const FILTERED_QUIZZES_SET = 'CONDITION/FILTERED_QUIZZES_SET';

// Task
export const TAST_LOADED = 'TASK/TAST_LOADED';
export const EASTER_EGG_UPDATED = 'TASK/EASTER_EGG_UPDATED';
export const GENERAL_TASK_UPDATED = 'TASK/GENERAL_TASK_UPDATED';
export const ALL_TASK_COMPLETED_SET = 'TASK/ALL_TASK_COMPLETED_SET';
// Dashboard
export const TASK_SET = 'TASK/TASK_SET';
export const TASK_MODAL_SET = 'TASK/TASK_MODAL_SET';
export const SET_PROFILE_POPOVER = 'SET_PROFILE_POPOVER';

// Learn Mode
export const LEARN_LIST_SET = 'LEARN/LEARN_LIST_SET';
export const LEARN_INDEX_SET = 'LEARN/LEARN_INDEX_SET';
export const LEARN_MODE_SESSION_ID_SET = 'LEARN/LEARN_MODE_SESSION_ID_SET';
export const LEARN_MODE_NEXT_QUESTION_SET = 'LEARN/LEARN_MODE_NEXT_QUESTION_SET';
export const LEARN_MODE_LAST_RESPONSE_SET = 'LEARN/LEARN_MODE_LAST_RESPONSE_SET';
export const LEARN_MODE_SESSION_SET = 'LEARN/LEARN_MODE_SESSION_SET';
export const FILTERED_LEARNS_SET = 'LEARN/FILTERED_LEARNS_SET';
export const CAN_NEXT_MODULE_SET = 'LEARN/CAN_NEXT_MODULE_SET';

// Diagnosis
export const DIAGNOSIS_LIST_SET = 'DIAGNOSIS/DIAGNOSIS_LIST_SET';
export const DIAGNOSIS_SESSION_SET = 'DIAGNOSIS/DIAGNOSIS_SESSION_SET';
export const DIAGNOSIS_START_SET = 'DIAGNOSIS/DIAGNOSIS_START_SET';

// Home Jobs
export const JOBS_SET = 'HOME/JOBS_SET';
export const JOBS_QUANTITY_SET = 'HOME/JOBS_QUANTITY_SET';
