import { makeStyles } from '@material-ui/core/styles';
import tourBackground from 'assets/userTour/mobile-tour-background.png';
import SectionBG from '../../assets/home/section-hero.svg';
import TeamBG from '../../assets/home/swipe-right-bg.svg';

const useStyles = makeStyles((theme) => ({
  Jobs__section: {
    maxWidth: 1920,
    margin: '106px  auto 120px auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: 39,
      marginBottom: 40,
    },
  },
  Jobs__section__main: {
    overflow: 'hidden',
  },
  Jobs__tag: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.6,
  },
  Jobs__joinus: {
    marginBottom: 40,
  },
  Jobs__oddSection: {
    background: `url(${SectionBG})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  Jobs__benefitSection: {
    background: '#ecf2ff',
  },
  Jobs__container__outer: {
    overflow: 'hidden',
  },
  Jobs__container__inner: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  Jobs__showCaseWrapper: {
    // background: theme.palette.inherit.main,
    position: 'relative',
  },
  Jobs__container: {
    display: 'flex',
  },
  Jobs__container__left: {
    flex: 1,
    marginRight: 60,
  },
  Jobs__container__right: {
    flex: 1,
  },
  Jobs__firstContainer: {
    paddingTop: 85,
    paddingBottom: 100,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  Jobs__firstContainer__bottom: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  Jobs__firstContainer__btn: {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.lightDark,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  Jobs__title: {
    maxWidth: 720,
    marginBottom: 50,
    lineHeight: 1.5,
    textAlign: 'center',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    },
  },
  Jobs__title__values: {
    marginBottom: 40,
    lineHeight: 1.5,
    textAlign: 'center',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  Jobs__subtitle: {
    textAlign: 'center',
    zIndex: 1,
    marginBottom: 40,
    maxWidth: 1100,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  Jobs__overlay1: {
    position: 'absolute',
    left: 0,
    bottom: 450,
    maxWidth: 170,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__overlay2: {
    position: 'absolute',
    right: 0,
    top: 300,
    maxWidth: 170,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__overlay3: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__overlay4: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: 170,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__overlay5: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__overlay6: {
    position: 'absolute',
    top: 0,
    left: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__overlay7: {
    position: 'absolute',
    bottom: 100,
    right: 0,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Jobs__img: {
    width: '100%',
    zIndex: '1',
    [theme.breakpoints.down('xs')]: {
      width: '110%',
    },
  },
  Jobs__team: {
    paddingTop: 20,
    flexDirection: 'column',
    alignItems: 'center',
  },
  Jobs__team__benefits: {
    paddingTop: 50,
    flexDirection: 'column',
    alignItems: 'center',
  },
  Jobs__lastSection: {
    marginBottom: 0,
    paddingBottom: 60,
  },
  Jobs__opportunities: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 640,
    maxWidth: '100%',
    textAlign: 'center',
  },
  Jobs__opportunities__helperText: {
    color: theme.palette.text.main,
    fontSize: 18,
  },
  Jobs__btn: {
    padding: '25px 30px',
    fontSize: 18,
    background: '#9dc9f6',
    color: theme.palette.text.main,
    borderRadius: 38,
    boxShadow: '0 1px 6px 0 rgba(0, 35, 72, 0.15)',
    width: '100%',
    letterSpacing: 0,
    justifyContent: 'space-between',
    marginBottom: 20,
    alignSelf: 'stretch',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
    '&:hover': {
      color: 'grey',
    },
  },
  Jobs__btn__label: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.44,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'left',
    },
  },
  Jobs__btn__location: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      fontSize: 15,
    },
  },
  Jobs__email: {
    textDecoration: 'underline',
    color: theme.palette.text.main,
  },
  Jobs__benefitsSection: {
    background: theme.palette.inherit.main,
    marginBottom: 0,
  },
  Jobs_benefitsContainer: {
    paddingTop: 60,
    paddingBottom: 80,
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 30,
      paddingBottom: 40,
    },
  },
  Jobs__benefitLists: {
    display: 'flex',
    justifyContent: 'space-evenly',
    textAlign: 'left',
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  Jobs_benefitLists_ul: {
    listStyle: 'none',
    zIndex: 1,
    marginBottom: 0,
  },
  Jobs_benefitLists_li: {
    marginBottom: 20,
    '&:before': {
      content: "'•'",
      color: theme.palette.primary.main,
      fontSize: 18,
      marginRight: 14,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      fontSize: 16,
    },
  },
  Jobs__imageShowcase: {
    marginBottom: 120,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 60,
    },
  },
  Jobs__join: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  Jobs__join__text: {
    fontSize: 30,
    lineHeight: 1.5,
    color: '#919aa3',
    flex: 1,
    marginRight: 60,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      textAlign: 'center',
      fontSize: 17,
      marginBottom: 20,
    },
  },
  Jobs__join__img: {
    maxWidth: 520,
    boxShadow: '0 0px 0px 0px rgba(0, 35, 72, 0.06)',
    borderRadius: 16,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  Jobs__job__title: {
    marginBottom: 40,
  },
  Jobs__values__main: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  Jobs__showcase__root: {
    padding: 40,
    [theme.breakpoints.down('xs')]: {
      padding: 15,
    },
  },
  Jobs__showcase__image: {
    height: '250px',
    width: '460px',
    boxShadow: '0 0px 0px 0px rgba(0, 35, 72, 0.06)',
    borderRadius: 16,
  },
  Jobs__showcase__title: {
    fontSize: 30,
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.text.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      marginBottom: 5,
    },
  },
  Jobs__showcase__subtitle: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      lineHeight: 1.38,
    },
  },
  Jobs__benefits: {
    background: `url(${tourBackground})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  Jobs__job: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  Jobs__job__category: {
    alignSelf: 'flex-start',
    fontSize: 30,
    fontWeight: 500,
    color: theme.palette.text.main,
    lineHeight: 1.5,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  Jobs__job__wrapper: {
    width: '100%',
    position: 'relative',
  },
  Jobs__btn__slice: {
    top: 0,
    left: 0,
    position: 'absolute',
    width: 15,
    height: '100%',
  },
}));

export default useStyles;
