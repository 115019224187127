import React from 'react';
import PropTypes from 'prop-types';

const IconTick = (props) => {
  const { size = 20, ...restProps } = props;

  return (
    <svg width={size} height={size} version="1.1" {...restProps}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <g stroke="#83D0F0" strokeWidth="1.88">
          <g>
            <g>
              <path
                d="M19.982 8.627v.872c-.003 4.198-2.904 7.895-7.132 9.086-4.227 1.19-8.773-.41-11.173-3.932-2.4-3.522-2.08-8.127.784-11.319C5.325.143 10.052-.875 14.079.834"
                transform="translate(-1393 -715) translate(1104 389) translate(290 327)"
              />
              <path
                d="M19.982 1.915L10.028 11.404 7.042 8.56"
                transform="translate(-1393 -715) translate(1104 389) translate(290 327)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconTick.propTypes = {
  size: PropTypes.number,
};

export default IconTick;
