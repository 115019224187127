// User Groups
const uniGroup = ['unimelb', 'sydney univeristy'];
const recommendOnlyGroup = ['unimelb', 'dailytask'];
// Features

export const featureToogleByUser = (feature, user) => {
  switch (feature) {
    case 'unimelbCaseUIToggle':
      return uniGroup.includes(user);

    case 'unimelbRubricUIToggle':
      return uniGroup.includes(user);

    case 'unimelbHistoryUIToggle':
      return uniGroup.includes(user);

    default:
      return true;
  }
};

export const caseJourneyToRecommendToogle = (userDomain) => {
  return recommendOnlyGroup.includes(userDomain);
};
