import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Teams__hidden: {
    visibility: 'hidden',
  },
  Teams__teams: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
}));

export default useStyles;
