import React from 'react';
import PropTypes from 'prop-types';

const IconHint = props => {
  const { color = 'white', size = 25, className, ...restProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 45 47" version="1.1" className={className}>
      <g id="assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="new-icons-light-dark" transform="translate(-309.000000, -638.000000)">
          <g id="hint-icon-dark" transform="translate(310.000000, 639.000000)">
            <g id="Group-3" transform="translate(5.000000, 6.000000)">
              <path
                d="M12.5097,0.3672 C5.9167,0.3672 0.5717,5.7132 0.5717,12.3052 C0.5717,17.2002 3.5197,21.4012 7.7347,23.2452 L7.7347,31.0242 C7.7347,32.1152 8.6187,33.0002 9.7097,33.0002 L16.1067,33.0002 C17.0427,33.0002 17.8217,32.3462 18.0267,31.4722 L14.8857,31.4722 C14.3327,31.4722 13.8857,31.0252 13.8857,30.4722 C13.8857,29.9202 14.3327,29.4722 14.8857,29.4722 L18.0817,29.4722 L18.0817,29.0932 L14.0167,29.0932 C13.4637,29.0932 13.0167,28.6452 13.0167,28.0932 C13.0167,27.5402 13.4637,27.0932 14.0167,27.0932 L18.0817,27.0932 L18.0817,26.7082 L13.3127,26.7362 L13.3067,26.7362 C12.7567,26.7362 12.3097,26.2932 12.3067,25.7422 C12.3037,25.1902 12.7477,24.7392 13.3007,24.7362 L18.0817,24.7082 L18.0817,22.8612 C21.8667,20.8592 24.4487,16.8872 24.4487,12.3052 C24.4487,5.7132 19.1037,0.3672 12.5097,0.3672"
                id="Fill-1"
                fill={color}
                mask="url(#mask-2)"
              ></path>
            </g>
            <line
              x1="17.5097"
              y1="3.9795"
              x2="17.5097"
              y2="0.0005"
              id="Stroke-4"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="2.6923"
              y1="28.5674"
              x2="5.4303"
              y2="25.6794"
              id="Stroke-6"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="28.1621"
              y1="8.6689"
              x2="30.8991"
              y2="5.7809"
              id="Stroke-8"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="32.4912"
              y1="28.5674"
              x2="29.7542"
              y2="25.6794"
              id="Stroke-10"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="7.0224"
              y1="8.6689"
              x2="4.2844"
              y2="5.7809"
              id="Stroke-12"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="31.8369"
              y1="16.3164"
              x2="35.8159"
              y2="16.3164"
              id="Stroke-14"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="0"
              y1="16.3164"
              x2="3.979"
              y2="16.3164"
              id="Stroke-16"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconHint.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconHint;
