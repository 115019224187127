import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { TextField, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { signIn, clearAuthErrors } from '../../../actions/auth';
import SignIn from '../../../components/SignIn';
import useStyles from './style';
import iconOscerSignIn from '../../../assets/character/character-signup-lookdown.svg';

const SignInBox = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.SignIn__wrapper}>
      <div className={classes.SignIn}>
        <img src={iconOscerSignIn} alt="oscer__icon__signIn" className={classes.SignIn__icon__signIn} />
        <SignIn {...props} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  invitedReferralCode: state.home.invitedReferralCode,
  authError: state.auth.error,
});

SignInBox.propTypes = {
  location: PropTypes.object,
  signIn: PropTypes.func,
  history: PropTypes.object,
  setSignUp: PropTypes.func,
  clearAuthErrors: PropTypes.func,
  authError: PropTypes.string,
};

export default withRouter(connect(mapStateToProps, { signIn, clearAuthErrors })(SignInBox));
