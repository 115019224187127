import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { createMessage, compoundSelectedOptSet } from 'actions/messages';
import { requestResponse } from 'actions/cases';
import userNameGenerator from 'helpers/userNameGenerator';
import iconOscerProfessor from 'assets/character/oscer-professor.svg';
import { OUTGOING_MESSAGE, INCOMING_MESSAGE, OSCE_HISOTRY, LEARN_MODE } from '../../../constants';
import './style.scss';

/**
 * @desc Message send out from user, include avatar and text content
 * @param { message, user } props
 * - message is user input
 * - user is an object with user detials
 */
const MessageOut = (props) => {
  const { message, user, msgIndex, onMessageSent } = props;
  const [animated, setAnimiated] = useState(false);

  const isUserMessage = !message?.status || message?.status === 'sent';
  const [compoundDisabled, setCompoundDisabled] = useState(false);

  const handleCompoundQs = (msg, key, mode = OSCE_HISOTRY) => {
    setCompoundDisabled(true);
    props.compoundSelectedOptSet(key);
    const currentDate = new Date().toString();
    props.createMessage(OUTGOING_MESSAGE, msg, currentDate);
    switch (mode) {
      case LEARN_MODE:
        onMessageSent(msg);
        break;
      case OSCE_HISOTRY:
        props
          .requestResponse(msg)
          // .then(() => updateScroll())
          .catch(() =>
            props.createMessage(
              INCOMING_MESSAGE,
              {
                response_type: 'normal',
                response: 'Sorry, Oscer is diagnosing himself, please wailt for a while and try again latter',
                current_score: 0,
                points_earned: 0,
                label: '',
                response_status: 'failure',
                guide_msg: '',
              },
              currentDate
            )
          );
        break;
      default:
        break;
    }
  };

  const CompoundQuestionsSelector = (msg) => {
    // OSCE Hisotry Message
    if (msg?.status === 'compound') {
      return (
        <div className={classnames({ animated }, 'message-out__compound')}>
          <div className="message-out__compound__msg">
            {Object.entries(msg.options).map(([key, value]) => (
              <Button
                key={key}
                disabled={msg.selectedOpt || msgIndex + 1 < props.messagesList.length}
                variant="contained"
                onClick={() => handleCompoundQs(value, key)}
                classes={{
                  contained: classnames('message-out__compound__btn', {
                    'message-out__compound__btn-active': msg.selectedOpt === key,
                  }),
                }}
              >
                {value}
              </Button>
            ))}
          </div>
        </div>
      );
    }
    // Learn Mode Message
    if (msg?.text?.response_status === 'compound') {
      return (
        <div className={classnames({ animated }, 'message-out__compound')}>
          <div className="message-out__compound__msg">
            {msg.text.response.map((message) => (
              <Button
                key={msg.date}
                disabled={compoundDisabled || msgIndex + 1 < props.messagesList.length}
                variant="contained"
                onClick={() => handleCompoundQs(message, msg.date, LEARN_MODE)}
                classes={{
                  contained: classnames('message-out__compound__btn', {
                    'message-out__compound__btn-active': msg.selectedOpt === msg.date,
                  }),
                }}
              >
                {message}
              </Button>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className={classnames('message-out', { animated }, { 'message-out__guide': !isUserMessage })}>
        <p>{typeof message.text === 'string' ? message.text : message.text?.guide_msg || message.text?.attention}</p>
        {isUserMessage ? (
          <Avatar size={35} className="message-out-avatar">
            {userNameGenerator(user).charAt(0).toUpperCase()}
          </Avatar>
        ) : (
          <img src={iconOscerProfessor} alt="oscer_professor" className="message-out__icon" />
        )}
      </div>
      {CompoundQuestionsSelector(message)}

      {message?.status === 'learn_mode_intro' && (
        <div className="message-out__learnMode__intro">Oscer Instructions</div>
      )}
    </>
  );
};

// use memo to reduce rerender
const arePropsEqual = (prevProps, nextProps) => prevProps.message.text === nextProps.message.text;

const mapStateToProps = (state) => ({
  // isGuideMode: state.cases.isGuideMode,
  messagesList: state.messages.messages,
});

MessageOut.propTypes = {
  message: PropTypes.object.isRequired,
  user: PropTypes.object,
  createMessage: PropTypes.func,
  // isGuideMode: PropTypes.bool,
  requestResponse: PropTypes.func,
  msgIndex: PropTypes.number,
  compoundSelectedOptSet: PropTypes.func,
  onMessageSent: PropTypes.func,
};

export default connect(mapStateToProps, {
  createMessage,
  requestResponse,
  compoundSelectedOptSet,
})(memo(MessageOut, arePropsEqual));
