import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TextField } from '@material-ui/core/';
import useStyles from './style';
import { postMailchimpJourney } from '../../../actions/checkout';

//  interface for interate Stripe with MUI
const StripeInputComponent = (props) => {
  const { component: Component, inputRef, ...other } = props;

  // implement `InputElement` interface
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
      // inputRef.focus();
    },
    // hiding the value e.g. react-stripe-elements
  }));

  // `Component` will be your `SomeThirdPartyComponent` e.g. CardNumberElement from below
  return <Component {...other} />;
};

StripeInputComponent.propTypes = {
  component: PropTypes.any,
  inputRef: PropTypes.func,
};

const StripeTextField = (props) => {
  const { component, label, id, endAdornment, setCardType, setCompleted } = props;
  const [focused, setFocused] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [error, setError] = useState(false);
  const cs = useStyles();

  const handleChange = (changeObj) => {
    if (id === 'stripe-card-number') {
      setCardType(changeObj.brand);
      const args = {
        journey: 'cart_abandonment_journey',
      };
      if (process.env.REACT_APP_STAGE === 'production') {
        postMailchimpJourney(args);
      }
    }

    // we can't access the card value due to security restriction from Stripe
    setEmpty(changeObj.empty);
    setCompleted(changeObj.complete);
    setError(changeObj.error);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <TextField
      className={cs.StripeTextField}
      id={id}
      label={label}
      helperText={error && error.message}
      InputProps={{
        inputComponent: StripeInputComponent,
        inputProps: { component },
        placeholder: '',
        onFocus: handleFocus,
        onBlur: handleBlur,
        onChange: handleChange,
        endAdornment,
      }}
      InputLabelProps={{
        shrink: focused || !empty,
        focused,
        error: !!error,
      }}
    />
  );
};

StripeTextField.propTypes = {
  component: PropTypes.any,
  label: PropTypes.string,
  id: PropTypes.string,
  endAdornment: PropTypes.node,
  setCardType: PropTypes.func,
  setCompleted: PropTypes.func,
};

export default StripeTextField;
