import produce from 'immer';
import {
  TASK_SET,
  TASK_MODAL_SET,
  EASTER_EGG_UPDATED,
  GENERAL_TASK_UPDATED,
  ALL_TASK_COMPLETED_SET,
} from '../actions/types';

const initialState = {
  // 2 types of tasks: general and easter egg. Only unclaimed easter egg tasks will bi saved in unclaimed tasks
  tasks: { general_tasks: [], unclaimed_tasks: [], all_tasks: [] },
  taskModal: {
    isToggle: false,
    heading: 'Yay you just shared Oscer in Facebook!',
    subheading: 'So 5 days FREE PRIME are yours!',
  },
};

const taskReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TASK_MODAL_SET:
        return {
          ...state,
          taskModal: {
            ...state.taskModal,
            ...action.value,
          },
        };

      case TASK_SET:
        return { ...state, tasks: action.tasks };

      case EASTER_EGG_UPDATED:
        draft.tasks.unclaimed_tasks[action.index].is_showed = action.value;
        break;

      case GENERAL_TASK_UPDATED:
        draft.tasks.general_tasks[action.index].is_showed = action.value;
        // draft.tasks.general_tasks[action.index].is_showed = action.value.is_showed;
        // draft.tasks.general_tasks[action.index].is_reward_claimed = action.value.is_reward_claimed;
        break;

      case ALL_TASK_COMPLETED_SET:
        draft.tasks.all_tasks[action.index].is_completed = true;
        break;

      default:
        return state;
    }
  });

export default taskReducer;
