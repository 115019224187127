import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { patchEmailConfirm, getUserProfile } from '../../actions/auth';
import OscerLoader from '../../components/loader/OscerLoader';
import imgVerified from '../../assets/verify/verified.svg';
import imgNotVerified from '../../assets/verify/specialty.svg';
import useStyles from './style';

const Verification = (props) => {
  const cs = useStyles();
  const [message, setMessage] = useState('');
  const [verifyState, setVerifyState] = useState('initialise');
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    const activationKey = props.match.params.token;
    setIsWaiting(true);
    props
      .patchEmailConfirm(activationKey)
      .then(() => {
        setVerifyState('success');
        setMessage('Verification  successful.');
        // Only update user info if user authenticate, if not redirect user to login
        if (props.isAuthenticated) {
          props.getUserProfile();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setMessage('Verification failed. Code not match.');
        } else if (typeof err?.response?.data === 'string') {
          setMessage(err.response.data);
        } else {
          setMessage('Sorry there is something wrong with the verification');
        }
        setVerifyState('failed');
      })
      .then(() => {
        setIsWaiting(false);
      });
  }, []);

  return (
    <div className={cs.Verification}>
      <div className={cs.Verification__main}>
        {isWaiting && (
          <div>
            <OscerLoader />
            <div className={cs.Verification__whiteWrapper}>
              <div className={cs.Verification__text}>Oscer is verfiying your account...</div>
            </div>
          </div>
        )}
        {verifyState === 'success' && (
          <>
            <img src={imgVerified} alt="verified" className={cs.Verification__img} />
            <div className={cs.Verification__title}>Verification is complete</div>
            <div className={cs.Verification__subtitle}>Your accound has been successfully verified.</div>
          </>
        )}
        {verifyState === 'failed' && (
          <>
            <img src={imgNotVerified} alt="not_verified" className={cs.Verification__img} />
            <div className={cs.Verification__title}>{message}</div>
            <div className={cs.Verification__subtitle}>Please try again or use verify code.</div>
          </>
        )}
        {!isWaiting && (
          <Button
            color="primary"
            classes={{ root: cs.Verification__bth }}
            variant="contained"
            component={Link}
            to={verifyState === 'success' && props.isAuthenticated ? '/dashboard/home/' : '/signin'}
          >
            {verifyState === 'success' && props.isAuthenticated ? 'Go to Dashboard' : 'Login'}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

Verification.propTypes = {
  match: PropTypes.object,
  patchEmailConfirm: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  getUserProfile: PropTypes.func,
};

// const mapStateToProps = (state) => ({
// });

export default connect(mapStateToProps, { patchEmailConfirm, getUserProfile })(Verification);
