import React from 'react';
import PropTypes from 'prop-types';
import StarRating from 'components/StarRating';
import quatationIcon from 'assets/common/hp-review-quotation-icon.svg';
import useStyles from './style';

const ReviewCard = (props) => {
  const classes = useStyles();
  const { stars, review, name, role } = props;

  // Shotter long review
  const paragraphOmit = (string, limit = 1) => {
    const temp = string.split(' ');
    if (temp.length < limit) {
      return string;
    }
    return `${string.split(' ').slice(0, limit).join(' ')}...`;
  };

  return (
    <div className={classes.ReviewCard}>
      <div className={classes.ReviewCard__wrapper}>
        <div className={classes.ReviewCard__main}>
          <img src={quatationIcon} alt="quatation" className={classes.ReviewCard__qutation} />
          <StarRating
            total={5}
            starClass={classes.ReviewCard__star}
            groupClass={classes.ReviewCard__star__group}
            rate={5}
          />
          <div className={classes.ReviewCard__review}>{paragraphOmit(review, 30)}</div>
        </div>
        <div className={classes.ReviewCard__foot}>
          <div className={classes.ReviewCard__separator} />
          <div className={classes.ReviewCard__name}>{name}</div>
          <div className={classes.ReviewCard__role}>{role}</div>
        </div>
      </div>
    </div>
  );
};

ReviewCard.propTypes = {
  stars: PropTypes.number,
  review: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
};

export default ReviewCard;
