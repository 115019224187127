import { useEffect, useState, useMemo } from 'react';
import * as Sentry from '@sentry/react';

const useAudio = (audioURL, autoPlay = false) => {
  const audio = useMemo(() => new Audio(audioURL), [audioURL]);
  // const [audio] = useState(new Audio(audioURL));
  // const audio = new Audio(audioURL);
  const [isPlaying, setPlaying] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [isPlaying]);

  // Determine audio file has ended and set playing to false
  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  // Determine audio file has loaded to allow playing
  useEffect(() => {
    // Fix ios not invoke canplay api
    audio.load();
    audio.addEventListener('canplay', () => {
      try {
        if (autoPlay) {
          audio.play();
        }
      } catch (error) {
        Sentry.captureException(error, {
          tags: {
            section: 'audio',
          },
        });
      }

      // setLoaded(true);
    });

    return () => {
      audio.pause();
    };
  }, []);

  const audioToggle = () => setPlaying(!isPlaying);

  return [isPlaying, isLoaded, audioToggle];
};

export default useAudio;
