import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Tour from 'reactour';
import * as Sentry from '@sentry/react';
import * as caseActions from '../../actions/cases';
import { setMessages, createMessage } from '../../actions/messages';
import { INCOMING_MESSAGE, PRESENTATION_ICON_XLARGE, EXAM_LEARN_MODE, SYSTEM_COLORS } from '../../constants';
import ErrorModal from '../../components/Modal/ErrorModal';
import isEmptyObject from '../../helpers/emptyObject';

import { trackPage, trackEvent } from '../../service/analytics';
// import components for fill layout
import TimerHeader from './TimerHeader';
import MainArea from './MainArea';
import RightSidebar from './RightSideBar';
// import modals
import BeginCaseModal from './Modals/BeginCaseModal';
import ExitModal from '../../components/Modal/ExitModal';
import TimeoutModal from './Modals/TimeoutModal';
import AudioBlockedModal from './Modals/AudioBlockedModal';
import AudioIssueModal from './Modals/AudioIssueModal';
import useUserTour from '../../hook/useUserTour';
import userTourSteps from './UserTourSteps/UserTourSteps';
import OscerLoader from '../../components/loader/OscerLoader';
import SubmitCaseModal from './Modals/SubmitCaseModal';
import PatientInfoModal from './Modals/PatientInfoModal';

import './case.scss';

// Define modal static
const BEGIN_CASE_MODAL = 'BEGIN_CASE_MODAL';
const EXIT_CASE_MODAL = 'EXIT_CASE_MODAL';
const HINT_MODAL = 'HINT_MODAL';
const TIMEOUT_CASE_MODAL = 'TIMEOUT_CASE_MODAL';
const SESSION_EXPIRED_MODAL = 'SESSION_EXPIRED_MODAL';
const AUDIO_BLOCKED_MODAL = 'AUDIO_BLOCKED_MODAL';
const AUDIO_ISSUE_MODAL = 'AUDIO_ISSUE_MODAL';

const Case = (props) => {
  const { currentCase, stopSession, setTimerComplete, wantToNavigateTo, wantingToNavigate } = props;
  const [modalToggle, setModalToggle] = useState(BEGIN_CASE_MODAL);
  const [error, setError] = useState('');
  const [mode, setMode] = useState('LEARN MODE');
  const [loading, setLoading] = useState(false);
  // Exit modal toggle
  const [isToggle, setToggle] = useState(false);
  // Patient info toggle
  const [isInfoToggle, setInfoToggle] = useState(false);

  // User Tour
  const [isTourOpen, setTourOpen, handleTourClosed] = useUserTour('case', 'osce_history_flag');
  const [tourPage, setTourPage] = useState(0);
  const [panelHidden, setPanelHidden] = useState(false);

  const query = new URLSearchParams(props.location.search);
  let sessionId = query.get('session') || props.sessionId || '';

  // Remove the oldest sessions if localstorage has reach 10
  // Get localstroage session id and time
  const localStroageSessions = JSON.parse(localStorage.getItem('sessions')) || {};
  // Sort the sessions from oldest to newest
  const sortedSession = Object.entries(localStroageSessions).sort((a, b) => a[1]?.created - b[1]?.created);
  // Check if max sessions longer than 10, if yes delete extra sessions
  if (sortedSession.length > 10) {
    const removedSessions = [];
    for (let i = 0; i < sortedSession.length - 10; i += 1) {
      removedSessions.push(sortedSession[i][0]);
    }

    // Delete them and save to localstorage
    removedSessions.forEach((session) => {
      if (localStroageSessions.hasOwnProperty(session)) {
        delete localStroageSessions[session];
      }
    });
    localStorage.setItem('sessions', JSON.stringify(localStroageSessions));
  }

  const sessions = localStroageSessions;
  const { caseId, journeyId } = props.match.params;

  window.Intercom('update', {
    hide_default_launcher: true,
  });
  window.Intercom('update');

  // Set case mode to learn or exam.
  const setModes = (mode_val) => {
    setMode(mode_val);
    toggle();
    setTourPage(1);
  };

  // Change the modal status to hide Modal.
  const toggle = () => {
    setModalToggle('');
  };

  const submitToggle = () => {
    setToggle((toggle) => !toggle);
  };

  const patientInfoToggle = () => {
    setInfoToggle((toggle) => !toggle);
  };

  // Show Exitcase Modal
  const exitCase = () => {
    setModalToggle(EXIT_CASE_MODAL);
  };

  // Show Timeout Modal
  const timeoutCase = () => {
    setModalToggle(TIMEOUT_CASE_MODAL);
  };

  // Show Audio Blocked Modal
  const showAudioBlockedModal = (type) => {
    setModalToggle(type);
  };

  // Show Follow Questions Modal
  const proceed = () => {
    trackEvent({
      GAAction: 'submit_single_player_case',
      FBAction: 'Submit Single Player Case',
      category: 'Single Player',
      label: `User ${props.id} Submit Single Player Case`,
    });
    if (journeyId === undefined) {
      props.history.push(`/cases/${caseId}/session_id/${props.sessionId}/summary`);
    } else {
      props.history.push(`/cases/${caseId}/journey/${journeyId}/session_id/${props.sessionId}/summary`);
    }
  };

  const handleExitCase = () => {
    trackEvent({
      GAAction: 'exit_single_player_case',
      FBAction: 'Exit Single Player Case',
      category: 'Single Player',
      label: `User ${props.id} Exit Single Player Case`,
    });
    props.setSessionId(null);
    props.history.push('/dashboard/home/');
  };

  const handleRetry = () => {
    trackEvent({
      GAAction: 'Retry_single_player_case',
      FBAction: 'Retry Single Player Case',
      category: 'Single Player',
      label: `User ${props.id} Retry Single Player Case`,
    });
    window.location.href = `/cases/${caseId}/spm`;
  };

  const handleSubmit = () => {
    trackEvent({
      GAAction: 'submit_single_player_case',
      FBAction: 'Submit Single Player Case',
      category: 'Single Player',
      label: `User ${props.id} Submit Single Player Case`,
    });
    setLoading(true);
    submitToggle();
    stopSession(sessionId)
      .then(() => {
        setLoading(false);
        props.setSessionId(null);
        proceed();
      })
      .catch((err) => {
        Sentry.captureException(err, {
          tags: {
            section: 'Stop session in case interface',
            url: window.location.href,
            err: JSON.stringify(err.response),
          },
        });
        if (err.response && err.response.data) {
          setError(JSON.stringify(err.response.data));
        } else {
          setError('Sorry, something went wrong, please try submit again or contact support.');
        }
      });
  };

  const countDownExit = () => {
    // coutdown show exit modal when session expires
    setTimeout(() => {
      setModalToggle(SESSION_EXPIRED_MODAL);
    }, process.env.REACT_APP_SESSION_DURATION - (Date.now() - sessions[sessionId].created));
  };

  useEffect(() => {
    props.startCase(false);
    props.initialize();
    if (currentCase.id !== caseId && !query.get('session')) {
      sessionId = '';
      props.setSessionId(null);
    }
    props.getCurrentCase(caseId).catch((err) => {
      setError(err?.response?.data || 'Sorry, we have some issue with Oscer, please try again later');
    });
    // match messages with localstorage
    if (!sessionId) {
      props
        .postSessionId(caseId, journeyId)
        .then((data) => {
          sessionId = data.session_id;
          props.history.push({
            pathname: props.location.pathname,
            search: `?${new URLSearchParams({ session: sessionId }).toString()}`,
          });
          localStorage.setItem('sessionId', sessionId);
          sessions[sessionId] = {
            messages: [],
            created: Date.now(),
          };
          localStorage.setItem('sessions', JSON.stringify(sessions));

          countDownExit();
        })
        .catch((err) => {
          // If error.code === 103, then "You haven't unlocked this case", this error has different error structure with others
          if (err?.code === 103) {
            props.history.push(`/dashboard/home?id=${caseId}`);
          }
          if (err?.response?.status === 404) {
            setError(`Sorry, the case doesn't exist, please try other cases`);
          } else if (err.response?.data?.case_id && typeof err.response.data.case_id === 'object') {
            setError(err.response.data.case_id[0]);
          } else {
            setError('Sorry, we have some issue with Oscer, please try again later');
          }
        });
    } else if (!Object.prototype.hasOwnProperty.call(sessions, sessionId)) {
      // when sessionId exist but not in local storage
      setModalToggle(SESSION_EXPIRED_MODAL);
    } else if (sessions[sessionId].created <= Date.now() - process.env.REACT_APP_SESSION_DURATION) {
      // when session has expired
      delete sessions[sessionId];
      localStorage.setItem('sessions', JSON.stringify(sessions));
      setModalToggle(SESSION_EXPIRED_MODAL);
    } else {
      if (sessions[sessionId].messages.length) {
        const questionsAsked = sessions[sessionId].messages.reduce(
          (acc, cur) => (cur.type === 'OUTGOING_MESSAGE' && !cur.status ? acc + 1 : acc),
          0
        );
        let bedsideManner = 0;
        let currentScore = 0;
        // Find last valid incoming message and get bedside manner and score
        for (let i = sessions[sessionId].messages.length - 1; i > 0; i -= 1) {
          if (
            sessions[sessionId].messages[i].type === 'INCOMING_MESSAGE' &&
            !isEmptyObject(sessions[sessionId].messages[i].text)
          ) {
            if (sessions[sessionId].messages[i].text.bedside_manner) {
              bedsideManner = sessions[sessionId].messages[i].text.bedside_manner;
            }
            if (sessions[sessionId].messages[i].text.current_score) {
              currentScore = sessions[sessionId].messages[i].text.current_score;
            }
            break;
          }
        }
        props.setCurrentScore(currentScore);
        props.setQuestionsInfo(questionsAsked, 0, 0, bedsideManner);
      }

      props.setMessages(sessions[sessionId].messages);
      localStorage.setItem('sessionId', sessionId);
      props.setSessionId(sessionId);
      // coutdown show exit modal when session expires
      countDownExit();
    }
    // After case initialise and loaded (refresh), set audio to default, a timeout is to prevent message auto play
    // props.autoPlayAudioToggle();
    trackPage(`/cases/${caseId}/spm`);
  }, []);

  useEffect(() => {
    if (props.currentCase.isExpired) {
      props.setSessionId(null);
      setModalToggle(SESSION_EXPIRED_MODAL);
    }
  }, [props.currentCase.isExpired]);

  useEffect(() => {
    if (!props.currentCase.id) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [props.currentCase]);

  const startCase = () => {
    props
      .getCaseSession(sessionId)
      .then((res) => {
        if (+new Date(res.started) === +new Date(res.created_time)) {
          props
            .addStartTime(sessionId, new Date())
            .then((res) => props.startCase(res.started))
            .catch((err) => {
              Sentry.captureException(err, {
                tags: {
                  section: 'History Mode Timer error',
                  url: window.location.href,
                  err: JSON.stringify(err.response),
                },
              });
              if (err.response && err.response.data) {
                setError(JSON.stringify(err.response.data));
              } else {
                setError('Sorry, something went wrong, please try submit again or contact support.');
              }
            });
        } else {
          props.startCase(res.started);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Set user tour close
  const handleUserTourClose = () => {
    setTourOpen(false);
    handleStartCase();
  };

  const handleStartCase = () => {
    // setMode(EXAM_LEARN_MODE);
    startCase();

    // Set Initial statement
    if (props.messages.length === 0) {
      props.createMessage(
        INCOMING_MESSAGE,
        {
          response_type: 'normal',
          response: startingStatement(),
          current_score: 0,
          points_earned: 0,
          label: '',
          guide_msg: '',
        },
        new Date().toString()
      );
      trackEvent({
        GAAction: 'single_player_start_case',
        FBAction: 'Single Player Start Case',
        category: 'Single Player',
        label: `Case id ${caseId}`,
      });
    }
  };

  const startingStatement = () => {
    const random = Math.floor(Math.random() * 4);
    switch (random) {
      case 0:
        return 'Thanks for seeing me today, Doc.';
      case 1:
        return `Hi ${props.user.first_name || 'Doc'}, good to finally see you.`;
      case 2:
        return `Hi ${props.user.first_name || 'Doc'}.`;
      default:
        return `Hey ${props.user.first_name || 'Doc'}, can you help me out?`;
    }
  };

  return (
    <>
      <div className="case_container">
        <Tour
          steps={userTourSteps(setModes, handleUserTourClose, currentCase)}
          isOpen={isTourOpen && !loading}
          onRequestClose={() => handleTourClosed('osce_history_flag')}
          closeWithMask={false}
          scrollDuration={200}
          showNavigation={false}
          showNumber={false}
          showButtons={false}
          showCloseButton={false}
          // disableInteraction
          goToStep={tourPage}
          rounded={8}
          maskClassName="case_container-mask-tour"
        />
        <>
          {loading ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '30vh' }}>
              <OscerLoader />
            </div>
          ) : (
            <>
              <div className={classnames('case_container-messages-wrapper')}>
                <div className="case__body">
                  <div className="case_container-main-col">
                    {/* Show timer */}
                    <TimerHeader
                      isToggle={isToggle}
                      setToggle={setToggle}
                      isInfoToggle={isInfoToggle}
                      setInfoToggle={setInfoToggle}
                      currentCase={currentCase}
                      timeoutCase={timeoutCase}
                      modalStatus={modalToggle}
                      setTimerComplete={setTimerComplete}
                      pause={modalToggle === HINT_MODAL}
                      wantingToNavigate={wantingToNavigate}
                      proceed={proceed}
                      stopSession={stopSession}
                      exitCase={exitCase}
                      sessionId={sessionId}
                      handleSubmit={handleSubmit}
                    />
                    {/* Show the customer and message box */}
                    <MainArea
                      panelHidden={panelHidden}
                      caseMode={mode}
                      sessionId={sessionId}
                      beginModalToggle={modalToggle === BEGIN_CASE_MODAL}
                      audioBlockedToggle={showAudioBlockedModal}
                      toggle={toggle}
                    />
                  </div>
                  <div
                    className="case_container-right-col"
                    style={{ width: panelHidden && '30px', height: panelHidden && '30px' }}
                  >
                    {/* Right side bar with user info, score, hints and diagnosis */}
                    <RightSidebar
                      panelHidden={panelHidden}
                      setPanelHidden={setPanelHidden}
                      handleSubmit={handleSubmit}
                      modalState={modalToggle}
                      currentMode={mode}
                      exitCase={exitCase}
                      proceed={proceed}
                      stopSession={stopSession}
                      setTimerComplete={setTimerComplete}
                      sessionId={sessionId}
                    />
                  </div>
                </div>
              </div>

              <BeginCaseModal
                isToggle={modalToggle === BEGIN_CASE_MODAL}
                setMode={setModes}
                toggle={toggle}
                startCase={startCase}
                currentCase={currentCase}
                sessionId={sessionId}
              />
            </>
          )}
        </>
        <ExitModal
          isToggle={modalToggle === EXIT_CASE_MODAL || wantingToNavigate}
          toggle={toggle}
          {...{ wantToNavigateTo }}
          system={currentCase.system}
          leftBtnFunc={handleExitCase}
        />
        <TimeoutModal
          isToggle={modalToggle === TIMEOUT_CASE_MODAL}
          setMode={setModes}
          toggle={toggle}
          stopSession={stopSession}
          proceed={proceed}
          system={currentCase.system}
          setTimerComplete={setTimerComplete}
        />
        <AudioBlockedModal
          isToggle={modalToggle === AUDIO_BLOCKED_MODAL}
          toggle={toggle}
          currentCase={currentCase}
          sessionId={sessionId}
        />
        <AudioIssueModal
          isToggle={modalToggle === AUDIO_ISSUE_MODAL}
          toggle={toggle}
          currentCase={currentCase}
          sessionId={sessionId}
        />
        {props.messages.length ? (
          <ExitModal
            isToggle={modalToggle === SESSION_EXPIRED_MODAL}
            system={currentCase.system}
            title="Do you want to submit the case?"
            message="The current session is expired/invalid."
            leftBtnText="Submit"
            leftBtnFunc={handleSubmit}
            rightBtnFunc={handleExitCase}
          />
        ) : (
          <ExitModal
            isToggle={modalToggle === SESSION_EXPIRED_MODAL}
            system={currentCase.system}
            title="Do you want to restart the case?"
            message="The current session is expired/invalid."
            leftBtnText="Retry"
            leftBtnFunc={handleRetry}
            rightBtnFunc={handleExitCase}
          />
        )}
        <ErrorModal
          isToggle={!!error}
          toggle={() => setError('')}
          title="Ops, something went wrong."
          subtitle={error}
        />
        <SubmitCaseModal
          isToggle={isToggle}
          toggle={submitToggle}
          stopSession={stopSession}
          proceed={proceed}
          setTimerComplete={setTimerComplete}
          system={currentCase.system}
          presentation={currentCase.presentation}
          handleSubmit={handleSubmit}
        />
        <PatientInfoModal currentCase={currentCase} isInfoToggle={isInfoToggle} infoToggle={patientInfoToggle} />
      </div>
    </>
  );
};

Case.propTypes = {
  currentCase: PropTypes.object,
  timerComplete: PropTypes.bool,
  navigatingToPage: PropTypes.string,
  wantingToNavigate: PropTypes.bool,
  sessionId: PropTypes.string,
  match: PropTypes.object,
  initialize: PropTypes.func,
  postSessionId: PropTypes.func,
  getCurrentCase: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  setSessionId: PropTypes.func,
  setMessages: PropTypes.func,
  messages: PropTypes.array,
  id: PropTypes.number,
  setCurrentScore: PropTypes.func,
  setQuestionsInfo: PropTypes.func,
  autoPlayAudioToggle: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentCase: state.cases.currentCase,
  sessionId: state.cases.sessionId,
  messages: state.messages.messages,
  totalMessages: state.messages.messages.length,
  timerComplete: state.cases.timerComplete,
  navigatingToPage: state.cases.navigatingToPage,
  wantingToNavigate: state.cases.wantingToNavigate,
  id: state.auth.user.id,
  stopSession: PropTypes.func,
  setTimerComplete: PropTypes.func,
  wantToNavigateTo: PropTypes.func,
  startTime: state.cases.start,
  startCase: PropTypes.func,
});

const mapDispatchToProps = (dispatch) => ({
  createMessage: (type, text, date, status, options, response_type) =>
    dispatch(createMessage(type, text, date, status, options, response_type)),
  startCase: (payload) => dispatch(caseActions.startCase(payload)),
  postSessionId: (caseId, journeyId) => dispatch(caseActions.postSessionId(caseId, journeyId)),
  stopSession: (args) => dispatch(caseActions.stopSession(args)),
  getCurrentCase: (caseId) => dispatch(caseActions.getCurrentCase(caseId)),
  fetchConfirmed: () => dispatch(caseActions.fetchConfirmedCases()),
  initialize: () => dispatch(caseActions.initialize()),
  setTimerComplete: (timerComplete) => dispatch(caseActions.setTimerComplete(timerComplete)),
  wantToNavigateTo: (page) => dispatch(caseActions.wantToNavigateTo(page)),
  setSessionId: (page) => dispatch(caseActions.setSessionId(page)),
  setMessages: (messages) => dispatch(setMessages(messages)),
  setCurrentScore: (score) => dispatch(caseActions.setCurrentScore(score)),
  setQuestionsInfo: (totalQuestions, markStreak, maxMarkStreak, bedsideManner) =>
    dispatch(caseActions.setQuestionsInfo(totalQuestions, markStreak, maxMarkStreak, bedsideManner)),
  autoPlayAudioToggle: () => dispatch(caseActions.autoPlayAudioToggle()),
  getCaseSession: (sessionId) => dispatch(caseActions.getCaseSession(sessionId)),
  addStartTime: (sessionId, startTime) => dispatch(caseActions.addStartTime(sessionId, startTime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Case);
