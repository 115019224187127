import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  OscerLoader: {
    position: 'relative',
    width: 270,
  },
  OscerLoader__spinner: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  OscerLoader__icon: {
    marginTop: 10,
  },
}));

export default useStyles;
