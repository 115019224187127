import {
  SET_PRESENTATION_FILTER,
  SET_SYSTEM_FILTER,
  SET_MODAL_TO_SHOW,
  USER_LOADED,
  SET_SELECTED_CASE_INDEX,
  SET_SELECTED_CASE_SECTION,
  SET_SYSTEM_AVERAGE_FILTER,
  SET_SYSTEM_AVERAGE_SELECTED,
  SET_LEVEL_FILTER,
  SET_INSENTIVE_MODAL,
  SET_MODE_SELECTION_MODAL,
  SET_SELECTED_CASE,
  SET_EXPLORER_UPGRADE_MODAL,
  SET_UPGRADE_MODAL,
  UPGRADE_MODAL_STEP_FLAG_SET,
  SET_PROFILE_POPOVER,
} from '../actions/types';
// import { hlp } from '../components/App';

export const emptyFilter = [];
export const NO_MODAL = 0;
export const TRIAL_FINISHED_MODAL = 1;
export const UPGRADE_TO_PRIME_MODAL = 2;
export const SUBSCRIPTION_ENDS_IN_MODAL = 3;
export const SELECTED_CASE_INDEX = 0;
export const SELECTED_CASE_SECTION = 'recommended';
export const SYSTEM_AVERAGE_FILTER = null;
export const IS_SYSTEM_AVERAGE_SELECTED = false;

const initialState = {
  presentationFilter: emptyFilter,
  systemFilter: emptyFilter,
  levelFilter: emptyFilter,
  systemAverageFilter: SYSTEM_AVERAGE_FILTER,
  modalToShow: NO_MODAL,
  selectedCaseIndex: SELECTED_CASE_INDEX,
  selectedCaseSection: SELECTED_CASE_SECTION,
  isSystemAverageSelected: IS_SYSTEM_AVERAGE_SELECTED,
  insentiveModal: false,
  modeSelectionModal: false,
  selectedCase: {},
  upgradeModalToggle: false,
  upgradeExplorerModalToggle: false,
  upgradeModalStepFlag: '',
  profilePopoverOpen: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE_POPOVER:
      return { ...state, profilePopoverOpen: action.toggle };
    case SET_PRESENTATION_FILTER:
      return { ...state, presentationFilter: action.value };
    case SET_SYSTEM_FILTER:
      return { ...state, systemFilter: action.value };
    case SET_LEVEL_FILTER:
      return { ...state, levelFilter: action.value };
    case SET_MODAL_TO_SHOW:
      return { ...state, modalToShow: action.value };
    case SET_SELECTED_CASE_INDEX:
      return { ...state, selectedCaseIndex: action.idx };
    case SET_SELECTED_CASE_SECTION:
      return { ...state, selectedCaseSection: action.section };
    case SET_SYSTEM_AVERAGE_FILTER:
      return { ...state, systemAverageFilter: action.value };
    case SET_SYSTEM_AVERAGE_SELECTED:
      return { ...state, isSystemAverageSelected: action.value };
    case SET_INSENTIVE_MODAL:
      return { ...state, insentiveModal: action.value };
    case SET_MODE_SELECTION_MODAL:
      return { ...state, modeSelectionModal: action.value };
    case SET_SELECTED_CASE:
      return { ...state, selectedCase: action.caseObj };
    case SET_UPGRADE_MODAL:
      return { ...state, upgradeModalToggle: action.toggle };
    case SET_EXPLORER_UPGRADE_MODAL:
      return { ...state, upgradeExplorerModalToggle: action.toggle };
    case UPGRADE_MODAL_STEP_FLAG_SET:
      return { ...state, upgradeModalStepFlag: action.step };
    default:
      return state;
  }
}
