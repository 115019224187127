import React from 'react';
import cx from 'classnames';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import useStyles from './style';

const GeneralSwitch = (props) => {
  const classes = useStyles();
  // size can only be small or medium
  const { size = 'small', textLeft = 'OFF', textRight = 'ON', disabled, toggle, isToggle = false, styles = {} } = props;

  return (
    <div className={cx(classes.GeneralSwitch, styles.root)}>
      <span
        className={cx(classes.GeneralSwitch__text, {
          [classes.GeneralSwitch__text__active]: !isToggle,
        })}
      >
        {textLeft}
      </span>
      <Switch
        checked={isToggle}
        onChange={toggle}
        color="primary"
        name="audioSwitch"
        disabled={disabled}
        size={size}
        classes={{
          root: classes.GeneralSwitch__switch__root,
          colorPrimary: styles.colorPrimary,
        }}
      />
      <span
        className={cx(classes.GeneralSwitch__text, {
          [classes.GeneralSwitch__text__active]: isToggle,
        })}
      >
        {textRight}
      </span>
    </div>
  );
};

GeneralSwitch.propTypes = {
  size: PropTypes.string,
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
  toggle: PropTypes.func,
  isToggle: PropTypes.bool,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
};

export default GeneralSwitch;
