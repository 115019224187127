import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withResizeDetector } from 'react-resize-detector';
import { getHints, setHintRevealed } from '../../../../../actions/cases';
import HintModal from '../../../Modals/HintModal';
import IconHint from '../../../../../components/icons/IconHint';
import useStyles from './style';

const Hint = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { width } = props;

  // const [hints, setHints] = useState([]);
  const [hintsRevealedIndex, setHintsRevealedIndex] = useState(0);
  const [currentHintIndex, setCurrentHintIndex] = useState(0);

  // Hint modal toggle
  const [isHintModalToggle, setHintModalToggle] = useState(false);
  // const [error, setError] = useState('');

  const handleRevealHint = (index) => {
    setCurrentHintIndex(index);
    if (index === hintsRevealedIndex) {
      setHintsRevealedIndex(hintsRevealedIndex + 1);
      props.setHintRevealed({ currentHint: index + 1, totalHints: props.hints.length });
    }
    setHintModalToggle(true);
  };

  // Flex space between, give place holder for bottom line
  const hintsChipsPlaceholder = () => {
    const MAX_CHIPS = Math.floor(width / 72);
    // Calculate how many chips need to add
    const chipsNum = props.hints.length % MAX_CHIPS;
    const chipsList = [];

    if (chipsNum === 0) {
      return chipsList;
    }

    for (let i = 0; i < MAX_CHIPS - chipsNum; i += 1) {
      chipsList.push(`hint_placeholder_${i}`);
    }
    return chipsList;
  };

  return (
    <div className={classes.Hint}>
      {!!props.hints.length && (
        <HintModal
          isToggle={isHintModalToggle}
          toggle={() => setHintModalToggle(!isHintModalToggle)}
          hint={{ ...props.hints[currentHintIndex], hintsCount: currentHintIndex, hintsLength: props.hints.length }}
          system={props.currentCase.system}
        />
      )}
      {/* <div className={classes.Hint__label}>
        <IconHint color={theme.palette.primary.main} size={35} />
        Hints
      </div> */}
      <div className={classes.Hint__btns}>
        {props.hints.map((hint, index) => (
          <Button
            key={hint.text}
            disabled={index > hintsRevealedIndex}
            variant="contained"
            classes={{ contained: classes.Hint__btn }}
            onClick={() => handleRevealHint(index)}
          >
            {`Hint ${index + 1}`}
          </Button>
        ))}
        {hintsChipsPlaceholder().map((placeholder) => (
          <div key={placeholder} className={classes.Hint__btn__placeholder} />
        ))}
      </div>
      {/* <div className={classes.Hint__error}>{error}</div> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentCase: state.cases.currentCase,
  hints: state.cases.hints,
});

Hint.propTypes = {
  // getHints: PropTypes.func,
  currentCase: PropTypes.object,
  hints: PropTypes.array,
  setHintRevealed: PropTypes.func,
  width: PropTypes.number,
};

export default connect(mapStateToProps, { getHints, setHintRevealed })(withResizeDetector(Hint));
