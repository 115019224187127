import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  PlanCard: {
    height: 341,
    width: 335,
    boxShadow: '0 3px 7px 0 rgba(0, 35, 72, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    margin: 10,
    position: 'relative',
  },
  PlanCard__best__img: {
    position: 'absolute',
    top: -105,
    left: 40,
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  PlanCard__duration: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 91,
    width: '100%',
    backgroundColor: '#9dc9f6',
    color: 'white',
    fontWeight: 500,
    fontSize: 25,
    textTransform: 'uppercase',
    borderRadius: '16px 16px 0 0',
  },
  PlanCard__duration__best: {
    backgroundColor: '#f6c89f',
  },
  PlanCard__duration__value: {
    fontSize: 16,
    fontWeight: 'normal',
  },
  PlanCard__main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'white',
    borderRadius: '0 0 16px 16px',
  },
  PlanCard__originalPrice: {
    color: theme.palette.text.main,
    fontWeight: 500,
    opacity: 0.7,
    fontSize: 20,
    textDecoration: 'line-through',
    marginBottom: 10,
  },
  PlanCard__currentPrice: {
    display: 'flex',
    color: theme.palette.text.main,
    fontWeight: 500,
    fontSize: 20,
    marginBottom: 10,
  },
  PlanCard__currentPrice__price: {
    color: theme.palette.text.main,
    fontSize: 44,
    marginTop: -10,
    fontWeight: 'normal',
  },
  PlanCard__currentPrice__usd: {
    opacity: 0.6,
    alignSelf: 'flex-end',
    fontWeight: 'normal',
    marginBottom: 10,
    fontSize: 16,
  },
  PlanCard__btn__root: {
    fontWeight: 500,
    backgroundColor: '#9dc9f6',
    marginBottom: 15,
  },
  PlanCard__btn__best__root: {
    backgroundColor: '#f6c89f',
    '&:hover': {
      backgroundColor: '#E57010',
    },
  },
  PlanCard__desc: {
    color: '#919aa3',
    fontSize: 18,
  },
}));

export default useStyles;
