import React from 'react';
import PropTypes from 'prop-types';

const IconPreminum = props => {
  const { size = 40, ...restProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 36 36" version="1.1" {...restProps}>
      <g id="dashboard-final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-2">
          <circle id="Oval" stroke="#F6C89F" strokeWidth="3" fill="#FBFBFB" cx="18" cy="18" r="16.5"></circle>
          <path d="M18,14.75 C16.96875,14.75 16.125,13.9166667 16.125,12.8981481 C16.125,11.8564815 16.96875,11.0462963 18,11.0462963 C19.03125,11.0462963 19.875,11.8564815 19.875,12.8981481 C19.875,13.9166667 19.03125,14.75 18,14.75 Z M10.5234375,13.9861111 C11.34375,13.9861111 12,14.6574074 12,15.4675926 C12,16.3009259 11.34375,16.9490741 10.5234375,16.9490741 C9.6796875,16.9490741 9,16.3009259 9,15.4675926 C9,14.6574074 9.6796875,13.9861111 10.5234375,13.9861111 Z M25.5234375,13.9861111 C26.34375,13.9861111 27,14.6574074 27,15.4675926 C27,16.3009259 26.34375,16.9490741 25.5234375,16.9490741 C24.6796875,16.9490741 24,16.3009259 24,15.4675926 C24,14.6574074 24.6796875,13.9861111 25.5234375,13.9861111 Z M12.1640625,24.2175926 L10.921875,17.5046296 C11.296875,17.4351852 11.6484375,17.2268519 11.9296875,16.9953704 C12.703125,17.6898148 13.6875,18.3611111 14.625,18.3611111 C15.75,18.3611111 16.6640625,16.6481481 17.296875,15.212963 C17.5078125,15.2824074 17.765625,15.3518519 18,15.3518519 C18.234375,15.3518519 18.4921875,15.2824074 18.703125,15.212963 C19.3359375,16.6481481 20.25,18.3611111 21.375,18.3611111 C22.3125,18.3611111 23.296875,17.6898148 24.0703125,16.9953704 C24.3515625,17.2268519 24.703125,17.4351852 25.078125,17.5046296 L23.8359375,24.2175926 L12.1640625,24.2175926 Z M11.90625,24.7962963 L24.09375,24.7962963 L24.09375,25.9305556 L11.90625,25.9305556 L11.90625,24.7962963 Z" id="premium-icon" fill="#F6C89F" fillRule="nonzero"></path>
        </g>
      </g>
    </svg>
  );
};

IconPreminum.propTypes = {
  size: PropTypes.number,
};

export default IconPreminum;
