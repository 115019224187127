import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  HomeFooter: {
    width: '100%',
    backgroundColor: theme.palette.inherit.light,
    padding: '80px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 50px',
    },
  },
  HomeFooter__container__root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  HomeFooter__main: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  HomeFooter__col: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 180,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  HomeFooter__link: {
    fontSize: 18,
    color: theme.palette.text.primary,
    marginBottom: 30,
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      textShadow: '0px 0px 0.2px #000000',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
      fontSize: 14,
    },
  },
  HomeFooter__text: {
    color: theme.palette.text.primary,
    marginBottom: 30,
    lineHeight: 1.22,
    letterSpacing: 1.23,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
      fontSize: 14,
      lineHeight: 1.29,
      letterSpacing: 1.08,
    },
  },
  HomeFooter__logo: {
    marginBottom: 30,
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  HomeFooter__copyright: {
    fontSize: 16,
    opacity: 0.78,
    color: '#9dc9f6',
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: 130,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HomeFooter__copyright__mobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      opacity: 0.78,
      color: '#9dc9f6',
      lineHeight: 1.5,
      display: 'flex',
      marginTop: 20,
    },
  },
  HomeFooter__social: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      marginBottom: 15,
    },
  },
  HomeFooter__social__icon: {
    maxWidth: 39,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 30,
    },
  },
  ScrollTopBtn: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      background: theme.palette.primary.light,
      width: 50,
      height: 50,
      borderRadius: '50%',
      padding: 0,
      minWidth: 50,
    },
  },
  ScrollTopBtn__label: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: -5,
  },
  ScrollTopBtn__text: {
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginTop: -5,
  },
}));

export default useStyles;
