import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  MobileMenuPopover: {},
  MobileMenuPopover__dropback: {
    backgroundColor: '#0000004D',
    position: 'absolute',
    top: 100,
    left: 0,
    width: '100vw',
  },
  MobileMenuPopover__dropback__on: {
    height: '90vh',
  },
  MobileMenuPopover__icon__wrapper: {
    position: 'relative',
  },
  MobileMenuPopover__icon: {
    fontSize: 36,
    position: 'absolute',
    bottom: -24,
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: 0,
    height: 0,
    transition: 'opacity 0.4s',
    color: theme.palette.primary.main,
  },
  MobileMenuPopover__icon__active: {
    opacity: 1,
    height: 'auto',
  },
}));

export default useStyles;
