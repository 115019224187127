import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  HomeContainer__outer: {
    position: 'relative',
  },
  HomeContainer__inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 60,
    paddingBottom: 60,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
}));

export default useStyles;
