import { makeStyles } from '@material-ui/core/styles';
import TeamBG from '../../../assets/home/swipe-right-bg.svg';

const useStyles = makeStyles((theme) => ({
  SwipeRight__caseCard__root: {
    margin: '0 auto',
    width: 280,
    [theme.breakpoints.down('md')]: {
      width: 240,
    },
    [theme.breakpoints.down('xs')]: {
      width: 250,
      marginTop: 0,
    },
  },
  SwipeRight__slide__wrapper: {
    width: '90%',
    height: 500,
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      height: 300,
      marginTop: 15,
    },
  },
  SwipeRight__container__outer: {
    background: `url(${TeamBG})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },
}));

export default useStyles;
