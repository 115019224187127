import React from 'react';
import { Typography } from '@material-ui/core';
import HomeContainer from '../../../components/Container/HomeContainer';
import imgTechnologyHero from '../../../assets/home/hp-ai-img.svg';
import imgOverlayLeft from '../../../assets/home/shape-2-2.svg';
import imgOverlayRight from '../../../assets/home/half-circle.svg';
import useStyles from './style';

const Hero = () => {
  const classes = useStyles();
  return (
    <HomeContainer
      outerStyles={classes.TechnologyHero__container__outer}
      innerStyles={classes.TechnologyHero__container__inner}
    >
      <p className={classes.TechnologyHero__tag}>TECHNOLOGY</p>
      <Typography variant="h1" className={classes.TechnologyHero__title} gutterBottom>
        Oscer’s intelligence is more than just artificial
      </Typography>
      <img src={imgTechnologyHero} alt="img_tech_hero" className={classes.TechnologyHero__img} />
      <img src={imgOverlayLeft} alt="hero_overlay_left" className={classes.TechnologyHero__overlay__left} />
      <img src={imgOverlayRight} alt="hero_overlay_right" className={classes.TechnologyHero__overlay__right} />
    </HomeContainer>
  );
};

export default Hero;
