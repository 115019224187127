import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Container from '@material-ui/core/Container';
import useStyles from './style';

/**
 * @desc The container which can be used in home page to size max width
 * @param {*} param0
 */
const HomeContainer = ({ children, outerStyles, innerStyles }) => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl" classes={{ root: cx(classes.HomeContainer__outer, outerStyles) }}>
      <Container maxWidth="lg" classes={{ root: cx(classes.HomeContainer__inner, innerStyles) }}>
        {children}
      </Container>
    </Container>
  );
};

HomeContainer.propTypes = {
  children: PropTypes.node,
  outerStyles: PropTypes.string,
  innerStyles: PropTypes.string,
};

export default HomeContainer;
