import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ImageCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ImageCard__iamge: {
    maxWidth: 240,
    maxHeight: 63,
    marginBottom: 48,
  },
  ImageCard__name: {
    fontSize: 18,
    color: theme.palette.text.main,
    textAlign: 'center',
  },
}));

export default useStyles;
