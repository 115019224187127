import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import Slider from '../../../components/Slider';
import HomeContainer from '../../../components/Container/HomeContainer';
import useStyles from './style';
import ReviewCard from '../../../components/Card/ReviewCard';
import OverlayShape from '../../../assets/home/shape-7.svg';
import './style.scss';

const StudentsReview = () => {
  const classes = useStyles();

  const reviews = [
    {
      stars: 5,
      review: 'Oscer is great! You guys are geniuses.',
      name: 'Jonathan',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `Oscer is a great tool to practice for OSCEs because it provides flexibility to practice and detailed feedback on my performance, it's easy to navigate and the AI grader is accurate.`,
      name: 'Myuggwan',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `I am OBSESSED with your software! I love it so much! It really helps me a lot cos I'm so awkward at history taking and struggle to get meaningful feedback!.`,
      name: 'Hannah',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: 'Oscer assists me with my nursing studies a ton!!',
      name: 'Maya',
      role: 'Nursing Student',
    },
    {
      stars: 5,
      review:
        'Oscer is the only platform where you can actually practice your medical knowledge in a virtual clinical scenario.',
      name: 'Lina',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: 'Extremely helpful when practicing histories with new cases.',
      name: 'Josephine',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `Oscer is, I think, the only platform I've come across that exposes me to clinical cases in an interactive way, that is also easy to navigate and understand. Pretty much all of my learning of clinical cases comes from this platform.`,
      name: 'Pav',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'Due to Covid, the patient access has been very limited across the world, and having this virtual patient contact has been a great way to hone on our history taking skills. Furthermore, it allows us to access specific systems so we can implement our learning after revising a system or symptom and the ease of access from using it in our own homes is always an advantage. It has given me the confidence to practice with real life people because there is no fear of judgement with a virtual person and once practising enough you gain the confidence to practice with real people.',
      name: 'Ana',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `OSCER has helped me practice my history taking skills so much and I've improved using this app!`,
      name: 'Vina',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'OSCER is an amazing way of learning and applying clinical skills before interacting with real patients, and gives an opportunity for more forethought and planning of taking a history.',
      name: 'Tony',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'As a community paramedic i use Oscer to hone my newly acquired skills of advanced history taking, advanced pathophysiology and  pharmacology to develop and test myself to ensure that i have understood the content and am able to apply this in a clinical setting outside of work.',
      name: 'Matt',
      role: 'Paramedicine Student',
    },
    {
      stars: 5,
      review: `It'a a great resource to have practice OSCE style scenarios especially during covid where teaching is over zoom rather than face to face.`,
      name: 'Danielle',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: 'The application is super helpful and helps with diagnosing patients',
      name: 'Danny',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'Oscer has been so helpful for teaching me systems reviews and developing my own illness scripts for each disease. The diseases are all high yield which is something very difficult to find in such an easy format.',
      name: 'James',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'I think it has the potential to maximise clinical learning in a time efficient way, as clinical learning on the wards is not always high yield.',
      name: 'Chris',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `I am starting to learn about system review questions, and investigations specific to organ systems. Using Oscer to consolidate this knowledge as i am learning it, and to give me an idea of the bigger picture of patient care and understanding is invaluable. It is really helping me appreciate what information is important, which isn't, and how to ask the right questions to get it. I think the biggest mistake people could make is not using this sooner.`,
      name: 'Grace',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: 'It is very useful in assisting me to recognise symptoms for diagnosis.',
      name: 'Jayde',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'I think the app has a lot of potential and as a first yr med student the interactions are valuable in building my questions for each presentation.',
      name: 'Gabriella',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `As a clinical year medical student I'm meant to be in the hospital practicing my history taking skills and clinical skills with the in-patients, however the circumstances brought in by the pandemic has to some extent limited the opportunities that would normally be available in clinical settings. Oscer came into my life at the right time and it gives me the opportunity to practice my history taking and diagnostic skills at my own convenience. I think the software does a really good job of mimicking a healthcare professional-patient interaction.`,
      name: 'Maya',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'It is really difficult to arrange face to face practice with friends consistently. Just being able to voice the scripts and routine without feeling like a crazy person talking to themselves is amazingly helpful.',
      name: 'Simon',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `I think it's a really innovative and interesting way to practice your skills. There is nothing else like it on the market. It's very hands on and allows for a lot of experimentation. At the same time, information and hints provides enough steering that you can still progress even if you're stuck. It doesn't hold your hand, but it doesn't completely throw you in the deep end.`,
      name: 'Emma',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'Oscer is a one of a kind education tool that has made adaptation from paper to real life to much easier for myself and a number of my colleagues.',
      name: 'Michael',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `It's easier to practice OSCE spontaneously without relying on finding partners at a moment's notice.`,
      name: 'Stephen',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `In the era of COVID-19 and online clinical skills tutorials, getting adequate time and trustworthy resources to practice history taking skills as a pre-clinical years student is minimal. Oscer has been a god sent resource for me. I can practice my history taking skills everyday with cases that are well programmed with all domains of health so I can trust I am practicing with a 'patient' as close to a real patient which brings confidence to my learning. Also, Oscer has given me the convenience to practice history taking and my clinical reasoning anywhere, anytime which supports my motivation to improve my clinical skills. Without Oscer anymore I would lose a valuable resource now essential in my study schedule.`,
      name: 'Ana',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'I find oscer very helpful compared to other services I have used (osce bank, amboss) due to the interactive aspects of it.',
      name: 'Sylvia',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `It's really nice to have a resource to practice on, especially in my own time and not dependent on waiting for a patient or a peer.`,
      name: 'Josh',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `it's a fantastic idea and aims to plug the biggest gap in medical education material - an osce question bank.`,
      name: 'Mansa',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `I feel that Oscer has really helped my history taking and dialogue as a clinician. I have noticed an improvement in my work as a health practitioner, especially with real life cases that mirror those that Oscer uses. I find that it prompts my recall and leads me to asking questions that I may never have thought relevant and therefore I can present my diagnostic review to my superiors with much more confidence and insight. I would be particularly upset if I lost this opportunity to practice my skills like this. Even as I have undergone the simulations I have never received a 'perfect' score so I am constantly learning and challenging myself.`,
      name: 'Larry',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'As this program condenses and helps me to understand the possibilities in one location rather than having to cross check with several websites',
      name: 'Maddison',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `This website is one of a kind. It's the next best thing for practicing for OSCE, without other people. If I could no longer use it I would be devastated. It has also been great to improve my communication with patients.`,
      name: 'Tarren',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'I find that Oscer has made me a better diagnostician.  If I lost it I may struggle to evolve and improve as a clinician which I would find dissapointing.',
      name: 'Luke',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review:
        'Especially during times of COVID and lockdowns, OSCEr has enabled me to most effectively practice my clinical skills and further develop them.',
      name: 'Cody',
      role: 'Medical Student',
    },
    {
      stars: 5,
      review: `Its really revolutionised the way you prepare for OSCES, and I think its especially timely in during g current circumstances because with COVID its not ideal top practice with a friend. Another reason would be that it provides a good marking scheme to study off , regardless of you're level off experience (ie whether your in your preclinical years or studying for your fellowship long case).`,
      name: 'Ash',
      role: 'Medical Student',
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <HomeContainer
      outerStyles={cx('StudentsReview', classes.StudentsReview)}
      innerStyles={classes.StudentsReview__container}
    >
      <Typography variant="h2" gutterBottom>
        Our happy students
      </Typography>
      <Typography variant="subtitle1" classes={{ root: classes.StudentsReview__subtitle }}>
        Trusted and used by medical students from 150+ Universities, across 35+ countries and counting...
      </Typography>
      <div className={classes.StudentsReview__wrapper}>
        <Slider settings={settings} isMobileArrowHide>
          {reviews.map((review) => (
            <div key={review.name}>
              <ReviewCard stars={review.stars} review={review.review} name={review.name} role={review.role} />
            </div>
          ))}
        </Slider>
      </div>

      <Button
        className={classes.StudentsReview__signUpBtn}
        variant="contained"
        color="primary"
        component={Link}
        to="/signup"
      >
        Sign Up
      </Button>
      <img src={OverlayShape} className={classes.StudentsReview__overlay1} alt="Oscer Practice with Friends Overlay" />
    </HomeContainer>
  );
};
export default StudentsReview;
