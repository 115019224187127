import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ModeSelectionSlide: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 574,
    width: 594,
    padding: 20,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      height: '90vh',
      width: '100vw',
    },
  },
  ModeSelectionSlide__header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  ModeSelectionSlide__header__badges: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 105,
  },
  ModeSelectionSlide__badge__level: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 47,
    width: 47,
    backgroundColor: 'rgba(131, 208, 240, 0.1)',
    borderRadius: '50%',
  },
  ModeSelectionSlide__cancel: {
    fontWeight: 300,
    color: `${theme.palette.text.main}66`,
    cursor: 'pointer',
  },
  ModeSelectionSlide__back: {
    fontWeight: 300,
    position: 'absolute',
    bottom: 30,
    left: 25,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  ModeSelectionSlide__content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      justifyContent: 'center',
    },
  },
  ModeSelectionSlide__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ModeSelectionSlide__btns: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  ModeSelectionSlide__pres: {
    marginBottom: 10,
    height: 250,
    [theme.breakpoints.down('xs')]: {
      height: 180,
    },
  },
  ModeSelectionSlide__title: {
    fontSize: 21,
    textAlign: 'center',
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  ModeSelectionSlide__subtitle: {
    fontWeight: 300,
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.text.main,
    marginBottom: 30,
  },
  ModeSelectionSlide__error: {
    fontSize: 14,
    marginTop: 10,
    textAlign: 'center',
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
