import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  UserTour__content__1: {
    display: 'flex',
    flexDirection: 'column',
  },
  UserTour__title__1: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__subtitle__1: {
    fontSize: 15,
    lineHeight: 1.33,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__btn__1: {
    height: 34,
    width: 78,
    fontSize: 14,
    fontWeight: 500,
    alignSelf: 'flex-end',
    padding: 0,
  },
}));

const UserTourSteps = (proceed, handleUserTourClose) => {
  const classes = useStyles();

  return [
    {
      selector: '[data-tut="reactour__submit_case"]',
      position: 'bottom',
      content: () => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Submit the Case</div>
          <div className={classes.UserTour__subtitle__1}>
            Let’s continue the tour and submit the case so you can see your results… (normally you would chat longer to
            achieve a high score)
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleUserTourClose();
              proceed();
            }}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Next
          </Button>
        </div>
      ),
      style: {
        maxWidth: 320,
        borderTop: '4px solid #83d0f0',
        borderRadius: 16,
        padding: 16,
      },
    },
  ];
};

UserTourSteps.propTypes = {
  setToggle: PropTypes.func,
};

export default UserTourSteps;
