import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LinearProgress } from '@material-ui/core';
import useStyles from './style';

const LinearProgressBar = (props) => {
  const classes = useStyles();
  const { name, children, score = 0, maxScore = 100, minScore = 0, styles = {} } = props;

  const normalise = (value) => ((value - minScore) * 100) / (maxScore - minScore);

  return (
    <div className={cx(classes.LinearProgressBar, styles.root)}>
      <div className={classes.LinearProgressBar__info}>
        <div className={cx(classes.LinearProgressBar__info__name, styles.name)}>{name}</div>
        <div className={cx(classes.LinearProgressBar__info__result, styles.result)}>{children}</div>
      </div>
      <LinearProgress
        variant="determinate"
        value={normalise(score)}
        classes={{
          root: cx(classes.LinearProgressBar__LinearProgress__root, styles.linearProgress__root),
          colorPrimary: cx(
            classes.LinearProgressBar__LinearProgress__colorPrimary,
            styles.linearProgress__colorPrimary
          ),
          barColorPrimary: cx(
            classes.LinearProgressBar__LinearProgress__barColorPrimary,
            styles.linearProgress__barColorPrimary
          ),
        }}
      />
    </div>
  );
};

LinearProgressBar.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  score: PropTypes.number,
  maxScore: PropTypes.number,
  minScore: PropTypes.number,
  styles: PropTypes.object,
};

export default LinearProgressBar;
