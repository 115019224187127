import { makeStyles } from '@material-ui/core/styles';
import HomeHeroBG from 'assets/home/section-hero.svg';

const useStyles = makeStyles((theme) => ({
  Job: {},
  HeroSection: {
    background: `url(${HomeHeroBG})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    maxWidth: 1920,
    margin: '0 auto',
    marginBottom: 120,
    [theme.breakpoints.down('md')]: {
      marginBottom: 60,
    },
  },
  HeroSection__contents: {
    paddingTop: 240,
    paddingBottom: 160,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 240,
      paddingBottom: 30,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 80,
    },
  },
  HeroSection__text: {
    flex: 1,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  HeroSection__video: {
    flex: 1,
    position: 'relative',
    width: '100%',
  },
  // HeroSection__video__iframe: {
  //   border: 'none',
  //   width: 'auto',
  //   height: 360,
  // },
  HeroSection__video__player: {
    maxWidth: 540,
    '& video': {
      borderRadius: 16,
      boxShadow: '0 4px 15px 0 rgba(7, 42, 68, 0.1)',
    },
  },
  HeroSection__title: {
    marginBottom: 24,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
      marginRight: 5,
    },
  },

  HeroSection__subTitle: {
    marginBottom: 30,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
    },
  },
  HeroSection__description: {
    fontSize: 14,
    lineHeight: 1.29,
    marginBottom: 15,
    textTransform: 'uppercase',
    color: theme.palette.text.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  HeroSection__signUpBtn: {
    backgroundColor: theme.palette.primary.light,
    fontWeight: 500,
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#7bc6e6',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  HeroSection__job__inner: {
    marginTop: -20,
    paddingTop: 0,
  },
  HeroSection__oscerCharacter: {
    position: 'absolute',
    width: '55%',
    maxWidth: 280,
    bottom: -214,
    left: -208,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  Job___wysiwyg: {
    maxWidth: 720,
    marginBottom: 30,
    '& h1': {
      textAlign: 'center',
      color: theme.palette.text.main,
      fontSize: 32,
    },
    '& h2': {
      marginBottom: 15,
      color: theme.palette.text.main,
      fontSize: 28,
    },
    '& h3': {
      margin: '10px 0',
      color: theme.palette.text.main,
      fontSize: 24,
    },
    '& div': {
      fontSize: 18,
      lineHeight: 1.56,
      color: theme.palette.text.main,
    },
    '& ul': {
      marginLeft: 40,
      '& li': {
        marginBottom: 10,
        fontWeight: 'normal',
      },
    },
  },
  HeroSection__overlay1: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: 170,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HeroSection__overlay2: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: 170,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HeroSection__overlay4: {
    position: 'absolute',
    right: 0,
    top: 200,
    maxWidth: 170,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
