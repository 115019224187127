import produce from 'immer';

import {
  LEARN_LIST_SET,
  LEARN_INDEX_SET,
  LEARN_MODE_SESSION_ID_SET,
  LEARN_MODE_NEXT_QUESTION_SET,
  LEARN_MODE_LAST_RESPONSE_SET,
  LEARN_MODE_SESSION_SET,
  FILTERED_LEARNS_SET,
  CAN_NEXT_MODULE_SET,
} from '../actions/types';

const initialState = {
  allLearns: [],
  learnIndex: 0,
  sessionId: null,
  nextQuestionOption: '',
  currentCategoryScore: 0,
  lastResponseMessage: {
    current_category_index: '1',
    category_complete_flag: false,
    complete_flag: false,
  },
  session: {
    learn_rubric: {
      categories: {},
    },
  },
  filteredLearns: [],
  canNextModule: false,
};

const learnReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LEARN_LIST_SET:
        draft.allLearns = action.allLearns;
        break;
      case LEARN_INDEX_SET:
        draft.learnIndex = action.learnIndex;
        break;
      case LEARN_MODE_SESSION_ID_SET:
        draft.sessionId = action.sessionId;
        break;
      case LEARN_MODE_NEXT_QUESTION_SET:
        draft.nextQuestionOption = action.nextQuestionOption;
        break;
      case LEARN_MODE_LAST_RESPONSE_SET:
        draft.lastResponseMessage = action.lastResponseMessage;
        // update the canNextModule as well
        if (action.lastResponseMessage.category_complete_flag) {
          draft.canNextModule = true;
        }
        break;
      case FILTERED_LEARNS_SET:
        draft.filteredLearns = action.cases;
        break;
      case LEARN_MODE_SESSION_SET:
        // clean up previous state
        draft.canNextModule = false;
        draft.session = action.session;

        // draft.currentCategory = currentCategory;
        break;
      case CAN_NEXT_MODULE_SET:
        draft.canNextModule = action.canNextModule;
        break;
      default:
        break;
    }
  });

export default learnReducer;
