import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style.scss';

function RoundButton(props) {
  const {
    fontSize,
    borderRadius,
    fontColor,
    backgroundColor,
    style,
    className,
    children,
    onClick,
    animationColor,
    timeOut,
    ...buttonProps
  } = props;
  const [isClicked, setClicked] = React.useState(false);
  const buttonStyle = {
    fontSize,
    borderRadius,
    backgroundColor,
    color: fontColor,
  };

  return (
    <button
      style={{ ...style, ...buttonStyle, outline: 'none' }}
      className={classnames(className, 'animated-btn')}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </button>
  );
}

RoundButton.propTypes = {
  fontSize: PropTypes.string,
  borderRadius: PropTypes.string,
  backgroundColor: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  fontColor: PropTypes.string,
  onClick: PropTypes.func,
  animationColor: PropTypes.string,
  timeOut: PropTypes.number,
};

export default RoundButton;
