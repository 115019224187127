import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Hint: {
    display: 'flex',
    flexDirection: 'column',
  },
  Hint__label: {
    display: 'flex',
    alignItems: 'center',
  },
  Hint__label__icon: {
    marginRight: 5,
  },
  Hint__btns: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  Hint__btn: {
    width: 68,
    height: 32,
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: 0,
    marginBottom: 10,
    color: theme.palette.text.main,
    backgroundColor: theme.palette.inherit.main,
    '&:hover': {
      // boxShadow: 'none',
      backgroundColor: `${theme.palette.inherit.main}E6`,
    },
    '&.Mui-disabled': {
      color: `${theme.palette.text.main}4D`,
      backgroundColor: theme.palette.inherit.main,
    },
  },
  Hint__btn__placeholder: {
    width: 68,
    height: 32,
    marginBottom: 5,
  },
}));

export default useStyles;
