import { DIAGNOSIS_LIST_SET, DIAGNOSIS_SESSION_SET, DIAGNOSIS_START_SET } from './types';
import { APIClient } from '../settings';
import { clearMessages } from './messages';
import { setDiagnosisScore } from './cases';

export const setDiagnosisSession = (session) => ({
  type: DIAGNOSIS_SESSION_SET,
  session,
});

// Action to initialize the states in cases state
export const initializeDiagnosisModeSession = () => (dispatch) => {
  dispatch(clearMessages());
  dispatch(setDiagnosisSession({}));
};

export const setDiagnosisList = (diagnosisList) => ({
  type: DIAGNOSIS_LIST_SET,
  diagnosisList,
});

export const setDiagnosisStart = (payload) => ({
  type: DIAGNOSIS_START_SET,
  payload,
});

export const getDiagnosisList = () => (dispatch) =>
  APIClient.get(`/api/v1/agent/diagnosis/option-list/`).then((res) => {
    dispatch(setDiagnosisList(res.data));
  });

export const patchDiagnosisSession = ({ sessionId, completed, diagnosisList }) => (dispatch) =>
  APIClient.patch(`/api/v1/agent/diagnosis/session/${sessionId}/`, {
    completed,
    ddx_list: diagnosisList,
  }).then((res) => {
    dispatch(setDiagnosisSession(res.data));
    dispatch(setDiagnosisScore({ id: res.data.case_id, percentile: res.data.percentile }));
  });

export const getDiagnosisSession = (sessionId) => (dispatch) =>
  APIClient.get(`/api/v1/agent/diagnosis/session/${sessionId}/`).then((res) => {
    dispatch(setDiagnosisSession(res.data));
  });
