import React from 'react';
import PropTypes from 'prop-types';

import { MdClose } from 'react-icons/md';
import { RoundButton } from '..';

function CloseButton({ onClick, size = 30, className }) {
  return (
    <RoundButton
      className={className}
      animationColor="black"
      backgroundColor="white"
      style={{ border: 'none' }}
      onClick={onClick}
    >
      <MdClose size={size} />
    </RoundButton>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default CloseButton;
