import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DividerWithText: {
    borderBottom: '1px solid var(--secondary-color-2-o1)',
    width: '100%',
    marginTop: 25,
    textAlign: 'center',
    lineHeight: '0.01em',

    '& span': {
      fontSize: 14,
      backgroundColor: 'var(--neutral-color-2)',
      padding: '0 5px',
      color: 'var(--secondary-color-2-o4)',
    },
  },
}));

export default useStyles;
