import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Dashboard__tabMenu: {
    borderBottom: 'solid 1px #00234833',
    color: '#00234899',

    '& .Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  },
  Dashboard__tour: {
    maxWidth: 320,
    borderTop: '4px solid #83d0f0',
    borderRadius: '0px 16px 16px',
    padding: 16,
  },
  Dashboard__tour__mask: {
    opacity: 0.4,
  },
  Dashboard__tour__highlightmask: {
    stroke: theme.palette.primary.light,
    strokeWidth: 4,
    rx: 12,
  },
}));

export default useStyles;
