import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { trackEvent } from '../../../../service/analytics';
import iconCrownGold from '../../../../assets/icons/prime-crown-icon-gold.svg';
import useStyles from './style';

const UpgradeHeader = (props) => {
  const classes = useStyles();
  const { isPrime, subscriptionDaysLeft } = props;
  const location = useLocation();

  const renewHeader = (
    <div className={classes.UpgradeHeader}>
      <img src={iconCrownGold} alt="crown" className={classes.UpgradeHeader__crown} />
      <span className={classes.UpgradeHeader__text}>Your Prime subscription ends in</span>
      <span className={classes.UpgradeHeader__days}>{subscriptionDaysLeft}</span>
      <span className={classes.UpgradeHeader__text}>days.</span>
      <Button
        variant="contained"
        to={{
          pathname: '/compare',
          state: { from: location.pathname },
        }}
        component={Link}
        classes={{ root: classes.UpgradeHeader__btn__renew }}
        onClick={() => {
          trackEvent({
            GAAction: 'Click Renew',
            FBAction: 'Click Renew',
            category: 'Upgrade',
            label: `User click renew button from dashboard banner`,
          });
        }}
      >
        Renew Now
      </Button>
    </div>
  );

  const upgradeHeader = (
    <div className={classes.UpgradeHeader}>
      <img src={iconCrownGold} alt="crown" className={classes.UpgradeHeader__crown} />
      <span className={classes.UpgradeHeader__text}>Upgrade to Oscer Prime to access all cases.</span>
      <Button
        variant="contained"
        to={{
          pathname: '/compare',
          state: { from: location.pathname },
        }}
        component={Link}
        classes={{ root: classes.UpgradeHeader__btn__renew }}
        onClick={() => {
          trackEvent({
            GAAction: 'Click Upgrade',
            FBAction: 'Click Upgrade',
            category: 'Upgrade',
            label: `User click upgrade button from dashboard banner`,
          });
        }}
      >
        Upgrade Now
      </Button>
    </div>
  );

  const headerSelector = () => {
    if (isPrime) {
      if (subscriptionDaysLeft > 0 && subscriptionDaysLeft < 8) {
        return renewHeader;
      }
      return <></>;
    }
    return upgradeHeader;
  };

  return <>{headerSelector()}</>;
};

UpgradeHeader.propTypes = {
  isPrime: PropTypes.bool,
  subscriptionDaysLeft: PropTypes.number,
};

export default UpgradeHeader;
