/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import cx from 'classnames';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Modal } from '../../common';
import useStyles from './style';
import { useWidth } from 'helpers/customHooks';

const WelcomeAlert = (props) => {
  const classes = useStyles();
  const screenSize = useWidth();

  const { notifications, isToggle, toggle, firstAvailableCase } = props;

  const ModeSlider = useRef(null);

  // Remember current notification step
  const [currentNotiId, setCurrentNotiId] = useState(notifications[0].id);
  // const [isVideoLoading, setVideoLoading] = useState(false);

  const modeSelectionSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
    // dots: true,
  };

  const createLineText = (text) => {
    const newText = text.split('\n').map((str) => <p>{str}</p>);
    return newText;
  };

  const handleSlideNext = (id) => {
    // setCurrentNotiId(id);
    // toggle(id, true);
    ModeSlider.current.slickNext();
  };

  const handleSlideBefore = (id) => {
    // setCurrentNotiId(id);
    // toggle(id, true);
    ModeSlider.current.slickPrev();
  };

  const setAllNotificationsRead = () => {
    for (let notif of notifications) {
      toggle(notif.id, true);
    }
  };

  // !todo: this logic only handle diagnosis with redirect link to a specific case
  const redirectLogic = (title, caseId, link) => {
    if (title === 'Race to Diagnosis') {
      if (caseId) {
        return `${link}${caseId}`;
      }
      return `/dashboard/home`;
    }
    if (link) {
      return link;
    }
    return '/dashboard/home';
  };

  const mediaSelector = (video, image) => {
    if (video) {
      // setVideoLoading(true);
      return (
        <div className={classes.WelcomeAlert_media}>
          {/* {isVideoLoading && <CircularProgress />}
          <video width="100%" autoPlay loop muted playsInline onLoadEnd={() => setVideoLoading(false)}>
            <source src="http://localhost:3000/static/media/oscer-gif_desktop_large.29c918c2.mp4" type="video/mp4" />
          </video> */}

          <video width="100%" autoPlay loop muted playsInline>
            <source src={video} type="video/mp4" />
          </video>
        </div>
      );
    }
    if (image) {
      return <img src={image} alt="image_alert" className={classes.WelcomeAlert__image} />;
    }
    return <></>;
  };

  const modalRef = useRef(null);
  const [modalHeight, setModalHeight] = useState(509);

  // useEffect(() => {
  //   if (modalRef && modalRef.current && modalRef.current.offsetHeight) {
  //     // setTimeout(() => {
  //       setModalHeight(modalRef.current?.offsetHeight);
  //     // }, 3000);
  //   }
  // }, [modalRef?.current?.clientHeight]);

  return (
    <Modal
      isToggle={isToggle}
      toggle={() => {
        setAllNotificationsRead();
      }}
      centered
      closable
      zIndex={9999}
    >
      <div className={classes.WelcomeAlert__wrapper_main} ref={modalRef}>
        <ClearIcon
          classes={{ root: classes.WelcomeAlert__close }}
          onClick={() => {
            setAllNotificationsRead();
          }}
        />
        <Slider {...modeSelectionSetting} ref={ModeSlider}>
          {notifications.map((notification, index) => (
            <div key={notification.id}>
              <div className={classes.WelcomeAlert} style={{ height: modalHeight }}>
                <div className={classes.WelcomeAlert__ArrowLeft}>
                  {screenSize !== 'xs' && index !== 0 && (
                    <Button onClick={() => handleSlideBefore(notification.id)}>
                      {' '}
                      <KeyboardArrowLeftIcon fontSize="large" />{' '}
                    </Button>
                  )}
                </div>
                <div className={classes.WelcomeAlert__content}>
                  <div className={classes.WelcomeAlert__text}>NEW FEATURE ALERT</div>
                  <div className={classes.WelcomeAlert__title}>{notification.title}</div>
                  <div className={classes.WelcomeAlert__subtitle}>{createLineText(notification.message)}</div>
                  {mediaSelector(notification.video, notification.image)}
                  <div className={classes.WelcomeAlert__wrapper}>
                    {notifications.length &&
                      notifications.length > 1 &&
                      index === notifications.length - 1 &&
                      (notification.link ? (
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={redirectLogic(notification.title, firstAvailableCase, notification.link)}
                          onClick={
                            index === notifications.length - 1
                              ? () => setAllNotificationsRead()
                              : () => handleSlideNext(notification.id)
                          }
                          classes={{ contained: classes.WelcomeAlert__btn }}
                        >
                          {index === notifications.length - 1 ? `${notification.button_text || 'Try It Out'}` : 'Next'}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={
                            index === notifications.length - 1
                              ? () => setAllNotificationsRead()
                              : () => handleSlideNext(notification.id)
                          }
                          classes={{ contained: classes.WelcomeAlert__btn }}
                        >
                          {index === notifications.length - 1 ? `${notification.button_text || 'Try It Out'}` : 'Next'}
                        </Button>
                      ))}
                  </div>
                  {notifications.length > 1 && (
                    <div className={classes.WelcomeAlert__dots}>
                      {range(notifications.length).map((noti, dotIndex) => (
                        <FiberManualRecordIcon
                          key={noti}
                          classes={{
                            root: cx(classes.WelcomeAlert__dot, {
                              [classes.WelcomeAlert__dot__light]: dotIndex !== index,
                            }),
                          }}
                        />
                      ))}
                    </div>
                  )}
                  {/* <div className={classes.WelcomeAlert__error}>{error}</div> */}
                </div>
                <div className={classes.WelcomeAlert__ArrowRight}>
                  {screenSize !== 'xs' && index !== notifications.length - 1 && (
                    <Button onClick={() => handleSlideNext(notification.id)}>
                      {' '}
                      <KeyboardArrowRightIcon fontSize="large" />{' '}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Modal>
  );
};

WelcomeAlert.propTypes = {
  notifications: PropTypes.array,
  isToggle: PropTypes.number,
  toggle: PropTypes.func,
  firstAvailableCase: PropTypes.string,
};

export default WelcomeAlert;
