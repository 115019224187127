import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useUserGroup = (props) => {
  const { whiteList = [], blackList = [] } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);

  const userGroups = useSelector((state) => state.auth.user.groups);

  useEffect(() => {
    if (userGroups.length) {
      setIsAuthenticated(!!userGroups.find((userGroup) => whiteList.includes(userGroup.name)));
      setIsBlocked(!!userGroups.find((userGroup) => blackList.includes(userGroup.name)));
    }
  }, [userGroups]);

  return [userGroups, isAuthenticated, isBlocked];
};

useUserGroup.propTypes = {};

export default useUserGroup;
