import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  MobileUserTour: {},
  MobileUserTour__main: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
  },
}));

export default useStyles;
