import React from 'react';
import { Typography } from '@material-ui/core';
import WistiaPlayer from '../../../components/WistiaPlayer';
import HomeContainer from '../../../components/Container/HomeContainer';
import heroOverlayImg from '../../../assets/home/section-hero-overlay-1.svg';
import useStyles from './style';

const Hero = () => {
  const classes = useStyles();
  return (
    <HomeContainer
      outerStyles={classes.InstitutionHero__container__outer}
      innerStyles={classes.InstitutionHero__container__inner}
    >
      <p className={classes.InstitutionHero__tag}>INSTITUTIONS</p>
      <Typography variant="h1" className={classes.InstitutionHero__title} gutterBottom>
        We're proud to work with the world's best institutions
      </Typography>
      <div className={classes.InstitutionHero__video__player}>
        <WistiaPlayer videoId="g5ioomzwj9" />
      </div>
      <img src={heroOverlayImg} alt="hero_overlay" className={classes.InstitutionHero__overlay__left} />
    </HomeContainer>
  );
};

export default Hero;
