import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  UntiringPatients: {
    position: 'relative',
  },
  UntiringPatients__container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  UntiringPatients__left: {
    flex: 1,
    marginRight: 60,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginRight: 20,
      flexDirection: 'column',

      marginBottom: 30,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      marginRight: 0,
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  UntiringPatients__right: {
    flex: 1,
    alignSelf: 'stretch',
  },
  UntiringPatients__chatWindow: {
    maxWidth: '100%',
  },
}));

export default useStyles;
