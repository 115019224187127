import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { loadUser } from '../../actions/auth';
import { trackUserId } from '../../service/analytics';
import Home from '../Home';
import Institutions from '../Institutions';
import Technology from '../Technology';
import Jobs from '../Jobs';
import Job from '../Job';
import Case from '../Case';
import About from '../About';
import Plans from '../Plans';
import CreatePrimeAccount from '../CreatePrimeAccount';
import Verfication from '../Verification';
import ErrorBoundary from '../../components/ErrorBoundary';
import { AuthOnlyRoute, NonAuthOnlyRoute, HomeRoute, DashboardRoute, SAMLRoute } from './PrivateRoute';
import OuterHelp from '../OuterHelp';
import NotFound from '../NotFound';
import CaseHistory from '../CaseHistory';

// const Leaderboard = React.lazy(() => import('../Leaderboard'));
const Account = React.lazy(() => import('../Account'));
const ReferralSignUp = React.lazy(() => import('../ReferralSignUp'));
const QuizQuestions = React.lazy(() => import('../QuizQuestions'));
const QuizResult = React.lazy(() => import('../QuizResult'));
// Dashboard
const Dashboard = React.lazy(() => import('../Dashboard'));
const SignInContainer = React.lazy(() => import('../SignIn'));
const SAML2Verification = React.lazy(() => import('../SAML2Verification'));
const SAML2Login = React.lazy(() => import('../SAML2Login'));
const SignUp = React.lazy(() => import('../SignUp'));
const SignUpSocial = React.lazy(() => import('../SignupSocial'));
const ForgotPassword = React.lazy(() => import('../ForgotPassword'));
const RestorePassword = React.lazy(() => import('../RestorePassword'));
const SinglePlayerRubric = React.lazy(() => import('../SinglePlayerRubric'));
const PracticeWithFriends = React.lazy(() => import('../PracticeWithFriends'));
const DailytaskQuestion = React.lazy(() => import('../DailytaskQuestion'));
const PWFScript = React.lazy(() => import('../PWFScript'));
const PWFResultRubric = React.lazy(() => import('../PWFResultRubric'));
const CaseSummary = React.lazy(() => import('../CaseSummary'));
const PWFQuickMode = React.lazy(() => import('../PWFQuickMode'));
const LearnMode = React.lazy(() => import('../LearnMode'));
const Diagnosis = React.lazy(() => import('../DiagnosisMode'));
const DiagnosisResult = React.lazy(() => import('../DiagnosisResult'));
const CasePreview = React.lazy(() => import('../CasePreview'));
const Upgrade = React.lazy(() => import('../Upgrade'));
const FollowUpQuestions = React.lazy(() => import('../FollowUpQuestions'));
const SignupSurvey = React.lazy(() => import('../SignupSurvey'));
const DashboardHome = React.lazy(() => import('../DashboardHome'));
const DashboardCases = React.lazy(() => import('../DashboardCases'));
const DashboardScripts = React.lazy(() => import('../DashboardScripts'));
const DashboardLearn = React.lazy(() => import('../DashboardLearn'));
const ConditionExplorer = React.lazy(() => import('../ConditionExplorer'));
const DashboardSearch = React.lazy(() => import('../DashboardSearch'));
const DashboardProfile = React.lazy(() => import('../Profile'));
const UpgradeCompare = React.lazy(() => import('../Upgrade/Compare'));

const App = (props) => {
  window.Intercom('boot', {
    app_id: 'xyqioc2g',
  });

  const trackingCode = () => (
    <Helmet>
      {/* Hotjar */}
      <script>
        {`
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2232518,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `}
      </script>
      {/* Facebook Pixel */}
      <script>
        {`!(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1630750960310529');
      fbq('track', 'PageView');`}
      </script>
    </Helmet>
  );

  const caseContainer = () => (
    // <div className="pusher">
    <Switch>
      <AuthOnlyRoute exact path="/cases/:caseId/journey/:journeyId" component={Case} />
      <AuthOnlyRoute exact path="/cases/:caseId/spm" component={Case} />
      <AuthOnlyRoute exact path="/cases/:caseId/session_id/:session_id/summary" component={CaseSummary} />
      <AuthOnlyRoute exact path="/cases/:id/session_id/:session_id/questions" component={FollowUpQuestions} />
      <AuthOnlyRoute exact path="/cases/:id/session_id/:session_id/rubric" component={SinglePlayerRubric} />
      <AuthOnlyRoute
        exact
        path="/cases/:caseId/journey/:journeyId/session_id/:session_id/summary"
        component={CaseSummary}
      />
      <AuthOnlyRoute
        exact
        path="/cases/:id/journey/:journeyId/session_id/:session_id/questions"
        component={FollowUpQuestions}
      />
      <AuthOnlyRoute
        exact
        path="/cases/:id/journey/:journeyId/session_id/:session_id/rubric"
        component={SinglePlayerRubric}
      />
    </Switch>
    // </div>
  );

  const pwfContainer = () => (
    <Switch>
      {/* <AuthOnlyRoute exact path="/pwf/script/:session_id" component={PWFScript} /> */}
      <AuthOnlyRoute exact path="/pwf/script/:session_id" component={PWFQuickMode} />
      <AuthOnlyRoute exact path="/pwf/preview/:session_id" component={PWFScript} />
      <AuthOnlyRoute exact path="/pwf/rubric/:session_id" component={PWFResultRubric} />
      {/* <AuthOnlyRoute exact path="/pwf/:session_id" component={PracticeWithFriends} /> */}
      <AuthOnlyRoute exact path="/pwf/:session_id" component={PWFQuickMode} />
    </Switch>
  );

  const quizContainer = () => (
    <Switch>
      <AuthOnlyRoute exact path="/quiz/:conditionId" component={QuizQuestions} />
      <AuthOnlyRoute exact path="/quiz/:conditionId/:quizId/result" component={QuizResult} />
    </Switch>
  );

  const learnContainer = () => (
    <Switch>
      <AuthOnlyRoute exact path="/learn/:caseId/" component={LearnMode} />
    </Switch>
  );

  const diagnosisContainer = () => (
    <Switch>
      <AuthOnlyRoute exact path="/diagnosis/:caseId/" component={Diagnosis} />
      {/* <AuthOnlyRoute exact path="/diagnosis/:caseId/session/:session_id/" component={Diagnosis} /> */}
      <AuthOnlyRoute exact path="/diagnosis/:caseId/session_id/:session_id/result" component={DiagnosisResult} />
    </Switch>
  );

  useEffect(() => {
    props.loadUser().then((res) => {
      // Reset user tour if website refresh
      if (res.profile?.user_tour_flags?.onboarding_flag === 'TODO') {
        localStorage.removeItem('userTour');
      }
    });
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      trackUserId(props.user);
    }
    // append JS that WistiaPlayer requires
    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script2);
    };
  }, [props.isAuthenticated]);

  // get locale
  const supportLocale = ['au', 'uk'];
  const locale = window.location.pathname.split('/')[1];
  const baseName = supportLocale.includes(locale) ? locale : '';

  //! router tips: children of Switch must only be route
  return (
    <>
      {/* {country || error ? ( */}
      <ErrorBoundary>
        {process.env.REACT_APP_STAGE === 'production' && trackingCode()}
        {props.isAuthenticated !== null && (
          <Router basename={baseName} onUpdate={() => window.userpilot.reload()}>
            <Switch>
              <SAMLRoute path="/saml2/login" component={SAML2Login} />
              <SAMLRoute path="/saml2/verify" component={SAML2Verification} />
              <SAMLRoute path="/lti/verify" component={SAML2Verification} />
              <HomeRoute exact path="/" component={Home} />
              <HomeRoute exact path="/institutions" component={Institutions} />
              <HomeRoute exact path="/about" component={About} />
              <HomeRoute exact path="/technology" component={Technology} />
              <HomeRoute exact path="/careers" component={Jobs} />
              <HomeRoute exact path="/careers/:job_name" component={Job} />
              <HomeRoute exact path="/plans" component={Plans} />
              <HomeRoute exact path="/plans/create-prime-account" component={CreatePrimeAccount} />
              <NonAuthOnlyRoute exact path="/signin" component={SignInContainer} />
              <NonAuthOnlyRoute exact path="/signup" component={SignUp} />
              <NonAuthOnlyRoute exact path="/signup/:social" component={SignUpSocial} />
              <NonAuthOnlyRoute exact path="/forgot_password" component={ForgotPassword} />
              <NonAuthOnlyRoute exact path="/reset_password/:key" component={RestorePassword} />
              {/* <NonAuthOnlyRoute exact path="/ref/:code" component={ReferralSignUp} /> */}
              <Route exact path="/dailytask-case/:case_name" component={DailytaskQuestion} />
              <Route path="/cases" component={caseContainer} />
              <Route path="/pwf" component={pwfContainer} />
              <Route path="/quiz" component={quizContainer} />
              <Route path="/learn" component={learnContainer} />
              <Route path="/diagnosis" component={diagnosisContainer} />
              <AuthOnlyRoute exact path="/verify" component={SignupSurvey} />
              <AuthOnlyRoute exact path="/upgrade" component={Upgrade} />
              <AuthOnlyRoute exact path="/compare" component={UpgradeCompare} />
              <NonAuthOnlyRoute exact path="/verify/:token" component={Verfication} />
              <DashboardRoute exact path="/account" component={Account} />
              {/* <DashboardRoute excat path="/dashboard/:activeTab" component={DashboardHome} /> */}
              <DashboardRoute excat path="/dashboard/home" component={DashboardHome} />
              <DashboardRoute excat path="/dashboard/cases" component={DashboardCases} />
              <DashboardRoute excat path="/dashboard/scripts" component={DashboardScripts} />
              <DashboardRoute excat path="/dashboard/learn" component={DashboardLearn} />
              <DashboardRoute excat path="/dashboard/explorer" component={ConditionExplorer} />
              <DashboardRoute excat path="/search" component={DashboardSearch} />
              <DashboardRoute excat path="/profile" component={DashboardProfile} />
              <DashboardRoute excat path="/dashboard" component={DashboardHome} />
              {/* <DashboardRoute exact path="/leaderboard" component={Leaderboard} /> */}
              <DashboardRoute exact path="/help" component={OuterHelp} />
              <DashboardRoute exact path="/case-history" component={CaseHistory} />
              {/* some route that for internal use only */}
              <AuthOnlyRoute exact path="/admin/case/:caseId/preview/" component={CasePreview} />
              {/* note below is the final router, don't put any router after this */}
              <HomeRoute path="/*" component={NotFound} />
            </Switch>
          </Router>
        )}
      </ErrorBoundary>
      {/* ) : (
        <div className="application__loader">
          <OscerLoader />
        </div>
      )} */}
    </>
  );
};

App.propTypes = {
  loadUser: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(App);
