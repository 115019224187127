import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import IconPreminum from '../../icons/IconPreminum';
import userNameGenerator from '../../../helpers/userNameGenerator';

import useStyles from './style';

const CaseInterfaceProfile = (props) => {
  const classes = useStyles();
  const { user, isPrime, setInfoToggle } = props;

  const patientInfoToggle = () => {
    setInfoToggle((toggle) => !toggle);
  };

  const removeEmailDomain = (name) => name.replace(/@.*$/, '');

  return (
    <div className={classes.CaseInterfaceProfile}>
      <Avatar className={classes.CaseInterfaceProfile__avatar} onClick={patientInfoToggle}>
        {userNameGenerator(user)
          .split(' ')
          .map((nameCap) => (
            <span key={nameCap} className={classes.CaseInterfaceProfile__avatar__name}>
              {nameCap.charAt(0).toUpperCase()}
            </span>
          ))}
        {isPrime && <IconPreminum size={36} className={classes.CaseInterfaceProfile__avatar__crown} />}
      </Avatar>
      <div className={classes.CaseInterfaceProfile__name}>{removeEmailDomain(userNameGenerator(props.user))}</div>
    </div>
  );
};

CaseInterfaceProfile.propTypes = {
  user: PropTypes.object,
  isPrime: PropTypes.bool,
  setInfoToggle: PropTypes.func,
};

export default CaseInterfaceProfile;
