import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PatientInfoModal from '../../Modals/PatientInfoModal';
import PresentationImg from '../../../../components/icons/PresentationImg';
import { PRESENTATION_ICON_MEDIUM, PRESENTATION_ICON_SMALL } from '../../../../constants';
import useStyles from './style';

const ProfilePanelMobile = (props) => {
  const classes = useStyles();
  const { currentCase } = props;
  // Patient info toggle
  const [isInfoToggle, setInfoToggle] = useState(false);

  const patientInfoToggle = () => {
    setInfoToggle((toggle) => !toggle);
  };

  return (
    <div className={classes.ProfilePanelMobile}>
      <PatientInfoModal currentCase={currentCase} isInfoToggle={isInfoToggle} infoToggle={patientInfoToggle} />
      <div className={classes.ProfilePanelMobile__icon__wrapper} onClick={patientInfoToggle} aria-hidden="true">
        <PresentationImg
          className={classes.ProfilePanelMobile__icon}
          presentation={currentCase.presentation}
          size={PRESENTATION_ICON_SMALL}
        />
      </div>
    </div>
  );
};

ProfilePanelMobile.propTypes = {
  currentCase: PropTypes.object,
};

export default ProfilePanelMobile;
