import React from 'react';
import PropTypes from 'prop-types';

import Cardio from '../../../assets/system/cardiology-icon.svg';
import Dermatology from '../../../assets/system/dermatology-rotated-icon.svg';
import Endocrine from '../../../assets/system/endocrine-icon.svg';
import Gastro from '../../../assets/system/gastrology-icon.svg';
import Musculo from '../../../assets/system/musculoskeletal-icon.svg';
import Neuro from '../../../assets/system/neurology-icon.svg';
import Onc from '../../../assets/system/onc-icon.svg';
import Renal from '../../../assets/system/renal-icon.svg';
import Respiratory from '../../../assets/system/respiratory-icon.svg';
import Gynecology from '../../../assets/system/gynecology-icon.svg';
import Psychiatric from '../../../assets/system/psychiatric-icon.svg';
import Rheumatological from '../../../assets/system/rheumatological-icon.svg';
import Genitourinary from '../../../assets/system/genitourinary-icon.svg';
import Ent from '../../../assets/system/ent-icon.svg';
import Ophthalmological from '../../../assets/system/ophthalmological-icon.svg';

import {
  SYSTEM_CARDIO,
  SYSTEM_DERMATOLOGY,
  SYSTEM_ENDOCRINE,
  SYSTEM_GASTRO,
  SYSTEM_MUSCULO,
  SYSTEM_NEURO,
  SYSTEM_ONC,
  SYSTEM_RENAL,
  SYSTEM_RESPIRATORY,
  SYSTEM_PSYCHIATRIC,
  SYSTEM_GYNECOLOGY,
  SYSTEM_CARDIO2,
  SYSTEM_DERMATOLOGY2,
  SYSTEM_ENDOCRINE2,
  SYSTEM_GASTRO2,
  SYSTEM_NEURO2,
  SYSTEM_ONC2,
  SYSTEM_RENAL2,
  SYSTEM_RESPIRATORY2,
  SYSTEM_PSYCHIATRIC2,
  SYSTEM_GYNECOLOGY2,
  SYSTEM_MUSCULO2,
  SYSTEM_GENITOURINARY,
  SYSTEM_OPHTHALMOLOGICAL,
  SYSTEM_RHEUMATOLOGICAL,
  SYSTEM_ENT,
} from '../../../constants';

const systemIcons = {
  [SYSTEM_CARDIO]: Cardio,
  [SYSTEM_DERMATOLOGY]: Dermatology,
  [SYSTEM_ENDOCRINE]: Endocrine,
  [SYSTEM_GASTRO]: Gastro,
  [SYSTEM_MUSCULO]: Musculo,
  [SYSTEM_NEURO]: Neuro,
  [SYSTEM_ONC]: Onc,
  [SYSTEM_RENAL]: Renal,
  [SYSTEM_RESPIRATORY]: Respiratory,
  [SYSTEM_PSYCHIATRIC]: Psychiatric,
  [SYSTEM_GYNECOLOGY]: Gynecology,
  // new
  [SYSTEM_CARDIO2]: Cardio,
  [SYSTEM_DERMATOLOGY2]: Dermatology,
  [SYSTEM_ENDOCRINE2]: Endocrine,
  [SYSTEM_GASTRO2]: Gastro,
  [SYSTEM_NEURO2]: Neuro,
  [SYSTEM_ONC2]: Onc,
  [SYSTEM_RENAL2]: Renal,
  [SYSTEM_RESPIRATORY2]: Respiratory,
  [SYSTEM_PSYCHIATRIC2]: Psychiatric,
  [SYSTEM_GYNECOLOGY2]: Gynecology,
  [SYSTEM_MUSCULO2]: Musculo,
  [SYSTEM_GENITOURINARY]: Genitourinary,
  [SYSTEM_OPHTHALMOLOGICAL]: Ophthalmological,
  [SYSTEM_RHEUMATOLOGICAL]: Rheumatological,
  [SYSTEM_ENT]: Ent,
};

const SystemIcon = (props) => (
  <img src={systemIcons[props.system]} alt={props.system} width={props.size} className={props.className} />
);

SystemIcon.propTypes = { system: PropTypes.string, size: PropTypes.number, className: PropTypes.string };

export default SystemIcon;
