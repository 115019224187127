import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Typography, Button, Link as materialLink } from '@material-ui/core';
import { getVideoMeta } from 'helpers/video';
import HomeContainer from '../../components/Container/HomeContainer';
import WistiaPlayer from '../../components/WistiaPlayer';
import { getJob } from '../../actions/home';
import NotFound from '../NotFound';
import shape3 from '../../assets/home/jobs-role-hero-shape-1.svg';
import shape4 from '../../assets/home/jobs-role-hero-shape-2.svg';
import shape5 from '../../assets/home/jobs-role-shape.svg';
import shape6 from '../../assets/home/shape-7.svg';
import useStyles from './style';

const Job = (props) => {
  const classes = useStyles();
  const { job_name } = useParams();

  const [jobData, setJobData] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    getJob('Jobs', job_name)
      .then((res) => {
        setJobData(res.data);
      })
      .catch((err) => setError(true));
  }, []);

  const cleanHTML = DOMPurify.sanitize(jobData?.elements?.WYCSWYG?.wycswyg);

  return (
    <div>
      {error ? (
        <NotFound />
      ) : (
        <>
          <HomeContainer outerStyles={classes.HeroSection} innerStyles={classes.HeroSection__contents}>
            <img className={classes.HeroSection__overlay1} src={shape3} alt="shape-1" />
            <img className={classes.HeroSection__overlay2} src={shape4} alt="shape-2" />
            <div className={classes.HeroSection__text}>
              <Typography className={classes.HeroSection__description}>
                {jobData?.elements?.PageSubTitle?.main_text}
              </Typography>
              <Typography variant="h1" className={classes.HeroSection__title}>
                {jobData?.elements?.PageTile?.main_text}
              </Typography>
              <Typography variant="h3" className={classes.HeroSection__subTitle}>
                {jobData?.elements?.PageDescription?.main_text}
              </Typography>
              <Button
                className={classes.HeroSection__signUpBtn}
                variant="contained"
                color="primary"
                component={materialLink}
                target="_blank"
                href={jobData?.elements?.HeroButton?.link}
              >
                {jobData?.elements?.HeroButton?.main_text || 'Apply Now'}
              </Button>
            </div>
            <div className={classes.HeroSection__video}>
              {jobData?.elements?.PageHeroMedia?.file ? (
                <img src={jobData?.elements?.PageHeroMedia?.file} alt="img" className="" />
              ) : (
                <WistiaPlayer
                  videoId={getVideoMeta(jobData?.elements?.PageHeroMedia?.link).videoId}
                  videoLink={jobData?.elements?.PageHeroMedia?.link}
                />
              )}
            </div>
          </HomeContainer>
          <HomeContainer innerStyles={classes.HeroSection__job__inner}>
            <img className={classes.HeroSection__overlay1} src={shape6} alt="shape-3" />
            <img className={classes.HeroSection__overlay4} src={shape5} alt="shape-4" />
            <div
              className={classes.Job___wysiwyg}
              dangerouslySetInnerHTML={{
                __html: cleanHTML,
              }}
            />
            <Button
              className={classes.HeroSection__signUpBtn}
              variant="contained"
              color="primary"
              component={materialLink}
              target="_blank"
              href={jobData?.elements?.HeroButton?.link}
            >
              {jobData?.elements?.HeroButton?.main_text || 'Apply Now'}
            </Button>
          </HomeContainer>
        </>
      )}
    </div>
  );
};

Job.propTypes = {};

export default Job;
