import React, { useState, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ClickAwayListener, InputBase, InputAdornment } from '@material-ui/core';
import DropdownList from '../DropdownList';
import useStyles from './style';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

/**
 * @desc Multiselect dropdown with panel showing selections
 * @param {*} props
 * - options: dropdown values (string array/obj array)
 * - selectedList: list of values selected
 * - setSelectedList: function to set selectedList
 * - searchValue: input value
 * - setSearchValue: function to set input value
 * - handleInputChange: function to handle inputchange
 * - width: search bar, dropdown width
 * - marginTop: margin top of the search bar
 * - search: toggle to enable input search
 * - topic: value to be presented at the search bar e.g. 'Presentation'
 * - dropdownState: function to determine whether dropdown is opened
 * - sort: boolean -- whether sort is true in dropdown
 * - fieldToDisplay: name of field to be displayed if array is obj
 */
const DropdownMulti = (props) => {
  const cs = useStyles();
  const [searchVal, setSearchVal] = useState('');
  // Note: options can be array of string or array of object
  // Array of string requires fieldToDisplay to be default/'name'
  // Array of objects requires [{fieldToBeDisplayed: "anything"}], and the prop fields to display be the name of that key
  // e.g [{name: "Chest Pain", id: 2}], fieldToDisplay = "name" (default)
  const {
    options = [],
    selectedList = [],
    setSelectedList = () => {},
    searchValue = searchVal,
    setSearchValue = setSearchVal,
    handleInputChange = (e) => {
      setSearchVal(e.target.value);
    },
    // optional
    search = true,
    placeholder = 'Search',
    requiredIntro = false,
    requiredIntroText = '',
    topic = '',
    fieldToDisplay = 'name',
    sort = false,
    dropdownState = () => {},
    setDropdownHeight = () => {},
    style = {},
    className = {},
    level = false,
    system = false,
    tickEnd = false,
    seperator = false,
    clearIcon = false,
    light = false,
    locked = false,
    comingSoon = false,
  } = props;

  // input field active
  const [isActive, setActive] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [childHeight, setChildHeight] = useState(0);

  let textInput = useRef(null);
  const widthRef = useRef(null);

  // delete selections
  const handleDelete = (elem) => {
    const newList = selectedList.filter((item) => item !== elem);
    setSelectedList([...newList]);
    textInput.current.focus();
  };

  // The state to control the presentation click away listener
  const [systemClickAway, setSystemClickAway] = useState(false);

  // updates parent if dropdown is active
  useEffect(() => {
    dropdownState(systemClickAway);
  }, [systemClickAway]);

  // set input to be focused
  const setInputFocus = () => {
    textInput.current.focus();
  };

  // handles click outside the dropdown
  const handleClickAway = () => {
    setSearchValue('');
    setSystemClickAway(false);
  };

  // handles input active
  const handleInputBaseActive = () => {
    setActive((active) => !active);
  };

  const seperatorValue = () => {
    let str = '';
    for (let items of selectedList) {
      str += items[fieldToDisplay];
      str += ' | ';
    }
    return str.slice(0, str.length > 3 ? str.length - 3 : 0);
  };

  const clearAll = () => {
    setSelectedList([]);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    setDropdownWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  useEffect(() => {
    if (widthRef.current) {
      setDropdownHeight(childHeight + widthRef.current.offsetHeight);
    } else {
      setDropdownHeight(childHeight);
    }
  }, [selectedList, childHeight]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <InputBase
          inputRef={textInput}
          ref={elementRef}
          style={style}
          InputProps={{ className: cs.inputRoot }}
          classes={{
            root: cx(
              cs.FuzzyFilter__InputBase__root,
              {
                [cs.FuzzyFilter__InputBase__root__selected]: selectedList.length > 0 && !systemClickAway,
              },
              {
                [cs.FuzzyFilter__InputBase__root__selected__light]:
                  selectedList.length > 0 && !systemClickAway && light,
              },
              {
                [cs.FuzzyFilter__InputBase__root__required]:
                  requiredIntro && !systemClickAway && selectedList.length < 1,
              },
              className
            ),
          }}
          placeholder={placeholder}
          onChange={handleInputChange}
          readOnly={!search}
          value={
            !systemClickAway && selectedList.length < 1 && requiredIntro
              ? `${requiredIntroText}`
              : systemClickAway || selectedList.length < 1
              ? searchValue
              : seperator
              ? seperatorValue(selectedList)
              : `${topic} Selected (${selectedList.length})`
          }
          onFocus={() => {
            setActive(true);
            // Set system popup open
            setSystemClickAway(true);
          }}
          onBlur={handleInputBaseActive}
          endAdornment={
            <InputAdornment position="end">
              {clearIcon ? (
                <>
                  {selectedList.length > 0 && !systemClickAway ? (
                    <CancelRoundedIcon
                      className={cs.Input__RoundedIcon}
                      onClick={clearAll}
                      style={{ marginLeft: '5px' }}
                      fontSize="inherit"
                    />
                  ) : (
                    <ArrowDropDownIcon
                      onClick={() => {
                        setActive(!isActive);
                        // Set system popup open
                        setSystemClickAway(!systemClickAway);
                      }}
                      classes={{
                        root: cx(
                          cs.FuzzyFilter__SearchIcon__root,
                          {
                            [cs.FuzzyFilter__SearchIcon__root__selected]: selectedList.length > 0 && !systemClickAway,
                          },
                          {
                            [cs.FuzzyFilter__SearchIcon__root__selected__light]:
                              selectedList.length > 0 && !systemClickAway && light,
                          },
                          {
                            [cs.FuzzyFilter__SearchIcon__root__active]: isActive,
                          }
                        ),
                      }}
                    />
                  )}
                </>
              ) : (
                <ArrowDropDownIcon
                  onClick={() => {
                    setActive(!isActive);
                    // Set system popup open
                    setSystemClickAway(!systemClickAway);
                  }}
                  classes={{
                    root: cx(
                      cs.FuzzyFilter__SearchIcon__root,
                      {
                        [cs.FuzzyFilter__SearchIcon__root__selected]: selectedList.length > 0 && !systemClickAway,
                      },
                      {
                        [cs.FuzzyFilter__SearchIcon__root__selected__light]:
                          selectedList.length > 0 && !systemClickAway && light,
                      },
                      {
                        [cs.FuzzyFilter__SearchIcon__root__active]: isActive,
                      }
                    ),
                  }}
                />
              )}
            </InputAdornment>
          }
        />

        <div className={cs.DragDropDown__systemList__wrapper}>
          {systemClickAway ? (
            <div className={cs.DragDropDown__system__wrapper} style={{ width: dropdownWidth }}>
              {selectedList.length > 0 && (
                <>
                  <div className={cs.multiSelections} ref={widthRef}>
                    {selectedList.map((elem, idx) => (
                      <div
                        className={cx(cs.selectedWrapper, { [cs.selectedWrapperSm]: dropdownWidth < 200 })}
                        key={idx}
                      >
                        <span style={{ wordBreak: 'break-word' }}> {elem[fieldToDisplay]} </span>
                        <CancelRoundedIcon
                          className={cs.RoundedIcon}
                          onClick={() => handleDelete(elem)}
                          style={{ marginLeft: '5px' }}
                          fontSize="inherit"
                        />
                      </div>
                    ))}
                  </div>
                  <div className={cs.shadow}> </div>
                </>
              )}
              <DropdownList
                options={options}
                setInputFocus={setInputFocus}
                setSelectedList={setSelectedList}
                selectedList={selectedList}
                setSearchValue={setSearchValue}
                setActive={setActive}
                setDropdownHeight={setChildHeight}
                userInput={searchValue}
                fieldToDisplay={fieldToDisplay}
                sort={sort}
                system={system}
                level={level}
                multi
                locked={locked}
                comingSoon={comingSoon}
                tickEnd={tickEnd}
              />
            </div>
          ) : null}
        </div>
      </div>
    </ClickAwayListener>
  );
};

DropdownMulti.propTypes = {
  options: PropTypes.array.isRequired,
  selectedList: PropTypes.array.isRequired,
  setSelectedList: PropTypes.func.isRequired,
  search: PropTypes.bool,
  topic: PropTypes.string,
  requiredIntroText: PropTypes.string,
  dropdownState: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  handleInputChange: PropTypes.func,
  sort: PropTypes.bool,
  fieldToDisplay: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  requiredIntro: PropTypes.bool,
  level: PropTypes.bool,
  system: PropTypes.bool,
  tickEnd: PropTypes.bool,
  seperator: PropTypes.bool,
  clearIcon: PropTypes.bool,
  light: PropTypes.bool,
  comingSoon: PropTypes.bool,
  locked: PropTypes.bool,
  setDropdownHeight: PropTypes.func,
};

export default DropdownMulti;
