import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  StudentsReview: {},
  StudentsReview__container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  StudentsReview__left: {
    flex: 1,
    marginRight: 60,
  },
  StudentsReview__subtitle: {
    textAlign: 'center',
    maxWidth: 1100,
  },
  StudentsReview__right: {
    flex: 1,
  },
  StudentsReview__chatWindow: {
    maxWidth: '100%',
    marginBottom: 80,
  },
  StudentsReview__wrapper: {
    width: '100%',
    marginTop: 50,
    marginBottom: 20,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      height: 300,
      marginTop: 15,
    },
  },
  StudentsReview__signUpBtn: {
    '&:hover': {
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  StudentsReview__overlay1: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: 200,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
