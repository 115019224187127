import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './modal.scss';

function Modal(props) {
  const {
    isToggle,
    children,
    className,
    toggle,
    centered = false,
    bottom = false,
    closable = true,
    overlay = false,
    zIndex = 9999,
  } = props;
  const handleClick = (e) => {
    if (e.target === e.currentTarget && closable) toggle(e);
  };
  return (
    isToggle && (
      <div
        className={classnames('custom_modal', { overlay, 'custom_modal-bottom': bottom }, className)}
        tabIndex="-1"
        onClick={handleClick}
        style={{ zIndex }}
      >
        <div className={classnames('custom_modal_dialog', { centered, bottom })} onClick={handleClick}>
          <div className={classnames('custom_modal_content')}>{children}</div>
        </div>
      </div>
    )
  );
}

Modal.propTypes = {
  isToggle: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  centered: PropTypes.bool,
  closable: PropTypes.bool,
  overlay: PropTypes.bool,
  className: PropTypes.string,
};

export default Modal;
