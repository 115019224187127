import { makeStyles } from '@material-ui/core/styles';
import tourBackground from 'assets/userTour/mobile-tour-background.png';

const useStyles = makeStyles((theme) => ({
  MobileTourSlide: {
    background: `url(${tourBackground})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 52%',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    width: '100%',
  },
  MobileTourSlide__img: {
    maxWidth: '90%',
    maxHeight: '45%',
    marginBottom: 55,
  },
  MobileTourSlide__title: {
    fontSize: 23,
    textAlign: 'center',
    lineHeight: 1.39,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  MobileTourSlide__tag: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  MobileTourSlide__text: {
    fontSize: 17,
    textAlign: 'center',
    lineHeight: 1.47,
    color: theme.palette.text.main,
    marginBottom: 40,
    padding: '0 20px',
  },
  MobileTourSlide__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  MobileTourSlide__btn: {
    height: 40,
    width: 300,
  },
  MobileTourSlide__dots: {
    display: 'flex',
    marginTop: 20,
  },
  MobileTourSlide__dot: {
    margin: 5,
    fontSize: 10,
  },
  MobileTourSlide__dot__light: {
    opacity: 0.2,
  },
  MobileTourSlide__btn__icon: {
    marginLeft: 5,
  },
}));

export default useStyles;
