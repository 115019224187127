import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { fetchPlans, setSelectedPlan } from '../../../actions/checkout';
import { trackEvent } from '../../../service/analytics';
import StripeCheckout from '../../../components/StripeCheckout';

import useStyles from './style';

const PaymentBox = (props) => {
  const cs = useStyles();

  const { history } = props;

  const [errorMsg, setErrorMsg] = useState('');

  const PLAN_META = {
    '1 Year': 'Billed every year',
    '6 Months': 'Billed every 6 months',
    Lifetime: 'Access for your medical career',
  };

  useEffect(() => {
    if (!props.plans.length) {
      props
        .fetchPlans()
        .then((res) => {})
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrorMsg(err.response.data);
          } else {
            setErrorMsg('Sorry, we have something wrong with our plans, please try again latter');
          }
        });
    } else {
      selectPlan();
    }
    trackEvent({
      GAAction: 'view_plans',
      FBAction: 'View Plans',
      category: 'View Plans',
      label: `User ${props.userId} views plan ${window.location.search}`,
    });
  }, []);

  // If payment success, redirect to homepage
  if (props.checkoutComplete) {
    history.push('/');
  }

  const selectPlan = () => {
    const query = queryString.parse(history.location.search);
    props.setSelectedPlan(query.plan);
  };

  if (!props.plans.length) {
    return <div className="">Fetching plans</div>;
  }

  const selectedPlanObj = props.plans.find((plan) => plan.name === props.selectedPlan) || props.plans[0];

  return (
    <div className={cs.PaymentBox}>
      <div className={cs.PaymentBox__checkout}>
        <div className={cs.PaymentBox__summary}>
          <div className={cs.PaymentBox__checkout__title}>Summary</div>
          <div className={cs.PaymentBox__product}>
            <div className={cs.PaymentBox__product__name}>{`Oscer Prime - ${selectedPlanObj?.display_name}`}</div>
            <div className={cs.PaymentBox__priceWrapper}>
              <div className={cs.PaymentBox__currentcy}>USD</div>${selectedPlanObj.price.toFixed(2)}
            </div>
          </div>
          <div className={cs.PaymentBox__product__info}>
            <div className={cs.PaymentBox__product__billing}>{PLAN_META[selectedPlanObj.display_name]}</div>
            {selectedPlanObj.original_price > selectedPlanObj.price && (
              <div className={cs.PaymentBox__product__originalPrice}>{`$${selectedPlanObj.original_price.toFixed(
                2
              )}`}</div>
            )}
          </div>
          <div className={cs.PaymentBox__seperator} />
        </div>
        <StripeCheckout />
      </div>
      <div className="">{errorMsg}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  plans: state.checkout.plans,
  selectedPlan: state.checkout.selectedPlan,
  checkoutComplete: state.checkout.checkoutComplete,
  userId: state.auth.user.id,
});

PaymentBox.propTypes = {
  history: PropTypes.object,
  plans: PropTypes.array,
  setSelectedPlan: PropTypes.func,
  fetchPlans: PropTypes.func,
  selectedPlan: PropTypes.string,
  checkoutComplete: PropTypes.bool,
  userId: PropTypes.number,
};

export default withRouter(connect(mapStateToProps, { fetchPlans, setSelectedPlan })(PaymentBox));
