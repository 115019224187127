export const PRESENTATION_ABDOMINAL_PAIN = 'abdominal_pain';
export const PRESENTATION_BACK_PAIN = 'back_pain';
export const PRESENTATION_CHEST_PAIN = 'chest_pain';
export const PRESENTATION_COUGH = 'cough';
export const PRESENTATION_COUGH_F = 'cough_f';
export const PRESENTATION_FEVER_TRAVEL = 'fever_travel';
export const PRESENTATION_FEVER = 'fever';
export const PRESENTATION_HEADACHE = 'headache';
export const PRESENTATION_HEARING_LOSS = 'hearing_loss';
export const PRESENTATION_JOINT_PAIN = 'joint_pain';
export const PRESENTATION_LEG_PAIN = 'leg_pain';
export const PRESENTATION_RASH = 'rash';
export const PRESENTATION_VISION_LOSS = 'vision_loss';
export const PRESENTATION_WEIGHT_LOSS = 'weight_loss';
export const PRESENTATION_MUSCLE_WEAKNESS = 'muscle_weakness';
export const PRESENTATION_SHORTNESS_BREATH = 'shortness_of_breath';
export const PRESENTATION_RETURNED_TRAVELLER = 'returned_traveller';
export const PRESENTATION_URINARY_INCONTINENCE = 'urinary_incontinence';
export const HINT_1 = 'hint_1';
export const HINT_2 = 'hint_2';
export const HINT_3 = 'hint_3';
export const HINT_4 = 'hint_4';
export const HINT_5 = 'hint_5';
export const empathy = 'empathy';
export const functional = 'functional';
export const introduction = 'introduction';
export const medication = 'medication';
export const occupational = 'occupational';
export const past_medical_history = 'past_medical_history';
export const social = 'social';

export const ALL_PRESENTATIONS = [
  PRESENTATION_ABDOMINAL_PAIN,
  PRESENTATION_BACK_PAIN,
  PRESENTATION_CHEST_PAIN,
  PRESENTATION_COUGH,
  PRESENTATION_FEVER_TRAVEL,
  PRESENTATION_FEVER,
  PRESENTATION_HEADACHE,
  PRESENTATION_HEARING_LOSS,
  PRESENTATION_JOINT_PAIN,
  PRESENTATION_LEG_PAIN,
  PRESENTATION_RASH,
  PRESENTATION_VISION_LOSS,
  PRESENTATION_WEIGHT_LOSS,
  PRESENTATION_MUSCLE_WEAKNESS,
  PRESENTATION_SHORTNESS_BREATH,
  PRESENTATION_RETURNED_TRAVELLER,
  PRESENTATION_URINARY_INCONTINENCE,
  empathy,
  functional,
  introduction,
  medication,
  occupational,
  past_medical_history,
  social,
];
