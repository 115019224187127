import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  CreatePrimeAccount: {
    marginTop: 120,
    backgroundColor: 'rgba(74, 132, 255, 0.1)',
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  CreatePrimeAccount__progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 90,
  },
  CreatePrimeAccount__progress: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    opacity: 0.4,
  },
  CreatePrimeAccount__progressBar__item: {
    display: 'flex',
    alignItems: 'center',
  },
  CreatePrimeAccount__progress__stage: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    border: `solid 2px ${theme.palette.primary.main}`,
    background: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  CreatePrimeAccount__progress__name: {
    fontSize: 13,
    color: theme.palette.primary.main,
  },
  CreatePrimeAccount__seprator: {
    height: 2,
    background: theme.palette.primary.main,
    width: 165,
    marginBottom: 28,
    opacity: 0.4,
    [theme.breakpoints.down('xs')]: {
      width: 90,
    },
  },
  CreatePrimeAccount__active: {
    opacity: 1,
  },
  CreatePrimeAccount__completed: {
    background: theme.palette.primary.main,
    color: '#ffffff',
  },
}));

export default useStyles;
