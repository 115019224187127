import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tour from 'reactour';
import { isMobileOnly, isDesktop } from 'react-device-detect';
import { getTasks, taskModalSet, easterEggUpdated, generalTaskUpdated } from '../../actions/task';
import {
  setSystemAverageFilter,
  setModeSelectionModal,
  setSelectedCase,
  setUpgradeModalToggle,
  setUpgradeModalStepFlag,
  setProfilePopover,
  setUpgradeExplorerModalToggle,
} from '../../actions/dashboard';
import { setFinishedCase, fetchCasesCollection } from '../../actions/cases';
import { loadUser, patchUserDetails, patchNotification } from '../../actions/auth';
import ModeSelectionModal from '../../components/Modal/ModeSelectionModal';
import './style.scss';
import RefLinkModal from '../Account/MainBoard/RefLinkModal';
import { SET_SHOW_REFERRAL_MODAL } from '../../actions/types';
import { trackPage, trackEvent } from '../../service/analytics';
import ReferralModal from '../../components/Modal/ReferralModal';
import UpgradeModal from './Modal/UpgradeModal';
import UpgradeExplorerModal from './Modal/UpgradeExplorerModal';
import DiscontinuePrimeModal from './Modal/DiscontinuePrimeModal';
import FeedbackModal from '../../components/Modal/FeedbackModal';
import isEmptyObject from '../../helpers/emptyObject';
import MobileUserTour from './MobileUserTour';
// import Notification from '../../components/Notification';
import useUserTour from '../../hook/useUserTour';
import newOnboardingTour from './UserTourSteps/NewOnboardingTour';
import welcomePage from './UserTourSteps/WelcomePage';
import WelcomeAlert from '../../components/FeatureAlert/WelcomeAlert';
// import FeatureAlert from '../../components/FeatureAlert/NewFeatureAlert';

import { withRouter, useLocation, useHistory } from 'react-router-dom';

import { NOTIFICATION_BLACK_LIST } from '../../constants';
import useUserGroup from '../../hook/useUserGroup';

import useStyles from './style';

const DashboardHelper = (props) => {
  const location = useLocation();
  const history = useHistory();

  // User Tour single player
  const [isTourOpen, setTourOpen, handleTourClosed, handleTourfinished] = useUserTour(
    'new_onboarding',
    'new_onboarding_flag'
  );

  const [isTourOpenWelcome, setTourOpenWelcome, handleTourClosedWelcome, handleTourfinishedWelcome] = useUserTour(
    'welcome',
    'welcome_flag'
  );

  const [tourPage, setTourPage] = useState(1);
  const [firstCaseId, setFirstCaseId] = useState(null);

  // Feedback Modal
  const [feebbackModalToggle, setFeedbackModalToggle] = useState(false);

  // Hide Notification for unimelb users
  const [userGroup, isNotificationAllow, isNotificationBlocked] = useUserGroup({ blackList: NOTIFICATION_BLACK_LIST });

  const classes = useStyles();

  // Check new user, if new user and just consume a session, then popup referral modal
  const isNewUser = localStorage.getItem('is_new_user') || false;

  // upgrade modal steps default, 2-cases, 4-cases
  const [upgradeStep, setUpgradeStep] = useState('default');

  const [referralModalToggle, setReferralModalToggle] = useState(
    // ! This modal has conflicts with tour if initially setup
    // isNewUser === 'true' && props.user.profile.available_session < 10
    false
  );

  // When user's prime is expired, popup when they cancel renew in the first time renew modal popup
  const [cancelModalToggle, setCancelModalToggle] = useState(false);

  const handleShare = () => {
    setReferralModalToggle(!referralModalToggle);
    history.push({
      pathname: location.pathname,
      search: '?share_oscer',
    });
    localStorage.setItem('is_new_user', false);
  };

  const handleUpgradeModal = (toggle = true, step = 'default') => {
    // For none subscriptions, control the modal status
    if (step === 'end') {
      props.setUpgradeModalToggle(toggle);
      props.patchUserDetails({ profile: { show_renew_modal: props.showRenewModal - 1 } });
    } else {
      props.setUpgradeModalToggle(toggle);
    }
  };

  const getTourOpenState = () => {
    for (let notif of props.notification) {
      if (!notif.is_read) {
        return false;
      }
    }
    return true;
  };

  const handleExplorerUpgradeModal = (toggle = true, step = 'default') => {
    props.setUpgradeExplorerModalToggle(toggle);
  };

  const handleCancelPrimeModal = () => {
    setCancelModalToggle((toggle) => !toggle);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has('share_oscer')) {
      setReferralModalToggle(true);
    }
    props.loadUser().then((res) => {
      // update upgrade 2 case and 4 case
      if (!res.prime_status) {
        if (location?.pathname === '/dashboard/explorer' || location?.state?.from === '/dashboard/explorer') {
          setUpgradeStep('explorer');
          handleUpgradeModal(true);
        } else if (location?.state?.from !== '/upgrade' && res.profile.display_upgrade_flag === '2') {
          setUpgradeStep('2-cases');
          handleUpgradeModal(true);
          handleFeedbackModalClose();
        } else if (res.profile.display_upgrade_flag === '4' && location?.state?.from !== '/upgrade') {
          setUpgradeStep('4-cases');
          handleUpgradeModal(true);
          handleFeedbackModalClose();
          // Should pop upgrade modal when prime expired for the first 2 logins
        } else if (res.has_ever_been_prime && res.subscription_days_left === 0 && res.profile.show_renew_modal > -1) {
          setUpgradeStep('end');
          props.setUpgradeModalToggle(true);
          handleFeedbackModalClose();
        } else if (location?.state?.from === '/upgrade' && res.has_ever_been_prime) {
          // If user's prime has expired, and he just exit from upgrade page
          handleCancelPrimeModal();
        } else {
          handleFeedbackModalOpen();
        }
      }
      // If no modal open them popup feedback
      else {
        handleFeedbackModalOpen();
      }
    });
    // props.getTasks();
    trackPage(location.pathname);

    // Update info in intercom
    window.Intercom('update', {
      hide_default_launcher: false,
    });
    window.Intercom('boot', {
      app_id: 'xyqioc2g',
      email: props.user.email,
      user_id: props.user.id,
      prime: props.user.isPrime ? 'yes' : 'no',
      user_type: window.location.href.includes('unimelb') ? 'unimelb' : 'public',
    });
    window.Intercom('update');
  }, []);

  // Set user tour close
  const handleUserTourClose = () => {
    props.setModeSelectionModal(false);
    handleTourfinished('new_onboarding_flag');
  };

  // Feedback modal should be the last one to open and cannot stack with other modal
  const handleFeedbackModalOpen = () => {
    if (!isEmptyObject(props.finishedCase)) {
      setFeedbackModalToggle(true);
    }
  };

  const handleFeedbackModalClose = () => {
    setFeedbackModalToggle(false);
    props.setFinishedCase({});
  };

  useEffect(() => {
    if (!props.collection.length) {
      props.fetchCasesCollection();
    }
  }, []);

  useEffect(() => {
    if (props.collection.length) {
      if (props.collection.filter((col) => col.cases.length > 0)[0].cases.length) {
        setFirstCaseId(props.collection.filter((col) => col.cases.length > 0)[0].cases[0].id);
      }
    }
  }, [props.collection]);

  return (
    <div className="dashboard">
      {isMobileOnly ? (
        <MobileUserTour
          isOpen={isTourOpen}
          onRequestClose={() => {
            handleUserTourClose('new_onboarding_flag');
            if (isNewUser && firstCaseId) {
              history.push(`/cases/${firstCaseId}/spm`);
            }
          }}
        />
      ) : (
        <>
          <Tour
            steps={welcomePage(handleUserTourClose, setTourPage, handleTourfinishedWelcome, props.isPrime)}
            isOpen={isTourOpenWelcome}
            onRequestClose={() => handleTourfinishedWelcome('welcome_flag')}
            closeWithMask={false}
            scrollDuration={200}
            showNavigation={false}
            showNumber={false}
            showButtons={false}
            showCloseButton={false}
            disableInteraction
            goToStep={tourPage}
            rounded={8}
            className={classes.Dashboard__tour}
            maskClassName={classes.Dashboard__tour__mask}
            highlightedMaskClassName={classes.AllOtherCases__tour__highlightmask}
          />
          {/* For new mobile users: only show mobile tour, for desktop new users, onboarding tour first then welcome alert. 
          For old users, show alert  */}
          {/* disabled notification for mobile */}
          {!!props.notification.length && !isMobileOnly && (
            <WelcomeAlert
              notifications={props.notification}
              isToggle={props.notification.some((noti) => !noti.is_read) && !isTourOpenWelcome}
              toggle={(id, isRead) => props.patchNotification(id, isRead)}
              firstAvailableCase={firstCaseId}
            />
          )}
          <Tour
            steps={newOnboardingTour(
              handleUserTourClose,
              setTourPage,
              handleTourfinished,
              props.isPrime,
              props.setProfilePopover,
              firstCaseId,
              getTourOpenState() && isTourOpen && !isTourOpenWelcome
            )}
            isOpen={getTourOpenState() && isTourOpen && !isTourOpenWelcome}
            onRequestClose={() => handleTourfinished('new_onboarding_tour')}
            closeWithMask={false}
            scrollDuration={200}
            showNavigation={false}
            showNumber={false}
            showButtons={false}
            showCloseButton={false}
            disableInteraction
            goToStep={tourPage}
            rounded={8}
            className={classes.Dashboard__tour}
            maskClassName={classes.Dashboard__tour__mask}
            highlightedMaskClassName={classes.AllOtherCases__tour__highlightmask}
          />
        </>
      )}
      <ModeSelectionModal />
      <FeedbackModal toggle={handleFeedbackModalClose} isToggle={feebbackModalToggle} />
      <UpgradeExplorerModal
        toggle={handleExplorerUpgradeModal}
        isToggle={props.upgradeExplorerModalToggle}
        type={upgradeStep}
      />
      <UpgradeModal toggle={handleUpgradeModal} isToggle={props.upgradeModalToggle} type={upgradeStep} />
      <DiscontinuePrimeModal toggle={handleCancelPrimeModal} isToggle={cancelModalToggle} />
      <ReferralModal toggle={handleShare} isToggle={referralModalToggle} />
      {/* <TaskModal /> */}
      {!props.showReferralModal ? null : (
        <RefLinkModal close={() => props.dispatch({ type: SET_SHOW_REFERRAL_MODAL, value: false })} />
      )}
    </div>
  );
};

DashboardHelper.propTypes = {
  location: PropTypes.object,
  showReferralModal: PropTypes.bool,
  dispatch: PropTypes.func,
  setSystemAverageFilter: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  loadUser: PropTypes.func,
  // getTasks: PropTypes.func,
  // tasks: PropTypes.object,
  // easterEggUpdated: PropTypes.func,
  // generalTaskUpdated: PropTypes.func,
  setFinishedCase: PropTypes.func,
  finishedCase: PropTypes.object,
  // notification: PropTypes.array,
  setModeSelectionModal: PropTypes.func,
  // tour: PropTypes.object,
  // patchNotification: PropTypes.func,
  setUpgradeModalToggle: PropTypes.func,
  upgradeModalToggle: PropTypes.bool,
  // AttemptedCasesNumber: PropTypes.number,
  isPrime: PropTypes.bool,
  // setUpgradeModalStepFlag: PropTypes.func,
  // upgradeModalStepFlag: PropTypes.string,
  patchUserDetails: PropTypes.func,
  showRenewModal: PropTypes.number,
  // isPastPrimeUser: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  profilePopover: state.dashboard.profilePopoverOpen,
  cases: state.cases.filteredCases,
  collection: state.cases.casesCollection,
  user: state.auth.user,
  redirectRoute: state.auth.redirectRoute,
  showReferralModal: state.account.showReferralModal,
  tasks: state.task.tasks,
  finishedCase: state.cases.finishedCase,
  notification: state.auth.notification,
  tour: state.auth.user.profile.user_tour_flags,
  upgradeExplorerModalToggle: state.dashboard.upgradeExplorerModalToggle,
  upgradeModalToggle: state.dashboard.upgradeModalToggle,
  AttemptedCasesNumber: state.auth.user.profile.num_attempted_cases,
  isPrime: state.auth.user.prime_status,
  unlockedCases: state.cases.unlockedCases,
  upgradeModalStepFlag: state.dashboard.upgradeModalStepFlag,
  subscriptionDaysLeft: state.auth.user.subscription_days_left,
  showRenewModal: state.auth.user.profile.show_renew_modal,
  // isPastPrimeUser: state.auth.user.has_ever_been_prime
});

export default connect(mapStateToProps, {
  fetchCasesCollection,
  setSystemAverageFilter,
  loadUser,
  setProfilePopover,
  getTasks,
  taskModalSet,
  easterEggUpdated,
  generalTaskUpdated,
  setFinishedCase,
  setModeSelectionModal,
  setSelectedCase,
  patchUserDetails,
  patchNotification,
  setUpgradeModalToggle,
  setUpgradeModalStepFlag,
  setUpgradeExplorerModalToggle,
})(DashboardHelper);
