import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { featureToogleByUser } from '../../../../configuration/featureToggle';
import ProgressBar from '../../../../components/Progress/LinearProgress';
import { TOTAL_QUESTION_ASKED } from '../../../../helpers/constants';
import highScoreIcon from '../../../../assets/character/character-payment-modal.svg';
import useStyles from './style';

/**
 * @desc This is score panel at lift sied of case section
 * @param {*} props
 * - markStreak number of hot streak
 * - currentScore total score
 * - QuestionsAsked number of question asked
 * - currentCase object of current case
 */
const Score = (props) => {
  const { currentCase, currentScore, markStreak, QuestionsAsked, bedsideManner = 50, messages } = props;
  const classes = useStyles();
  const maxMarkStreak = markStreak > currentCase.max_score ? markStreak : currentCase.max_score;

  const unimelbToggle = featureToogleByUser(
    'unimelbCaseUIToggle',
    window.location.hostname.includes('unimelb') ? 'unimelb' : 'public'
  );

  const bedsideMannerResult = (score) => {
    if (score < 20) {
      return 'Poor';
    }
    if (score >= 20 && score < 40) {
      return 'Okay';
    }
    if (score >= 40 && score < 60) {
      return 'Good';
    }
    if (score >= 60 && score < 80) {
      return 'Great';
    }
    if (score >= 80) {
      return 'Excellent';
    }
    return '';
  };

  // Check whether current case is new high score
  const isNewHighScore = () => {
    if (currentCase.result) {
      return currentCase.result.score !== 0 && currentCase.result.score < currentScore;
    }
    return false;
  };

  // Calculate question asked
  const calcQuesAskedProgress = (questionLength) => {
    if (questionLength > 80) {
      return 99;
    }
    if (questionLength >= 40) {
      return 79 + (questionLength - 40) * 0.5;
    }
    if (questionLength >= 30) {
      return 69 + (questionLength - 30) * 1;
    }
    if (questionLength >= 10) {
      return 29 + (questionLength - 10) * 2;
    }
    return 3 * questionLength;
  };

  return (
    <div className={classes.ScorePanel}>
      {unimelbToggle ? (
        <ProgressBar
          name="Your Progress"
          score={currentScore}
          maxScore={currentCase.max_score}
          minScore={0}
          styles={{
            name: classes.ScorePanel__progress__name,
            root: classes.ScorePanel__progress__score,
            linearProgress__root: classes.ScorePanel__linearProgress__root,
            linearProgress__colorPrimary: classes.ScorePanel__progress__colorPrimary__4,
            linearProgress__barColorPrimary: classes.ScorePanel__progress__colorPrimary__2,
          }}
        ></ProgressBar>
      ) : (
        <ProgressBar
          name={isNewHighScore() ? 'New High Score' : 'Your Score'}
          score={currentScore}
          maxScore={currentCase.max_score}
          minScore={0}
          styles={{
            name: classes.ScorePanel__progress__name,
            root: classes.ScorePanel__progress__score,
            linearProgress__root: classes.ScorePanel__linearProgress__root,
            linearProgress__colorPrimary: classes.ScorePanel__progress__colorPrimary__4,
            linearProgress__barColorPrimary: classes.ScorePanel__progress__colorPrimary__2,
          }}
        >
          <div className={classes.ScorePanel__score__wrapper}>
            <div className={classes.ScorePanel__score__current}>{currentScore}</div>
            <div className={classes.ScorePanel__score__total}>{`/${currentCase.max_score}`}</div>
          </div>
        </ProgressBar>
      )}
      {!unimelbToggle && (
        <ProgressBar
          name="Mark Streak"
          score={markStreak}
          maxScore={maxMarkStreak}
          minScore={0}
          styles={{
            root: classes.ScorePanel__progress,
            linearProgress__barColorPrimary: classes.ScorePanel__progress__colorPrimary__2,
          }}
        >
          {markStreak}
        </ProgressBar>
      )}
      <ProgressBar
        name="Questions Asked"
        score={TOTAL_QUESTION_ASKED + calcQuesAskedProgress(QuestionsAsked)}
        maxScore={TOTAL_QUESTION_ASKED}
        minScore={0}
        styles={{
          root: classes.ScorePanel__progress,
          linearProgress__barColorPrimary: classes.ScorePanel__progress__barColorPrimary__1,
          linearProgress__colorPrimary: classes.ScorePanel__progress__colorPrimary__3,
        }}
      >
        {QuestionsAsked}
      </ProgressBar>
      <ProgressBar
        name="Bedside Manner"
        score={bedsideManner + 100}
        maxScore={100}
        minScore={0}
        styles={{
          linearProgress__barColorPrimary: classes.ScorePanel__progress__barColorPrimary__1,
          linearProgress__colorPrimary: classes.ScorePanel__progress__colorPrimary__1,
        }}
      >
        {bedsideMannerResult(bedsideManner)}
      </ProgressBar>
    </div>
  );
};

Score.propTypes = {
  currentCase: PropTypes.object,
  currentScore: PropTypes.number,
  markStreak: PropTypes.number,
  QuestionsAsked: PropTypes.number,
  bedsideManner: PropTypes.number,
  messages: PropTypes.array,
};

export default Score;
