import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ScorePanelMobile: {},
  ScorePanelMobile__vertical__separator: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 1,
    height: 50,
  },
  ScorePanelMobile__progress: {
    marginBottom: 10,
  },
  ScorePanelMobile__progress__score: {
    marginBottom: 20,
  },
  ScorePanelMobile__progress__name: {
    fontSize: 18,
    color: theme.palette.text.main,
    fontWeight: 'bold',
  },
  ScorePanelMobile__linearProgress__root: {
    height: 10,
  },
  ScorePanelMobile__progress__root: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0 10px',
  },
  ScorePanelMobile__progress__colorPrimary__1: {
    backgroundImage: 'linear-gradient(to right, #fcb9ad, #83d0f0, #4a84ff)',
  },
  ScorePanelMobile__progress__colorPrimary__2: {
    backgroundImage: 'linear-gradient(to right, #4a84ff, #83d0f0)',
  },
  ScorePanelMobile__progress__colorPrimary__3: {
    backgroundImage: 'linear-gradient(to right, #83d0f0, #4a84ff,#fcb9ad )',
  },
  ScorePanelMobile__progress__colorPrimary__4: {
    backgroundColor: 'rgba(131, 208, 240, 0.2)',
  },
  ScorePanelMobile__progress__barColorPrimary__1: {
    backgroundColor: '#E3E3E3',
    backgroundImage: 'none',
  },
  ScorePanelMobile__score__wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  ScorePanelMobile__score__current: {
    color: theme.palette.primary.main,
    fontSize: 23,
    lineHeight: 1.17,
    fontWeight: 'bold',
  },
  ScorePanelMobile__score__total: {
    color: 'rgba(18, 19, 19, 0.2)',
    fontSize: 16,
    lineHeight: 1.17,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  ScorePanelMobile__details__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ScorePanelMobile__accordion__root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 'calc((100vw - 125px) * 0.75)',
    boxShadow: 'none',
    '&.Mui-expanded': {
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      margin: 0,
    },
  },
  ScorePanelMobile__accordionSummary__root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    padding: 0,
    '&.Mui-expanded': {
      backgroundColor: '#ecf2ff',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      minHeight: 60,
    },
  },
  ScorePanelMobile__accordionDetails__root: {
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
  },
}));

export default useStyles;
