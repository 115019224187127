import React from 'react';
import PropTypes from 'prop-types';
import { FaForward } from 'react-icons/fa';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createMessage } from '../../../actions/messages';
import { autoPlayAudioToggle, setSessionId } from '../../../actions/cases';
import { Modal } from '../../../components/common';
import { trackEvent } from '../../../service/analytics';
import PresentationImg from '../../../components/icons/PresentationImg';
import SystemBadge from '../../../components/badge/systemBadge';
import LevelBadge from '../../../components/badge/LevelBadge';
import GeneralSwitch from '../../../components/Switch/GeneralSwitch';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import * as caseActions from '../../../actions/cases';

import '../case.scss';
import './style.scss';
import { INCOMING_MESSAGE, PRESENTATION_ICON_XLARGE, EXAM_LEARN_MODE, SYSTEM_COLORS } from '../../../constants';
import { toTitle } from '../../../helpers/toTitleCase';

/**
 * @desc Case begin modal
 * @param {*} props
 * - currentCase an object of current case details
 * - isToggle a boolean which represents the modal is popup or not
 * - toggle a function to control modal
 * - setMode a function to set mode, either 'EXAM MODE' or 'LEARN MODE'
 */
const BeginCaseModal = (props) => {
  const { id, patient_name, gender, patient_age, system, presentation, case_level, description } = props.currentCase;
  const { isToggle, toggle, setMode, sessionId, startCase } = props;

  const handleAudioSwitch = () => {
    trackEvent({
      GAAction: `turn_${props.autoPlayAudio ? 'off' : 'on'}_text_to_voice`,
      FBAction: `Turn ${props.autoPlayAudio ? 'Off' : 'On'} Text to Voice`,
      category: 'Single Player',
      label: `User ${props.id} Turn ${props.autoPlayAudio ? 'Off' : 'On'} Text to Voice`,
    });
    props.autoPlayAudioToggle();
  };

  const removeEmailDomain = (name) => name.replace(/@.*$/, '');

  const handleStartCase = () => {
    setMode(EXAM_LEARN_MODE);
    startCase();

    // Set Initial statement
    if (props.messages.length === 0) {
      props.createMessage(
        INCOMING_MESSAGE,
        {
          response_type: 'normal',
          response: startingStatement(),
          current_score: 0,
          points_earned: 0,
          label: '',
          guide_msg: '',
        },
        new Date().toString()
      );
      trackEvent({
        GAAction: 'single_player_start_case',
        FBAction: 'Single Player Start Case',
        category: 'Single Player',
        label: `Case id ${id}`,
      });
    }
  };

  const startingStatement = () => {
    const random = Math.floor(Math.random() * 4);
    switch (random) {
      case 0:
        return 'Thanks for seeing me today, Doc.';
      case 1:
        return `Hi ${props.user.first_name || 'Doc'}, good to finally see you.`;
      case 2:
        return `Hi ${props.user.first_name || 'Doc'}.`;
      default:
        return `Hey ${props.user.first_name || 'Doc'}, can you help me out?`;
    }
  };

  return (
    <Modal isToggle={isToggle} toggle={toggle} centered closable={false}>
      <div className={classnames('case__modal')}>
        <div className={classnames('case__modal__icons', 'case__modal__icons-patient')}>
          <div className={classnames('icon__background', 'icon__background-sm')}>
            <LevelBadge level={case_level} />
          </div>
          <SystemBadge system={system} />
        </div>
        <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
          <div className="case__modal">
            <PresentationImg
              className={classnames('case__modal__img', 'case__modal__img-patient')}
              presentation={presentation}
              size={PRESENTATION_ICON_XLARGE}
            />

            <div className="case__modal__title">
              <div className={classnames('title')}>
                <h5>Here’s what you need to know about your patient before you begin…</h5>
              </div>
            </div>
            <div className={classnames('note', 'case__modal__subtitle-text')}>
              <p>
                {description ||
                  `${patient_name} is a ${patient_age} y.o ${
                    gender === 'Female' ? 'female' : 'male'
                  } who is presenting with 
                ${toTitle(presentation).toLowerCase()}.`}
              </p>
            </div>
            {/* <div className="case__modal__begin-switch">
              <span className="case__modal__begin-switch-voice">
                Turn on your patient’s voice to hear their responses
              </span>
              <GeneralSwitch size="medium" toggle={handleAudioSwitch} isToggle={props.autoPlayAudio} />
            </div> */}

            <div className={classnames('buttons', 'case__modal__btns')}>
              <ConfirmButton
                className={classnames('btn-cancel', 'case__modal__btns-cancel')}
                onClick={() => {
                  props.setSessionId(null);
                  props.history.push('/dashboard/home');
                }}
              >
                Cancel
              </ConfirmButton>
              <ConfirmButton
                className={classnames('btn-learn')}
                onClick={handleStartCase}
                data-tut="reactour__start_case"
                cy-id="start-single-player"
                disabled={!sessionId}
              >
                <span className="btn-learn-name">
                  Start Case <FaForward />
                </span>
                <span className="btn-learn-name-sm">
                  Proceed <FaForward />
                </span>
              </ConfirmButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

BeginCaseModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  setMode: PropTypes.func,
  currentCase: PropTypes.object.isRequired,
  description: PropTypes.string,
  history: PropTypes.object,
  autoPlayAudioToggle: PropTypes.func,
  autoPlayAudio: PropTypes.bool,
  id: PropTypes.number,
  messages: PropTypes.array,
  sessionId: PropTypes.string,
  createMessage: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  autoPlayAudio: state.cases.autoPlayAudio,
  id: state.auth.user.id,
  messages: state.messages.messages,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, { autoPlayAudioToggle, createMessage, setSessionId })(BeginCaseModal)
);
