export const getVideoMeta = (url) => {
  if (typeof url === 'string') {
    if (url.includes('wistia.com')) {
      // parse id
      const videoId = url.split('medias/')[1].replace(/\//g, ' ');
      return {
        videoId,
        url,
        platform: 'Wistia',
      };
    }
  }

  // default
  return {
    videoId: null,
    url,
    platform: 'Undefiend',
  };
};
