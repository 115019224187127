import React from 'react';
import PropTypes from 'prop-types';
import { MdPlayArrow } from 'react-icons/md';

import './style.scss';

function StartButton(props) {
  const [isClicked, setClicked] = React.useState(false);
  const [isVisible, setVisible] = React.useState(true);
  const onClick = () => {
    setClicked(true);
    setTimeout(() => {
      setVisible(false);
      props.onClick();
    }, 1000);
  };

  return (
    isVisible && (
      <span>
        <button className={`start-btn ${isClicked && 'animated'}`} onClick={onClick}>
          <MdPlayArrow />
        </button>
      </span>
    )
  );
}

StartButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default StartButton;
