/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import clsx from 'clsx';
import { useWidth } from '../../helpers/customHooks';
import cx from 'classnames';
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import SwiperCore, { Pagination } from 'swiper';
import useStyles from './style';
import StarRating from '../StarRating';
import IconReplayWhite from '../../assets/common/retry-icon-white.svg';
import PresentationImage from '../icons/PresentationImg';
import { SYSTEM_COLORS } from '../../constants';
import TickIcon from '../icons/IconTick';
import SmallTickIcon from '../icons/IconSmallTick';
import tickIcon from '../../assets/cases/check_icons/completed-tick-icon.svg';
import DoneIcon from '@material-ui/icons/Done';
import { featureToogleByUser } from '../../configuration/featureToggle';
import { ISOtoDate } from '../../helpers/dateTime';
import { setSelectedCase, setModeSelectionModal } from '../../actions/dashboard';
import { removeUnderscore } from '../../helpers/toTitleCase';
import { mapSystems } from '../../helpers/mapSystems';
import { connect } from 'react-redux';

SwiperCore.use([Pagination]);

const CaseHistoryCard = (props) => {
  const classes = useStyles();
  const { caseItem, className, onClick, styles = {}, isHover = false, first = false, last = false } = props;
  const dispatch = useDispatch();
  const screenSize = useWidth();

  const [cardHovered, setCardHovered] = useState(false);

  const pagination = {
    clickable: true,
  };

  const colorSelector = (level) => {
    switch (level) {
      case 1:
        return 'var(--case-1-color)';

      case 2:
        return 'var(--case-2-color)';

      case 3:
        return 'var(--case-3-color)';

      case 4:
        return 'var(--case-4-color)';

      default:
        return 'var(--case-1-color)';
    }
  };

  const modeToNum = (mode) => {
    if (mode === 'DIAGNOSIS') {
      return 'Dx';
    }
    if (mode === 'multi' || mode === 'two') {
      return 'Script';
    }
    return 'Hx';
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const unimelbToggle = featureToogleByUser(
    'unimelbHistoryUIToggle',
    window.location.hostname.includes('unimelb') ? 'unimelb' : 'public'
  );

  const handleRetry = (e) => {
    e.stopPropagation();
    dispatch(setSelectedCase(caseItem));
    dispatch(setModeSelectionModal(true));
  };

  // scroll into view of card if cut
  const handleCardClick = (e) => {
    const element = document.getElementById('mobile-cases-dashboard-scroll-id');
    let offset;
    if (e.target.getBoundingClientRect().bottom > 805) {
      offset = 300;
    } else if (e.target.getBoundingClientRect().bottom > 700) {
      offset = 250;
    } else if (e.target.getBoundingClientRect().bottom > 650) {
      offset = windowHeight < 750 ? 225 : 180;
    } else if (e.target.getBoundingClientRect().bottom > 600) {
      offset = windowHeight < 750 ? 175 : 150;
    } else if (e.target.getBoundingClientRect().bottom > 500) {
      offset = windowHeight < 750 ? 100 : 150;
    } else if (e.target.getBoundingClientRect().bottom > 400) {
      offset = 50;
    } else if (e.target.getBoundingClientRect().bottom > 300) {
      offset = windowHeight < 750 ? -100 : -200;
    } else if (e.target.getBoundingClientRect().bottom > 250) {
      offset = windowHeight < 750 ? -180 : -225;
    } else if (e.target.getBoundingClientRect().bottom > 200) {
      offset = windowHeight < 750 ? -195 : -240;
    } else if (e.target.getBoundingClientRect().bottom > 100) {
      offset = windowHeight < 750 ? -205 : -290;
    } else if (e.target.getBoundingClientRect().bottom > 30) {
      offset = windowHeight < 750 ? -270 : -300;
    } else {
      offset = windowHeight < 750 ? -275 : -350;
    }
    setTimeout(() => {
      element.scrollTo({
        top: element.scrollTop + offset,
        behavior: 'smooth',
      });
    }, 1);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const handleResize = (e) => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, []);

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

  return (
    // <div className = {classes.CardWrapper}>
    <div
      className={cx(
        classes.CaseHistoryCard,
        className,
        { [classes.CaseHistoryCard__hover]: isHover && !props.modalOpened },
        { [classes.CaseHistoryCard__hover__last]: isHover && !props.modalOpened && last },
        { [classes.CaseHistoryCard__hover__first]: isHover && !props.modalOpened && first },
        // { [classes.CaseHistoryCard__hover__safari]: isHover && !props.modalOpened && isSafari },
        styles.root
      )}
      role="button"
      onKeyDown={handleKeyDown}
      onMouseEnter={(e) => {
        setCardHovered(true);
        handleCardClick(e);
      }}
      onMouseLeave={() => setCardHovered(false)}
      tabIndex={onClick ? '0' : '-1'}
    >
      <div
        className={classes.CaseHistoryCard__borderWrapper}
        style={{ borderBottomColor: SYSTEM_COLORS[caseItem.primary_system] }}
      >
        <div className={classes.CaseHistoryCard__main}>
          <div className={classes.CaseHistoryCard__patient}>
            <PresentationImage
              className={classes.CaseHistoryCard__patient__avatar}
              presentation={caseItem.presenting_complaint}
            />

            <div className={classes.CaseHistoryCard__patient__info}>
              <div
                className={cx(classes.CaseHistoryCard__patient__diagnosis, {
                  [classes.CaseHistoryCard__patient__diagnosis__undiagnosed]: !caseItem.result.stars,
                })}
              >
                {caseItem.result.stars ? removeUnderscore(caseItem.diagnosis) : 'UNDIAGNOSED'}
              </div>
              <div className={classes.CaseHistoryCard__patient__condition}>
                <span className={classes.CaseHistoryCard__patient__condition__ellipsis}>
                  {removeUnderscore(caseItem.presenting_complaint)}
                </span>
              </div>
              <div className={classes.CaseHistoryCard__patient__basic}>
                <div className={classes.CaseHistoryCard__patient__name}>{caseItem.patient_name.split(' ')[0]}</div>
                <div className={clsx(classes.CaseHistoryCard__seperator, classes.HideOnMobile)}>|</div>
                <div className={classes.CaseHistoryCard__patient__year}>{caseItem.patient_age} y.o</div>
                <div className={classes.CaseHistoryCard__seperator}>|</div>
                <div className={classes.CaseHistoryCard__patient__year}>{caseItem.gender} </div>

                <div className={classes.CaseHistoryCard__seperator}>|</div>

                <div className={classes.CaseHistoryCard__patient__level}>
                  <div
                    className={classes.CaseHistoryCard__dot}
                    style={{ backgroundColor: colorSelector(caseItem.case_level) }}
                  />
                  Level {caseItem.case_level}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.CaseHistoryCard__status}>
            {screenSize !== 'xs' && (
              <div className={classes.CaseHistoryCard__status__section}>
                <div className={classes.CaseHistoryCard__status__title}>Attempts</div>
                {caseItem.sessions.length}
              </div>
            )}
            {unimelbToggle ? (
              <div />
            ) : (
              <>
                <div className={classes.CaseHistoryCard__status__section}>
                  <div className={classes.CaseHistoryCard__status__title}>Best Hx Score</div>
                  <div>
                    {caseItem.result.score}
                    <span className={classes.CaseHistoryCard__status__totalScore}>/{caseItem.result.max_score}</span>
                  </div>
                </div>
                <div className={classes.CaseHistoryCard__status__section}>
                  <div className={classes.CaseHistoryCard__status__title}>Best Dx Score</div>
                  <div>
                    {Math.round(caseItem.result.ddx_efficiency * 100)}
                    <span className={classes.CaseHistoryCard__status__totalScore}>%</span>
                  </div>
                </div>
                {screenSize !== 'xs' && (
                  <div className={classes.CaseHistoryCard__status__section}>
                    <div className={classes.CaseHistoryCard__status__title}>Best Scripts Score</div>
                    <div>
                      {caseItem.result.best_pwf_score}
                      <span className={classes.CaseHistoryCard__status__totalScore}>/{caseItem.result.max_score}</span>
                    </div>
                  </div>
                )}
              </>
            )}
            {screenSize === 'xs' && (
              <div className={classes.CaseHistoryCard__status__section}>
                <div className={classes.CaseHistoryCard__status__title}>Attempts</div>
                {caseItem.sessions.length}
              </div>
            )}
          </div>
        </div>

        <div onClick={handleRetry} className={classes.CaseHistoryCard__retryIconWrapper}>
          <img className={classes.CaseHistoryCard__retryIcon} alt="Oscer Retry Icon" src={IconReplayWhite} />
        </div>
      </div>
      <div
        className={classes.CaseCardHistory__full}
        style={{
          height:
            screenSize === 'xs'
              ? // mobile
                caseItem.sessions.length < 3
                ? caseItem.sessions.length * 50 + 40
                : 175
              : // desktop
              caseItem.sessions.length < 4
              ? caseItem.sessions.length * 42 + 40
              : 200,
        }}
      >
        <Swiper pagination={pagination} style={{ height: '100%', width: '100%' }}>
          {Array.apply(null, Array(Math.ceil(caseItem.sessions.length / (screenSize === 'xs' ? 3 : 4))))
            .map(function () {})
            .map((el, idx) => (
              <SwiperSlide key={idx} className={classes.swiperSlide}>
                <div className={classes.ExpandedCase__tableHead}>
                  <div className={clsx(classes.ExpandedCase__tableHead_item, classes.ExpandedCase__tableHead_date)}>
                    DATE
                  </div>
                  <div className={classes.ExpandedCase__tableHead_item}>SCORE</div>
                  <div className={classes.ExpandedCase__tableHead_item}>MODE</div>
                  <div className={classes.ExpandedCase__tableHead_item}>RUBRIC</div>
                </div>
                {caseItem.sessions
                  .slice(screenSize === 'xs' ? idx * 3 : idx * 4, screenSize === 'xs' ? idx * 3 + 3 : idx * 4 + 4)
                  .map((session) => (
                    <div className={classes.ExpandedCase__tableRow} key={session.uuid}>
                      <div className={cx(classes.ExpandedCase__tableRow__item, classes.ExpandedCase__tableRow__date)}>
                        {ISOtoDate(session.when_completed)}
                      </div>
                      <div className={classes.ExpandedCase__tableRow__item}>
                        {session.mode === 'DIAGNOSIS'
                          ? `${Math.round(session.ddx_efficiency < 0 ? 0 : session.ddx_efficiency * 100)}%`
                          : session.mode === 'multi' || session.mode === 'two'
                          ? `${session.current_score}/${caseItem.result.max_score}`
                          : `${session.current_score}/${caseItem.result.max_score}`}{' '}
                      </div>
                      <div className={cx(classes.ExpandedCase__tableRow__item)}>
                        <div
                          className={cx(classes.ExpandedCase__player__dx, {
                            [classes.ExpandedCase__player__pwf]: session.mode === 'multi' || session.mode === 'two',
                            [classes.ExpandedCase__player__hx]:
                              session.mode !== 'DIAGNOSIS' && session.mode !== 'multi' && session.mode !== 'two',
                          })}
                        >
                          {modeToNum(session.mode)}
                        </div>
                      </div>
                      <div className={classes.ExpandedCase__tableRow__item}>
                        <Link
                          className={classes.ExpandedCase__viewBtn}
                          to={
                            session.mode === 'DIAGNOSIS'
                              ? `/diagnosis/${caseItem.id}/session_id/${session.uuid}/result`
                              : session.mode === 'multi' || session.mode === 'two'
                              ? `/pwf/rubric/${session.link_code}`
                              : `/cases/${caseItem.id}/session_id/${session.uuid}/rubric`
                          }
                          underline="none"
                          onClick={() => {}}
                        >
                          VIEW
                        </Link>
                      </div>
                    </div>
                  ))}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div
        className={classes.CaseHistoryCard__tickIcon}
        style={{
          backgroundColor: SYSTEM_COLORS[caseItem.primary_system],
          bottom:
            (screenSize === 'xs' || cardHovered) &&
            (screenSize === 'xs'
              ? caseItem.sessions.length < 3 && 50 * caseItem.sessions.length + 40
              : caseItem.sessions.length < 4 && 42 * caseItem.sessions.length + 40),
        }}
      >
        {screenSize === 'xs' || screenSize === 'sm' ? (
          <img src={tickIcon} style={{ maxHeight: 50 }} />
        ) : (
          <img src={tickIcon} style={{ maxHeight: 50 }} />
        )}
      </div>
      <div
        className={classes.CaseHistoryCard__tickIcon__background}
        style={{
          backgroundColor: SYSTEM_COLORS[caseItem.primary_system],
          bottom:
            (screenSize === 'xs' || cardHovered) &&
            (screenSize === 'xs'
              ? caseItem.sessions.length < 3 && 50 * caseItem.sessions.length + 40
              : caseItem.sessions.length < 4 && 42 * caseItem.sessions.length + 40),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  modalOpened: state.dashboard.modeSelectionModal,
});

CaseHistoryCard.propTypes = {
  caseItem: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  modalOpened: PropTypes.bool,
};

export default connect(mapStateToProps)(CaseHistoryCard);
