import * as Sentry from '@sentry/react';
import {
  CASES_LOADING,
  CASES_LOADED,
  CASES_CREATE_SESSION_ID,
  CASES_SET_CURRENT,
  CASES_SET_SCORE,
  CASES_SET_HINTS,
  CASES_SET_HINT_REVEALED,
  CASES_SET_FOLLOW_UP_QUESTIONS,
  CASES_SET_RUBRICS,
  CASES_SET_POINTS_LABEL,
  CASES_INITIALIZE,
  CASES_QUESTIONS_INFO,
  CASES_SET_FILTERED,
  CASES_SET_ALL,
  ALL_CASES_SET_FILTERED,
  ALL_CASES_INITIALIZE,
  SEARCH_CASES_SET,
  PWF_CASES_SET,
  PWF_CASES_FILTERED_SET,
  CASES_SET_STATS,
  CASES_CONFIRMED_LOADED,
  TIMER_COMPLETE,
  WANT_TO_NAVIGATE_TO,
  CASES_SET_PERCENTILE,
  SESSION_EXPIRED,
  CASES_SET_RUBRIC_STATS,
  SET_RECOMMENDED_CASES,
  QUESTION_ANSWER_UPDATED,
  COMPLETED_CASES_LOADED,
  GUIDE_MODE_SET,
  CASE_JOURNEY_SET,
  CASE_JOURNEY_ID_SET,
  AUTO_PLAY_AUDIO,
  FINISHED_CASE_SET,
  SESSIONS_ADDED,
  CASE_UNLOCKED,
  MISSING_SWITCH_SET,
  SESSION_CHRONO_SET,
  CHRONO_MARKED_SET,
  CASE_SCORE_SET,
  UNLOCKED_CASE_SET,
  DIAGNOSIS_SCORE_SET,
  CASES_COLLECTIONS_SET,
  FILTERED_CASES_COLLECTIONS_SET,
  CASES_START,
} from './types';
import { APIClient } from '../settings';
import { createMessage, clearMessages, deleteLoadingMessage } from './messages';
import { tokenConfig } from './auth';
import * as Api from '../service/api';
import { INCOMING_MESSAGE, LOADING_MESSAGE, OUTGOING_MESSAGE } from '../constants';
import isEmptyObj from '../helpers/emptyObject';
import shuffleArray from '../helpers/shuffleArray';
import orderCases from '../helpers/cases';
import { template } from 'lodash';

export const startCase = (payload) => ({
  type: CASES_START,
  payload,
});

export const setFilteredCases = (cases) => ({
  type: CASES_SET_FILTERED,
  cases,
});

export const setRecommendedCases = (cases) => ({
  type: SET_RECOMMENDED_CASES,
  cases,
});

export const loadCases = (cases, next) => ({
  type: CASES_LOADED,
  data: cases,
  next,
});

export const setCompletedCases = (completedCases) => ({
  type: COMPLETED_CASES_LOADED,
  completedCases,
});

export const setSessionId = (sessionId) => ({
  type: CASES_CREATE_SESSION_ID,
  sessionId,
});

export const setSessionExpired = (isExpired) => ({
  type: SESSION_EXPIRED,
  isExpired,
});

// Action to set hints in global states
export const setHints = (hints) => ({
  type: CASES_SET_HINTS,
  hints,
});

// Action to set the number of hints have revealed
export const setHintRevealed = (hintRevealed) => ({
  type: CASES_SET_HINT_REVEALED,
  hintRevealed,
});

// Action to set follow-up questions in global states
export const setFUQuestions = (questions) => ({
  type: CASES_SET_FOLLOW_UP_QUESTIONS,
  questions,
});

export const questionAnswerUpdated = (answer) => ({
  type: QUESTION_ANSWER_UPDATED,
  answer,
});

// Set Session Rubric
export const setSessionRubric = (sessionRubric) => ({
  type: CASES_SET_RUBRICS,
  rubrics: sessionRubric,
});

// Action to set points earned per every label
export const setScorePerLabel = (labelName, pointsEarned) => ({
  type: CASES_SET_POINTS_LABEL,
  payload: { labelName, pointsEarned },
});

export const guideModeSet = (value) => ({
  type: GUIDE_MODE_SET,
  value,
});

export const caseJourneySet = (payload) => ({
  type: CASE_JOURNEY_SET,
  payload,
});

export const caseJourneyIdSet = (id) => ({
  type: CASE_JOURNEY_ID_SET,
  id,
});

export const autoPlayAudioToggle = () => ({
  type: AUTO_PLAY_AUDIO,
});

export const setFinishedCase = (finishedCase) => ({
  type: FINISHED_CASE_SET,
  finishedCase,
});

export const unlockCase = (caseId) => ({
  type: CASE_UNLOCKED,
  caseId,
});

export const addSessions = (addedSessions) => ({
  type: SESSIONS_ADDED,
  addedSessions,
});

// Action to set current score of case
export const setCurrentScore = (score) => ({
  type: CASES_SET_SCORE,
  score,
});

// Action to set TIMER COMPLETE status
export const setTimerComplete = (timerComplete) => ({
  type: TIMER_COMPLETE,
  timerComplete,
});

// Action to set Page to Navigate to
export const wantToNavigateTo = (page) => ({
  type: WANT_TO_NAVIGATE_TO,
  page,
});

export const setCaseScore = (results) => ({
  type: CASE_SCORE_SET,
  results,
});

export const setDiagnosisScore = (results) => ({
  type: DIAGNOSIS_SCORE_SET,
  results,
});

export const setSearchCases = (cases) => ({
  type: SEARCH_CASES_SET,
  cases,
});

export const setPwfCases = (cases) => ({
  type: PWF_CASES_SET,
  cases,
});

export const setPwfFilteredCases = (cases) => ({
  type: PWF_CASES_FILTERED_SET,
  cases,
});

export const setAllCasesInitialize = (cases) => ({
  type: ALL_CASES_INITIALIZE,
  cases,
});

export const setFilteredAllCases = (cases) => ({
  type: ALL_CASES_SET_FILTERED,
  cases,
});

export const setUnlockedCases = (cases) => ({
  type: UNLOCKED_CASE_SET,
  cases,
});

export const setCasesCollection = (cases) => ({
  type: CASES_COLLECTIONS_SET,
  cases,
});

export const setFilteredCasesCollection = (cases) => ({
  type: FILTERED_CASES_COLLECTIONS_SET,
  cases,
});

export const getSearchCases = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { token } = getState().auth;
    Api.getSearchCases(token)
      .then((res) => {
        dispatch(setSearchCases(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const getPwfCases = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { token } = getState().auth;
    Api.getPwfCases(token)
      .then((res) => {
        dispatch(setPwfCases(orderCases(res.data, 'free')));
        dispatch(setPwfFilteredCases(orderCases(res.data, 'free')));
      })
      .catch((err) => {
        console.log(err);
      });
  });

export const getAllCases = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { token } = getState().auth;
    Api.getAllCases(token)
      .then((res) => {
        dispatch(setAllCasesInitialize(orderCases(res.data, 'completed')));
      })
      .catch((err) => {
        console.log(err);
      });
  });

// Get all cases and separate into recommended and all cases
export const fetchAllCases = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CASES_LOADING });
    const domain = window.location.hostname.split('.')[0];
    const baseDomain = ['www', 'internal', 'staging', 'localhost'];
    let baseName = 'base';
    if (!baseDomain.includes(domain)) {
      baseName = domain;
    }
    const url = getState().cases.casesNextPage
      ? getState().cases.casesNextPage
      : `/api/v1/cases/?page_size=300&domain=${baseName}`;
    APIClient.get(url, tokenConfig(getState))
      .then((res) => {
        resolve(res.data);
        // Randomly shuffle cases
        // Todo: combine 2 sort into 1
        const cases = shuffleArray(res.data.results)
          .sort((a) => {
            // put the pined cases to the bottom
            if (a.locked) {
              return 0;
            }
            return -1;
          })
          .sort((b) => {
            // top the featered cases
            if (b.featured) {
              return -1;
            }
            return 0;
          });

        dispatch(loadCases(cases, res.data.next));
      })
      .catch((err) => {
        reject(err);
      });
  });

// Get all cases collections
export const fetchCasesCollection = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch({ type: CASES_LOADING });
    const domain = window.location.hostname.split('.')[0];
    const baseDomain = ['www', 'internal', 'staging', 'localhost'];
    let baseName = 'base';
    if (!baseDomain.includes(domain)) {
      baseName = domain;
    }
    const url = `api/v1/cases/collection/list/?domain=${baseName}`;
    APIClient.get(url)
      .then((res) => {
        resolve(res.data);
        dispatch(setCasesCollection(res.data));
      })
      .catch((err) => {
        console.log(err.response);
        // reject(err);
      });
  });

export const getUnlockedCases = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { token } = getState().auth;
    Api.getUnlockedCase(token)
      .then((res) => {
        dispatch(setUnlockedCases(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  });

// Get session id when user enters the case page
export const postSessionId = (caseId, journeyId, mode) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { token } = getState().auth;
    Api.postSessionId(token, caseId, journeyId, mode)
      .then((res) => {
        dispatch(setSessionId(res.data.session_id));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

// Stop Session when finish case
export const stopSession = (sessionId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { markStreak, hintRevealed } = getState().cases;
    const hintUsed = isEmptyObj(hintRevealed) ? 0 : hintRevealed.currentHint;
    const args = {
      mark_streak: markStreak,
      hint_used: hintUsed,
    };

    APIClient.post(`/api/v1/agent/${sessionId}/stop/`, args)
      .then((res) => {
        dispatch({
          type: CASES_SET_PERCENTILE,
          casePercentile: res.data.percentile,
        });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getRubricStats = (sessionId) => (dispatch, getState) => {
  const { token } = getState().auth;
  return new Promise((resolve, rejected) =>
    Api.getRubricStats(token, sessionId)
      .then((res) => {
        resolve(res);
        // Update scores in the dashboard cases list, if empty then ignore
        if (getState().cases.cases.length) {
          dispatch(setCaseScore({ id: res.data.case.id, score: res.data.current_score, stars: res.data.stars }));
        }
        dispatch({
          type: CASES_SET_RUBRIC_STATS,
          rubricStats: {
            ...res.data,
          },
        });
      })
      .catch((err) => {
        rejected(err);
      })
  );
};

// Action to request response from patient, responding to question
export const requestResponse = (text) => (dispatch, getState) =>
  new Promise((resolve) => {
    dispatch(createMessage(LOADING_MESSAGE, 'LOADING', new Date().toString()));

    const { token } = getState().auth;
    const { sessionId, totalQuestions, markStreak, maxMarkStreak } = getState().cases;
    Api.getResponse(token, sessionId, text)
      .then((res) => {
        // Remove loading message
        dispatch(deleteLoadingMessage());

        const {
          current_score,
          points_earned: pointsEarned,
          label: labelName = '',
          bedside_manner: bedsideManner,
        } = res.data;
        // We are using all lowercased letters
        if (res.data.response_type === 'multiple_responses') {
          dispatch(
            createMessage(
              OUTGOING_MESSAGE,
              res.data.guide_msg,
              new Date().toString(),
              res.data.response_status,
              res.data.options
            )
          );
        } else {
          if (res.data.response_status === 'leading' || res.data.response_status === 'oneword') {
            dispatch(
              createMessage(OUTGOING_MESSAGE, res.data.guide_msg, new Date().toString(), res.data.response_status)
            );
          }
          dispatch(createMessage(INCOMING_MESSAGE, res.data, new Date().toString()));
          dispatch(setScorePerLabel(labelName.toLowerCase(), pointsEarned));
          // Set number of total questions and useful questions
          const newMarkStreak = pointsEarned >= 1 ? markStreak + 1 : 0;
          const newMaxMarkStreak =
            newMarkStreak !== 0 && newMarkStreak > maxMarkStreak ? maxMarkStreak + 1 : maxMarkStreak;
          dispatch(setQuestionsInfo(totalQuestions + 1, newMarkStreak, newMaxMarkStreak, bedsideManner));
          // Set the current score
          dispatch(setCurrentScore(current_score));
        }
        resolve();
      })
      .catch((err) => {
        dispatch(setSessionExpired(true));
      });
  });

// Action to get currrent selected case
export const getCurrentCase = (caseId) => (dispatch) =>
  new Promise((resolve, reject) =>
    APIClient.get(`/api/v1/cases/${caseId}/`)
      .then((res) => {
        resolve(res);
        dispatch({
          type: CASES_SET_CURRENT,
          case: res.data,
        });
      })
      .catch((err) => {
        reject(err);
      })
  );

// Action to fetch hints of current case
export const fetchHints = (caseId) => (dispatch, getState) => {
  const { token } = getState().auth;
  Api.getHints(token, caseId).then((res) => {
    dispatch(setHints(res.data));
  });
};

export const fetchFollowUpQuestions = (caseId) => (dispatch, getState) => {
  const { token } = getState().auth;
  Api.getFUQuestions(token, caseId).then((res) => {
    dispatch(setFUQuestions(res.data));
  });
};

export const fetchConfirmedCases = () => (dispatch, getState) => {
  APIClient.get('/api/v1/cases/confirmed/', tokenConfig(getState)).then((res) => {
    dispatch({
      type: CASES_CONFIRMED_LOADED,
      data: res.data,
    });
  });
};

// Action to initialize the states in cases state
export const initialize = () => (dispatch) => {
  dispatch(clearMessages());
  dispatch({
    type: CASES_INITIALIZE,
  });
};

// Action to set total questions and useful questions
export const setQuestionsInfo = (totalQuestions, markStreak, maxMarkStreak, bedsideManner) => ({
  type: CASES_QUESTIONS_INFO,
  payload: { totalQuestions, markStreak, maxMarkStreak, bedsideManner },
});

// Action to fetch hints of current case
export const fetchCaseSystemStats = () => (dispatch, getState) => {
  const { token } = getState().auth;
  Api.getStats(token).then((res) => {
    dispatch({
      type: CASES_SET_STATS,
      systemStats: res.data,
    });
  });
};

export const postQuestions = (questions, sessionId) => (_dispatch, getState) => {
  const { token } = getState().auth;
  return new Promise((resolve, reject) => {
    Api.postQuestions(token, sessionId, { questions })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const getCaseHistory = () => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.get('/api/v1/agent/case-history/', tokenConfig(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getHints = (caseId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.get(`/api/v1/cases/${caseId}/hints/`, tokenConfig(getState))
      .then((res) => {
        dispatch(setHints(res.data));
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getCaseJourney = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.get(`/api/v1/cases/case-journey-list/`, tokenConfig(getState))
      .then((res) => {
        dispatch(caseJourneySet(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const patchMessageReview = (messageId, needReview) => (_, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/message/${messageId}`, { need_review: needReview }, tokenConfig(getState))
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const patchCaseSummary = (session_id, caseSummary) =>
  new Promise((resolve, reject) =>
    APIClient.patch(`/api/v1/agent/${session_id}/info/`, { patient_summary: caseSummary })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err))
  );

export const patchCaseUnlock = (caseId, price) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/cases/unlock/`, { case_ids: [caseId] })
      .then((res) => {
        dispatch(addSessions(-price));
        dispatch(unlockCase(caseId));
        resolve(res);
      })
      .catch((err) => reject(err));
  });

export const setMissingSwitchToggle = () => ({
  type: MISSING_SWITCH_SET,
});

export const setChronoMarked = (path) => ({
  type: CHRONO_MARKED_SET,
  path,
});

export const setSessionChrono = (rubric) => ({
  type: SESSION_CHRONO_SET,
  rubric,
});

export const patchSessionChrono = (sessionId, path) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`api/v1/agent/session/${sessionId}/chrono-rubric/`, {
      path,
    })
      .then((res) => {
        dispatch(setSessionChrono(res.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getCasePreview = (caseId) => {
  const params = {
    case_id: caseId,
  };
  return APIClient.get(`/api/v1/cases/admin/case/preview/`, { params });
};

export const postEmailRubric = (email, caseId, resultsLink) =>
  new Promise((resolve, reject) => {
    APIClient.post(`/api/v1/cases/result-rubric/email/`, {
      secondary_email: email,
      case_id: caseId,
      results_link: resultsLink,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        Sentry.captureException(err, {
          tags: {
            section: 'Send detailed rubric email',
          },
        });
        reject(err);
      });
  });

// Action to get currrent selected case info
export const getCaseSession = (sessionId) => (dispatch) =>
  new Promise((resolve, reject) =>
    APIClient.get(`/api/v1/agent/${sessionId}/info/`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  );

// add start time to backend
export const addStartTime = (sessionId, startTime) => (_, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/agent/${sessionId}/info/`, { started: startTime }, tokenConfig(getState))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
