import React, { Component } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';
import './help.scss';
import IconEmail from '../../../../components/icons/IconEmail';
import IconChat from '../../../../components/icons/IconChat';
import IconCommunity from '../../../../components/icons/IconCommunity';
import ReactHtmlParser from 'react-html-parser';

const dummyText =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's\n" +
  '        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make\n' +
  '        a type specimen book. It ha\n';

const accountFaqData = [
  [
    'Can’t log in',
    `<p>Try our <a class="c4" href = "https://www.google.com/url?q=https://www.oscer.ai/%23/forgot_password&amp;sa=D&amp;ust=1589871397530000" >
 forgot your password </a> process.<p>If you log in with your social accounts and are having difficulty retrieving your account information 
 please speak to us at <a class="c4" href="mailto:support@oscer.ai">support@oscer.ai</a></p>`,
  ],
  [
    'Manage your personal details',
    `<p>Head to <a class="c4" href="https://www.google.com/url?q=https://staging.oscer.ai/%23/account/profile&amp;sa=D&amp;ust=1589871397531000">my profile</a>.</p><p>If you click on any of the fields you will be able to edit the details within.</p><p>The only field you can’t edit is your email address associated with the account. If you need to edit your email, speak to us at <a class="c4" href="mailto:support@oscer.ai">support@oscer.ai</a></p>`,
  ],
  [
    'How to change subscription',
    `<p>Head to <a class="c4" href="https://www.google.com/url?q=https://staging.oscer.ai/%23/account/billing-plan&amp;sa=D&amp;ust=1589871397532000">plan &amp; billing</a>.</p><p>Click ‘alter billing’, depending on your current plan you will be presented with different options. Follow the relevant prompts and make your required changes. If you have inadvertently altered your billing or been charged incorrectly, speak to us at <a class="c4" href="mailto:support@oscer.ai">support@oscer.ai</a></p>`,
  ],
  [
    'How to change my credit card',
    `<p>Head to <a class="c4" href="https://www.google.com/url?q=https://staging.oscer.ai/%23/account/billing-plan&amp;sa=D&amp;ust=1589871397532000">plan &amp; billing</a>.</p><p>Click ‘alter billing’, follow the relevant prompts to change the card associated with your account.</p>`,
  ],
  [
    'Is there a limit to referrals',
    `<p>Oscer is really lonely in Morbidia, he needs your help.</p><p>Soon he hopes all the patients will be cured, but until then bring as many as you can.</p>`,
  ],
  [
    'My trial expired early',
    `<p>Oh no, it’s not nice without your crown.</p><p>Speak to us at <a class="c4" href="mailto:support@oscer.ai">support@oscer.ai</a></p>`,
  ],
  [
    'My Prime isn’t working as expected',
    `<p>Every king is a little different but not this different.</p><p>Speak to us at <a class="c4" href="mailto:support@oscer.ai">support@oscer.ai</a></p>`,
  ],
  [
    'Cancelling my subscription',
    `<p>Head to <a class="c4" href="https://www.google.com/url?q=https://staging.oscer.ai/%23/account/billing-plan&amp;sa=D&amp;ust=1589871397532000">plan &amp; billing</a>.</p><p>Click ‘alter billing’, follow the relevant prompts to cancel your subscription and lose the crown.</p>`,
  ],
];

const accountFaqData2 = [
  [
    'Getting started',
    `<p>Oscer needs your help. Morbidia is overrun with sick patients, chest pain, vomiting, rashes they’re sick and scared.</p><p>Start by chatting to a patient, try to get the highest score you can.</p>`,
  ],
  [
    'Rules for chatting with patients',
    `<p>Oscer’s patients have a bad short term memory.</p><p>Ask in full the location of a symptom and what the symptom is.</p><p><b>Every time you ask a question</b>, repeat the full description</p><p>"Where is your chest pain?" ✔️</p><p>"Where is your pain?" ❌</p><p>"Where is it/this/that?" ❌</p>`,
  ],
  [
    'Troubleshooting your chat',
    `<p>There are <b>two different ways</b> that the patient can become confused.</p><p><b>If you ask a medical question outside of the scope of that case</b></p><p>In this situation, you will see Oscer label the patient response. Ie: “Temporal Onset” and the response will be “Try to focus on the case ~ Oscer”</p><p>If you think it is an appropriate question click ! on the message and Oscer will make sure his patients know what to say next time.</p><p><b>If you ask a question the patient doesn’t understand</b></p><p>In this situation, the patient will respond “Could you try asking me that a different way?” or something similar, because we don’t understand the question.</p><p>If this is an appropriate questions click ! on the message and Oscer will tell them what to say next time.</p>`,
  ],
  [
    'Getting an unexpected response',
    `<p>If you get an unexpected response from the patient many things could have gone wrong. The best thing to do is mark it with a ! for Oscer and he’ll work our why the patient was confused</p>`,
  ],
  [
    'Struggling to get passed the undiagnosed screen',
    `<p>To get 1 star and unlock the diagnosis for your case you need to get at least 25% of the available marks for the case. If you think it is too hard, speak to us at <a class="c4" href="mailto:support@oscer.ai">support@oscer.ai</a></p>`,
  ],
  [
    'Using the rubrics',
    `<p>Anything you ask the patient will be codified in your rubric. Study your performance and make note of the questions you should have asked, including the differential diagnoses you may or may not have considered. That’s the only way to become the king of diagnosis like Oscer.</p>`,
  ],
  [
    'Understanding differential diagnoses',
    `<p>Every case even low-level cases have diagnoses associated with them. Oscer thinks it is very important to consider the differentials and the questions you ask to reveal them as soon as you start becoming a doctor, it is the only way.</p>`,
  ],
  [
    'Case levels',
    `<p>Cases range from level 1 to 12.</p><p>The levels are based on the difficulty of the case assigned by</p><p>Oscer he thinks about it roughly like this:</p><p>Level 1 ~ 1st Year Medical Student</p><p>Level 4 ~ 2nd Year Medical Student</p><p>Level 7 ~ Final Year Medical Student</p><p>Level 10 ~ Junior Resident</p><p>Level 12 ~ Oscer Status (King of Diagnosis)</p>`,
  ],
];

const oscerFaqData = [
  ['Getting Started', dummyText],
  ['Video Tutorials', dummyText],
  ['Best Features', dummyText],
  ['How to download case summary', dummyText],
  ['Personalise your Dashboard', dummyText],
  ['Find incomplete cases', dummyText],
  ['General Troubleshooting', dummyText],
];

class HelpItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  render() {
    return (
      <li className={'help-item-li'}>
        <div className={'help-line-header'}>
          <div className={'help-item'}>{this.props.item[0]}</div>
          <div className={'help-item-expand'}>
            {this.state.collapsed ? (
              <FiPlus onClick={() => this.setState({ collapsed: false })} />
            ) : (
              <FiMinus onClick={() => this.setState({ collapsed: true })} />
            )}
          </div>
        </div>
        <div className={`${'help-content'} ${this.state.collapsed && 'collapsed'}`}>
          {ReactHtmlParser(this.props.item[1])}
        </div>
      </li>
    );
  }
}

class Help extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={'help-contact-card'}>
          <table>
            <thead>
              <tr>
                <td>Help</td>
                <td />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Speak to Oscer’s servants</td>
                <td className={'help-contact'}>
                  <a href="mailto:support@oscer.ai">
                    <IconEmail size={30} color={'rgb(74, 132, 255)'} />
                    support@oscer.ai
                  </a>
                </td>
              </tr>
              <tr>
                <td>Help make Oscer better</td>
                <td className={'help-contact'}>
                  <IconChat size={30} color={'rgb(74, 132, 255)'} />
                  Suggest a feature{' '}
                </td>
              </tr>
              <tr>
                <td>Find answers & tips from Oscer’s fans</td>
                <td className={'help-contact'}>
                  <IconCommunity size={30} color={'rgb(74, 132, 255)'} />
                  Visit Our Community
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={'help-cards-wrapper'}>
          <div className={'help-card'}>
            <ul>
              <li>Account & Payment FAQs</li>
              {accountFaqData.map((item, key) => (
                <HelpItem item={item} key={key} />
              ))}
            </ul>
          </div>
          <div className={'help-card'}>
            <ul>
              <li>How To Use Oscer</li>
              {accountFaqData2.map((item, key) => (
                <HelpItem item={item} key={key} />
              ))}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Help;
