import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { TextField, Checkbox, FormControlLabel, Button, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { signUp, clearAuthErrors } from '../../../actions/auth';
import SocialAuthPanel from '../../SocialAuthPanel';
import DividerWithText from '../../../components/common/Divider';

import iconLogo from '../../../assets/logos/oscer-full-logo.svg';
import EmailIcon from '../../../assets/login/email-icon-dark.svg';
import PasswordIcon from '../../../assets/login/password-icon-dark.svg';
import iconReferral from '../../../assets/login/referral-icon.svg';
import freeSession from '../../../assets/login/free-sessions-icon.svg';
import termPDF from '../../../assets/policy/Oscer-Terms&Conditions.pdf';
import OscerPrime from '../../../assets/character/oscer-character-chat-help.svg';
import useGeoLocation from '../../../hook/useGeoLocation';
import iconPromo from '../../../assets/login/promo-icon.svg';
import { emailRegx } from '../../../helpers/validation';
import useStyles from './style';
import SignUp from '../../../components/SignUp';

const SignUpBox = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.Signup}>
      <img src={OscerPrime} alt="oscer__icon__signup" className={classes.Signup__icon__signup} />
      <SignUp {...props} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authError: state.auth.error,
});

SignUpBox.propTypes = {
  signUp: PropTypes.func,
  setStep: PropTypes.func,
  setSignUp: PropTypes.func,
  clearAuthErrors: PropTypes.func,
  authError: PropTypes.string,
  location: PropTypes.object,
};

export default connect(mapStateToProps, { signUp, clearAuthErrors })(SignUpBox);
