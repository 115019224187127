import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  WelcomeAlert__wrapper_main: {
    // display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 660,
    // height: 600,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    padding: '60px 10px 30px 10px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
      height: 'auto',
      padding: '30px 15px',
    },
  },
  WelcomeAlert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
  },
  WelcomeAlert__ArrowLeft: {
    display: 'flex',
    width: 50,
  },
  WelcomeAlert__ArrowRight: {
    display: 'flex',
    width: 50,
    marginRight: 15,
  },
  WelcomeAlert__close: {
    position: 'absolute',
    right: 20,
    top: 20,
    color: `${theme.palette.text.main}33`,
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.text.main}CC`,
    },
  },
  WelcomeAlert__text: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginBottom: 10,
  },
  WelcomeAlert__content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '85%',
    height: '100%',
    justifyContent: 'center',
  },
  WelcomeAlert__image: {
    maxHeight: 360,
    maxWidth: 360,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 250,
      maxWidth: 250,
    },
  },
  WelcomeAlert_media: {
    // height: 360,
    // [theme.breakpoints.down('xs')]: {
    //   height: 250,
    // },
  },
  WelcomeAlert__title: {
    fontSize: 30,
    color: theme.palette.text.main,
    marginBottom: 20,
    textAlign: 'center',
  },
  WelcomeAlert__subtitle: {
    fontSize: 16,
    color: theme.palette.text.main,
    marginBottom: 10,
    textAlign: 'center',
  },
  WelcomeAlert__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  WelcomeAlert__error: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontSize: 14,
    marginTop: 10,
  },
  WelcomeAlert__btn: {
    height: 40,
    marginTop: 35,
    padding: '6px 16px',
    boxShadow: 'none',
  },
  WelcomeAlert__dots: {
    display: 'flex',
    marginTop: 'auto',
  },
  WelcomeAlert__dot: {
    margin: 5,
    fontSize: 10,
  },
  WelcomeAlert__dot__light: {
    opacity: 0.2,
  },
}));

export default useStyles;
