import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import * as Sentry from '@sentry/react';
import {
  InputAdornment,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  IconButton,
} from '@material-ui/core/';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Radio from '@material-ui/core/Radio';
import { range } from 'lodash';
import { connect } from 'react-redux';
import { fetchAllCases } from '../../../actions/cases';
import StripeTextField from '../StripeTextField';
import { trackEvent, ecommerceTrack } from '../../../service/analytics';
import { useWidth } from '../../../helpers/customHooks';

import Amex from '../../../assets/icons/amex.svg';
import MasterCard from '../../../assets/icons/mastercard.svg';
import Visa from '../../../assets/icons/visa.svg';
import DinnerClub from '../../../assets/icons/diners-club.svg';
import VisaNew from '../../../assets/icons/visa-logo-new.svg';
import {
  postStripeCheckout,
  setSelectedPlan,
  getVoucher,
  fetchPlans,
  getDefaultPayment,
} from '../../../actions/checkout';

import useStyles from './style';

const InjectedStripeForm = (props) => {
  const cs = useStyles();
  const [cardType, setCardType] = useState('');
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const [isCardNumberCompleted, setCardNumberCompleted] = useState(false);
  const [isCardExpireCompleted, setCardExpireCompleted] = useState(false);
  const [isCardCVCompleted, setCardCVCompleted] = useState(false);
  const [voucher, setVoucher] = useState('');
  const [isVoucherValid, setVoucherValid] = useState(false);
  const [isVoucherChecked, setVoucherChecked] = useState(false);
  const [voucherHelperText, setVoucherHelpText] = useState('');
  const [voucherProcessing, setVoucherProcessing] = useState(false);
  const [isDefaultPayment, setDefaultPayment] = useState(false);

  // const screenSize = useWidth();
  const isSmallScreen = useWidth() === 'xs';

  const validateVoucher = () => {
    if (voucher.length > 1 && !voucherProcessing) {
      setVoucherProcessing(true);
      props
        .getVoucher(voucher, selectedPlanObj?.id)
        .then((res) => {
          setVoucherValid(true);
          trackEvent({
            category: 'Voucher',
            GAAction: 'Apply Voucher',
            FBAction: 'Apply Voucher',
            label: `User ${props.userID} applied voucher ${voucher}`,
          });
          props.fetchPlans('', voucher);
        })
        .catch((err) => {
          setVoucherValid(false);
          setVoucherHelpText('Invalid Code');
        })
        .then((res) => {
          setVoucherChecked(true);
          setVoucherProcessing(false);
        });
    }
  };

  useEffect(() => {
    if (props.defaultVoucher) {
      setVoucher(props.defaultVoucher);
      validateVoucher();
    }

    props
      .getDefaultPayment()
      .then((res) => {
        if (res.data.length) {
          setDefaultPayment(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err, {
          tags: {
            section: 'Get default payment',
          },
        });
      });
  }, []);

  useEffect(() => {
    // Check if user switch plans, if promo code is invalid, remove error message and let user apply again
    if (isVoucherChecked) {
      if (!isVoucherValid) {
        setVoucherHelpText('');
        setVoucherValid(false);
        setVoucherChecked(false);
      }
    }
  }, [props.selectedPlan]);

  if (props.plans.length === 0) {
    return <div className="">No plans</div>;
  }
  const selectedPlanObj = props.plans.find((plan) => plan.name === props.selectedPlan) || props.plans[0];

  const cardBrands = [
    {
      image: Amex,
      name: 'amex',
      alt: 'Amex Logo',
    },
    {
      image: MasterCard,
      name: 'mastercard',
      alt: 'MasterCard Logo',
    },
    {
      image: VisaNew,
      name: 'visa',
      alt: 'Visa Logo',
    },
    {
      image: DinnerClub,
      name: 'diners_club',
      alt: 'DinnersClub Logo',
    },
  ];

  const handleVoucher = (event) => {
    setVoucher(event.target.value.toUpperCase());
    // console.log(voucher);
    setVoucherChecked(false);
    setVoucherHelpText('');
  };

  const handleVoucherCross = () => {
    setVoucher('');
    setVoucherChecked(false);
    setVoucherHelpText('');
  };

  // When user click Edit on payment method
  const handleDefaultPayment = () => {
    setDefaultPayment((toggle) => !toggle);
  };

  const voucherStateSelector = () => {
    if (isVoucherChecked) {
      if (isVoucherValid) {
        return <CheckIcon color="primary" />;
      }
      return (
        <IconButton
          classes={{ root: cs.StripeCheckout__IconButton__root }}
          aria-label="toggle password visibility"
          onClick={handleVoucherCross}
          edge="end"
        >
          <ClearIcon color="secondary" />
        </IconButton>
      );
    }
    if (voucherProcessing) {
      return <CircularProgress className={cs.StripeCheckout__spinner} color="primary" size="20px" />;
    }
    return (
      <div
        onClick={validateVoucher}
        className={cx(cs.StripeCheckout__voucher__apply, {
          [cs.StripeCheckout__voucher__apply__active]: voucher.length > 1,
        })}
        aria-hidden="true"
      >
        APPLY
      </div>
    );
  };

  // Handle user use enter key after input promo code
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && voucher.length) {
      validateVoucher();
    }
  };

  const handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    setPaymentProcessing(true);
    // Default payment is return user who have purchased our plan and have payment details saved
    if (isDefaultPayment) {
      const args = {
        plan_id: selectedPlanObj?.id,
        promo_code: voucher || '',
        use_default_pamyment_method: isDefaultPayment,
      };
      props
        .postStripeCheckout(args)
        .then(() => {
          // Update cases list because all cases unlocked
          props.fetchAllCases();
          // Tracking
          const currentDate = new Date();
          ecommerceTrack({
            isFBTrack: true,
            transactionId: `${props.userID}_${selectedPlanObj?.display_name}_${currentDate.getTime()}`,
            value: selectedPlanObj?.price,
            id: selectedPlanObj?.id,
            label: `User ${props.userID} succeed to purchase plan ${selectedPlanObj?.display_name} $${selectedPlanObj?.discounted_price}`,
            displayName: selectedPlanObj?.display_name,
            category: selectedPlanObj?.charge_option,
            price: selectedPlanObj?.price,
            period: selectedPlanObj?.period,
            time: currentDate,
          });
        })
        .catch((err) => {
          trackEvent({
            category: 'Purchase Failed',
            GAAction: 'purchase_failed',
            FBAction: 'Purchase Failed',
            value: selectedPlanObj?.price,
            label: `User ${props.userID} failed to purchase plan ${selectedPlanObj?.display_name} $${
              selectedPlanObj?.price
            }. Error:${typeof err === 'string' ? err : 'unknown'}`,
          });
          if (typeof err === 'string') {
            setPaymentError(err);
          } else {
            setPaymentError('Sorry, we are unable to make the payment [1], please contact support team.');
          }

          setPaymentProcessing(false);
        });
    } else {
      // User who first try our payment, will create stripe token first
      props.stripe
        .createToken()
        .then((result) => {
          if (result.error) {
            setPaymentError(result.error.message);
          } else {
            const args = {
              token: result.token.id,
              plan_id: selectedPlanObj?.id,
              promo_code: voucher || '',
            };
            props
              .postStripeCheckout(args)
              .then(() => {
                // Update cases list because all cases unlocked
                props.fetchAllCases();
                // Tracking
                const currentDate = new Date();
                ecommerceTrack({
                  isFBTrack: true,
                  transactionId: `${props.userID}_${selectedPlanObj?.display_name}_${currentDate.getTime()}`,
                  value: selectedPlanObj?.price,
                  id: selectedPlanObj?.id,
                  label: `User ${props.userID} succeed to purchase plan ${selectedPlanObj?.display_name} $${selectedPlanObj?.discounted_price}`,
                  displayName: selectedPlanObj?.display_name,
                  category: selectedPlanObj?.charge_option,
                  price: selectedPlanObj?.price,
                  period: selectedPlanObj?.period,
                  time: currentDate,
                });
              })
              .catch((err) => {
                trackEvent({
                  category: 'Purchase Failed',
                  GAAction: 'purchase_failed',
                  FBAction: 'Purchase Failed',
                  value: selectedPlanObj?.price,
                  label: `User ${props.userID} failed to purchase plan ${selectedPlanObj?.display_name} $${
                    selectedPlanObj?.price
                  }. Error:${typeof err === 'string' ? err : 'unknown'}`,
                });
                if (typeof err === 'string') {
                  setPaymentError(err);
                } else {
                  setPaymentError('Sorry, we are unable to make the payment [2], please contact support team.');
                }
                setPaymentProcessing(false);
              });
          }
        })
        .catch((err) => {
          setPaymentError('Sorry, we are unable to make the payment [3], please contact support team.');
          setPaymentProcessing(false);
        });
    }
  };

  return (
    <>
      <div className={cs.StripeCheckout__payment__method}>
        <div className={cs.StripeCheckout__method}>{isSmallScreen ? 'PRIME PAYMENT' : 'PAYMENT METHOD'}</div>
        {isSmallScreen && <div className={cs.StripeCheckout__method__subtitle}>Step 2 | payment</div>}
        {isDefaultPayment && props.defaultPayment[0]?.last4 ? (
          <div className={cs.StripeCheckout__default}>
            <div className={cs.StripeCheckout__default__payment}>
              <div className={cs.StripeCheckout__default__wrapper}>
                <Radio
                  checked
                  color="primary"
                  name="default_payment"
                  classes={{ root: cs.StripeCheckout__default__radio }}
                />
                <div className={cs.StripeCheckout__default__info}>
                  <div className={cs.StripeCheckout__default__text}>Your Card</div>
                  <div className={cs.StripeCheckout__default__card}>
                    {range(3).map((num) => (
                      <div key={num} className={cs.StripeCheckout__default__dot__wrapper}>
                        {range(4).map((dot) => (
                          <div key={`${num}_${dot}`} className={cs.StripeCheckout__default__dot} />
                        ))}
                      </div>
                    ))}
                    <div className={cs.StripeCheckout__default__dot__wrapper}>{props.defaultPayment[0]?.last4}</div>
                  </div>
                </div>
              </div>
              <Fade in timeout={700}>
                <div>
                  {cardBrands
                    .filter((brand) => brand.name.includes(props.defaultPayment[0]?.brand))
                    .map((brand) => (
                      <img
                        key={brand.name}
                        className={cs.StripeCheckout__default__cardBrand}
                        src={brand.image}
                        alt={brand.alt}
                      />
                    ))}
                </div>
              </Fade>
            </div>
            <div className={cs.StripeCheckout__default__edit} onClick={handleDefaultPayment} aria-hidden="true">
              {isDefaultPayment ? 'EDIT' : 'CANCEL'}
            </div>
          </div>
        ) : (
          <>
            <div className={cs.StripeCheckout__row}>
              <StripeTextField
                id="stripe-card-number"
                label="Card Number"
                component={CardNumberElement}
                setCardType={setCardType}
                setCompleted={setCardNumberCompleted}
                endAdornment={
                  <InputAdornment className={cs.StripeCheckout__cardLogos} position="end">
                    {cardType ? (
                      <Fade in timeout={700}>
                        <div>
                          {cardBrands
                            .filter((brand) => brand.name.includes(cardType))
                            .map((brand) => (
                              <img
                                key={brand.name}
                                className={cs.StripeCheckout__cardBrand}
                                src={brand.image}
                                alt={brand.alt}
                              />
                            ))}
                        </div>
                      </Fade>
                    ) : (
                      <div>
                        {cardBrands.map((brand) => (
                          <img
                            key={brand.name}
                            className={cs.StripeCheckout__cardBrand}
                            src={brand.image}
                            alt={brand.alt}
                          />
                        ))}
                      </div>
                    )}
                  </InputAdornment>
                }
              />
            </div>
            <div className={cs.StripeCheckout__row}>
              <StripeTextField
                id="stripe-card-expiry"
                label="Expiry"
                component={CardExpiryElement}
                setCompleted={setCardExpireCompleted}
              />
              <StripeTextField
                id="stripe-card-cvc"
                label="CVC"
                component={CardCVCElement}
                setCompleted={setCardCVCompleted}
              />
              {props.defaultPayment[0]?.last4 && (
                <div className={cs.StripeCheckout__default__edit} onClick={handleDefaultPayment} aria-hidden="true">
                  {isDefaultPayment ? 'EDIT' : 'CANCEL'}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className={cs.StripeCheckout__summary}>
        <div className={cs.StripeCheckout__title}>SUMMARY</div>
        <div className={cs.StripeCheckout__seperator} />
        <div className={cs.StripeCheckout__plan}>
          <div className={cs.StripeCheckout__plan__name}>
            {`${selectedPlanObj?.display_name}`}
            <span className={cs.StripeCheckout__plan__type}>
              {selectedPlanObj.charge_option === 'recurring' ? '(Recurring Payment)' : '(One Time Payment)'}
            </span>
          </div>
          <div className={cs.StripeCheckout__plan__price}>
            {`${selectedPlanObj?.currency_symbol}${selectedPlanObj?.original_price.toFixed(2)}`}
            <span className={cs.StripeCheckout__plan__price__unit}>{selectedPlanObj?.currency}</span>
          </div>
        </div>
        {selectedPlanObj?.original_price > selectedPlanObj?.discounted_price && (
          <div className={cs.StripeCheckout__plan__discount}>
            <div className={cs.StripeCheckout__plan__discount__wrapper}>
              <div className="">{`- at ${(
                100 -
                (selectedPlanObj?.discounted_price / selectedPlanObj?.original_price) * 100
              ).toFixed(0)}% off`}</div>
              {/* <div className={cs.StripeCheckout__plan__type}>
                {selectedPlanObj.charge_option === 'recurring' ? '(Recurring Payment)' : '(One Time Payment)'}
              </div> */}
            </div>
            <div className={cs.StripeCheckout__plan__price}>
              {`${selectedPlanObj?.currency_symbol}${selectedPlanObj?.discounted_price.toFixed(2)}`}
              <span className={cs.StripeCheckout__plan__price__unit}>{selectedPlanObj?.currency}</span>
            </div>
          </div>
        )}
        {selectedPlanObj?.original_price > selectedPlanObj?.price && (
          <div className={cs.StripeCheckout__global_discount}>
            <div className={cs.StripeCheckout__plan__price}>
              -{selectedPlanObj?.currency_symbol}
              {(selectedPlanObj?.original_price - selectedPlanObj?.price).toFixed(2)}
              <span className={cs.StripeCheckout__plan__price__unit}>{selectedPlanObj?.currency}</span>
            </div>
          </div>
        )}
        {props.subscriptionDaysLeft > 0 && (
          <div className={cs.StripeCheckout__plan__desc}>
            {selectedPlanObj?.charge_option === 'one_off'
              ? `Your ${props.subscriptionDaysLeft} days of remaining Prime will be added to the end of your plan.`
              : `You will be charged now. Your next billing date will be delayed by ${props.subscriptionDaysLeft} days to credit your remaining Prime.`}
          </div>
        )}
        <div
          className={cx(cs.StripeCheckout__discount, {
            [cs.StripeCheckout__discount__active]: voucher || isVoucherValid,
          })}
        >
          <div className={cs.StripeCheckout__discount__wrapper}>
            <div
              className={cx(cs.StripeCheckout__discount__code, {
                [cs.StripeCheckout__discount__code__active]: isVoucherValid,
              })}
            >
              Discount Code
            </div>
            {!isVoucherValid && (
              <CancelIcon
                fontSize="inherit"
                classes={{ root: cs.StripeCheckout__discount__icon }}
                onClick={handleVoucherCross}
              />
            )}
          </div>
          {isVoucherValid && (
            <div className={cs.StripeCheckout__plan__price}>
              {`-${selectedPlanObj?.currency_symbol}${(
                selectedPlanObj?.price - selectedPlanObj?.discounted_price
              ).toFixed(2)}`}
              <span className={cs.StripeCheckout__plan__price__unit}>{selectedPlanObj?.currency}</span>
            </div>
          )}
        </div>
        <FormControl className="" variant="outlined" onKeyPress={handleKeyPress}>
          <OutlinedInput
            id="outlined-adornment-voucher"
            value={voucher}
            autoComplete="off"
            placeholder="Enter Discount Code"
            onChange={handleVoucher}
            classes={{
              root: cs.StripeCheckout__OutlinedInput__root,
              notchedOutline: cs.StripeCheckout__formControl__notchedOutline,
            }}
            endAdornment={<InputAdornment position="end">{voucherStateSelector()}</InputAdornment>}
            labelWidth={70}
          />
          <FormHelperText error id="outlined-weight-helper-text">
            {voucherHelperText}
          </FormHelperText>
        </FormControl>
        <div className={cs.StripeCheckout__total}>
          <div className={cs.StripeCheckout__total__text}>Total</div>
          <div className={cs.StripeCheckout__total__price}>
            {`${selectedPlanObj?.currency_symbol}${selectedPlanObj?.discounted_price.toFixed(2)}`}
            <span className={cs.StripeCheckout__total__price__unit}>{selectedPlanObj?.currency}</span>
          </div>
        </div>
        {paymentProcessing ? (
          <Button
            className={cs.StripeCheckout__confirmBtn}
            variant="contained"
            color="primary"
            disabled
            endIcon={<CircularProgress className={cs.StripeCheckout__spinner} color="secondary" size="20px" />}
          >
            Processing...
          </Button>
        ) : (
          <Button
            className={cs.StripeCheckout__confirmBtn}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              !props.stripe ||
              (!isDefaultPayment && (!isCardNumberCompleted || !isCardExpireCompleted || !isCardCVCompleted))
            }
          >
            Purchase Now
          </Button>
        )}
        <div className={cs.StripeCheckout__error}>{paymentError}</div>
      </div>
    </>
  );
};

InjectedStripeForm.propTypes = {
  stripe: PropTypes.object,
  postStripeCheckout: PropTypes.func,
  selectedPlan: PropTypes.string,
  userID: PropTypes.number,
  getVoucher: PropTypes.func,
  plans: PropTypes.array,
  defaultVoucher: PropTypes.string,
  fetchAllCases: PropTypes.func,
  getDefaultPayment: PropTypes.func,
  defaultPayment: PropTypes.array,
  subscriptionDaysLeft: PropTypes.number,
  fetchPlans: PropTypes.func,
};

const mapStateToProps = (state) => ({
  selectedPlan: state.checkout.selectedPlan,
  plans: state.checkout.plans,
  userID: state.auth.user.id,
  defaultVoucher: state.auth.user.profile.promo_code,
  defaultPayment: state.checkout.defaultPayment,
  subscriptionDaysLeft: state.auth.user.subscription_days_left,
});

export default connect(mapStateToProps, {
  postStripeCheckout,
  setSelectedPlan,
  getVoucher,
  fetchPlans,
  fetchAllCases,
  getDefaultPayment,
})(injectStripe(InjectedStripeForm));
