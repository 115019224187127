import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Button } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setModeSelectionModal } from '../../../actions/dashboard';
import { setDiagnosisStart } from '../../../actions/diagnosis';
import { setSessionId } from '../../../actions/cases';
import { trackEvent } from '../../../service/analytics';
import { postPWFInit, setCase } from '../../../actions/pwf';
import { Modal } from '../../common';
import { SYSTEM_COLORS } from '../../../constants';
import Tooltip from '../../Tooltip';
import ConfirmButton from '../../buttons/ConfirmButton';
import Slide from './Slide';
import useStyles from './style';

/**
 * @desc Mode selection modal
 * @param {*} param0
 */
const ModeSelectionModal = (props) => {
  const classes = useStyles();
  const {
    isModeModalToggle,
    selectedCase = { patient_name: 'Aiden', system: 'respiratory', id: 52 },
    history,
    zIndex = 9999,
    location,
  } = props;

  const [modeSelectError, setModeSelectError] = useState('');
  const [modeSelectProcessing, setModeSelectProcessing] = useState(false);
  const ModeSlider = useRef(null);
  const query = new URLSearchParams(props.location.search);

  // Remove id in url when user exit start case modal
  const removeIdFromUrl = () => {
    query.delete('id');
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const handleSinglePlayer = () => {
    handleCancel();
    props.setSessionId('');
    history.push(`/cases/${selectedCase.id}/spm`);
  };

  const handleDiagnosisMode = () => {
    handleCancel();
    props.setDiagnosisStart(false);
    history.push(`/diagnosis/${selectedCase.id}`);
  };

  const handleCancel = () => {
    if (isModeModalToggle) {
      removeIdFromUrl();
    }
    props.setModeSelectionModal(false);
    setModeSelectError('');
  };

  const modeSelectionSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
  };

  useEffect(() => {
    if (isModeModalToggle) {
      const mode = query.get('mode');
      if (mode === 'multi') {
        ModeSlider.current.slickGoTo(1);
      }
    }
  }, [isModeModalToggle]);

  return (
    <Modal isToggle={isModeModalToggle} toggle={handleCancel} centered zIndex={zIndex}>
      <div className={classes.ModeSelectionModal} style={{ borderBottomColor: SYSTEM_COLORS[selectedCase.system] }}>
        <Slider {...modeSelectionSetting} ref={ModeSlider}>
          <Slide
            handleCancel={handleCancel}
            caseObj={selectedCase}
            modeSelectError={modeSelectError}
            title={`Would you like to practice your history taking with ${selectedCase.patient_name}${
              selectedCase.participation_for_diagnosis_mode ? ' OR simply focus on the Diagnosis' : ''
            }?`}
            subtitle={
              selectedCase.participation_for_diagnosis_mode
                ? 'Select below which case mode you would like to enter…'
                : 'Start OSCE History mode'
            }
          >
            <Tooltip
              title="Prepare for your OSCE with a full exam simulation chatting to our AI patient and an exhaustive rubric."
              arrow
              placement="bottom-start"
              styles={{ tooltip: classes.ModeSelectionModal__btn__tooltip }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleSinglePlayer}
                classes={{ root: classes.ModeSelectionModal__history__btn }}
                data-tut="reactour__start_single_player"
              >
                OSCE History
              </Button>
            </Tooltip>
            {Number(process.env.REACT_APP_DIAGNOSIS_ON) && selectedCase.participation_for_diagnosis_mode ? (
              <Tooltip
                title="Practice diagnosis in this short case anticipate the right conditions and ask precise questions."
                arrow
                placement="bottom-start"
                styles={{ tooltip: classes.ModeSelectionModal__btn__tooltip }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDiagnosisMode}
                  classes={{ root: classes.ModeSelectionModal__diagnosis__btn }}
                >
                  Race To Diagnosis
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}
          </Slide>
        </Slider>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  selectedCase: state.dashboard.selectedCase,
  isModeModalToggle: state.dashboard.modeSelectionModal,
});

ModeSelectionModal.propTypes = {
  selectedCase: PropTypes.object,
  isModeModalToggle: PropTypes.bool,
  setModeSelectionModal: PropTypes.func,
  history: PropTypes.object,
  postPWFInit: PropTypes.func,
  setCase: PropTypes.func,
  zIndex: PropTypes.number,
  location: PropTypes.object,
  setDiagnosisStart: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, { setModeSelectionModal, postPWFInit, setCase, setDiagnosisStart, setSessionId })(
    ModeSelectionModal
  )
);
