import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { trackEvent } from '../../service/analytics';
import HomeContainer from '../../components/Container/HomeContainer';
import notFoundImg from '../../assets/common/hp-404-page-img.svg';
import useStyles from './style';

const NotFound = () => {
  const classes = useStyles();

  useEffect(() => {
    trackEvent({
      GAAction: 'page_not_found',
      FBAction: 'Page Not Found',
      category: 'Page Not Found',
      label: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <HomeContainer outerStyles={classes.NotFound__container__outer} innerStyles={classes.NotFound__container__inner}>
      <div className={classes.NotFound}>
        <div className={classes.NotFound__main}>
          <div className={classes.NotFound__title}>Whoops…someone has pulled a plug</div>
          <div className={classes.NotFound__subtitle}>Let’s hope it’s not the wrong one</div>
          <div className={classes.NotFound__text}>Head back to the homepage before anyone notices</div>
          <Button
            variant="contained"
            classes={{ contained: classes.NotFound__btn__contained }}
            color="primary"
            component={Link}
            to="/"
          >
            Back to Home
          </Button>
        </div>
        <img src={notFoundImg} alt="not_found_404" className={classes.NotFound__image} />
      </div>
    </HomeContainer>
  );
};

export default NotFound;
