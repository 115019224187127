import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';
import { OUTGOING_MESSAGE } from '../../../../constants';

const MessageRecommendation = (props) => {
  const cs = useStyles();
  const { createMessage, requestResponse, caseSession } = props;

  const messages = [
    `Hi ${caseSession.patient_name ? caseSession.patient_name.split(' ')[0] : 'patient'}`,
    'Do I have your consent to continue?',
    'What has brought you in?',
  ];

  const handleSubmit = (text) => {
    createMessage(OUTGOING_MESSAGE, text, new Date().toString());
    requestResponse(text)
      .then(() => {})
      .catch(() => {});
  };

  const handleResize = () => {
    setHeight(getHeightOffset());
  };

  const getHeightOffset = () => {
    // message input breakpoints
    if (window.innerWidth < 411) {
      return 73;
    } else if (window.innerWidth < 600) {
      return 53;
    } else if (window.innerWidth < 800) {
      return 98;
    } else {
      return 94;
    }
  };

  const [height, setHeight] = useState(getHeightOffset());
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  return (
    <div className={cs.MessageRecommendation} style={{ bottom: height, width: props.panelHidden && '96%' }}>
      <div className={cs.MessageRecommendation__ChipsWrapper}>
        {messages.map((message, index) => (
          <div key={index} onClick={() => handleSubmit(message)} className={cs.MessageRecommendation__Chips}>
            {message}
          </div>
        ))}
      </div>
    </div>
  );
};

MessageRecommendation.propTypes = {
  createMessage: PropTypes.func,
  requestResponse: PropTypes.func,
  caseSession: PropTypes.object,
};

export default MessageRecommendation;
