import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import useStyles from './style';

/**
 * @desc high-level card component. Hide/show different card components on hover,
 * there are 2 types of card appearance front and back (basic and completed)
 * completed also has two types which are diagnosed and undiagnosed.
 *
 * @param caseObj case object
 */
const HoverCard = (props) => {
  const { children, backCard, className, ...cardProps } = props;
  const cs = useStyles();

  return (
    <div className={cx(cs.HoverCard, className)}>
      {children}
      {backCard && <div className={cx(cs.HoverCard__overlay, 'overlay')}>{backCard}</div>}
    </div>
  );
};

HoverCard.propTypes = {
  children: PropTypes.node,
  backCard: PropTypes.node,
  className: PropTypes.string,
};

export default HoverCard;
