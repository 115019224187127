import React, { Component } from 'react';
import './style.scss';

class CommonModal extends Component {
  render() {
    const { width = 867, height = 587 } = this.props;
    return (
      <React.Fragment>
        <div className="dashboard-modal-overlay" onClick={this.props.close} />
        <div className="dashboard-modal-container" style={{ width: `${width}px`, height: `${height}px` }}>
          <div className="inner">{this.props.children}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default CommonModal;
