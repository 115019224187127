import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, Link, withRouter, useHistory } from 'react-router-dom';
import { Container, Avatar, MenuItem, MenuList, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { setShowLogoutModal } from '../../../../actions/auth';
import { setUpgradeExplorerModalToggle } from '../../../../actions/dashboard';
import imgOscer from '../../../../assets/logos/oscer-icon-solid.svg';
import iconCrownGold from '../../../../assets/icons/prime-crown-icon-gold.svg';
import userNameGenerator from '../../../../helpers/userNameGenerator';
import MenuPopover from '../../../MenuPopover';
import iconStreak from '../../../../assets/icons/streak-stat-icon.svg';
import iconSearch from '../../../../assets/icons/search-tab-icon.svg';
import iconCases from '../../../../assets/icons/cases-tab-icon.svg';
import iconHome from '../../../../assets/icons/home-tab-icon.svg';
import iconSkillsHub from '../../../../assets/icons/skills-hub-icon.svg';
import iconScripts from '../../../../assets/icons/scripts-tab-icon.svg';
import iconConExp from '../../../../assets/icons/condition-explorer-tab-icon.svg';
import Logout from '../../Logout';
import useStyles from './style';

const DashboardFooterNav = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = props;

  // const menuRef = useRef(null);
  const [menuElement, setMenuElement] = useState(null);

  const navigations = [
    {
      image: iconHome,
      name: 'HOME',
      link: '/dashboard/home',
    },
    // {
    //   image: iconCases,
    //   name: 'CASES',
    //   link: '/dashboard/cases',
    // },
    {
      image: iconSkillsHub,
      name: 'SKILLS HUB',
      link: '/dashboard/learn',
    },
    {
      image: iconScripts,
      name: 'SCRIPTS',
      link: '/dashboard/scripts',
    },
    {
      image: iconConExp,
      name: 'CONDITION EXPLORER',
      link: props.isPrime ? '/dashboard/explorer' : '/compare',
      beta: true,
    },
    {
      image: iconSearch,
      name: 'SEARCH',
      link: '/search',
    },
  ];

  const profileMenu = [
    {
      title: 'Your profile',
      subtitle: `Achievements, stats & more`,
      link: '/',
      number: 2,
    },
    {
      title: 'Skills Hub',
      subtitle: `Improve your history`,
      link: '',
    },
    {
      title: 'Case results',
      subtitle: `Your completed case results`,
      link: '',
    },
    {
      title: 'Logout',
      link: null,
      subtitle: `Bailing on us?`,
      function: () => props.setShowLogoutModal(true),
    },
  ];

  const handleProfile = (link, func) => {
    if (link) {
      history.push(link);
    } else if (func) {
      func();
    }
  };

  const handleMouseOver = (e) => {
    // console.log('hhhh', e.target);
    setMenuElement(e.target);
  };

  return (
    <div className={classes.DashboardFooterNav}>
      <Logout />
      <Container
        disableGutters
        classes={{
          root: classes.DashboardFooterNav__Container__root,
        }}
      >
        <div className={classes.DashboardFooterNav__main}>
          {navigations.map((nav) => (
            <NavLink
              to={!nav.beta ? nav.link : '/dashboard/explorer'}
              onClick={() => nav.beta && !props.isPrime && props.setUpgradeExplorerModalToggle(true)}
              key={nav.name}
              className={classes.DashboardFooterNav__nav}
              activeClassName={classes.DashboardFooterNav__nav__active}
            >
              <div className={classes.DashboardFooterNav__ItemWrapper}>
                <img
                  src={nav.image}
                  alt={`${nav.name}__image`}
                  style={{ marginLeft: nav.name === 'SEARCH' && '-7.5px' }}
                  className={cx(classes.DashboardFooterNav__nav__logo, {
                    [classes.DashboardFooterNav__nav__logo__beta]: !nav.beta,
                  })}
                />
                {nav.beta && <div className={classes.DashboardFooterNav__nav__text__beta}>(BETA)</div>}
              </div>
            </NavLink>
          ))}
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPrime: state.auth.user.prime_status,
});

DashboardFooterNav.propTypes = {
  user: PropTypes.object,
  setShowLogoutModal: PropTypes.func,
  isPrime: PropTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, { setShowLogoutModal, setUpgradeExplorerModalToggle })(DashboardFooterNav)
);
