// returns true if two arr of objects are same
const compareObjArr = (cases1, cases2) => {
  if (cases1.length !== cases2.length) {
    return false;
  }
  for (let i = 0; i < cases1.length; i++) {
    const temp1 = cases1[i].cases.filter((c) => !c.random);
    const temp2 = cases2[i].cases.filter((c) => !c.random);
    if (!(equalArray(temp1, temp2) && cases1[i].name === cases2[i].name && cases1[i].mode === cases2[i].mode)) {
      return false;
    }
  }
  return true;
};

const equalArray = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

export default compareObjArr;
