import produce from 'immer';
import compareObjArr from 'helpers/arrayObjCompare';
import {
  CASES_LOADING,
  CASES_LOADED,
  LOGOUT_SUCCESS,
  CASES_CREATE_SESSION_ID,
  CASES_SET_CURRENT,
  CASES_SET_SCORE,
  CASES_SET_HINTS,
  CASES_SET_HINT_REVEALED,
  CASES_LOADING_ERROR,
  CASES_SET_POINTS_LABEL,
  CASES_INITIALIZE,
  CASES_QUESTIONS_INFO,
  CASES_SET_FILTERED,
  CASES_SET_ALL,
  SEARCH_CASES_SET,
  PWF_CASES_SET,
  PWF_CASES_FILTERED_SET,
  ALL_CASES_INITIALIZE,
  ALL_CASES_SET_FILTERED,
  CASES_SET_STATS,
  CASES_CONFIRMED_LOADED,
  CASES_SET_FOLLOW_UP_QUESTIONS,
  TIMER_COMPLETE,
  WANT_TO_NAVIGATE_TO,
  CASES_SET_PERCENTILE,
  CASES_SET_RUBRIC_STATS,
  SET_RECOMMENDED_CASES,
  QUESTION_ANSWER_UPDATED,
  COMPLETED_CASES_LOADED,
  SESSION_EXPIRED,
  GUIDE_MODE_SET,
  CASE_JOURNEY_SET,
  CASE_JOURNEY_ID_SET,
  AUTO_PLAY_AUDIO,
  FINISHED_CASE_SET,
  CASE_UNLOCKED,
  MISSING_SWITCH_SET,
  SESSION_CHRONO_SET,
  CHRONO_MARKED_SET,
  CASE_SCORE_SET,
  UNLOCKED_CASE_SET,
  DIAGNOSIS_SCORE_SET,
  CASES_COLLECTIONS_SET,
  FILTERED_CASES_COLLECTIONS_SET,
  CASES_START,
  CASES_SESSION_START,
} from 'actions/types';

// todo: initial state can't be null
const initialState = {
  searchCases: [],
  pwfCases: [],
  filteredPwfCases: [],
  allCasesCollection: [],
  filteredAllCasesCollection: [],
  cases: [],
  casesCollection: [],
  filteredCasesCollection: [],
  session: {},
  filteredCases: [],
  unlockedCases: [],
  initialRecommendedCases: null,
  recommendedCases: [],
  isLoading: false,
  casesNextPage: null,
  sessionId: null,
  currentCase: {},
  timerComplete: false,
  currentScore: 0,
  isHintVisible: false,
  hints: [],
  hintRevealed: {},
  questions: [],
  rubricStats: {},
  totalQuestions: 0,
  markStreak: 0,
  maxMarkStreak: 0,
  labelNames: [],
  pointsPerLabel: {},
  systemStats: [],
  confirmedCases: null,
  navigatingToPage: '',
  wantingToNavigate: false,
  completedCases: [],
  isGuideMode: false,
  caseJourney: [],
  caseJourneyId: null,
  autoPlayAudio: false,
  numberOfCategories: 0,
  finishedCase: {},
  missingSwitchToggle: false,
  chronoMarked: [],
  start: false,
};

const unlockCase = (caseList, caseId) => {
  const newCaseList = caseList.map((obj) => {
    if (obj.id === caseId) {
      return { ...obj, locked: false };
    }
    return obj;
  });
  return newCaseList;
};

const casesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CASES_START:
        draft.start = action.payload;
        break;
      case CASES_SESSION_START:
        draft.session = action.session;
        break;
      case CASES_LOADING:
        return {
          ...state,
          isLoading: true,
        };

      case CASES_LOADED:
        return {
          ...state,
          isLoading: false,
          cases: action.data,
          filteredCases: action.data,
          casesNextPage: action.data.next,
        };

      case CASES_CONFIRMED_LOADED:
        return {
          ...state,
          confirmedCases: action.data,
        };

      case CASES_LOADING_ERROR:
        return {
          ...state,
          isLoading: false,
        };

      case LOGOUT_SUCCESS:
        return {
          ...state,
          ...initialState,
        };

      case CASES_CREATE_SESSION_ID:
        return {
          ...state,
          sessionId: action.sessionId,
        };

      case TIMER_COMPLETE:
        return {
          ...state,
          timerComplete: action.timerComplete,
        };

      case WANT_TO_NAVIGATE_TO:
        return {
          ...state,
          navigatingToPage: action.page,
          wantingToNavigate: !!action.page,
        };

      case CASES_SET_CURRENT:
        return {
          ...state,
          currentCase: action.case,
          timerComplete: false,
        };

      case CASES_SET_SCORE:
        return {
          ...state,
          currentScore: action.score,
        };

      case CASES_SET_HINTS:
        return {
          ...state,
          hints: action.hints,
        };

      case CASES_SET_FOLLOW_UP_QUESTIONS:
        return {
          ...state,
          questions: action.questions,
        };

      case CASES_SET_HINT_REVEALED:
        return {
          ...state,
          hintRevealed: action.hintRevealed,
        };

      case CASES_SET_RUBRIC_STATS:
        return {
          ...state,
          rubricStats: action.rubricStats,
        };

      case CASES_SET_POINTS_LABEL:
        const { pointsPerLabel } = state;
        const { labelName, pointsEarned } = action.payload;
        return {
          ...state,
          pointsPerLabel: {
            ...pointsPerLabel,
            [labelName]: pointsPerLabel[labelName] ? pointsPerLabel[labelName] + pointsEarned : pointsEarned,
          },
        };

      case CASES_INITIALIZE:
        return {
          ...initialState,
          cases: state.cases,
          filteredCases: state.cases,
        };

      case CASES_QUESTIONS_INFO:
        const { totalQuestions, markStreak, maxMarkStreak, bedsideManner } = action.payload;
        return { ...state, totalQuestions, markStreak, maxMarkStreak, bedsideManner };

      case CASES_SET_ALL:
        return { ...state, allCasesCollection: action.cases };

      case SEARCH_CASES_SET:
        return { ...state, searchCases: action.cases };

      case PWF_CASES_SET:
        return { ...state, pwfCases: action.cases };

      case PWF_CASES_FILTERED_SET:
        return { ...state, filteredPwfCases: action.cases };

      case ALL_CASES_INITIALIZE:
        return { ...state, allCasesCollection: action.cases, filteredAllCasesCollection: action.cases };

      case ALL_CASES_SET_FILTERED:
        return { ...state, filteredAllCasesCollection: action.cases };

      case CASES_SET_FILTERED:
        return { ...state, filteredCases: action.cases };

      case CASES_SET_STATS:
        return { ...state, systemStats: action.systemStats };

      case CASES_SET_PERCENTILE:
        return { ...state, casePercentile: action.casePercentile, caseStars: action.caseStars };

      case SET_RECOMMENDED_CASES:
        return { ...state, recommendedCases: action.cases };

      case QUESTION_ANSWER_UPDATED:
        return {
          ...state,
          questions: state.questions.map((question, questionIndex) =>
            questionIndex === action.answer.questionIndex
              ? { ...question, user_choice: action.answer.user_choice }
              : question
          ),
        };
      case COMPLETED_CASES_LOADED:
        draft.completedCases = action.completedCases;
        break;
      case SESSION_EXPIRED:
        draft.currentCase.isExpired = action.isExpired;
        break;

      case GUIDE_MODE_SET:
        return {
          ...state,
          isGuideMode: action.value,
        };

      case CASE_JOURNEY_SET:
        draft.caseJourney = action.payload;
        break;

      case CASE_JOURNEY_ID_SET:
        draft.caseJourneyId = action.id;
        break;

      case AUTO_PLAY_AUDIO:
        draft.autoPlayAudio = !state.autoPlayAudio;
        break;

      case FINISHED_CASE_SET:
        draft.finishedCase = action.finishedCase;
        break;

      case CASE_UNLOCKED:
        // todo: Do not put logic in reducer, should move to action
        draft.cases = unlockCase(state.cases, action.caseId);
        draft.filteredCases = unlockCase(state.filteredCases, action.caseId);
        break;

      case MISSING_SWITCH_SET:
        draft.missingSwitchToggle = !state.missingSwitchToggle;
        break;

      case CHRONO_MARKED_SET:
        return {
          ...state,
          chronoMarked: [...state.chronoMarked, action.path],
        };

      case CASE_SCORE_SET:
        return {
          ...state,
          cases: state.cases.map((caseObj) =>
            caseObj.id == action.results.id && caseObj.spm_best_scores < action.results.score
              ? { ...caseObj, spm_best_scores: action.results.score, spm_best_stars: action.results.stars }
              : caseObj
          ),
          filteredCases: state.filteredCases.map((filteredCaseObj) =>
            filteredCaseObj.id == action.results.id && filteredCaseObj.spm_best_scores < action.results.score
              ? { ...filteredCaseObj, spm_best_scores: action.results.score, spm_best_stars: action.results.stars }
              : filteredCaseObj
          ),
          unlockedCases: state.unlockedCases.map((unlockedCaseObj) =>
            unlockedCaseObj.id == action.results.id && unlockedCaseObj.spm_best_scores < action.results.score
              ? { ...unlockedCaseObj, spm_best_scores: action.results.score, spm_best_stars: action.results.stars }
              : unlockedCaseObj
          ),
        };

      case DIAGNOSIS_SCORE_SET:
        return {
          ...state,
          cases: state.cases.map((caseObj) =>
            caseObj.id == action.results.id && caseObj.diagnosis_ddx_percentile < action.results.percentile
              ? { ...caseObj, diagnosis_ddx_percentile: action.results.percentile }
              : caseObj
          ),
          filteredCases: state.filteredCases.map((filteredCaseObj) =>
            filteredCaseObj.id == action.results.id &&
            filteredCaseObj.diagnosis_ddx_percentile < action.results.percentile
              ? { ...filteredCaseObj, diagnosis_ddx_percentile: action.results.percentile }
              : filteredCaseObj
          ),
          unlockedCases: state.unlockedCases.map((unlockedCaseObj) =>
            unlockedCaseObj.id == action.results.id &&
            unlockedCaseObj.diagnosis_ddx_percentile < action.results.percentile
              ? { ...unlockedCaseObj, diagnosis_ddx_percentile: action.results.percentile }
              : unlockedCaseObj
          ),
        };

      case SESSION_CHRONO_SET:
        draft.rubricStats.chrono_rubric = action.rubric.chrono_rubric;
        break;
      case UNLOCKED_CASE_SET:
        return { ...state, unlockedCases: action.cases };
      case CASES_COLLECTIONS_SET:
        if (!compareObjArr(action.cases, state.casesCollection)) {
          draft.casesCollection = action.cases;
          draft.filteredCasesCollection = action.cases;
        } else {
          return state;
        }
        break;
      case FILTERED_CASES_COLLECTIONS_SET:
        draft.filteredCasesCollection = action.cases;
        break;

      default:
        return state;
    }
  });

export default casesReducer;
