import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  SeperatorMessage__root: {
    width: '100%',
    position: 'relative',
    margin: '0',
    display: 'flex',
    justifyContent: 'center',
    // alignItems center here will break the postion in ios
    // alignItems: 'center',
    zIndex: 0,
    marginBottom: 15,
    padding: 28,
  },
  SeperatorMessage__hr: {
    height: 1,
    background: '#ffffff',
    boxShadow: '0 2px 3px 0 rgba(27, 28, 29, 0.03)',
    border: 'solid 1px #ffffff',
    width: '100%',
    position: 'absolute',
    margin: 0,
    top: 47,
  },
  SeperatorMessage__message: {
    fontSize: 16,
    lineHeight: 1.25,
    color: theme.palette.text.main,
    padding: '10px 20px',
    borderRadius: 30,
    background: '#ffffff',
    textTransform: 'uppercase',
    zIndex: 1,
  },
}));

export default useStyles;
