import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  StripeCheckout__row: {
    display: 'flex',
    marginBottom: 10,
  },
  StripeCheckout__method: {
    fontSize: 20,
    fontWeight: 500,
    color: 'var(--secondary-color-2)',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  StripeCheckout__method__subtitle: {
    fontSize: 12,
    color: theme.palette.primary.main,
    lineHeight: 1.33,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  StripeCheckout__cardBrand: {
    marginLeft: 5,
  },
  StripeCheckout__confirmBtn: {
    marginTop: 15,
    maxHeight: 44,
    flex: 1,
    margin: '0 30px',
    '&.Mui-disabled': {
      borderColor: 'transparent',
    },
  },
  StripeCheckout__error: {
    fontSize: 14,
    marginTop: 5,
    color: theme.palette.secondary.main,
    lineHeight: 1,
    textAlign: 'center',
  },
  StripeCheckout__spinner: {},
  StripeCheckout__payment__method: {
    backgroundColor: 'white',
    padding: '30px 30px 15px 30px',
    borderRadius: 16,
    marginBottom: 8,
  },
  StripeCheckout__summary: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '30px 0 15px 0',
    borderRadius: 16,
    minHeight: 350,
    marginBottom: 20,
    '& > div': {
      padding: '0 30px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
      minHeight: 'auto',
    },
  },
  StripeCheckout__seperator: {
    height: 1,
    backgroundColor: `${theme.palette.text.main}4D`,
    margin: '5px 30px 10px 30px',
  },
  StripeCheckout__title: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.text.main,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start',
      fontSize: 10,
      marginBottom: 0,
      color: `${theme.palette.text.main}66`,
    },
  },
  StripeCheckout__plan: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  StripeCheckout__plan__name: {
    fontSize: 14,
    color: theme.palette.text.main,
    lineHeight: 1.14,
  },
  StripeCheckout__plan__percent__off: {
    marginBottom: 10,
  },
  StripeCheckout__plan__price: {
    color: theme.palette.primary.main,
    lineHeight: 1.14,
    fontSize: 14,
  },
  StripeCheckout__plan__discount: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  StripeCheckout__plan__discount__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  StripeCheckout__plan__type: {
    fontSize: 12,
    opacity: 0.4,
    color: theme.palette.text.main,
    lineHeight: 1,
    marginLeft: 10,
  },
  StripeCheckout__voucher__apply: {
    color: theme.palette.text.main,
    fontSize: 12,
    fontWeight: 500,
    opacity: 0.4,
  },
  StripeCheckout__voucher__apply__active: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    opacity: 1,
  },
  StripeCheckout__OutlinedInput__root: {
    borderRadius: 60,
    height: 50,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      height: 40,
      marginBottom: 0,
    },
  },
  StripeCheckout__OutlinedInput__input: {
    padding: '14.5px  14px',
  },
  StripeCheckout__formControl__notchedOutline: {
    '& legend': {
      width: '0 !important',
    },
  },
  StripeCheckout__IconButton__root: {
    padding: 5,
    marginRight: -6,
  },
  StripeCheckout__plan__price__unit: {
    fontSize: 10,
    marginLeft: 3,
    opacity: 0.6,
  },
  StripeCheckout__total: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 30px !important',
    backgroundColor: `${theme.palette.primary.main}1A`,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
      marginTop: 20,
    },
  },
  StripeCheckout__total__text: {
    fontWeight: 500,
    color: theme.palette.text.main,
  },
  StripeCheckout__total__price: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  StripeCheckout__total__price__unit: {
    fontSize: 12,
    marginLeft: 3,
    opacity: 0.6,
  },
  StripeCheckout__discount: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 14,
    margin: '10px 0 5px 0',
    transition: 'all 0.3s ease',
    opacity: 0,
  },
  StripeCheckout__discount__active: {
    opacity: 1,
  },
  StripeCheckout__discount__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    color: `${theme.palette.text.main}66`,
  },
  StripeCheckout__discount__code: {
    fontSize: 13,
    lineHeight: 1,
    marginRight: 5,
  },
  StripeCheckout__discount__code__active: {
    color: theme.palette.primary.main,
  },
  StripeCheckout__discount__icon: {
    color: `${theme.palette.text.main}99`,
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.text.main}E6`,
    },
  },
  StripeCheckout__global_discount: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  StripeCheckout__default: {
    display: 'flex',
    flexDirection: 'column',
  },
  StripeCheckout__default__payment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#ebf4fd',
    borderRadius: 8,
    padding: '5px 10px 5px 0',
    margin: '15px 0 15px 0',
  },
  StripeCheckout__default__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  StripeCheckout__default__radio: {},
  StripeCheckout__default__info: {
    display: 'flex',
    flexDirection: 'column',
  },
  StripeCheckout__default__text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  StripeCheckout__default__dot: {
    width: 6,
    height: 6,
    backgroundColor: `${theme.palette.text.main}99`,
    borderRadius: '50%',
    marginRight: 5,
  },
  StripeCheckout__default__dot__wrapper: {
    marginRight: 5,
    display: 'flex',
    color: `${theme.palette.text.main}99`,
    fontSize: 14,
    lineHeight: 1.14,
  },
  StripeCheckout__default__edit: {
    color: theme.palette.text.main,
    lineHeight: 1.14,
    fontSize: 14,
    alignSelf: 'flex-end',
    cursor: 'pointer',
    marginLeft: 20,
    '&:hover': {
      fontWeight: 500,
    },
  },
  StripeCheckout__edit: {},
  StripeCheckout__default__cardBrand: {
    width: 40,
  },
  StripeCheckout__default__card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  StripeCheckout__plan__desc: {
    margin: '10px 0 15px 0',
    fontSize: 11,
    lineHeight: 1.09,
    color: theme.palette.text.main,
    fontStyle: 'italic',
    [theme.breakpoints.down('xs')]: {
      margin: '5px 0 10px 0',
    },
  },
}));

export default useStyles;
