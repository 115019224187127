import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Tooltip: {
    backgroundColor: 'white',
    boxShadow: '0 2px 15px 0 rgba(27, 28, 29, 0.2)',
    borderRadius: '0 16px 16px 16px',
    borderTop: `3px solid ${theme.palette.primary.light}`,
    fontSize: 14,
    color: theme.palette.text.main,
    fontWeight: 300,
    lineHeight: 1.29,
    padding: 16,
    margin: 0,
  },
  Tooltip__arrow: {
    color: theme.palette.primary.light,
  },
}));

export default useStyles;
