import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ReviewCard: {
    borderRadius: 16,
    boxShadow: '3px 3px 30px 0 rgba(0, 35, 72, 0.15)',
    backgroundColor: 'white',
    height: 335,
    width: 335,
    overflow: 'hidden',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      height: 280,
      width: 280,
    },
  },
  ReviewCard__wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0 20px 15px 20px',
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px 10px 15px',
    },
  },
  ReviewCard__main: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ReviewCard__qutation: {},
  ReviewCard__star: {
    width: 20,
    height: 20,
  },
  ReviewCard__star__group: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: -10,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: -20,
      marginBottom: 10,
    },
  },
  ReviewCard__review: {
    fontSize: 18,
    color: theme.palette.text.main,
    lineHeight: 1.4,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  ReviewCard__foot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  ReviewCard__separator: {
    width: 60,
    height: 1.5,
    backgroundColor: theme.palette.text.main,
    opacity: 0.2,
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      marginBottom: 5,
    },
  },
  ReviewCard__name: {
    color: theme.palette.text.main,
  },
  ReviewCard__role: {
    fontSize: 12,
    color: '#919aa3',
  },
}));

export default useStyles;
