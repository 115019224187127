import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  PaymentBox: {
    position: 'relative',
    width: 510,
    backgroundColor: 'rgba(157, 201, 246, 0.6)',
    borderRadius: 16,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '70px 60px',

    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: 'auto',
      borderRadius: 0,
      padding: '30px 45px',
    },
  },
  PaymentBox__summary: {
    padding: '30px 30px 0 30px',
  },
  PaymentBox__checkout: {
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    alignSelf: 'stretch',
    borderRadius: 16,
  },
  PaymentBox__product: {
    fontSize: 20,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  },
  PaymentBox__product__info: {
    fontSize: 14,
    color: theme.palette.text.main,
    display: 'flex',
    justifyContent: 'space-between',
    opacity: 0.4,
  },
  PaymentBox__product__originalPrice: {
    textDecoration: 'line-through',
  },
  PaymentBox__seperator: {
    opacity: 0.4,
    border: 'solid 1px #002348',
    margin: '15px 0',
  },

  PaymentBox__Stripe__textField: {
    flex: 1,
    '& .MuiInputLabel-root': {
      color: '#00234866',
    },
    '& .MuiInputBase-root': {
      '&:hover:before': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
  },
  PaymentBox__priceWrapper: {
    display: 'flex',

    alignItems: 'center',
  },
  PaymentBox__currentcy: {
    color: theme.palette.primary.main,
    opacity: 0.6,
    letterSpacing: -0.5,
    fontSize: 12,
    marginRight: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 9,
    },
  },
}));

export default useStyles;
