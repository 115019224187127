// convert ISO time to date in format: MM/DD/YY
export const ISOtoDate = (iso) => {
  const date = new Date(iso);
  const Y = `${date.getFullYear()}`;
  const M = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const D = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  return `${M}/${D}/${Y}`;
};

export const dateFormat1 = (iso) => {
  const date = new Date(iso);
  const Y = `${date.getFullYear()}`;
  const M = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const D = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  return `${M} ${mapDateToAbbrev(D)} ${Y}`;
};

export const mapDateToAbbrev = (date) => {
  switch (date) {
    case '01':
      return 'Jan';
    case '02':
      return 'Feb';
    case '03':
      return 'Mar';
    case '04':
      return 'Apr';
    case '05':
      return 'May';
    case '06':
      return 'Jun';
    case '07':
      return 'Jul';
    case '08':
      return 'Aug';
    case '09':
      return 'Sep';
    case '10':
      return 'Oct';
    case '11':
      return 'Nov';
    case '12':
      return 'Dev';
    default:
      return '';
  }
};

export const millionSecondsToTime = (millis) => {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return seconds === 60 ? `${minutes + 1}:00` : `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
