import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery, Typography, Container, Breadcrumbs, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';
import useStyles from './style';
import { useWidth } from '../../helpers/customHooks';
import OscerLoader from '../../components/loader/OscerLoader';
import { trackEvent, trackPage } from '../../service/analytics';
import CaseHistoryCard from '../../components/CaseHistoryCard';
import ModeSelectionModal from '../../components/Modal/ModeSelectionModal';
import MainboardFilter from '../../components/Filter';
import { setCompletedCases, getCaseHistory } from '../../actions/cases';
import { patchTaskComplete, getTasks } from '../../actions/task';
import NoHistory from '../../assets/character/no-history-img.svg';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const CaseHistory = (props) => {
  const classes = useStyles();
  const screenSize = useWidth();
  //! remember to use the useTheme hook, otherwise the breakpoints won't be right.
  const theme = useTheme();
  const history = useHistory();
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    props
      .getCaseHistory()
      .then((data) => {
        // todo: maybe use redux only instead of state
        setCases(data);
        // filteredCases = cases by default
        setFilteredCases(data);
        // split array by chunk
        props.setCompletedCases(data);
      })
      .then(() => {
        setIsloading(false);
      });
    // if (!props.allTasks.length) {
    //   props.getTasks();
    // }
    trackPage(window.location.pathname);
    trackEvent({
      GAAction: 'view_previous_rubric',
      FBAction: 'View Previous Rubric',
      category: 'View Case History',
      label: `User ${props.userId} views case history`,
    });
  }, []);

  const [scrollPosition, setScrollPosition] = useState(
    document.getElementById('mobile-cases-dashboard-scroll-id')?.scrollTop
  );
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const scrollHandler = (_) => {
    setScrollPosition(document.getElementById('mobile-cases-dashboard-scroll-id')?.scrollTop);
  };

  const handleResize = (e) => {
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    const element = document.getElementById('mobile-cases-dashboard-scroll-id');
    element.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    window.addEventListener('scroll', scrollHandler, true);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
      window.addEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container
      maxWidth="lg"
      disableGutters
      classes={{
        root: classes.DashboardHome__Container__root,
      }}
    >
      <div className={classes.CaseHistory}>
        <ModeSelectionModal zIndex={101} />
        <div className={classes.CaseHistory__main}>
          <div
            className={cx(classes.CaseHistory__header, {
              [classes.CaseHistory__header__scroll]: scrollPosition > 0,
            })}
          >
            <div className={classes.CaseHistory__sideLeft} onClick={() => history.push('/dashboard/home')}>
              <KeyboardArrowLeftIcon size={32} style={{ fontSize: 16 }} />
              <span>Back</span>
            </div>

            <Breadcrumbs
              separator="|"
              aria-label="breadcrumb"
              classes={{
                root: classes.CaseHistory__breadcrumbs__root,
                li: classes.CaseHistory__breadcrumbs__li,
              }}
            >
              {screenSize !== 'xs' ? (
                <div style={{ width: 'max-content' }}>
                  <Typography>{'COMPLETED CASE RESULTS'}</Typography>
                </div>
              ) : (
                <span className={classes.CaseHistory__MobileTitle}> YOUR CASE RESULTS </span>
              )}
            </Breadcrumbs>
            <div className={classes.CaseHistory__sideRight} style={{ justifyContent: 'flex-end' }}>
              <MainboardFilter
                cases={cases}
                setFilteredCases={setFilteredCases}
                hasSystem
                hasPresentation
                hasLevel
                hasSearch
                hasRotation
              />
            </div>
          </div>

          {filteredCases.length ? (
            <div className={classes.CaseHistory__cases}>
              {filteredCases.map((item, idx) => (
                <>
                  <CaseHistoryCard
                    last={idx === filteredCases.length - 1}
                    first={screenSize === 'xs' && idx === 0}
                    caseItem={item}
                    key={`case-${item.id}`}
                    isHover
                    className={classes.CaseHistory__caseCard}
                  />
                </>
              ))}
            </div>
          ) : (
            <div className={classes.CaseHistory__noHistory}>
              {isLoading ? (
                <div className={classes.OscerLoader__wrapper}>
                  <OscerLoader />
                </div>
              ) : (
                <>
                  <img className={classes.CaseHistory__noHistory__image} src={NoHistory} alt="oscer no history" />
                  <Typography variant="h3">You haven’t seen any patients yet!?</Typography>
                  <Typography className={classes.CaseHistory__noHistory__paragraph}>
                    There’s so many of them waiting…so you better get diagnosing!
                  </Typography>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.user.id,
  allTasks: state.task.tasks.all_tasks,
});

CaseHistory.propTypes = {
  getCaseHistory: PropTypes.func,
  setCompletedCases: PropTypes.func,
  loadCases: PropTypes.func,
  userId: PropTypes.number,
  allTasks: PropTypes.array,
  patchTaskComplete: PropTypes.func,
  getTasks: PropTypes.func,
};

export default connect(mapStateToProps, {
  getCaseHistory,
  setCompletedCases,
  patchTaskComplete,
  getTasks,
})(CaseHistory);
