import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  InstantGraphication: {
    position: 'relative',
  },
  InstantGraphication__signUpBtn: {
    '&:hover': {
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
