import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './style';

const ValueCard = ({ image, title, detail }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.ValueCard)}>
      <img src={image} alt={`img_${title}`} className={classes.ValueCard__iamge} />
      <div className={classes.ValueCard__name}>{title}</div>
      <span className={classes.ValueCard__detail}>{detail}</span>
    </div>
  );
};

ValueCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  detail: PropTypes.string,
};

export default ValueCard;
