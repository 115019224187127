import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  systemList__list: {
    zIndex: 100,
    overflow: 'auto',
    maxHeight: 300,
    borderRadius: '6px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 200,
    },
  },
  systemList__list__subheader: {
    backgroundColor: 'white',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    color: `${theme.palette.text.main}66`,
    fontSize: 10,
    height: 35,
    borderRadius: '20px',
  },
  systemList__system__icon: {
    maxWidth: 25,
    maxHeight: 25,
    marginRight: 15,
  },
  systemList__system__icon__sm: {
    maxWidth: 18,
    maxHeight: 18,
    marginRight: 8,
  },
  systemList__level__icon: {
    maxWidth: 20,
    maxHeight: 20,
  },
  systemList__level__icon__sm: {
    maxWidth: 14,
    maxHeight: 14,
  },
  systemList__locked__icon: {
    marginLeft: -5,
    marginRight: 6,
  },
  systemList__list__text: {
    color: theme.palette.primary.dark,
    lineHeight: 1.13,
  },
  systemList__list__listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 16px',
    paddingLeft: 28,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.dark,
    '&.Mui-selected': {
      opacity: 1,
      color: theme.palette.primary.main,
      backgroundColor: '#ecf2ff',
    },
    '&:hover': {
      opacity: 1,
      color: theme.palette.primary.main,
      backgroundColor: '#ecf2ff',
    },
  },
  systemList__back__icon: {
    fontSize: 12,
  },
  systemList__list__subheader__results: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.main,
    },
  },
  systemList__listItem__disabled: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.text.main}1A`,
  },
  systemList__list__tick: {
    fontSize: 18,
    marginLeft: -21,
    marginRight: 6,
  },
  systemList__list__tick__right: {
    fontSize: 14,
    marginLeft: -21,
    marginRight: 6,
  },
  loading__message: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    color: theme.palette.primary.dark,
  },
  systemList__coming_soon: {
    background: '#d1edfb',
    fontSize: 10,
    minWidth: 'max-content',
    padding: '0px 3px',
    position: 'absolute',
    right: 5,
    fontWeight: 400,
    color: theme.palette.primary.dark,
    borderRadius: '0.9px',
  },
  systemList__coming_soon__lg: {
    fontSize: 12,
  },
}));

export default useStyles;
