import produce from 'immer';
import {
  SET_SELECTED_ROLE,
  SET_MODE,
  SET_EXIT_MODAL,
  SET_CASE,
  SELECTED_ROLE_SET,
  OCCUPIED_ROLES_SET,
  PWF_INFO_SET,
  MARKING_RUBRIC_SET,
  MARKING_RUBRIC_UPDATED,
  MARKER_STEP_UPDATED,
  MARKER_QUESTION_UPDATED,
  MARKER_OVERALL_UPDATED,
  SET_SUBMIT_CASE_MODAL,
  SET_MARKER_NOT_SUBMIT_MODAL,
  SET_HOST_NOT_START_MODAL,
  SET_ERROR_MODAL,
} from '../actions/types';

const initialState = {
  pwfInfo: {
    isHost: false,
    mode: null,
    selectedRole: '',
    occupiedRoles: [],
    currentCase: {},
  },
  sessionId: null,
  isExitModalOpen: false,
  isSubmitCaseModalOpen: false,
  isMarkerNotSubmitModalOpen: false,
  isHostNotStartModalOpen: false,
  isErrorModalOpen: false,
  markingRubric: {},
  currentCase: {},
  markerStep: 'Proceed to Questions',
};

const pwfReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SELECTED_ROLE:
        return { ...state, selectedRole: action.selectedRole };
      case SET_MODE:
        return { ...state, mode: action.mode };
      case SET_EXIT_MODAL:
        return { ...state, isExitModalOpen: action.value };
      case SET_SUBMIT_CASE_MODAL:
        return { ...state, isSubmitCaseModalOpen: action.value };
      case SET_MARKER_NOT_SUBMIT_MODAL:
        return { ...state, isMarkerNotSubmitModalOpen: action.value };
      case SET_HOST_NOT_START_MODAL:
        return { ...state, isHostNotStartModalOpen: action.value };
      case SET_ERROR_MODAL:
        return { ...state, isErrorModalOpen: action.value };
      case SET_CASE:
        return { ...state, case: action.value };
      case SELECTED_ROLE_SET:
        return {
          ...state,
          pwfInfo: {
            ...state.pwfInfo,
            selectedRole: action.selectedRole,
          },
        };
      case OCCUPIED_ROLES_SET:
        return {
          ...state,
          pwfInfo: {
            ...state.pwfInfo,
            occupiedRoles: action.occupiedRoles,
          },
        };
      case PWF_INFO_SET:
        return {
          ...state,
          pwfInfo: {
            ...state.pwfInfo,
            isHost: action.value.is_host,
            mode: action.value.mode,
            selectedRole: action.value.selected_role,
            occupiedRoles: action.value.occupied_roles,
            currentCase: action.value.case,
            completed: action.value.completed,
          },
          markingRubric: {
            ...state.markingRubric,
            case: action.value.case,
            session_question_answers: action.value.session_question_answers
              ? action.value.session_question_answers.sort((a, b) => a.index - b.index)
              : [],
            session_overall_answers: action.value.session_overall_answers,
            summary: action.value.summary,
            chrono_rubric: action.value.chrono_rubric,
            differential_rubric: action.value.differential_rubric,
            current_score: action.value.current_score,
            max_score: action.value.max_score,
            stars: action.value.stars,
            z_score: action.value.z_score,
            is_new_high_score: action.value.is_new_high_score,
          },
        };
      case MARKING_RUBRIC_SET:
        return { ...state, markingRubric: action.markingRubric };
      case MARKER_STEP_UPDATED:
        return { ...state, markerStep: action.step };
      case MARKING_RUBRIC_UPDATED:
        return {
          ...state,
          markingRubric: {
            ...state.markingRubric,
            chrono_rubric: {
              ...state.markingRubric.chrono_rubric,
              children: {
                ...state.markingRubric.chrono_rubric.children,
                [action.marking.tab]: {
                  ...state.markingRubric.chrono_rubric.children[action.marking.tab],
                  children: {
                    ...state.markingRubric.chrono_rubric.children[action.marking.tab].children,
                    [action.marking.subtitle]: {
                      ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                        action.marking.subtitle
                      ],
                      earned_marks: action.marking.sheetEarendMarks,
                      children: {
                        ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                          action.marking.subtitle
                        ].children,
                        [action.marking.parentLabel]: {
                          ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                            action.marking.subtitle
                          ].children[action.marking.parentLabel],
                          earned_marks: action.marking.subheadingEarendMarks,
                          children:
                            action.marking.tab === 'HOPC'
                              ? {
                                  ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                    action.marking.subtitle
                                  ].children[action.marking.parentLabel].children,
                                  [action.marking.hopcLabel]: {
                                    ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                      action.marking.subtitle
                                    ].children[action.marking.parentLabel].children[action.marking.hopcLabel],
                                    earned_marks: action.marking.parentEarendMarks,
                                    children: {
                                      ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                        action.marking.subtitle
                                      ].children[action.marking.parentLabel].children[action.marking.hopcLabel]
                                        .children,
                                      [action.marking.rubricLabel]: {
                                        ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                          action.marking.subtitle
                                        ].children[action.marking.parentLabel].children[action.marking.hopcLabel]
                                          .children[action.marking.rubricLabel],
                                        earned_marks: action.marking.totalRubricEarnedMarks,
                                        children: {
                                          ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                            action.marking.subtitle
                                          ].children[action.marking.parentLabel].children[action.marking.hopcLabel]
                                            .children[action.marking.rubricLabel].children,
                                          [action.marking.factorLabel]: {
                                            ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                              action.marking.subtitle
                                            ].children[action.marking.parentLabel].children[action.marking.hopcLabel]
                                              .children[action.marking.rubricLabel].children[
                                              action.marking.factorLabel
                                            ],
                                            earned_marks: action.marking.earned_marks,
                                          },
                                        },
                                      },
                                    },
                                  },
                                }
                              : {
                                  ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                    action.marking.subtitle
                                  ].children[action.marking.parentLabel].children,
                                  [action.marking.rubricLabel]: {
                                    ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                      action.marking.subtitle
                                    ].children[action.marking.parentLabel].children[action.marking.rubricLabel],
                                    earned_marks: action.marking.totalRubricEarnedMarks,
                                    children: {
                                      ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                        action.marking.subtitle
                                      ].children[action.marking.parentLabel].children[action.marking.rubricLabel]
                                        .children,
                                      [action.marking.factorLabel]: {
                                        ...state.markingRubric.chrono_rubric.children[action.marking.tab].children[
                                          action.marking.subtitle
                                        ].children[action.marking.parentLabel].children[action.marking.rubricLabel]
                                          .children[action.marking.factorLabel],
                                        earned_marks: action.marking.earned_marks,
                                      },
                                    },
                                  },
                                },
                          // },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        };

      case MARKER_QUESTION_UPDATED:
        return {
          ...state,
          markingRubric: {
            ...state.markingRubric,
            session_question_answers: state.markingRubric.session_question_answers.map((answer, answerIndex) =>
              answerIndex === action.answer.questionIndex
                ? { ...answer, earned_marks: action.answer.earnedMarks }
                : answer
            ),
          },
        };
      case MARKER_OVERALL_UPDATED:
        return {
          ...state,
          markingRubric: {
            ...state.markingRubric,
            session_overall_answers: state.markingRubric.session_overall_answers.map((answer, answerIndex) =>
              answerIndex === action.answer.questionIndex
                ? { ...answer, earned_marks: action.answer.earnedMarks }
                : answer
            ),
          },
        };

      default:
        return state;
    }
  });

export default pwfReducer;
