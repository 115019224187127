import React, { Component } from 'react';
// import { Helmet } from 'react-helmet';
import './home.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SwipeRight from './SwipeRight';
import { trackPage } from '../../service/analytics';
import InstantGraphication from './InstantGraphication';
import UntiringPatients from './UntiringPatients';
import HeroSection from './HeroSection';
import PracticeWithFriend from './PracticeWithFriend';
import PaymentSuccessModal from './PaymentSuccessModal';
import StudentsReview from './StudentsReview';

// const seo = {
//   title: 'Democratizing Diagnostic Reasoning',
//   description:
//     'Making a diagnosis has never been so fun. Using machine learning and NLP to train better medical students and doctors.',
//   url: window.location.href,
// };

class Home extends Component {
  componentDidMount = () => {
    trackPage(window.location.pathname);
  };

  render() {
    return (
      <>
        {/* <Helmet
          title={seo.title}
          meta={[
            {
              name: 'description',
              property: 'og:description',
              content: seo.description,
            },
            { property: 'og:title', content: `Oscer - ${seo.title}` },
            { property: 'og:url', content: seo.url },
            { property: 'twitter:title', content: `Oscer - ${seo.title}` },
            { property: 'twitter:description', content: seo.description },
          ]}
        /> */}
        <main className="main">
          <PaymentSuccessModal />
          <HeroSection />
          <UntiringPatients />
          <InstantGraphication />
          <StudentsReview />
          <SwipeRight />
          <PracticeWithFriend />
        </main>
      </>
    );
  }
}

export default Home;
