import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './style';

const MicButton = (props) => {
  const classes = useStyles();

  const { listening, handleAudio, disabled, tanscripting } = props;

  const iconSelector = () => {
    if (tanscripting) {
      return <CircularProgress color="inherit" size={25} />;
    }
    if (listening) {
      return <MicIcon fontSize="inherit" />;
    }
    return <MicNoneIcon fontSize="inherit" />;
  };

  return (
    <div className={classes.MicButton__wrapper}>
      <div className={cx({ [classes.MicButton__wave]: listening })} />
      <button
        className={cx(classes.MicButton, { [classes.MicButton__animation]: listening })}
        onClick={handleAudio}
        disabled={disabled || tanscripting}
        aria-hidden="true"
        type="button"
      >
        {/* {!listening ? <MicNoneIcon fontSize="inherit" /> : <MicIcon fontSize="inherit" />} */}
        {iconSelector()}
      </button>
    </div>
  );
};

MicButton.propTypes = {
  listening: PropTypes.bool,
  handleAudio: PropTypes.func,
  disabled: PropTypes.bool,
  tanscripting: PropTypes.bool,
};

export default MicButton;
