import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Help from './MainBoard/Help';

import * as style from './style.module.scss';
import RefLinkModal from './MainBoard/RefLinkModal';
import { SET_SHOW_REFERRAL_MODAL } from '../../actions/types';
import { connect } from 'react-redux';
import { getReferralCode } from '../../actions/auth';
const OuterHelp = ({ match, dispatch, showReferralModal }) => {
  React.useEffect(() => {
    dispatch(getReferralCode());
  }, []);

  return (
    <div className={style.container}>
      <div className={style['main-wrapper']}>
        {/* Nested Route of /account */}
        <Help />
      </div>

      {!showReferralModal ? null : (
        <RefLinkModal close={() => dispatch({ type: SET_SHOW_REFERRAL_MODAL, value: false })} />
      )}

      <div className={style['right-col']} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  showReferralModal: state.account.showReferralModal,
});

export default connect(mapStateToProps, null)(OuterHelp);
