import {
  HIDE_BILLING_EDIT_MODAL,
  INIT_USER_DETAILS,
  RESET_PASSWORD_DETAILS,
  SET_INVITED_USERS,
  SET_PASSWORD_ERRORS,
  SET_PAYMENT_METHODS,
  SET_SHOW_LOGOUT_MODAL,
  SET_SHOW_REFERRAL_MODAL,
  SET_USER_DETAIL_ERRORS,
  SHOW_BILLING_EDIT_MODAL,
  UPDATE_PASSWORD_DETAILS,
  UPDATE_USER_DETAILS,
  SET_PETITION,
} from '../actions/types';

const initialState = {
  showBillingEdit: false,
  paymentMethods: [],
  invitedUsers: null,
  showReferralModal: false,
  userDetails: {
    firstName: '',
    lastName: '',
    country: '',
    university: '',
    yearLevel: '',
    // new
    isVerified: true,
    experience: '',
    studyFiled: '',
    specialty: '',
    city: '',
    hospital: '',
    degree: '',
    studyYear: '',
  },
  passwordDetails: {
    passwordEdit: false,
    password: '',
    password2: '',
  },
  userDetailsErrors: null,
  passwordErrors: null,
  showLogoutModal: false,
  isPetitioned: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PASSWORD_ERRORS:
      return { ...state, passwordErrors: action.error };
    case INIT_USER_DETAILS:
      // user details object is expected in action.user
      return {
        ...state,
        userDetails: {
          editing: false,
          firstName: action.user.first_name,
          lastName: action.user.last_name,
          country: action.user.profile && action.user.profile.country,
          university: action.user.profile && action.user.profile.university,
          yearLevel: action.user.profile && action.user.profile.year_level,
          // new
          isVerified: action.user.is_verified,
          studyFiled: action.user.study_field,
          specialty: action.user.specialty,
          city: action.user.state,
          hospital: action.user.hospital,
          degree: action.user.major,
          studyYear: action.user.study_stage,
        },
      };
    case RESET_PASSWORD_DETAILS:
      return {
        ...state,
        passwordDetails: {
          passwordEdit: false,
          password: '',
          password2: '',
        },
      };
    case SET_USER_DETAIL_ERRORS:
      return {
        ...state,
        userDetailsErrors: action.error,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.field]: action.value,
        },
      };
    case UPDATE_PASSWORD_DETAILS:
      return {
        ...state,
        passwordDetails: {
          ...state.passwordDetails,
          [action.field]: action.value,
        },
      };
    case SET_SHOW_REFERRAL_MODAL:
      return {
        ...state,
        showReferralModal: action.value,
      };
    case SHOW_BILLING_EDIT_MODAL:
      return {
        ...state,
        showBillingEdit: action.value,
      };
    case HIDE_BILLING_EDIT_MODAL:
      return {
        ...state,
        showBillingEdit: false,
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.value,
      };
    case SET_INVITED_USERS:
      // list of users invited with current user referral link
      return {
        ...state,
        invitedUsers: action.users,
      };
    case SET_SHOW_LOGOUT_MODAL:
      return { ...state, showLogoutModal: action.value };

    case SET_PETITION:
      return { ...state, isPetitioned: true };

    default:
      return state;
  }
}
