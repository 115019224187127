import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DiscontinuePrimeModal: {
    width: 790,
    height: 764,
    borderRadius: 15,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    backgroundColor: 'white',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    zIndex: 101,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    padding: '30px 0',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      padding: 15,
    },
  },
  DiscontinuePrimeModal__btn__renew: {
    color: 'white',
    height: 43,
    fontWeight: 500,
    '&:hover': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      height: 40,
    },
  },
  DiscontinuePrimeModal__btn__feedback: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    height: 41,
    fontWeight: 500,
    opacity: 0.4,
    '&:disabled': {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      boxShadow: 'none',
      cursor: 'default',
    },
    [theme.breakpoints.down('xs')]: {
      height: 40,
      border: 'none',
      color: theme.palette.primary.main,
      '&:disabled': {
        color: theme.palette.text.main,
      },
    },
  },
  DiscontinuePrimeModal__btn__feedback__active: {
    cursor: 'pointer',
    opacity: 1,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.text.main}1A`,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      '&:hover': {
        backgroundColor: 'white',
      },
    },
  },
  DiscontinuePrimeModal__img: {
    margin: '10px 0',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 150,
      margin: 0,
    },
  },
  DiscontinuePrimeModal__cancel: {
    position: 'absolute',
    top: 15,
    right: 20,
    fontWeight: 300,
    color: theme.palette.text.main,
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 400,
    },
  },
  DiscontinuePrimeModal__content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '85%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  DiscontinuePrimeModal__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  DiscontinuePrimeModal__btns: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      width: '90%',
    },
  },
  DiscontinuePrimeModal__title: {
    fontSize: 30,
    textAlign: 'center',
    color: theme.palette.text.main,
    marginBottom: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      marginBottom: 5,
    },
  },
  DiscontinuePrimeModal__subtitle: {
    textAlign: 'center',
    color: `${theme.palette.text.main}99`,
    lineHeight: 1.25,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  DiscontinuePrimeModal__reasons__desktop: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  DiscontinuePrimeModal__reasons__mobile: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '90%',
  },
  DiscontinuePrimeModal__reason__desktop: {
    color: theme.palette.primary.main,
    padding: '5px 15px',
    margin: '3px 2px',
    lineHeight: 1.25,
    borderRadius: 19,
    border: 'solid 1.5px rgba(18, 19, 19, 0.08)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(74, 132, 255, 0.05)',
    },
  },
  DiscontinuePrimeModal__reason__desktop__active: {
    backgroundColor: 'rgba(74, 132, 255, 0.15)',
    '&:hover': {
      backgroundColor: 'rgba(74, 132, 255, 0.2)',
    },
  },
  DiscontinuePrimeModal__rating: {
    display: 'flex',
    flexDirection: 'column',
  },
  DiscontinuePrimeModal__subtitle__rating: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.text.main,
    textAlign: 'center',
    marginBottom: 5,
    marginTop: 15,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginTop: 5,
    },
  },
  DiscontinuePrimeModal__rating__nums: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 5,
  },
  DiscontinuePrimeModal__rating__num: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: 'solid 1.5px rgba(18, 19, 19, 0.08)',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    width: 36,
    height: 36,
    margin: '0 2.5px',
    '&:hover': {
      backgroundColor: 'rgba(74, 132, 255, 0.05)',
    },
    [theme.breakpoints.down('xs')]: {
      width: 28,
      height: 28,
      fontSize: 13,
    },
  },
  DiscontinuePrimeModal__rating__num__active: {
    backgroundColor: 'rgba(74, 132, 255, 0.15)',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'rgba(74, 132, 255, 0.15)',
    },
  },
  DiscontinuePrimeModal__rating__desc: {
    display: 'flex',
    justifyContent: 'space-between',
    color: `${theme.palette.text.main}66`,
    lineHeight: 1.2,
    fontSize: 10,
    margin: 2.5,
  },
  DiscontinuePrimeModal__textarea: {
    borderRadius: 10,
    color: theme.palette.text.main,
    resize: 'none',
    padding: '10px 16px',
    border: `1.5px solid ${theme.palette.text.main}33`,
    marginTop: 3,
    width: '92%',
    '&:focus': {
      outline: 'none',
      border: `1.5px solid ${theme.palette.primary.main}99`,
    },
    '&::placeholder': {
      color: `${theme.palette.text.main}99`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '5px 10px',
      fontSize: 13,
      width: '78%',
    },
  },
  DiscontinuePrimeModal__reasons__Checkbox: {
    padding: 0,
    marginRight: 5,
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
  },
  DiscontinuePrimeModal__reasons__FormControl: {
    color: theme.palette.primary.main,
    margin: '0 0 5px 0',
  },
  DiscontinuePrimeModal__reasons__FormControl__label: {
    fontSize: 13,
    lineHeight: 1.23,
  },
}));

export default useStyles;
