import { useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { postMessageRecord } from '../actions/messages';

/**
 * This hook try to check if browser support speech-recognition, if yes it will directly update result to transcript and control
 * recording state for react mic, then send recording to backend. If not, it will send the recording to the backend, then update
 * result to message.
 *
 * @param {*} sessionId
 * @param {*} caseId
 * @param {*} type
 * @param {*} inputId
 * @returns
 */
const useRecording = (sessionId, caseId, type, inputId) => {
  const [message, setMessage] = useState('');
  const [isRecording, setRecording] = useState(false);
  const [tanscripting, setTranscripting] = useState(false);

  const { transcript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();

  const onStop = (recordedBlob) => {
    const formData = new FormData();
    formData.append('message', '');
    formData.append('record', recordedBlob.blob);
    formData.append('size', recordedBlob.blob.size);
    formData.append('start_time', recordedBlob.startTime);
    formData.append('stop_time', recordedBlob.stopTime);
    formData.append('case_id', caseId);
    formData.append('type', type);
    formData.append('spm_session_id', sessionId);
    formData.append('need_transcript', true);

    postMessageRecord(formData)
      .then((res) => {
        setMessage(res.transcript);
      })
      .catch(() => {
        console.log('error');
      })
      .then(() => {
        setTranscripting(false);
        setMessage('');
      });
  };

  // Start Recording
  const startRecordingRecognition = () => {
    SpeechRecognition.startListening();
    setRecording(true);
  };

  const startRecording = () => {
    setRecording(true);
  };

  // Stop Recording
  const stopRecordingRecognition = () => {
    SpeechRecognition.stopListening();
    setRecording(false);
  };

  const stopRecording = () => {
    setRecording(false);
    setTranscripting(true);
  };

  // Callback when stop recording
  const onStopRecognition = (recordedBlob) => {
    const msg = document.getElementById(inputId);
    const formData = new FormData();
    formData.append('message', msg?.textContent || '');
    formData.append('record', recordedBlob.blob);
    formData.append('size', recordedBlob.blob.size);
    formData.append('start_time', recordedBlob.startTime);
    formData.append('stop_time', recordedBlob.stopTime);
    formData.append('case_id', caseId);
    formData.append('type', type);
    formData.append('spm_session_id', sessionId);
    formData.append('need_transcript', false);

    postMessageRecord(formData)
      .then(() => {})
      .catch(() => {});
  };

  // Support voice recorgnition, message from library and audio from react mic
  if (browserSupportsSpeechRecognition) {
    return [
      listening,
      startRecordingRecognition,
      stopRecordingRecognition,
      onStopRecognition,
      transcript,
      tanscripting,
    ];
  }

  // Do not support text recognition, both message and audio from react mic
  return [isRecording, startRecording, stopRecording, onStop, message, tanscripting];
};

export default useRecording;
