import { withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.swiper': {
      width: '100%',
      height: '100%',
    },
    '&.swiper-slide': {
      textAlign: 'center',
      fontSize: '18px',
      background: '#fff',

      /* Center slide text vertically */
      display: '-webkit-box',
      display: '-ms-flexbox',
      display: '-webkit-flex',
      '-webkit-box-pack': 'center',
      '-ms-flex-pack': 'center',
      '-webkit-justify-content': 'center',
      justifyContent: 'center',
      '-webkit-box-align': 'center',
      '-ms-flex-align': 'center',
      '-webkit-align-items': 'center',
      'align-items': 'center',
    },
  },

  HideOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  CardWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  CaseHistoryCard: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    boxShadow: '0 2px 4px 0 rgba(0, 35, 72, 0.3)',
    borderRadius: 16,
    overflow: 'hidden',
    height: 'min-content',
    position: 'relative',
  },
  CaseHistoryCard__hover: {
    [theme.breakpoints.down('xs')]: {
      '& $CaseHistoryCard__tickIcon': {
        bottom: '175px',
      },
    },
    '&:hover': {
      margin: '-100px 0',
      padding: '100px 0,',
      marginRight: '10px',
      transition: 'all .5s ease',
      '-webkit-transition': 'all .5s ease',
      'transition-property': 'width, height, transform, filter, bottom, left, right',
      filter: 'brightness(1)',
      transform: 'translateY(70px) scale(1.1)',
      width: 200,
      zIndex: 200,
      boxShadow: '0 2px 15px 0 rgba(0, 35, 72, 0.3)',
      '& $CaseHistoryCard__tickIcon__background': {
        display: 'flex',
        bottom: 200,
      },
      [theme.breakpoints.down('xs')]: {
        '& $CaseHistoryCard__tickIcon__background': {
          bottom: 175,
        },
      },
      '& $CaseHistoryCard__tickIcon': {
        right: '0px',
        bottom: '201px',
        [theme.breakpoints.down('xs')]: {
          bottom: '175px',
        },
      },
      '& $CaseCardHistory__full': {
        display: 'flex',
      },
    },
  },
  CaseHistoryCard__hover__safari: {
    '&:hover': {
      transform: 'translateY(60px)',
      zoom: '110%',
    },
  },
  CaseHistoryCard__hover__first: {
    '&:hover': {
      transform: 'translateY(100px) scale(1.1)',
    },
  },
  CaseHistoryCard__hover__last: {
    '&:hover': {
      margin: '-100px 0 0 0',
      [theme.breakpoints.down('xs')]: {
        transform: 'translateY(100px) scale(1.1)',
      },
    },
  },
  CaseHistoryCard__borderWrapper: {
    borderBottom: 'solid 8px',
    position: 'relative',
    padding: 15,
    display: 'flex',
    height: '100%',
    // [theme.breakpoints.down('xs')]: {
    //   padding: 15,
    // },
  },
  CaseHistoryCard__main: {
    flex: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  CaseHistoryCard__seperator: {
    padding: '0 6px',
    opacity: '50%',
  },
  CaseHistoryCard__patient: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  CaseHistoryCard__patient__avatar: {
    marginRight: 10,
    padding: 4,
    background: '#f2fafd',
    width: 65,
    height: 65,
    borderRadius: '50%',
    '& img': {
      objectFit: 'contain',
    },
    [theme.breakpoints.down('sm')]: {
      width: 45,
      height: 45,
    },
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75,
    },
  },
  CaseHistoryCard__patient__name: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  CaseHistoryCard__patient__info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  CaseHistoryCard__patient__diagnosis: {
    alignSelf: 'baseline',
    fontSize: 10,
    lineHeight: 1.1,
    fontWeight: 'normal',
    color: '#4a84ff',
    padding: '4px 7px',
    background: '#dbe6ff',
    borderRadius: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '75%',
      marginRight: 23,
      justifyContent: 'start',
    },
  },
  CaseHistoryCard__patient__diagnosis__undiagnosed: {
    backgroundColor: '#FEEDEB',
    color: theme.palette.error.light,
    fontWeight: 500,
  },
  CaseHistoryCard__patient__condition: {
    fontSize: 16,
    marginBottom: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '150px',
      fontSize: 18,
    },
  },
  CaseHistoryCard__patient__diagnosis__ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  CaseHistoryCard__patient__basic: {
    display: 'flex',
    fontSize: 11,
    alignItems: 'center',
    lineHeight: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  CaseHistoryCard__patient__level: {
    display: 'flex',
    alignItems: 'center',
  },
  CaseHistoryCard__bottomBorder: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 8,
    background: '#f4e3ad',
    left: 0,
  },
  CaseHistoryCard__retry: {},
  CaseHistoryCard__tickIcon: {
    position: 'absolute',
    bottom: -2,
    right: -1.5,
    width: 45,
    height: 45,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      bottom: '195px',
      right: -2,
    },
  },
  CaseHistoryCard__tickIcon__background: {
    display: 'flex',
    position: 'absolute',
    width: 20,
    height: 25,
    zIndex: 1,
    bottom: 0,
    right: 0,
    [theme.breakpoints.down('xs')]: {
      bottom: '180px',
      display: 'flex',
      right: 0,
    },
  },
  CaseHistoryCard__status: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 7,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
      marginTop: 20,
      marginBottom: 15,
    },
  },
  CaseHistoryCard__status__section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    fontSize: 25.7,
    color: '#83d0f0',
    lineHeight: 1.17,
    fontWeight: 500,
    textAlign: 'start',
    marginLeft: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20.7,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: 35,
    },
  },
  CaseHistoryCard__status__title: {
    fontSize: 11,
    lineHeight: 1.22,
    color: '#ffffff',
    marginBottom: 5,
    textAlign: 'center',
    fontWeight: 400,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  CaseHistoryCard__status__totalScore: {
    fontSize: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  CaseHistoryCard__status__attempts: {
    color: '#7bc6e6',
  },
  CaseHistoryCard__starIcon: {
    color: '#f6c89f',
    width: 28,
    height: 28,
    [theme.breakpoints.down('sm')]: {
      width: 17,
      height: 17,
    },
  },
  CaseHistoryCard__dot: {
    width: 8,
    height: 8,
    background: '#3fd9c0',
    borderRadius: '50%',
    marginRight: 4,
  },
  CaseHistoryCard__retryIconWrapper: {
    alignSelf: 'baseline',
    width: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    height: 35,
    position: 'absolute',
    top: 15,
    right: 15,
    background: theme.palette.primary.light,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.92,
    },
    [theme.breakpoints.down('xs')]: {
      width: 40,
      height: 40,
      top: 15,
      right: 15,
    },
  },
  CaseHistoryCard__retryIcon: {
    maxHeight: 20,
  },
  CaseCardHistory__full: {
    display: 'none',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '0 5px',
    height: '195px',
    backgroundColor: theme.palette.inherit.main,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      height: '175px',
    },
  },
  ExpandedCase: {
    display: 'flex',
    flexDirection: 'column',
    width: 375,
    [theme.breakpoints.down('xs')]: {
      width: 280,
    },
  },
  ExpandedCase__detail: {
    background: '#ffffff',
    padding: '7px 15px 25px 15px',
    borderRadius: '0 0 16px 16px',
  },
  ExpandedCase__tableHead: {
    display: 'flex',
    padding: 4,
    fontSize: 12,
    lineHeight: 1.22,
    paddingTop: 6,
    color: theme.palette.text.light,
    textAlign: 'center',
    borderBottom: `solid 1px ${theme.palette.text.light}`,
  },
  ExpandedCase__tableHead_item: {
    flex: 1,
    textAlign: 'center',
  },
  ExpandedCase__tableHead_date: {
    flex: 1,
  },
  ExpandedCase__tableRow: {
    display: 'flex',
    padding: 2,
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.text.light,
    borderBottom: `solid 1px ${theme.palette.text.light}4D`,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  ExpandedCase__tableRow__item: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    color: theme.palette.text.main,
  },
  ExpandedCase__tableRow__date: {
    flex: 1,
  },
  ExpandedCase__player__dx: {
    background: '#d3ddec',
    color: theme.palette.primary.dark,
    borderRadius: '25px',
    width: 40,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '3px 0px',
    marginRight: 5,
  },
  ExpandedCase__player__pwf: {
    background: '#83D0F033',
    width: 45,
    color: '#83D0F0',
  },
  ExpandedCase__player__hx: {
    background: '#dae6ff',
    color: theme.palette.primary.main,
  },

  ExpandedCase__role: {
    background: '#e6f6fc',
    borderRadius: '50%',
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,
  },
  ExpandedCase__viewBtn: {
    lineHeight: 1,
    borderRadius: 14,
    background: '#83D0F0',
    fontSize: 12,
    padding: '5px 16px',
    color: 'white',
    '&:hover': {
      color: 'white',
      opacity: 0.92,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
