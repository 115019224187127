import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCheckoutComplete } from '../../../actions/checkout';
import { Modal } from '../../../components/common';
import crownIcon from '../../../assets/payment/oscer-crown.svg';
import oscerIcon from '../../../assets/cases/presentation_icons/oscer-pres-pr-bleeding.svg';
import useStyles from './style';

const PaymentSuccessModal = (props) => {
  const classes = useStyles();

  const toggle = () => {
    props.setCheckoutComplete(false);
  };

  return (
    <Modal isToggle={props.checkoutComplete} toggle={toggle} centered closable={false}>
      <div className={classes.PaymentSuccessModal}>
        <img src={crownIcon} alt="crown" className={classes.PaymentSuccessModal__crown} />
        <div className={classes.PaymentSuccessModal__content}>
          <div className={classes.PaymentSuccessModal__main}>
            <div className={classes.PaymentSuccessModal__title}>WAIT!</div>
            <div className={classes.PaymentSuccessModal__subtitle}>
              Michelle needs your help with an embarrassing issue…
            </div>
            <div className={classes.PaymentSuccessModal__text}>
              Can you diagnose her rectal bleeding? She’ll be forever grateful!
            </div>
            <div className={classes.PaymentSuccessModal__btns}>
              <Button
                classes={{ contained: classes.PaymentSuccessModal__btn__contained }}
                variant="contained"
                color="primary"
                component={Link}
                to="/cases/29"
                onClick={toggle}
              >
                Start Diagnosing
              </Button>
              <div className={classes.PaymentSuccessModal__btns__cancel} onClick={toggle} aria-hidden="true">
                I don't want to help her
              </div>
            </div>
          </div>
          <div className={classes.PaymentSuccessModal__right}>
            <img src={oscerIcon} alt="icon" className={classes.PaymentSuccessModal__right__icon} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  checkoutComplete: state.checkout.checkoutComplete,
});

PaymentSuccessModal.propTypes = {
  setCheckoutComplete: PropTypes.func,
  checkoutComplete: PropTypes.bool,
};

export default connect(mapStateToProps, { setCheckoutComplete })(PaymentSuccessModal);
