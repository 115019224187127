import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ScorePanel: {
    backgroundColor: '#fbfbfb',
    padding: '30px 20px',
    borderRadius: 16,
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  ScorePanel__progress: {
    marginBottom: 15,
  },
  ScorePanel__progress__score: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
    },
  },
  ScorePanel__score__wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  ScorePanel__score__current: {
    color: theme.palette.primary.main,
    fontSize: 30,
    lineHeight: 1.17,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  ScorePanel__score__total: {
    color: 'rgba(18, 19, 19, 0.2)',
    fontSize: 18,
    lineHeight: 1.17,
    fontWeight: 'bold',
    marginBottom: 5,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: 2,
    },
  },
  ScorePanel__progress__name: {
    fontSize: 18,
    color: theme.palette.text.main,
    fontWeight: 'bold',
  },
  ScorePanel__linearProgress__root: {
    height: 28,
  },
  ScorePanel__progress__colorPrimary__1: {
    backgroundImage: 'linear-gradient(to right, #fcb9ad, #83d0f0, #4a84ff)',
  },
  ScorePanel__progress__colorPrimary__2: {
    backgroundImage: 'linear-gradient(to right, #4a84ff, #83d0f0)',
  },
  ScorePanel__progress__colorPrimary__3: {
    backgroundImage: 'linear-gradient(to right, #83d0f0, #4a84ff,#fcb9ad )',
  },
  ScorePanel__progress__colorPrimary__4: {
    backgroundColor: 'rgba(131, 208, 240, 0.2)',
  },
  ScorePanel__progress__barColorPrimary__1: {
    backgroundColor: '#E3E3E3',
    backgroundImage: 'none',
  },
}));

export default useStyles;
