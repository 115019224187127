import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, NavLink, useLocation, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getJobs } from '../../../../actions/home';
import oscerLogo from '../../../../assets/logos/oscer-full-logo-dark.svg';
import oscerLogoSm from '../../../../assets/logos/oscer-icon-solid.svg';
import IconDashboard from '../../../../assets/common/dashboard-icon-light.svg';
import useStyles from './style';

/**
 * @desc The header of home page
 * @param {*} props
 */
const Header = (props) => {
  const { isAuthenticated } = props;
  const classes = useStyles();

  useEffect(() => {
    props.getJobs('Jobs', 'sub_category').catch((err) => console.log(err));
  }, []);

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // Popover for about nav tab
  const handlePopoverOpen = (event) => {
    if (open !== event.currentTarget) {
      // console.log(event.currentTarget);
      setOpen(true);
    }
  };

  // Popover for about nav tab
  const handlePopoverClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [drawer, setDrawer] = useState(false);

  const toggleDrawer = () => {
    setDrawer((toggle) => !toggle);
  };

  // Scroll offset to contact us
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -200;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const handleScrollTop = () => {
    animateScroll.scrollToTop();
  };

  const handleMenuItem = (event, link) => {
    handlePopoverClose(event);
    if (link !== '/about#contact') {
      handleScrollTop();
    }
  };

  const handleMobleAboutMenu = (link) => {
    toggleDrawer();
    if (link !== '/about#contact') {
      handleScrollTop();
    }
  };

  const navigation = [
    // { name: 'About', link: '/about' },
    { name: 'Plans', link: '/plans' },
    { name: 'Institutions', link: '/institutions' },
    { name: 'Technology', link: '/technology' },
  ];

  const aboutNav = [
    { title: 'About us', subtitle: 'Learn about Oscer', link: '/about' },
    { title: 'Careers', subtitle: 'Become part of the team', link: '/careers', num: true },
    { title: 'Contact us', subtitle: 'Reach out anytime', link: '/about#contact' },
  ];

  return (
    <div className={classes.HomeHeader}>
      <Container maxWidth="lg">
        <div className={classes.HomeHeader__main}>
          <div className={classes.HomeHeader__nav}>
            <div className={classes.HomeHeader__logo}>
              <Link to="/" onClick={handleScrollTop}>
                <img src={oscerLogo} alt="oscer_logo" className={classes.HomeHeader__nav__logo} />
              </Link>
              {!!props.jobsQuantity && (
                <Link to="/careers" onClick={handleScrollTop} className={classes.HomeHeader__hire}>
                  <div className={classes.HomeHeader__hiring}>We're Hiring!</div>
                  <div className={classes.HomeHeader__num}>{props.jobsQuantity}</div>
                </Link>
              )}
            </div>
            <div>
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
                onMouseOver={handlePopoverOpen}
                classes={{
                  root: cx({
                    [classes.HomeHeader__btn__active]: open || aboutNav.some((nav) => nav.link === location.pathname),
                  }),
                }}
              >
                About <ExpandMoreIcon fontSize="medium" />
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                onMouseLeave={handlePopoverClose}
              >
                {({ TransitionProps }) => (
                  <Grow {...TransitionProps} style={{ transformOrigin: 'center' }}>
                    <div className={classes.HomeHeader__about__main}>
                      <ArrowDropUpIcon
                        color="primary"
                        fontSize="medium"
                        classes={{ root: classes.HomeHeader__arrow }}
                      />
                      <div className={classes.HomeHeader__wrapper}>
                        <Paper classes={{ root: classes.HomeHeader__Paper }}>
                          <ClickAwayListener onClickAway={handlePopoverClose}>
                            <div className={classes.HomeHeader__about__wrapper}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {aboutNav.map((nav) => (
                                  <MenuItem
                                    key={nav.title}
                                    onClick={(event) => handleMenuItem(event, nav.link)}
                                    classes={{ root: classes.HomeHeader__about__MenuItem }}
                                    // component={Link}
                                    component={HashLink}
                                    scroll={(el) => scrollWithOffset(el)}
                                    to={nav.link}
                                  >
                                    <div
                                      className={cx(
                                        { [classes.HomeHeader__btn__active]: nav.link === location.pathname },
                                        classes.HomeHeader__about__title
                                      )}
                                    >
                                      {nav.title}
                                      {!!props.jobsQuantity && nav.num && (
                                        <div className={classes.HomeHeader__jobs__num}>{props.jobsQuantity}</div>
                                      )}
                                    </div>
                                    <div className={classes.HomeHeader__about__subtitle}>{nav.subtitle}</div>
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </div>
                          </ClickAwayListener>
                        </Paper>
                      </div>
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
            {navigation.map((nav) => (
              <NavLink
                key={nav.name}
                to={nav.link}
                className={classes.HomeHeader__nav__link}
                activeClassName={classes.HomeHeader__link__active}
                onClick={handleScrollTop}
                onMouseOver={handlePopoverClose}
              >
                {nav.name}
              </NavLink>
            ))}
          </div>
          {isAuthenticated ? (
            <Button
              classes={{ contained: classes.HomeHeader__dashboard__contained }}
              variant="contained"
              color="primary"
              component={Link}
              to="/dashboard/home"
            >
              Dashboard <img src={IconDashboard} alt="dashboard" className={classes.HomeHeader__dashboard__icon} />
            </Button>
          ) : (
            <div className={classes.HomeHeader__btn}>
              <Link to="/signin" className={classes.HomeHeader__signIn}>
                Sign In
              </Link>
              <Link to="/signup" className={classes.HomeHeader__signUp}>
                Sign Up
              </Link>
            </div>
          )}
        </div>
        <div className={classes.HomeHeader__main__mobile}>
          <Link to="/" onClick={handleScrollTop}>
            <img src={oscerLogoSm} alt="oscer_logo_small" className={classes.HomeHeader__logo__mobile} />
          </Link>
          <div onClick={toggleDrawer} aria-hidden="true">
            {drawer ? <CloseIcon color="primary" /> : <MenuIcon color="primary" />}
          </div>
        </div>
        <Drawer
          anchor="right"
          open={drawer}
          onClose={toggleDrawer}
          classes={{ paper: classes.HomeHeader__drawer__paper }}
        >
          <div className={classes.HomeHeader__drawer__main}>
            <div className={classes.HomeHeader__mobile__header}>
              <img src={oscerLogoSm} alt="logo" className={classes.HomeHeader__mobile__logo} />
              <div className={classes.HomeHeader__mobile__wrapper}>
                {isAuthenticated ? (
                  <Button
                    classes={{ contained: classes.HomeHeader__dashboard__contained }}
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/dashboard/home"
                  >
                    Dashboard{' '}
                    <img src={IconDashboard} alt="dashboard" className={classes.HomeHeader__dashboard__icon} />
                  </Button>
                ) : (
                  <div className={classes.HomeHeader__btn}>
                    <Link to="/signin" className={classes.HomeHeader__signIn}>
                      Sign In
                    </Link>
                    <Link to="/signup" className={classes.HomeHeader__signUp}>
                      Sign Up
                    </Link>
                  </div>
                )}
                <CloseIcon color="primary" onClick={toggleDrawer} />
              </div>
            </div>
            <div className={classes.HomeHeader__mobile__links}>
              <div className={classes.HomeHeader__mobile__link}>About</div>
              {aboutNav.map((nav) => (
                <HashLink
                  key={nav.title}
                  className={classes.HomeHeader__mobile__about}
                  onClick={handleMobleAboutMenu}
                  scroll={(el) => scrollWithOffset(el)}
                  to={nav.link}
                >
                  <div
                    className={cx(
                      { [classes.HomeHeader__btn__active]: nav.link === location.pathname },
                      classes.HomeHeader__about__title
                    )}
                  >
                    {nav.title}
                    {!!props.jobsQuantity && nav.num && (
                      <div className={classes.HomeHeader__jobs__num}>{props.jobsQuantity}</div>
                    )}
                  </div>
                  <div className={classes.HomeHeader__about__subtitle}>{nav.subtitle}</div>
                </HashLink>
              ))}
              <NavLink
                className={classes.HomeHeader__mobile__link}
                activeClassName={classes.HomeHeader__mobile__link__active}
                to="/plans"
                onClick={() => {
                  toggleDrawer();
                  handleScrollTop();
                }}
                exact
              >
                Plans
              </NavLink>
              <NavLink
                className={classes.HomeHeader__mobile__link}
                activeClassName={classes.HomeHeader__mobile__link__active}
                to="/institutions"
                onClick={() => {
                  toggleDrawer();
                  handleScrollTop();
                }}
                exact
              >
                Institutions
              </NavLink>
              <NavLink
                className={classes.HomeHeader__mobile__link}
                activeClassName={classes.HomeHeader__mobile__link__active}
                to="/technology"
                onClick={() => {
                  toggleDrawer();
                  handleScrollTop();
                }}
                exact
              >
                Technology
              </NavLink>
            </div>
          </div>
        </Drawer>
      </Container>
    </div>
  );
};

Header.propTypes = {
  isAuthenticated: PropTypes.bool,
  getJobs: PropTypes.func,
  jobs: PropTypes.array,
  jobsQuantity: PropTypes.number,
};

const mapStateToProps = (state) => ({
  jobsQuantity: state.home.jobsQuantity,
});

export default withRouter(connect(mapStateToProps, { getJobs })(Header));
