import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { InputBase, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './style';

const FuzzyFilter = (props) => {
  const classes = useStyles();
  const { searchValue, handleInputChange, setFocus = () => {} } = props;
  const inputRef = useRef(null);
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    setFocus(isActive);
  }, [isActive]);

  const [width, setWidth] = useState(47);

  const handleInputBaseActive = () => {
    if (isActive) {
      setWidth(47);
    } else {
      setWidth(props.width ? props.width : 220);
    }
    setActive((active) => !active);
  };

  const handleClick = () => {
    inputRef.current.focus();
  };

  return (
    <div className={classes.FuzzyFilter} style={{ maxWidth: width }}>
      <InputBase
        inputRef={inputRef}
        onClick={handleClick}
        classes={{
          root: cx(classes.FuzzyFilter__InputBase__root, {
            [classes.FuzzyFilter__InputBase__root__active]: searchValue.length > 0,
          }),
        }}
        placeholder="Search cases, conditions"
        inputProps={{ 'aria-label': 'search a Patient' }}
        onChange={handleInputChange}
        value={searchValue}
        onBlur={handleInputBaseActive}
        onFocus={handleInputBaseActive}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon
              classes={{
                root: cx(classes.FuzzyFilter__SearchIcon__root, {
                  [classes.FuzzyFilter__SearchIcon__root__active]: isActive,
                }),
              }}
            />
          </InputAdornment>
        }
      />
    </div>
  );
};

FuzzyFilter.propTypes = {
  handleInputChange: PropTypes.func,
  searchValue: PropTypes.string,
  isActive: PropTypes.bool,
};

export default FuzzyFilter;
