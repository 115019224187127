import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trackPage } from '../../service/analytics';
import Hero from './Hero';
import Institutions from './Institutions';
import SpeakToTeam from './SpeakToTeam';
import useStyles from './style';

const HomeInstitutions = (props) => {
  const classes = useStyles();

  useEffect(() => {
    trackPage('/institutions');
  }, []);

  return (
    <div className={classes.HomeInstitutions}>
      <Hero />
      <Institutions />
      <SpeakToTeam />
    </div>
  );
};

HomeInstitutions.propTypes = {};

export default HomeInstitutions;
