import React, { useEffect } from 'react';
import Hero from './Hero';
import Statistics from './Statistics';
import { trackPage } from '../../service/analytics';
import Features from './Features';
import useStyles from './style';

const HomeTechnology = () => {
  const classes = useStyles();

  useEffect(() => {
    trackPage('/technology');
  }, []);

  return (
    <div className={classes.HomeTechnology}>
      <Hero />
      <Statistics />
      <Features />
    </div>
  );
};

HomeTechnology.propTypes = {};

export default HomeTechnology;
