import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  SystemBadge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f2fafd',
    height: 47,
    width: 47,
    borderRadius: '50%',
  },
  SystemBadge__icon: {
    maxHeight: 40,
    maxWidth: 40,
  },
}));

export default useStyles;
