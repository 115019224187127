import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  RightSideBar: {
    display: 'flex',
    transition: 'all 1s ease',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  RightSideBarMoveRight: {
    display: 'flex',
    transition: 'all 2s',
    transform: 'translateX(300px)',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  RightSideBar__mobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      position: 'fixed',
      marginTop: '5px',
    },
  },
  RightSideBar__mobile__scoreHints: {
    position: 'relative',
  },
  RightSideBar__mobile__submit: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.09)',
    width: 65,
    height: 60,
    marginLeft: 'calc(100vw - 125px)',
  },
  RightSideBar__mobile__submit__btn: {
    width: 50,
    minWidth: 50,
    height: 50,
    borderRadius: '50%',
    fontSize: 10,
    fontWeight: 500,
    padding: 0,
  },
  RightSideBar__vertical__separator: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    width: 1,
    height: 50,
  },
  RightSideBar__tour__mask: {
    opacity: 0.4,
  },
  RightSideBar__mobile__timer: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: '-3px',
      left: 0,
      display: 'flex',
      width: '100%',
      zIndex: 10,
    },
  },
  RightSideBar__mobile__header: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    padding: 8,
    // width: 150,
    flex: 1,
    height: '100%',
  },
  RightSideBar__progress__colorPrimary__2: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.light})`,
  },
  RightSideBar__progress__color__1: {
    backgroundImage: 'linear-gradient(to right, #FBA799, #FBA799)',
  },
  RightSideBar__progress__color__2: {
    backgroundImage: 'linear-gradient(to right, #FBA799, #FBA799)',
  },
  RightSideBar__progress__color__3: {
    backgroundImage: 'linear-gradient(to right, #EDBD66, #FBA799)',
  },
  RightSideBar__progress__color__4: {
    backgroundImage: 'linear-gradient(to right, #83D0F0, #EDBD66)',
  },
  RightSideBar__progress__color__5: {
    backgroundImage: 'linear-gradient(to right, #4A84FF, #83D0F0)',
  },
  ProfilePanel__CollapseButton: {
    position: 'absolute',
    right: 0,
    top: 15,
    width: '32px',
    height: '62px',
    boxShadow: '2px 1px 4px 0 rgba(0, 35, 72, 0.15)',
    backgroundColor: `#deeaff`,
    borderTopLeftRadius: '100px',
    borderBottomLeftRadius: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.92,
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
