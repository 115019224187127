import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Sentry from '@sentry/react';
import { SyncLoader } from 'react-spinners';
import Lottie from 'lottie-react';
import CheckIcon from '@material-ui/icons/Check';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import VoiceOverOffIcon from '@material-ui/icons/VoiceOverOff';
import cardiologyAnimation from 'assets/animation/diagnosis/cardiology.json';
import dermatologyAnimation from 'assets/animation/diagnosis/dermatology.json';
import endocrineAnimation from 'assets/animation/diagnosis/endocrine.json';
import gastrointestinalAnimation from 'assets/animation/diagnosis/gastrointestinal.json';
import gynecologyAnimation from 'assets/animation/diagnosis/gynecology.json';
import hemeOcAnimation from 'assets/animation/diagnosis/heme-onc.json';
import musculoskeletalAnimation from 'assets/animation/diagnosis/musculoskeletal.json';
import neurologyAnimation from 'assets/animation/diagnosis/neurology.json';
import psychiatricAnimation from 'assets/animation/diagnosis/psychiatric.json';
import renalAnimation from 'assets/animation/diagnosis/renal.json';
import respiratoryAnimation from 'assets/animation/diagnosis/respiratory.json';
import { connect } from 'react-redux';
import { DIAGNOSIS_SESSION, LEARN_MODE_SESSION } from 'helpers/constants';
import {
  SYSTEM_CARDIO,
  SYSTEM_DERMATOLOGY,
  SYSTEM_ENDOCRINE,
  SYSTEM_GASTRO,
  SYSTEM_MUSCULO,
  SYSTEM_NEURO,
  SYSTEM_ONC,
  SYSTEM_RENAL,
  SYSTEM_RESPIRATORY,
  SYSTEM_PSYCHIATRIC,
  SYSTEM_GYNECOLOGY,
} from '../../../constants';

import { patchMessageReview } from '../../../actions/cases';
import { setMessageRead } from '../../../actions/messages';
import Tooltip from '../../Tooltip';
import Emoji from '../../Emoji';
import './style.scss';
import PresentationImg from '../../icons/PresentationImg';
import { featureToogleByUser } from '../../../configuration/featureToggle';
import useAudio from '../../../hook/useAudio';
/**
 * @desc Message response from bot
 * @param {*} props
 * - message is an object contains response, points earned etc
 * - currentCase is an object of current case
 * - isMessageFetching is boolean refers to fetching state
 */
const MessageIn = (props) => {
  const {
    messages,
    message: {
      text: {
        message_id,
        response: text,
        points_earned: pointsEarned,
        label: labelName,
        response_status: responseStatus,
        response_audio_url: audioURL,
        hit_must_asked_qn = false,
      },
      date,
    },
    currentCase,
    type,
    msgIndex,
  } = props;

  // isVisible is the state to make the component visible after the incoming message's animation.
  const [isVisible, setVisible] = useState(true);

  // isLoading is the state to distinguish whether the response is loading or not.
  const [isLoading, setLoading] = useState(true);

  const [clicked, setClicked] = useState(false);
  const [isWaiting, setWaiting] = useState(false);

  const [isPlaying, isLoaded, audioToggle] = useAudio(audioURL, props.autoPlayAudio && !messages[msgIndex]?.read);

  const lottieRef = useRef();

  const unimelbToggle = featureToogleByUser(
    'unimelbCaseUIToggle',
    window.location.hostname.includes('unimelb') ? 'unimelb' : 'public'
  );

  useEffect(() => {
    if (messages[msgIndex] && !messages[msgIndex]?.read) {
      props.setMessageRead(msgIndex);
    }
  }, []);

  const handleMessageClick = (clicked) => {
    if (!isWaiting && message_id) {
      setWaiting(true);
      props
        .patchMessageReview(message_id, !clicked)
        .then(() => setClicked(!clicked))
        .catch((err) =>
          Sentry.captureException(err, {
            tags: {
              section: 'Message Review Error',
            },
          })
        )
        .then(() => setWaiting(false));
    }
  };

  const complimentsWords = () => {
    if (hit_must_asked_qn) {
      return 'Yay must ask';
    }
    const compliments = [
      'Well Done',
      'Good Job',
      'Great',
      'Excellent',
      'Amazing',
      'Nice Going',
      'Incredible',
      'Awesome',
      'Fantastic',
      'Super Duper',
      'Tremendous',
    ];
    return compliments[Number(new Date(date)) % compliments.length];
  };

  const animationPicker = () => {
    const animations = [
      cardiologyAnimation,
      endocrineAnimation,
      gastrointestinalAnimation,
      hemeOcAnimation,
      musculoskeletalAnimation,
      neurologyAnimation,
      psychiatricAnimation,
      renalAnimation,
      respiratoryAnimation,
      dermatologyAnimation,
      gynecologyAnimation,
    ];
    const animationList = [];
    const randomNum = Math.floor(Math.random() * 10);
    if (randomNum >= 2) {
      return animationList;
    }

    switch (props.system) {
      case SYSTEM_CARDIO:
        animationList.push(animations[0]);
        break;
      case SYSTEM_ENDOCRINE:
        animationList.push(animations[1]);
        break;
      case SYSTEM_GASTRO:
        animationList.push(animations[2]);
        break;
      case SYSTEM_ONC:
        animationList.push(animations[3]);
        break;
      case SYSTEM_MUSCULO:
        animationList.push(animations[4]);
        break;
      case SYSTEM_NEURO:
        animationList.push(animations[5]);
        break;
      case SYSTEM_PSYCHIATRIC:
        animationList.push(animations[6]);
        break;
      case SYSTEM_RENAL:
        animationList.push(animations[7]);
        break;
      case SYSTEM_RESPIRATORY:
        animationList.push(animations[8]);
        break;
      case SYSTEM_DERMATOLOGY:
        animationList.push(animations[9]);
        break;
      case SYSTEM_GYNECOLOGY:
        animationList.push(animations[10]);
        break;
      default:
        animationList.push([]);
        break;
    }
    return animationList;
  };

  const selectMessageBGColor = (status) => {
    switch (status) {
      case 'red_message':
        return '#fdd3cc';
      case 'failure':
        return '#fdd3cc';
      case 'na':
        return '#fdebcc';
      case 'success':
        return 'rgb(131, 208, 240)';
      case 'profanity':
        return '#fdebcc';
      default:
        return 'rgb(131, 208, 240)';
    }
  };

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(0.7);
    }
  }, []);

  return (
    <div className={`message-in ${isVisible && 'visible'}`}>
      <PresentationImg presentation={currentCase.presentation} size={20} className="message-in__pre__img" />
      <div className={classnames('message-in_text', { animated: !isLoading })} aria-hidden="true">
        {text === 'LOADING' || text === undefined ? (
          <SyncLoader
            size={6}
            color="white"
            css={{
              backgroundColor: '#83D0F0',
              padding: '5px 15px',
              borderRadius: '15px',
              boxShadow: '0px 2px 3px rgba(27, 28, 29, 0.03)',
              width: 60,
              marginBottom: 3,
            }}
          />
        ) : (
          <>
            <div className="footer">
              {!!Number(pointsEarned) && (
                <div
                  className={`${hit_must_asked_qn && 'footer__score--highlight'} footer__score ${
                    type === DIAGNOSIS_SESSION && 'footer__score__diagnosis'
                  }`}
                >
                  {type == DIAGNOSIS_SESSION &&
                    animationPicker().map((animation, index) => (
                      <Lottie
                        key={index}
                        animationData={animation}
                        loop={1}
                        lottieRef={lottieRef}
                        style={{ position: 'absolute', right: 0, bottom: 70, zIndex: 1 }}
                      />
                    ))}
                  {complimentsWords()}
                  <span className={`${hit_must_asked_qn && 'footer__score__icon--highlight'}`}>
                    {type === DIAGNOSIS_SESSION || unimelbToggle ? (
                      <CheckIcon fontSize="inherit" />
                    ) : (
                      `+${pointsEarned}`
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="message-in__main">
              <Tooltip
                disableHoverListener={type === LEARN_MODE_SESSION || !message_id}
                title={
                  clicked ? (
                    <>
                      {`You've flagged this response. Thanks for training Oscer `}
                      <Emoji label="robot" symbol="🤖" />
                    </>
                  ) : (
                    <>
                      {`Click the flag ! if this response looks wrong to you, it helps make Oscer better `}
                      <Emoji label="cheers" symbol="🥳" />
                    </>
                  )
                }
                arrow
                styles={{ tooltip: 'message-in__tooltip', arrow: 'message-in__tooltip__arrow' }}
              >
                <p
                  className={`${clicked && 'clicked'}  ${message_id && 'message-in__text__container'}`}
                  onClick={() => handleMessageClick(clicked)}
                  aria-hidden="true"
                  style={{
                    backgroundColor: selectMessageBGColor(responseStatus),
                  }}
                >
                  {text.split('\n').map((item, key) => (
                    <span key={key}>
                      {item}
                      <br />
                    </span>
                  ))}
                </p>
              </Tooltip>
              {/* {!!audioURL &&
                isLoaded &&
                (isPlaying ? (
                  <VoiceOverOffIcon onClick={audioToggle} classes={{ root: 'message-in__main__voice' }} />
                ) : (
                  <RecordVoiceOverIcon onClick={audioToggle} classes={{ root: 'message-in__main__voice' }} />
                ))} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) =>
  prevProps?.message.text?.message_id === nextProps?.message.text?.message_id;

const mapStateToProps = (state) => ({
  autoPlayAudio: state.cases.autoPlayAudio,
  // messages: state.messages.messages,
  system: state.cases.currentCase.system,
});

MessageIn.propTypes = {
  message: PropTypes.object.isRequired,
  currentCase: PropTypes.object,
  patchMessageReview: PropTypes.func,
  autoPlayAudio: PropTypes.bool,
  type: PropTypes.string,
  setMessageRead: PropTypes.func,
  messages: PropTypes.array,
  system: PropTypes.string,
};

export default connect(mapStateToProps, { patchMessageReview, setMessageRead })(memo(MessageIn, arePropsEqual));
