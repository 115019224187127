import React from 'react';
import PropTypes from 'prop-types';

const IconCommunity = props => {
  const { color = 'var(--secondary-color-1)', size = 20, ...restProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 50 35" version="1.1" {...restProps}>
      <g id="new-icons" transform="translate(-619.000000, -123.000000)">
        <g id="community-icon-dark" transform="translate(619.000000, 123.000000)">
          <path d="M37.0005,21.5044 L19.0005,21.5044 C18.1715,21.5044 17.5005,20.8334 17.5005,20.0044 C17.5005,19.1754 18.1715,18.5044 19.0005,18.5044 L37.0005,18.5044 C37.8285,18.5044 38.5005,19.1754 38.5005,20.0044 C38.5005,20.8334 37.8285,21.5044 37.0005,21.5044 M37.0005,27.5044 L19.0005,27.5044 C18.1715,27.5044 17.5005,26.8334 17.5005,26.0044 C17.5005,25.1754 18.1715,24.5044 19.0005,24.5044 L37.0005,24.5044 C37.8285,24.5044 38.5005,25.1754 38.5005,26.0044 C38.5005,26.8334 37.8285,27.5044 37.0005,27.5044 M12.0005,15.0004 L12.0005,20.6704 L6.0005,24.0004 L8.0005,19.0004 L3.0005,19.0004 L3.0005,3.0004 L29.0005,3.0004 L29.0005,12.0004 L15.0005,12.0004 C13.3435,12.0004 12.0005,13.3434 12.0005,15.0004 M41.0005,12.0004 L32.0005,12.0004 L32.0005,3.0004 C32.0005,1.3434 30.6565,0.0004 29.0005,0.0004 L3.0005,0.0004 C1.3435,0.0004 0.0005,1.3434 0.0005,3.0004 L0.0005,19.0004 C0.0005,20.6564 1.3435,22.0004 3.0005,22.0004 L3.5695,22.0004 L3.2205,22.8904 C2.7475,24.0684 3.0675,25.4164 4.0205,26.2564 C4.9725,27.0964 6.3505,27.2464 7.4605,26.6294 L12.0005,24.1094 L12.0005,31.0104 C12.0005,32.6674 13.3435,34.0104 15.0005,34.0104 L28.2205,34.0104 L36.5395,38.6294 C37.6495,39.2464 39.0275,39.0964 39.9795,38.2564 C40.9325,37.4164 41.2525,36.0684 40.7795,34.8904 L40.4305,34.0004 L41.0005,34.0004 C42.6565,34.0004 44.0005,32.6564 44.0005,31.0004 L44.0005,15.0004 C44.0005,13.3434 42.6565,12.0004 41.0005,12.0004" id="Fill-1" fill="#4A84FF" mask="url(#mask-2)"></path>
        </g>
      </g>
    </svg>
  );
};

IconCommunity.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconCommunity;
