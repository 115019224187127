import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import useStyles from './style';
import { trackPage } from '../../service/analytics';
import HomeContainer from '../../components/Container/HomeContainer';
import SignUpBox from './SignUpBox';
import SignInBox from './SignInBox';
import PaymentBox from './PaymentBox';

const CreatePrimeAccount = (props) => {
  const cs = useStyles();
  const [step, setStep] = useState(1);
  const [isSignUp, setSignUp] = useState(true);

  useEffect(() => {
    trackPage(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      setStep(2);
    }
  }, [props.isAuthenticated]);

  const progress = [
    {
      title: 'SIGN UP',
      stepName: '1',
    },
    {
      title: 'PAYMENT',
      stepName: '2',
    },
    {
      title: 'START',
      stepName: '3',
    },
  ];

  return (
    <div className={cs.CreatePrimeAccount}>
      <HomeContainer>
        <div className={cs.CreatePrimeAccount__progressBar}>
          {progress.map((item, index) => (
            <div key={item.title} className={cs.CreatePrimeAccount__progressBar__item}>
              <div
                className={cx(cs.CreatePrimeAccount__progress, {
                  [cs.CreatePrimeAccount__active]: index + 1 <= step,
                })}
              >
                <div
                  className={cx(cs.CreatePrimeAccount__progress__stage, {
                    [cs.CreatePrimeAccount__completed]: index + 1 < step,
                  })}
                >
                  {item.stepName}
                </div>
                <div className={cs.CreatePrimeAccount__progress__name}>{item.title}</div>
              </div>
              {index !== progress.length - 1 && (
                <div
                  className={cx(cs.CreatePrimeAccount__seprator, {
                    [cs.CreatePrimeAccount__active]: index + 1 < step,
                  })}
                />
              )}
            </div>
          ))}
        </div>
        {step === 1 &&
          (isSignUp ? (
            <SignUpBox setStep={setStep} setSignUp={setSignUp} />
          ) : (
            <SignInBox setStep={setStep} setSignUp={setSignUp} />
          ))}
        {step === 2 && <PaymentBox setStep={setStep} />}
      </HomeContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

CreatePrimeAccount.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps)(CreatePrimeAccount);
