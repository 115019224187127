import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import useStyles from './style';

const ImageCard = ({ image, name, styles }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.ImageCard, styles)}>
      <img src={image} alt={`img_${name}`} className={classes.ImageCard__iamge} />
      <div className={classes.ImageCard__name}>{name}</div>
    </div>
  );
};

ImageCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  styles: PropTypes.string,
};

export default ImageCard;
