import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  UpgradeHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.text.main,
    height: 70,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 39,
      left: 0,
      height: 45,
      zIndex: 2,
    },
  },
  UpgradeHeader__crown: {
    marginRight: 10,
    marginBottom: 5,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 18,
      marginRight: 5,
      marginBottom: 3,
    },
  },
  UpgradeHeader__text: {
    color: 'white',
    lineHeight: 1.25,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  UpgradeHeader__days: {
    color: theme.palette.text.main,
    fontSize: 30,
    fontWeight: 500,
    lineHeight: 1,
    padding: '5px 8px',
    borderRadius: 6,
    backgroundColor: 'white',
    margin: '0 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      padding: '4px 6px',
      margin: '0 5px',
    },
  },
  UpgradeHeader__btn__renew: {
    backgroundColor: '#f6c89f',
    color: theme.palette.text.main,
    marginLeft: 20,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#f6c89fF5',
      color: theme.palette.text.main,
    },
    [theme.breakpoints.down('xs')]: {
      height: 25,
      width: 80,
      marginLeft: 10,
      padding: 0,
      fontSize: 10,
    },
  },
}));

export default useStyles;
