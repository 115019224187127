import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  App: {},
  App__dasboard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    backgroundColor: `${theme.palette.primary.main}1A`,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  App__dasboard__content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 0,
      left: 0,
      top: 56,
      bottom: 69,
      overflow: 'overlay',
      overflowX: 'hidden',
    },
  },
}));

export default useStyles;
