import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import HomeContainer from '../../../components/Container/HomeContainer';
import useStyles from './style';
import ChatWindow from '../../../assets/images/practice-with-friends.svg';
import OverlayShape from '../../../assets/home/shape-9.svg';

const PracticeWithFriend = () => {
  const classes = useStyles();
  return (
    <HomeContainer outerStyles={classes.PracticeWithFriend} innerStyles={classes.PracticeWithFriend__container}>
      <Typography variant="h2" gutterBottom classes={{ root: classes.PracticeWithFriend__title }}>
        Practice with friends using our OSCE Template
      </Typography>
      <Typography variant="subtitle1" gutterBottom classes={{ root: classes.PracticeWithFriend__subtitle }}>
        Be the coolest kid in your study group by suggesting Oscer. With our scripts and marking rubrics, all you have
        to decide is whose playing what role, and then get diagnosing!
      </Typography>

      <img src={ChatWindow} className={classes.PracticeWithFriend__chatWindow} alt="Oscer Practice with Friends " />
      <img src={OverlayShape} alt="shape" className={classes.PracticeWithFriend__overlay1} />
    </HomeContainer>
  );
};
export default PracticeWithFriend;
