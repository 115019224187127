import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Verification: {
    minHeight: '100vh',
    width: '100%',
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Verification__main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'stretch',
    textAlign: 'center',
  },
  Verification__img: {
    maxWidth: 350,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
      marginBottom: 15,
    },
  },
  Verification__title: {
    fontSize: 21,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  Verification__subtitle: {
    fontSize: 17,
    color: theme.palette.text.main,
    marginBottom: 20,
  },
  Verification__bth: {
    height: 44,
  },
  Verification__successMessage: {
    marginBottom: 20,
    fontSize: 18,
  },
  Verification__whiteWrapper: {
    background: '#ffffff',
    padding: '10px 20px',
    borderRadius: 24,
    marginTop: 20,
    marginBottom: 20,
  },
}));

export default useStyles;
