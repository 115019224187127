import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Button, FormControlLabel, Checkbox, TextareaAutosize, useMediaQuery, useTheme } from '@material-ui/core';
import { range } from 'lodash';
import { postPrimeFeedback } from '../../../../actions/dashboard';
import { Modal } from '../../../../components/common';
import imgCancelPrime from '../../../../assets/payment/cancel-email.svg';
import useStyles from './style';

/**
 * @desc Mode selection modal
 * @param {*} param0
 */
const DiscontinuePrimeModal = (props) => {
  const classes = useStyles();
  const { isToggle, toggle } = props;

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [reasons, setReasons] = useState([
    { title: 'I don’t understand how to use Oscer Prime', checked: false },
    { title: `It's too expensive`, checked: false },
    { title: 'I accomplished my study goal', checked: false },
    { title: 'I want to change subscriptions', checked: false },
    { title: `I don't currently need Oscer Prime, but may subscribe again in the future`, checked: false },
    { title: `Other`, checked: false },
  ]);

  const [other, setOther] = useState('');

  const [rating, setRating] = useState(-1);

  const location = useLocation();

  const handleReason = (idx) => {
    const updatedReasons = reasons.map((reason, index) => {
      if (index === idx) {
        return { ...reason, checked: !reasons[index].checked };
      }
      return reason;
    });
    setReasons(updatedReasons);
  };

  const handleRating = (rate) => {
    setRating(rate);
  };

  // Feedback api
  const handleSubmitFeedback = () => {
    const selectedReasons = [];
    reasons.forEach((reason) => {
      if (reason.checked) {
        selectedReasons.push(reason.title);
      }
    });
    if (other) {
      selectedReasons.push(other);
    }
    postPrimeFeedback(selectedReasons, rating);
    // Turn off modal
    toggle(false);
  };

  // Check if all reasons and rating meet
  const checkAllReasons = () => !!(rating > -1 && reasons.some((reason) => reason.checked));

  const hendleOthers = (e) => {
    setOther(e.target.value);
  };

  return (
    <Modal isToggle={isToggle} toggle={() => toggle(false)} centered zIndex={100} closable={false}>
      <div className={classes.DiscontinuePrimeModal}>
        {/* <div className={classes.DiscontinuePrimeModal__cancel} onClick={() => toggle(false)} aria-hidden="true">
          Exit
        </div> */}
        <div className={classes.DiscontinuePrimeModal__content}>
          <div className={classes.DiscontinuePrimeModal__wrapper}>
            <img src={imgCancelPrime} className={classes.DiscontinuePrimeModal__img} alt="cancel_prime_image" />
            <div className={classes.DiscontinuePrimeModal__title}>Let us know why you no longer want Prime</div>
            <div className={classes.DiscontinuePrimeModal__subtitle}>Select all that apply to you...</div>
            {!isSmScreen ? (
              <div className={classes.DiscontinuePrimeModal__reasons__desktop}>
                {reasons.map((reason, index) => (
                  <div
                    key={reason.title}
                    className={cx(classes.DiscontinuePrimeModal__reason__desktop, {
                      [classes.DiscontinuePrimeModal__reason__desktop__active]: reason.checked,
                    })}
                    onClick={() => handleReason(index)}
                    aria-hidden="true"
                  >
                    {reason.title}
                  </div>
                ))}
              </div>
            ) : (
              <div className={classes.DiscontinuePrimeModal__reasons__mobile}>
                {reasons.map((reason, index) => (
                  <FormControlLabel
                    key={reason.title}
                    control={
                      <Checkbox
                        color="primary"
                        size="small"
                        onClick={() => handleReason(index)}
                        classes={{ root: classes.DiscontinuePrimeModal__reasons__Checkbox }}
                      />
                    }
                    label={reason.title}
                    classes={{
                      root: classes.DiscontinuePrimeModal__reasons__FormControl,
                      label: classes.DiscontinuePrimeModal__reasons__FormControl__label,
                    }}
                  />
                ))}
              </div>
            )}
            {reasons[reasons.length - 1].checked && (
              <TextareaAutosize
                minRows={!isSmScreen ? 2 : 1}
                placeholder="Please describe..."
                onChange={hendleOthers}
                value={other}
                className={classes.DiscontinuePrimeModal__textarea}
              />
            )}
            <div className={classes.DiscontinuePrimeModal__subtitle__rating}>
              How likely are you to recommend Oscer Prime to friends?
            </div>
            <div className={classes.DiscontinuePrimeModal__rating}>
              <div className={classes.DiscontinuePrimeModal__rating__nums}>
                {range(11).map((num) => (
                  <div
                    key={num}
                    className={cx(classes.DiscontinuePrimeModal__rating__num, {
                      [classes.DiscontinuePrimeModal__rating__num__active]: rating === num,
                    })}
                    aria-hidden="true"
                    onClick={() => handleRating(num)}
                  >
                    {num}
                  </div>
                ))}
              </div>
              <div className={classes.DiscontinuePrimeModal__rating__desc}>
                <span>Not at all</span>
                <span>Extremely likely</span>
              </div>
            </div>
          </div>
          <div className={classes.DiscontinuePrimeModal__btns}>
            <Button
              variant="contained"
              classes={{
                root: cx(classes.DiscontinuePrimeModal__btn__feedback, {
                  [classes.DiscontinuePrimeModal__btn__feedback__active]: checkAllReasons(),
                }),
              }}
              disabled={!checkAllReasons()}
              onClick={handleSubmitFeedback}
            >
              Submit Feedback
            </Button>
            <Button
              variant="contained"
              color="primary"
              classes={{ root: classes.DiscontinuePrimeModal__btn__renew }}
              component={Link}
              to={{
                pathname: '/upgrade',
                state: { from: location.pathname, search: location.search },
              }}
            >
              Renew Prime
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

DiscontinuePrimeModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
};

export default DiscontinuePrimeModal;
