import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import Tour from 'reactour';
import { connect } from 'react-redux';
import { setHintRevealed, getHints } from '../../../actions/cases';
import SubmitCaseModal from '../Modals/SubmitCaseModal';
import emptyObject from '../../../helpers/emptyObject';
import ScorePanel from './ScorePanel';
import ScorePanelMobile from './ScorePanelMobile';
import HintsPanelMobile from './HintsPanelMobile';
import ProfilePanel from './ProfilePanel';
import ProfilePanelMobile from './ProfilePanelMobile';
import HintsPanel from './HintsPanel';
import useUserTour from '../../../hook/useUserTour';
import userTourSteps from './UserTourSteps/UserTourSteps';
import { LOADING_MESSAGE } from '../../../constants';
import useStyles from './style';
import LinearProgress from 'components/Progress/LinearProgress';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { postTrackEvent } from '../../../actions/tracking';
import NavigateNextArrow from '../../../assets/home/navigate-arrow.svg';

const BEGIN_CASE_MODAL = 'BEGIN_CASE_MODAL';
const MINUTES = 8;
const SECONDS = 60;
const LIMIT = MINUTES * SECONDS;

const RightSideBar = (props) => {
  const classes = useStyles();
  const {
    panelHidden,
    modalState,
    currentCase,
    currentScore,
    totalQuestions,
    markStreak,
    exitCase,
    user,
    isPrime,
    proceed,
    stopSession,
    setTimerComplete,
    bedsideManner,
    sessionId,
    handleSubmit,
  } = props;
  const [isToggle, setToggle] = useState(false);

  // Mobile score and hints panel
  const [panelExpanded, setPanelExpanded] = useState(false);

  // User Tour, useUserTour is to find if state has be saved in localstorage
  const [isTourOpen, setTourOpen, handleTourClosed] = useUserTour('case_submit', 'osce_history_flag');

  useEffect(() => {
    if (!emptyObject(currentCase)) {
      props.getHints(currentCase.id).catch((err) => {
        if (err?.response?.status === 404) {
          console.log('Sorry, the case not found.');
        } else {
          console.log('Sorry, we have something wrong with oscer, please try again latter');
        }
      });
    }
  }, [currentCase]);

  // Circular Progress
  const [progress, setProgress] = useState(0);
  const [second, setSecond] = useState(LIMIT);
  const [lock, setLock] = useState(false);
  const progressTimer = useRef(null);

  useEffect(() => {
    // update time when user refresh
    if (props.startTime) {
      const currentDuration = new Date() - new Date(props.startTime);
      if (currentDuration > 0) {
        const currentSeconds = Math.floor(currentDuration / 1000);
        setSecond(currentSeconds >= LIMIT ? 0 : LIMIT - Math.floor(currentDuration / 1000));
        if (!lock) {
          setProgress(currentSeconds >= LIMIT ? 100 : (currentSeconds % 60) * (100 / 60));
        }
      } else {
        setSecond(0);
        setProgress(100);
      }
    }

    // updates 10 times every second
    progressTimer.current = setInterval(() => {
      if (props.startTime) {
        // setProgress((prevProgress) => (prevProgress >= 100 ? (prevProgress - 100 + 1 / 6) : prevProgress + 1 / 6));
        setSecond((prevSecond) => (prevSecond <= 0 ? 0 : prevSecond - 1 / 10));
      }
    }, 100);

    return () => {
      clearInterval(progressTimer.current);
    };
  }, [props.startTime]);

  useEffect(() => {
    if (second === 2) {
      setLock(true);
      clearInterval(progressTimer.current);
      setSecond(0);
      setProgress(100);
    } else if (second <= 0) {
      setProgress(100);
      setSecond(0);
    } else {
      if (!lock) {
        setProgress((prevProgress) => (prevProgress >= 100 ? prevProgress - 100 + 1 / 6 : prevProgress + 1 / 6));
      }
    }
  }, [second]);

  // ! Turn off now
  // Trigger user tour when message list is 4 and last message is not loading
  // useEffect(() => {
  //   if (
  //     props.messageLists.length >= 2 &&
  //     props.messageLists[props.messageLists.length - 1]?.type !== LOADING_MESSAGE &&
  //     props.onboardingTour?.osce_history_flag === 'TODO'
  //   ) {
  //     setTourOpen(true);
  //   }
  // }, [props.messageLists]);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setPanelExpanded(isExpanded ? panel : false);
  };

  const submitToggle = () => {
    setToggle((toggle) => !toggle);
  };

  // Set user tour close
  const handleUserTourClose = () => {
    setTourOpen(false);
  };

  const handleTourSubmit = () => {
    stopSession().then(() => {
      proceed();
    });
  };

  const togglePanel = () => {
    props.setPanelHidden(!props.panelHidden);
    props.postTrackEvent({
      event: 'Collapse toggle',
      payload: {
        'Panel Opened': props.panelHidden,
      },
    });
  };

  return (
    <>
      <Tour
        steps={userTourSteps(handleTourSubmit, handleUserTourClose)}
        isOpen={isTourOpen}
        onRequestClose={() => handleTourClosed('osce_history_flag')}
        closeWithMask={false}
        scrollDuration={200}
        showNavigation={false}
        showNumber={false}
        showButtons={false}
        showCloseButton={false}
        disableInteraction
        rounded={8}
        maskClassName={classes.RightSideBar__tour__mask}
      />
      <SubmitCaseModal
        isToggle={isToggle}
        handleSubmit={handleSubmit}
        toggle={submitToggle}
        stopSession={stopSession}
        proceed={proceed}
        setTimerComplete={setTimerComplete}
        system={currentCase.system}
        presentation={currentCase.presentation}
      />
      {panelHidden && (
        <div
          className={classes.ProfilePanel__CollapseButton}
          onClick={() => {
            togglePanel();
          }}
        >
          <img
            src={NavigateNextArrow}
            height={30}
            width={30}
            alt="next"
            style={{ transform: 'rotate(180deg)', marginLeft: '10px' }}
          />
        </div>
      )}
      <div className={classes.RightSideBar} style={{ transform: props.panelHidden && 'translateX(600px)' }}>
        <ProfilePanel
          togglePanel={togglePanel}
          modalState={modalState}
          user={user}
          isPrime={isPrime}
          exitCase={exitCase}
          currentCase={currentCase}
          onboardingTour={props.onboardingTour}
          progress={progress}
          second={second}
        />
        <ScorePanel
          currentCase={currentCase}
          currentScore={currentScore}
          markStreak={markStreak}
          QuestionsAsked={totalQuestions}
          bedsideManner={bedsideManner}
          messages={props.messages}
        />
        <div>
          <HintsPanel />
        </div>
      </div>

      <div className={classes.RightSideBar__mobile__timer}>
        <LinearProgress
          score={second === 0 ? 60 : (LIMIT - second) % 60}
          maxScore={60}
          minScore={0}
          styles={{
            root: classes.ScorePanel__progress,
            linearProgress__barColorPrimary: cx(
              classes.RightSideBar__progress__colorPrimary__2,
              { [classes.RightSideBar__progress__color__1]: second === 0 },
              { [classes.RightSideBar__progress__color__2]: second <= 120 && second > 0 },
              { [classes.RightSideBar__progress__color__3]: second <= 240 && second > 120 },
              { [classes.RightSideBar__progress__color__4]: second <= 360 && second > 240 },
              { [classes.RightSideBar__progress__color__5]: second > 360 }
            ),
            linearProgress__colorPrimary: classes.ScorePanel__progress__colorPrimary__1,
          }}
        />
      </div>
      <div className={classes.RightSideBar__mobile}>
        <ProfilePanelMobile currentCase={currentCase} />
        <div className={classes.RightSideBar__mobile__scoreHints}>
          <ScorePanelMobile
            currentCase={currentCase}
            currentScore={currentScore}
            markStreak={markStreak}
            QuestionsAsked={totalQuestions}
            bedsideManner={bedsideManner}
            accordionExpanded={panelExpanded === 'score'}
            accordionOnChange={handlePanelChange('score')}
          />
          <HintsPanelMobile
            accordionExpanded={panelExpanded === 'hints'}
            accordionOnChange={handlePanelChange('hints')}
          />
        </div>
        <div className={classes.RightSideBar__mobile__submit}>
          <div className={classes.RightSideBar__vertical__separator}></div>
          <Button
            variant="contained"
            color="primary"
            onClick={submitToggle}
            classes={{ root: classes.RightSideBar__mobile__submit__btn }}
            data-tut="reactour__submit_case"
            disabled={!sessionId}
          >
            SUBMIT
          </Button>
          <div />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentScore: state.cases.currentScore,
  bedsideManner: state.cases.bedsideManner,
  currentCase: state.cases.currentCase,
  totalQuestions: state.cases.totalQuestions,
  usefulQuestions: state.cases.usefulQuestions,
  markStreak: state.cases.markStreak,
  user: state.auth.user,
  isPrime: state.auth.user.prime_status,
  token: state.auth.token,
  messages: state.messages.messages,
  getHints: PropTypes.func,
  messageLists: state.messages.messages,
  onboardingTour: state.auth.user.profile.user_tour_flags,
  messages: state.messages.messages,
  startTime: state.cases.start,
});

const mapDispatchToProps = (dispatch) => ({
  setHintRevealed: (hintRevealed) => dispatch(setHintRevealed(hintRevealed)),
  postTrackEvent: (event) => dispatch(postTrackEvent(event)),
  getHints: (caseId) => dispatch(getHints(caseId)),
});

RightSideBar.propTypes = {
  currentCase: PropTypes.object.isRequired,
  currentScore: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  markStreak: PropTypes.number.isRequired,
  exitCase: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  bedsideManner: PropTypes.number,
  isPrime: PropTypes.bool,
  proceed: PropTypes.func,
  stopSession: PropTypes.func,
  setTimerComplete: PropTypes.func,
  messages: PropTypes.array,
  getHints: PropTypes.func,
  messageLists: PropTypes.array,
  onboardingTour: PropTypes.object,
  sessionId: PropTypes.string,
  startTime: PropTypes.bool || PropTypes.string,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RightSideBar));
