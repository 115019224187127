import { makeStyles } from '@material-ui/core/styles';
import HomeHeroBG from '../../../assets/home/section-hero.svg';

const useStyles = makeStyles((theme) => ({
  InstitutionHero__container__outer: {
    background: `url(${HomeHeroBG})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: 140,
    [theme.breakpoints.down('xs')]: {
      marginTop: 39,
    },
  },
  InstitutionHero__tag: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.6,
  },
  InstitutionHero__container__inner: {
    paddingBottom: 100,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
    },
  },
  InstitutionHero__title: {
    textAlign: 'center',
    marginBottom: 60,
  },
  InstitutionHero__video__player: {
    height: 320,
    width: 640,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },
  InstitutionHero__overlay__left: {
    maxWidth: 208,
    maxHeight: 277,
    position: 'absolute',
    left: 0,
    bottom: -80,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
