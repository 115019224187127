import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Button } from '@material-ui/core';
import { isMobileSafari, isIOS, isChrome } from 'react-device-detect';
import { ReactMic } from '@cleandersonlobo/react-mic';
import MicButton from '../../../../components/buttons/MicButton';
import { OUTGOING_MESSAGE } from '../../../../constants';
import { trackEvent } from '../../../../service/analytics';
import useRecording from '../../../../hook/useRecording';
import { capitalizeFirstLetter } from '../../../../helpers/toTitleCase';
import useStyles from './style';

/**
 * @desc Input area includes text input, text recognition and submit button
 * @param {} props
 * - createMessage is an action to create message which requires type, message and date as parameter
 * - requestResponse is an action to request response from patient, responding to question
 * - timerComplete is a state in redux which is a boolean to detect whether case is timeout
 */
const AUDIO_BLOCKED_MODAL = 'AUDIO_BLOCKED_MODAL';
const AUDIO_ISSUE_MODAL = 'AUDIO_ISSUE_MODAL';

const MessageInput = (props) => {
  const {
    createMessage,
    requestResponse,
    timerComplete,
    userId,
    sessionId,
    beginModalToggle,
    audioBlockedToggle,
    messages,
  } = props;
  const classes = useStyles();

  const [isSubmitted, setSubmitted] = useState(false);
  const [text, setText] = useState('');
  const [audioIssue, setAudioIssue] = useState(false);

  const [isRecording, startRecording, stopRecording, onStop, message, tanscripting] = useRecording(
    sessionId,
    props.match.params.caseId,
    'CHAT',
    'MessageInput__inputField__spm'
  );

  const inputRef = useRef(null);

  // If recording is stop, add the message (from voice) to the text
  useEffect(() => {
    if (message && !isRecording) {
      if (text) {
        setText(`${text} ${capitalizeFirstLetter(message)}`);
      } else {
        setText(`${capitalizeFirstLetter(message)}`);
      }
    }
  }, [isRecording, tanscripting]);

  const handleTextInput = (e) => {
    const textValue = e.target.value;
    if (textValue === '' || textValue.trim()) {
      setText(textValue);
    }
  };

  const checkAudioPermissions = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        // no issue with microphone
        setAudioIssue(false);
      })
      .catch((err) => {
        setAudioIssue(true);
        if (isRecording) {
          stopRecording();
        }
        if (String(err) === 'NotAllowedError: Permission denied') {
          // error by permission denied
          audioBlockedToggle(AUDIO_BLOCKED_MODAL);
        } else {
          // other error, no input stream etc
          audioBlockedToggle(AUDIO_ISSUE_MODAL);
        }
      });
  };

  useEffect(() => {
    // If begin modal is opened, do not auto focus
    if (inputRef.current && !beginModalToggle) {
      inputRef.current.focus();
    }
  }, [isRecording]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // If user is recording, stop them first
    if (isRecording) {
      stopRecording();
      if (!!message && !isSubmitted) {
        setSubmitted(true);
        createMessage(OUTGOING_MESSAGE, `${text} ${capitalizeFirstLetter(message)}`, new Date().toString());
        requestResponse(message)
          .then(() => {})
          .catch(() => {})
          .then(() => {
            setText('');
            setSubmitted(false);
          });
      }
    } else if (!!text && !isSubmitted) {
      setSubmitted(true);
      setText('');
      createMessage(OUTGOING_MESSAGE, text, new Date().toString());
      requestResponse(text)
        .then(() => {})
        .catch(() => {})
        .then(() => {
          setSubmitted(false);
        });
    }
  };

  // Textarea is an uncontrolled element, so we need to handle enter key manually to submit
  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleAudio = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      await checkAudioPermissions();
      if (!audioIssue) {
        startRecording();
        trackEvent({
          GAAction: 'Audio Input in Case Interface',
          FBAction: 'Audio Input in Case Interface',
          category: 'Audio Input',
          label: `User ${userId} uses audio input in case interface`,
        });
      }
    }
  };

  return (
    <div
      className={classes.MessageInput}
      data-tut="reactour__time_to_chat"
      style={{ width: props.panelHidden && '96%' }}
    >
      {/* do not record on mobile safari, it blocks voice recognition */}
      {sessionId && !isMobileSafari && (
        <ReactMic
          record={isRecording}
          onStop={onStop}
          mimeType="audio/wav"
          strokeColor=" #ffffff00"
          backgroundColor=" #ffffff00"
          className={classes.MessageInput__reat__mic}
        />
      )}
      <form
        onSubmit={handleSubmit}
        className={cx(classes.MessageInput__form, {
          [classes.MessageInput__form__recommendation]: messages.length === 1,
        })}
      >
        {/* do not allowed chrome on ios use recording or voice recognition */}
        {!(isIOS && isChrome) && (
          <div className={classes.MessageInput__mic__wrapper}>
            <MicButton
              listening={isRecording && !audioIssue}
              handleAudio={handleAudio}
              tanscripting={tanscripting && !audioIssue}
            />
          </div>
        )}
        <TextareaAutosize
          id="MessageInput__inputField__spm"
          aria-label="MessageInput__input"
          minRows={1}
          maxRows={10}
          placeholder="Chat to the patient - type or use your voice"
          value={isRecording ? `${text} ${message}` : text}
          className={classes.MessageInput__textArea}
          ref={inputRef}
          onChange={handleTextInput}
          // disabled={timerComplete || listening}
          disabled={timerComplete}
          spellCheck
          required
          autoComplete="off"
          onKeyDown={onEnterPress}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          classes={{ root: classes.MessageInput__btn__send }}
          cy-id="single-player-send"
        >
          <span className={classes.MessageInput__send__text}>Send</span>
          <ArrowUpwardIcon className={classes.MessageInput__send__text__mobile} fontSize="inherit" />
        </Button>
      </form>
    </div>
  );
};

MessageInput.propTypes = {
  createMessage: PropTypes.func,
  requestResponse: PropTypes.func,
  timerComplete: PropTypes.bool,
  match: PropTypes.object,
  userId: PropTypes.number,
  sessionId: PropTypes.string,
  beginModalToggle: PropTypes.bool,
  audioBlockedToggle: PropTypes.func,
  messages: PropTypes.array,
};

export default withRouter(MessageInput);
