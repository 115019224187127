/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Typography } from '@material-ui/core';
import ChatDeskTop from 'assets/home/oscer-gif-chat-desktop.webm';
import Chat from 'assets/home/oscer-gif_desktop_large.mp4';
import { useWidth } from 'helpers/customHooks';
import HomeContainer from 'components/Container/HomeContainer';
import useStyles from './style';
// import MockChat from './MockChat';

const UntiringPatients = () => {
  const classes = useStyles();
  const screenSize = useWidth();
  return (
    <HomeContainer outerStyles={classes.UntiringPatients} innerStyles={classes.UntiringPatients__container}>
      <div className={classes.UntiringPatients__left}>
        <Typography variant="h2" gutterBottom>
          Unlimited patient access
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Chat to a variety of patients with different presentations. Or do the same case, just like the first time,
          every time…to be honest, they're just happy to chat and help you smash those OSCEs!
        </Typography>
      </div>
      <div className={classes.UntiringPatients__right}>
        <video width="100%" autoPlay loop muted playsInline>
          <source src={Chat} type="video/mp4" />
        </video>
      </div>
    </HomeContainer>
  );
};

export default UntiringPatients;
