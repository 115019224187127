import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  SpeakToTeam: {},
  SpeakToTeam__continer__outer: {
    backgroundColor: theme.palette.inherit.main,
    overflow: 'hidden',
  },
  SpeakToTeam__title: {
    marginBottom: 40,
    textAlign: 'center',
  },
  SpeakToTeam__subtitle: {
    marginBottom: 40,
    textAlign: 'center',
  },
  SpeakToTeam__institutions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 60,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    },
  },
  SpeakToTeam__institutions__wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '15px 30px',
    width: 260,
    [theme.breakpoints.down('xs')]: {
      margin: '15px',
    },
  },
  SpeakToTeam__institutions__text: {
    color: theme.palette.text.main,
    fontSize: 18,
    lineHeight: 1.44,
    textAlign: 'center',
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
    },
  },
  SpeakToTeam__list: {
    marginBottom: 0,
    '& li': {
      marginBottom: 20,
      fontSize: 18,
      color: theme.palette.text.main,
      '&::marker': {
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: 10,
        fontSize: 16,
      },
    },
  },
  SpeakToTeam__btn: {
    marginBottom: 40,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: '#56bfea',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      width: '100%',
    },
  },
  SpeakToTeam__overlay__left: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: 280,
    maxHeight: 309,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  SpeakToTeam__overlay__right: {
    position: 'absolute',
    right: -160,
    top: 200,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 247,
      maxHeight: 296,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  SpeakToTeam__img: {
    maxWidth: '90%',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '130%',
    },
  },
}));

export default useStyles;
