import { makeStyles } from '@material-ui/core/styles';
import HomeHeroBG from '../../../assets/home/section-hero.svg';

const useStyles = makeStyles((theme) => ({
  TechnologyHero__container__outer: {
    background: `url(${HomeHeroBG})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginTop: 140,
    [theme.breakpoints.down('xs')]: {
      marginTop: 39,
      overflow: 'hidden',
    },
  },
  TechnologyHero__container__inner: {
    paddingBottom: 100,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
    },
  },
  TechnologyHero__tag: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.6,
  },
  TechnologyHero__title: {
    textAlign: 'center',
    marginBottom: 60,
    maxWidth: 1100,
  },
  TechnologyHero__overlay__left: {
    maxWidth: 140,
    maxHeight: 296,
    position: 'absolute',
    left: 0,
    bottom: 300,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 100,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  TechnologyHero__overlay__right: {
    maxWidth: 150,
    position: 'absolute',
    right: 0,
    bottom: -250,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  TechnologyHero__img: {
    maxWidth: '80%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '140%',
      width: '140%',
    },
  },
}));

export default useStyles;
