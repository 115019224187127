import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  StripeTextField: {
    flex: 1,
    '& .MuiInputLabel-root': {
      color: theme.palette.text.main,
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.primary.main,
    },
    '& .MuiInputBase-root': {
      '&:hover:before': {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      marginRight: 5,
    },
  },
}));

export default useStyles;
