const orderCases = (cases, type) => {
  if (type === 'free') {
    return orderCasesByFree(cases);
  }
  if (type === 'completed') {
    for (let collection of cases) {
      collection.cases = orderCasesByCompleted(collection.cases);
    }
    return cases;
  }
  return orderCasesByCompleted(cases);
};

const orderCasesByFree = (cases) => {
  let tempPrime = [];
  let tempFree = [];
  for (let caseObj of cases) {
    if (!caseObj.user_stats?.length || (caseObj.user_stats?.length > 0 && caseObj.user_stats[0].locked)) {
      if (!checkExist(caseObj, tempPrime)) {
        tempPrime.push(caseObj);
      }
    } else {
      if (!checkExist(caseObj, tempFree)) {
        tempFree.push(caseObj);
      }
    }
  }
  return tempFree.concat(tempPrime);
};

const orderCasesByCompleted = (cases) => {
  let tempCompleted = [];
  let tempPartial = [];
  let tempElse = [];
  for (let caseObj of cases) {
    // case complete
    if (
      (caseObj.user_stats?.length > 0 &&
        caseObj.user_stats[0].spm_best_stars > 0 &&
        caseObj.user_stats[0].diagnosis_ddx_percentile > 0) ||
      (caseObj.user_stats?.length > 0 &&
        caseObj.user_stats[0].spm_best_stars > 0 &&
        !caseObj.participation_for_diagnosis_mode)
    ) {
      if (!checkExist(caseObj, tempCompleted)) {
        tempCompleted.push(caseObj);
      }
      // partial
    } else if (
      caseObj.user_stats?.length > 0 &&
      (caseObj.user_stats[0].spm_best_stars > 0 || caseObj.user_stats[0].diagnosis_ddx_percentile > 0)
    ) {
      if (!checkExist(caseObj, tempPartial)) {
        tempPartial.push(caseObj);
      }
    } else {
      if (!checkExist(caseObj, tempElse)) {
        tempElse.push(caseObj);
      }
    }
  }
  return tempElse.concat(tempPartial).concat(tempCompleted);
};

const checkExist = (caseToCheck, cases) => {
  for (let caseObj of cases) {
    if (caseToCheck.id === caseObj) {
      return true;
    }
  }
  return false;
};

export default orderCases;
