import React from 'react';
import PropTypes from 'prop-types';
import { withResizeDetector } from 'react-resize-detector';
import { useWidth } from '../../../helpers/customHooks';
import teamEliza from '../../../assets/home/Teams/about-team-eliza-materia.png';
import teamEllen from '../../../assets/home/Teams/about-team-ellen-foster.png';
import teamJames from '../../../assets/home/Teams/about-team-james-marsh.png';
import teamJoin from '../../../assets/home/Teams/about-team-join.png';
import teamKaylyn from '../../../assets/home/Teams/about-team-kaylyn-sorrentino.png';
import teamKerry from '../../../assets/home/Teams/about-team-kerry-zhang.png';
import teamMatt from '../../../assets/home/Teams/about-team-matt-lanki.png';
import teamSteve from '../../../assets/home/Teams/about-team-steve-chan.png';
import teamTim from '../../../assets/home/Teams/about-team-tim-lai.png';
import teamTom from '../../../assets/home/Teams/about-team-tom-freeman.png';
import teamTomKelly from '../../../assets/home/Teams/about-team-tom-kelly.png';
import teamWaleed from '../../../assets/home/Teams/about-team-waleed-mussa.png';
import teamWill from '../../../assets/home/Teams/about-team-will-crozier.png';
import teamYu from '../../../assets/home/Teams/about-team-yu-liu.png';
import teamAda from '../../../assets/home/Teams/about-team-ada-dong.png';
import teamJackie from '../../../assets/home/Teams/about-team-jackie-rabec.png';
import teamYi from '../../../assets/home/Teams/about-team-yi-han.png';
import teamKate from '../../../assets/home/Teams/about-team-kate-bennett.png';
import teamLu from '../../../assets/home/Teams/about-team-lu-truong.png';
import teamMo from '../../../assets/home/Teams/about-team-mo-ismail.png';

import TeamCard from '../../../components/Card/TeamCard';
import useStyles from './style';

const Teams = (props) => {
  const { width } = props;
  const classes = useStyles();
  const screenSize = useWidth();

  const teams = [
    { name: 'Dr. Tom Kelly', title: 'CEO & Founder', image: teamTomKelly, color: '#4a84ff' },
    { name: 'Waleed Mussa', title: 'CFO', image: teamWaleed, color: '#9dc9f6' },
    { name: 'Yu Liu', title: 'CTO', image: teamYu, color: '#fba799' },
    { name: 'Eliza Materia', title: 'Head of Design & UX', image: teamEliza, color: '#a99df6' },
    { name: 'Dr. Tim Lai', title: 'Head of Content', image: teamTim, color: '#fbcf99' },
    { name: 'Dr. Jackie Rabec', title: 'Head of Product', image: teamJackie, color: '#FBAEE9' },
    { name: 'Dr. Ada Dong', title: 'Head of Growth', image: teamAda, color: '#97EB8A' },
    { name: 'Kate Bennett', title: 'Senior Product Designer', image: teamKate, color: '#a99df6' },
    { name: 'Dr. James Marsh', title: 'Clinical Informatician', image: teamJames, color: '#fbcf99' },
    { name: 'Yi Han', title: 'Senior Machine Learning Engineer', image: teamYi, color: '#fba799' },
    { name: 'Dr. Tom Freeman', title: 'Clinical Informatician', image: teamTom, color: '#fbcf99' },
    { name: 'Kerry Zhang', title: 'Senior Software Engineer', image: teamKerry, color: '#fba799' },
    { name: 'Dr. Will Crozier', title: 'Clinical Informatician', image: teamWill, color: '#fbcf99' },
    { name: 'Steve Chan', title: 'Software Engineer', image: teamSteve, color: '#fba799' },
    { name: 'Kaylyn Sorrentino', title: 'Senior Account Manager', image: teamKaylyn, color: '#f4e3ad' },
    { name: 'Lu Truong', title: 'Junior Digital Designer', image: teamLu, color: '#a99df6' },
    { name: 'Dr. Ellen Foster', title: 'Clinical Informatician', image: teamEllen, color: '#fbcf99' },
    { name: 'Mohamed Ismail', title: 'Education Product Manager', image: teamMo, color: '#FBAEE9' },
  ];

  const cardPlaceHolder = () => {
    let cardWidth = 210;
    if (screenSize === 'xs') {
      cardWidth = 160;
    }
    const cardsPerRow = Math.floor(width / cardWidth);
    const cardsLastRow = (teams.length + 1) % cardsPerRow;
    const placeholder = [];
    if (cardsLastRow === 0) {
      return placeholder;
    }
    for (let i = 0; i < cardsPerRow - cardsLastRow; i += 1) {
      placeholder.push(i);
    }
    return placeholder;
  };

  cardPlaceHolder();

  return (
    <div className={classes.Teams__teams}>
      {teams.map((member) => (
        <TeamCard key={member.name} title={member.title} color={member.color} name={member.name} image={member.image} />
      ))}
      <TeamCard
        title="Join The Team"
        color="#4a84ff"
        name="What About You?"
        image={teamJoin}
        isJoin
        to="careers#positions"
      />
      {cardPlaceHolder().map((placeholder) => (
        <TeamCard
          key={placeholder}
          title="Join The Team"
          color="#4a84ff"
          name="What About You?"
          image={teamJoin}
          isJoin
          to="careers#positions"
          styles={{ root: classes.Teams__hidden }}
        />
      ))}
    </div>
  );
};

Teams.propTypes = {};

export default withResizeDetector(Teams);
