import React from 'react';
import PropTypes from 'prop-types';
import { FaForward } from 'react-icons/fa';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { Modal } from '../../../components/common';
import PresentationImg from '../../../components/icons/PresentationImg';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import { PRESENTATION_ICON_XLARGE, SYSTEM_COLORS } from '../../../constants';
import './style.scss';

/**
 * @desc Modal for showing that user has blocked audio permissions
 * @param { * } props
 * - isToggle Boolean, true means show modal
 * - toggle function which change modal status to '' (hide modal)
 * - proceed function which shows Follow Questions
 * - stopSession function which will call stop session api
 */
const AudioBlockedModal = ({ isToggle, toggle, proceed, stopSession, system }) => (
  <div>
    <Modal isToggle={isToggle} toggle={toggle} centered closable={false}>
      <div className={classnames('case__modal')}>
        <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
          <div className="case__modal">
            <div className="audio_title">
              <h5>Whoops! It seems like you have blocked access to your microphone in your browser</h5>
            </div>
            <div className={'audio_subtitle-text'}>
              <p>Please change your browser permissions to use this feature.</p>
            </div>

            <div className={'case__modal__okay'}>
              <ConfirmButton
                className={classnames('btn-learn', 'btn-round')}
                onClick={() => {
                  toggle();
                }}
                style={{ textTransform: 'none' }}
              >
                Ok, got it!
              </ConfirmButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
);

AudioBlockedModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  proceed: PropTypes.func,
  stopSession: PropTypes.func,
  system: PropTypes.string,
};

export default AudioBlockedModal;
