import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Button, TextareaAutosize, CircularProgress } from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { MdSentimentNeutral } from 'react-icons/md';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { postFeedback } from '../../../actions/dashboard';
import imageFeedback from '../../../assets/account/oscer-review-img.svg';
import Tooltip from '../../Tooltip';
import { Modal } from '../../common';
import useStyles from './style';

const FeedbackModal = (props) => {
  const classes = useStyles();
  const { isToggle, toggle, img = imageFeedback } = props;
  const theme = useTheme();

  /**
   * 0 -> not selected
   * 1 -> sad
   * 2 -> normal
   * 3 -> great
   */
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setProcessing] = useState(false);

  const handleRating = (emoji) => {
    if (rating === emoji) {
      setRating(0);
    } else {
      setRating(emoji);
    }
  };

  const handleSubmit = () => {
    setProcessing(true);
    props
      .postFeedback(
        props.finishedCase.case,
        feedback,
        rating,
        props.finishedCase.session_type,
        props.finishedCase.sessionId
      )
      .then((res) => toggle())
      .catch((err) => {
        if (typeof err === 'string') {
          setError(err);
        } else {
          setError('Sorry, we are currently having some issues, please try again latter');
        }
        Sentry.captureException(err, {
          tags: {
            section: 'feedback',
          },
        });
      })
      .then(() => setProcessing(false));
  };

  const handleFeedback = (e) => {
    setFeedback(e.target.value);
  };

  return (
    <Modal isToggle={isToggle} toggle={toggle} centered zIndex={9999}>
      <div className={classes.FeedbackModal}>
        <Button color="primary" onClick={toggle} classes={{ root: classes.FeedbackModal__exit }}>
          Exit
        </Button>
        <div className={classes.FeedbackModal__content}>
          <img src={img} alt="image_feedback" className={classes.FeedbackModal__image} />
          <div className={classes.FeedbackModal__title}>We’d love some case feedback!</div>
          <div
            className={classes.FeedbackModal__subtitle}
          >{`How would you rate the ${props.finishedCase.patient_name} case  you just completed?`}</div>
          <div className={classes.FeedbackModal__faces}>
            <Tooltip
              title="BAD!"
              arrow
              styles={{ tooltip: classes.FeedbackModal__emoji__tooltip, arrow: classes.FeedbackModal__emoji__arrow }}
            >
              <SentimentVeryDissatisfiedIcon
                htmlColor={rating === 1 ? theme.palette.primary.main : '#e0deda'}
                className={classes.FeedbackModal__facces__emoji}
                onClick={() => handleRating(1)}
              />
            </Tooltip>
            <Tooltip
              title=" OK "
              arrow
              placement="bottom"
              styles={{ tooltip: classes.FeedbackModal__emoji__tooltip__normal }}
            >
              <div>
                <MdSentimentNeutral
                  color={rating === 2 ? theme.palette.primary.main : '#e0deda'}
                  className={classes.FeedbackModal__facces__emoji}
                  onClick={() => handleRating(2)}
                />
              </div>
            </Tooltip>
            <Tooltip title="Great!" arrow styles={{ tooltip: classes.FeedbackModal__emoji__tooltip__normal }}>
              <SentimentVerySatisfiedIcon
                htmlColor={rating === 3 ? theme.palette.primary.main : '#e0deda'}
                className={classes.FeedbackModal__facces__emoji}
                onClick={() => handleRating(3)}
              />
            </Tooltip>
          </div>
          <div className={classes.FeedbackModal__text}>What did you like or dislike</div>
          <TextareaAutosize
            minRows={3}
            placeholder="Write feedback here…"
            className={classes.FeedbackModal__textarea}
            onChange={handleFeedback}
            value={feedback}
          />
          <div className={classes.FeedbackModal__wrapper}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/dashboard/home"
              disabled={rating === 0 || isProcessing}
              classes={{ contained: classes.FeedbackModal__btn }}
              onClick={handleSubmit}
            >
              {isProcessing ? <CircularProgress color="primary" size={16} /> : 'Submit'}
            </Button>
          </div>
          <div className={classes.FeedbackModal__error}>{error}</div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  finishedCase: state.cases.finishedCase,
});

FeedbackModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  img: PropTypes.string,
  finishedCase: PropTypes.object,
  postFeedback: PropTypes.func,
};

export default connect(mapStateToProps, { postFeedback })(FeedbackModal);
