import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  PaymentSuccessModal: {
    width: 900,
    height: 580,
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    position: 'relative',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: '70vh',
    },
  },
  PaymentSuccessModal__content: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  PaymentSuccessModal__crown: {
    position: 'absolute',
    left: 40,
    top: -160,
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 200,
      left: 20,
      top: -100,
    },
  },
  PaymentSuccessModal__main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '60%',
    padding: '100px 70px',
    color: theme.palette.text.main,
    [theme.breakpoints.down('sm')]: {
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 20,
    },
  },
  PaymentSuccessModal__title: {
    fontSize: 44,
    textAlign: 'center',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 38,
      marginBottom: 20,
    },
  },
  PaymentSuccessModal__subtitle: {
    fontSize: 30,
    lineHeight: 1.33,
    textAlign: 'center',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
      marginBottom: 20,
    },
  },
  PaymentSuccessModal__text: {
    fontSize: 20,
    lineHeight: 1.4,
    textAlign: 'center',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  PaymentSuccessModal__btns: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  PaymentSuccessModal__btn__contained: {
    marginBottom: 20,
    padding: '13px 80px',
  },
  PaymentSuccessModal__btns__cancel: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textAlign: 'center',
    cursor: 'pointer',
  },
  PaymentSuccessModal__right: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(131, 208, 240, 0.1)',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  PaymentSuccessModal__right__icon: {
    height: 290,
    width: 241,
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
}));

export default useStyles;
