import { makeStyles } from '@material-ui/core/styles';
import { MdStayPrimaryLandscape } from 'react-icons/md';

const useStyles = makeStyles((theme) => ({
  MainboardFilter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    // justifyContent: 'space-between',
    // marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      maxWidth: 50,
    },
  },
  MainboardFilter__main: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      maxWidth: 50,
    },
  },
  MainboardFilter__clearBtn: {
    borderRadius: '1rem',
    backgroundColor: '#ecf2ff',
    color: theme.palette.text.main,
    padding: '0.4rem 1rem',
    fontSize: 14.4,
    height: 29,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  MainboardFilter__all__clear: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  MainboardFilter__clear__root: {
    fontSize: 14,
    color: theme.palette.text.main,
    textDecoration: 'underline',
  },
  MainboardFilter__chip__icon: {
    maxHeight: 16,
    maxWidth: 16,
    marginRight: 8,
  },
  FilterWrapper: {
    marginLeft: 4,
  },
  SingleFilter__drawer: {
    maxHeight: '85vh',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '16px 16px 0 0',
  },
  SingleFilter__mobileModal: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  SingleFilter__drawer__wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  SingleFilter__drawer__header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px',
  },
  SingleFilter__drawer__cross: {
    alignSelf: 'flex-end',
    marginRight: '5px',
    marginTop: '5px',
  },
  SingleFilter__drawer__filter: {
    alignSelf: 'flex-start',
  },
  SingleFilter__drawer__title: {
    textAlign: 'center',
  },
  SingleFilter__drawer__subtitle: {
    fontSize: 15,
    margin: '0 0 10px 15px',
  },
  SingleFilter__drawer__border: {
    border: 'solid 1px rgba(18, 19, 19, 0.05)',
  },
  SingleFilter__drawer__main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 150px',
    height: '100%',
    marginTop: '-15px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 100px 15px 100px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 15px 20px',
    },
  },
  SingleFilter__drawer__chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 24,
  },
  SingleFilter__drawer__btns: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  SingleFilter__drawer__btn__clear: {
    border: `solid 2px ${theme.palette.primary.main}`,
    opacity: 0.4,
    color: theme.palette.primary.main,
    fontSize: 11,
    lineHeight: 1.36,
    letterSpacing: 0.21,
    textAlign: 'center',
    height: 34,
    padding: '0 50px',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.primary.main,
    },
  },
  SingleFilter__drawer__btn__clear__active: {
    opacity: 1,
  },
  SingleFilter__drawer__btn__apply: {
    fontSize: 14,
    lineHeight: 1.36,
    letterSpacing: 0.21,
    textAlign: 'center',
    height: 40,
    padding: '0 50px',
    boxShadow: '0 0 0 black',
    width: '60%',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  linkButton: {
    boxShadow: 'none',
    textTransform: 'none',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18.6,
    fontSize: 12,
    width: 'auto',
    padding: '5px 10px',
    border: 'solid 0.9px rgba(18, 19, 19, 0.05)',
    color: '#00234866',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 11.5,
      height: 26,
      // marginRight: -6,
    },
  },
  Filter__text: {
    // marginBottom: -2,
  },
  MobileFilterTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  },
  MobileFilterRows: {
    width: '100%',
    marginBottom: '20px',
  },
  SingleFilter__drawer__text__bottom: {
    fontSize: '14px',
    color: theme.palette.text.light,
  },
}));

export default useStyles;
