import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import cx from 'classnames';
import useStyles from './style';

/**
 * @desc Oscer Case card
 *
 * @param case case object
 */
const TeamCard = (props) => {
  const { image, name, title, color, isJoin, to, styles = {} } = props;

  const handleCardClick = () => {
    if (to) {
      props.history.push(to);
      handleScrollTop();
    }
  };

  const handleScrollTop = () => {
    animateScroll.scrollToTop();
  };

  const cs = useStyles();
  return (
    <div
      className={cx(cs.TeamCard, styles.root, { [cs.TeamCard__to]: to })}
      onClick={handleCardClick}
      aria-hidden="true"
    >
      <div className={cx(cs.TeamCard__wrapper)} style={{ borderBottomColor: color }}>
        <img src={image} alt={name} className={cs.TeamCard__img} />
        <div className={cs.TeamCard__name}>{name}</div>
        <span className={cs.TeamCard__star}>{isJoin ? 'WHY NOT' : 'STARRING AS'}</span>
        <div className={cs.TeamCard__title}>{title}</div>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  isJoin: PropTypes.bool,
  to: PropTypes.string,
  history: PropTypes.object,
  styles: PropTypes.object,
};

export default withRouter(TeamCard);
