import { APIClient } from '../settings';
import { JOBS_SET, JOBS_QUANTITY_SET } from './types';

export const getJob = (category, name) => {
  const params = {
    category,
    name,
  };
  return APIClient.get('api/v1/customised-page/dynamic-page', { params });
};

export const setJobs = (jobs) => ({
  type: JOBS_SET,
  jobs,
});

export const setJobsQuantity = (quantity) => ({
  type: JOBS_QUANTITY_SET,
  quantity,
});

export const getJobs = (category, groupBy) => (dispatch) =>
  new Promise((resolve, reject) => {
    const params = {
      category,
      group_by: groupBy,
    };
    APIClient.get('api/v1/customised-page/dynamic-page/list/', { params })
      .then((res) => {
        dispatch(setJobs(res.data));
        let quantity = 0;
        for (let i = 0; i < res.data.length; i += 1) {
          for (let j = 0; j < res.data[i].pages.length; j += 1) {
            quantity += 1;
          }
        }
        dispatch(setJobsQuantity(quantity));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err?.response?.data);
      });
  });
