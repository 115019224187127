// env constants
export const SESSION_DURATION = process.env.REACT_APP_SESSION_DURATION;

// user groups
export const UNIMELB_STUDENTS = 'Unimelb Students';
export const UNIMELB_TUTORS = 'Unimelb Tutors';
export const UNIMELB_USERS = 'Unimelb Users';
export const CASE_WRITERS = 'Case Writers';
export const CASE_STAFFS = 'Case Staff';
export const MARKETING = 'Marketing';
export const DAILY_TASK = 'Daily Task';
export const UK_USERS = 'UK User';
export const NORMAL_USERS = 'Normal Users';
export const CONTENT_TEAM_STAFFS = 'Content Team Staff';
export const GOOGLE_USERS = 'Google Users';

// MESSAGE TYPES
export const INCOMING_MESSAGE = 'INCOMING_MESSAGE';
export const OUTGOING_MESSAGE = 'OUTGOING_MESSAGE';
export const LOADING_MESSAGE = 'LOADING_MESSAGE';
export const SEPERATOR_MESSAGE = 'SEPERATOR_MESSAGE';
export const PARTIALLY_COMPLETE_MESSAGE = 'PARTIALLY_COMPLETE_MESSAGE';

// external app IDs/client IDs
// export const PAYPAL_CLIENT_ID = 'AayH29RSmuVQc4ZKBnmwrqp9F377YinHZmFWFsLxYZW31vgYiroyqHH4TqE1ACyQ32OdI57rOTd6dPo8';
// export const STRIPE_PUBLIC_KEY = 'pk_test_ShNV7vCkzOHCPCygs9ATfwpT';
// export const GOOGLE_CLIENT_ID = '80643761443-5j6t0ierlavqn1pv40ubefvvo9ghoqrm.apps.googleusercontent.com';
// export const FACEBOOK_APP_ID = '700933547108597';

export const PAYPAL_CLIENT_ID = 'AZsOj4FGCkWM948gykKGMY_wcdirQ6MC58qEA3XupL5J_q2YK4lzXGh2aacGuPPuRbF17Wh7FfR6Dw44';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const GOOGLE_CLIENT_ID = '1025187075574-leg6tbhd47q98nguhe33cpf68it5qmpg.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '2155349584599222';

export const SYSTEM_CARDIO = 'cardio';
export const SYSTEM_DERMATOLOGY = 'dermatology';
export const SYSTEM_ENDOCRINE = 'endocrine';
export const SYSTEM_GASTRO = 'gastro';
export const SYSTEM_MUSCULO = 'musculo';
export const SYSTEM_NEURO = 'neuro';
export const SYSTEM_ONC = 'onc';
export const SYSTEM_RENAL = 'renal';
export const SYSTEM_RESPIRATORY = 'respiratory';
export const SYSTEM_PSYCHIATRIC = 'mental';
export const SYSTEM_GYNECOLOGY = 'gyn';

export const ALL_SYSTEMS = [
  SYSTEM_CARDIO,
  SYSTEM_DERMATOLOGY,
  SYSTEM_ENDOCRINE,
  SYSTEM_GASTRO,
  SYSTEM_GYNECOLOGY,
  // SYSTEM_ONC = Haematology
  SYSTEM_ONC,
  SYSTEM_RESPIRATORY,
  SYSTEM_MUSCULO,
  SYSTEM_NEURO,
  SYSTEM_PSYCHIATRIC,
  SYSTEM_RENAL,
];

export const READABLE_SYSTEM_NAMES = {
  [SYSTEM_CARDIO]: 'Cardiology',
  [SYSTEM_DERMATOLOGY]: 'Dermatology',
  [SYSTEM_ENDOCRINE]: 'Endocrine',
  [SYSTEM_GASTRO]: 'Gastrointestinal',
  [SYSTEM_MUSCULO]: 'Musculoskeletal',
  [SYSTEM_NEURO]: 'Neurology',
  [SYSTEM_ONC]: 'Haematology',
  [SYSTEM_RENAL]: 'Renal',
  [SYSTEM_RESPIRATORY]: 'Respiratory',
  [SYSTEM_PSYCHIATRIC]: 'Psychiatric',
  [SYSTEM_GYNECOLOGY]: 'Gynecology',
};

export const MAP_NAMES_TO_ABBR = {
  Cardiology: SYSTEM_CARDIO,
  Dermatology: SYSTEM_DERMATOLOGY,
  Endocrine: SYSTEM_ENDOCRINE,
  Gastrointestinal: SYSTEM_GASTRO,
  Musculoskeletal: SYSTEM_MUSCULO,
  Neurology: SYSTEM_NEURO,
  Haematology: SYSTEM_ONC,
  Renal: SYSTEM_RENAL,
  Respiratory: SYSTEM_RESPIRATORY,
  Psychiatric: SYSTEM_PSYCHIATRIC,
  Gynecology: SYSTEM_GYNECOLOGY,
};

export const SYSTEM_COLORS = {
  [SYSTEM_CARDIO]: 'var(--cardiology-color)',
  [SYSTEM_DERMATOLOGY]: 'var(--dermatology-color)',
  [SYSTEM_ENDOCRINE]: 'var(--endocrine-color)',
  [SYSTEM_GASTRO]: 'var(--gastrointestinal-color)',
  [SYSTEM_MUSCULO]: 'var(--musculoskeletal-color)',
  [SYSTEM_NEURO]: 'var(--neurology-color)',
  [SYSTEM_ONC]: 'var(--heme-color)',
  [SYSTEM_RENAL]: 'var(--renal-color)',
  [SYSTEM_RESPIRATORY]: 'var(--respiratory-color)',
  [SYSTEM_PSYCHIATRIC]: '#FBCF99',
  [SYSTEM_GYNECOLOGY]: '#FFCCDC',
};

export const SYSTEM_OPTIONS = [
  'Cardiology',
  'Dermatology',
  'Endocrine',
  'Gastrointestinal',
  'Gynecology',
  'Haematology',
  'Musculoskeletal',
  'Neurology',
  'Psychiatric',
  'Renal',
  'Respiratory',
];

export const PRESENTATION_OPTIONS = [
  'Abdominal Distension',
  'Abdominal Pain',
  'Chest Pain',
  'Cough',
  'Fatigue',
  'Fever',
  'Headache',
  'Leg Pain',
  'Rash',
  'Sore Throat',
];

// Level names to display on filter
export const LEVEL_NAME = [
  'Level 1 - Preclinical',
  'Level 2 - Clinical',
  'Level 3 - Doctor',
  'Level 4 - Senior Doctor',
];

// case system icon size
export const SYSTEM_ICON_MEDIUM = 62;
export const SYSTEM_ICON_SMALL = 32;

// case presentation icon size
export const PRESENTATION_ICON_XLARGE = 200;
export const PRESENTATION_ICON_MEDIUM = 50;
export const PRESENTATION_ICON_SMALL = 32;

// chat avator size
export const CHAT_AVATAR_SIZE = 35;

// exam mode
export const EXAM_MODE = 'EXAM MODE';
export const EXAM_LEARN_MODE = 'LEARN MODE';

// Total question asked in right side bar
export const TOTAL_QUESTION_ASKED = 100;

// permissions
export const CASE_CARD_SCORE_BLACK_LIST = [UNIMELB_USERS];
export const PROFILE_PAGE_BLACK_LIST = [UNIMELB_USERS];
export const DIFFERENTIAL_DIAGNOSIS_BLACK_LIST = [UNIMELB_USERS];
export const TASK_BLACK_LIST = [UNIMELB_USERS];
export const FEATURED_CASES_BLACK_LIST = [UNIMELB_USERS, DAILY_TASK];
export const USER_TOUR_BLACK_LIST = [UNIMELB_USERS, DAILY_TASK];

// learn mode
export const LEANR_MODE_WHITE_LIST = [CASE_WRITERS, CASE_STAFFS, CONTENT_TEAM_STAFFS];

export const EXIT_CASE_MODAL = 'EXIT_CASE_MODAL';
export const HINT_MODAL = 'HINT_MODAL';
export const TIMEOUT_CASE_MODAL = 'TIMEOUT_CASE_MODAL';
export const SESSION_EXPIRED_MODAL = 'SESSION_EXPIRED_MODAL';
export const COMPLETE_MODAL = 'SESSION_COMPLETE_MODAL';
export const ASSIST_MODE_MODAL = 'ASSIST_MODE_MODAL';

// Diagnosis Mode
export const EMPTY_DIAGNOSIS_SESSION = {
  ddx_list: [],
};

// case session type
export const OSCE_HISTORY_SESSION = 'CASE/OSCE_HISTORY_SESSION';
export const LEARN_MODE_SESSION = 'CASE/LEARN_MODE_SESSION';
export const DIAGNOSIS_SESSION = 'CASE/DIAGNOSIS_SESSION';

// used by feedback
export const SPM_SESSION = 'SPM_SESSION';
