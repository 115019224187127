import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Slider_arrow: {
    color: '#a0a0a0',
    background: '#ffffff',
    boxShadow: '2px 2px 4px -1px #d8d8d8',
    padding: 7,
    minWidth: 30,
    minHeight: 30,
    maxWidth: 30,
    maxHeight: 30,
    position: 'absolute',
    top: 'calc(50% - 15px)',
    [theme.breakpoints.down('xs')]: {
      minWidth: 21,
      minHeight: 21,
      maxWidth: 21,
      maxHeight: 21,
      padding: 0,
    },
    '&:hover': {
      color: '#000000',
      background: '#ffffff',
    },
  },
  Slider__arrow__mobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Slider__backwardArrow: {
    left: -10,
    zIndex: 1,

    [theme.breakpoints.down('xs')]: {
      left: -5,
    },
    '&:before': {
      content: 'unset',
    },
  },
  Slider__forwardArrow: {
    right: -10,
    zIndex: 1,

    '&:before': {
      content: 'unset',
    },
  },
}));

export default useStyles;
