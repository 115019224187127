import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  MessageRecommendation: {
    position: 'absolute',
    bottom: 94,
    left: '2%',
    width: '92%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px 15px',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    [theme.breakpoints.down('sm')]: {
      left: '2%',
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 53,
      left: 0,
      width: '100%',
    },
  },
  MessageRecommendation__ChipsWrapper: {
    display: 'flex',
  },
  MessageRecommendation__Chips: {
    borderRadius: 22.5,
    boxShadow: '0 2px 6px 0 rgba(27, 28, 29, 0.06)',
    margin: 5,
    padding: 12,
    backgroundColor: '#cdecf9',
    color: theme.palette.primary.dark,
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#bcdbea',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 4,
      padding: 8,
      fontSize: 11,
    },
  },
}));

export default useStyles;
