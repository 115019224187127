import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ModeSelectionModal: {
    width: 594,
    borderRadius: 15,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    backgroundColor: 'white',
    borderBottom: '20px solid #fba799',
    zIndex: 101,
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: '90vh',
      borderRadius: 0,
    },
  },
  ModeSelectionModal__history__btn: {
    height: 43,
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    fontWeight: 500,
    padding: '0 25px',
    '&:hover': {
      backgroundColor: '#3fb6e8',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px',
      marginBottom: 10,
    },
  },
  ModeSelectionModal__diagnosis__btn: {
    height: 43,
    boxShadow: 'none',
    fontWeight: 500,
    padding: '0 25px',
    backgroundColor: theme.palette.text.main,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: `${theme.palette.text.main}CC`,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px',
    },
  },
  ModeSelectionModal__btn__multi: {
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('xs')]: {
      marginTop: 17,
    },
  },
  ModeSelectionModal__btn__tooltip: {
    marginTop: 10,
  },
  ModeSelectionModal__btn__multi__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ModeSelectionModal__btn__multi__text: {
    fontSize: 14,
    color: theme.palette.primary.light,
  },
}));

export default useStyles;
