import React, { useState, useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Container, Paper, ClickAwayListener, InputBase, InputAdornment } from '@material-ui/core';
import DropdownList from '../DropdownList';
import useStyles from './style';

/**
 * @desc Multiselect dropdown with panel showing selections
 * @param {*} props
 * - options: dropdown values (string array/obj array)
 * - selectedList: list of values selected
 * - setSelectedList: function to set selectedList
 * - searchValue: input value
 * - setSearchValue: function to set input value
 * - handleInputChange: function to handle inputchange
 * - search: toggle to enable input search
 * - placeholder: value to be presented at the search bar e.g. 'Presentation'
 * - dropdownState: function to determine whether dropdown is opened
 * - sort: boolean -- whether sort is true in dropdown
 * - fieldToDisplay: name of field to be displayed if array is obj
 */
const Dropdown = (props) => {
  const cs = useStyles();
  const {
    options = [],
    selectedList = [],
    dataTut = '',
    setSelectedList = () => {},
    searchValue = '',
    setSearchValue = () => {},
    handleInputChange = () => {},
    search = false,
    placeholder = 'Search',
    setDropdownHeight = () => {},
    sort = false,
    fieldToDisplay = 'name',
    dropdownState = () => {},
    style = {},
    className = {},
    system = false,
    light = false,
    locked = false,
    comingSoon = false,
  } = props;

  // The state to control the presentation click away listener
  const [systemClickAway, setSystemClickAway] = useState(false);
  const [isActive, setActive] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    setActive(false);
    setSearchValue('');
    setSystemClickAway(false);
  }, [selectedList]);

  useEffect(() => {
    dropdownState(systemClickAway);
  }, [systemClickAway]);

  const handleClickAway = () => {
    setSearchValue('');
    setSystemClickAway(false);
  };

  const handleInputBaseActive = () => {
    setActive((active) => !active);
  };

  const elementRef = useRef(null);

  useEffect(() => {
    setDropdownWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <InputBase
          ref={elementRef}
          style={style}
          data-tut={dataTut}
          classes={{
            root: cx(
              cs.FuzzyFilter__InputBase__root,
              {
                [cs.FuzzyFilter__InputBase__root__selected]: selectedList.length > 0 && !systemClickAway,
              },
              {
                [cs.FuzzyFilter__InputBase__root__selected__light]:
                  selectedList.length > 0 && !systemClickAway && light,
              },
              className
            ),
          }}
          placeholder={placeholder}
          readOnly={!search}
          inputProps={{ 'aria-label': 'search a Patient' }}
          onChange={handleInputChange}
          value={
            systemClickAway
              ? searchValue
              : selectedList.length > 0
              ? typeof selectedList[0] === 'string'
                ? selectedList[0]
                : selectedList[0][fieldToDisplay]
              : ''
          }
          onFocus={() => {
            setActive(true);
            setSystemClickAway(true);
          }}
          onBlur={handleInputBaseActive}
          endAdornment={
            <InputAdornment position="end">
              <ArrowDropDownIcon
                onClick={() => {
                  setActive(!isActive);
                  setSystemClickAway(!systemClickAway);
                }}
                classes={{
                  root: cx(
                    cs.FuzzyFilter__SearchIcon__root,
                    {
                      [cs.FuzzyFilter__SearchIcon__root__selected]:
                        selectedList.length > 0 && !systemClickAway && !light,
                    },
                    {
                      [cs.FuzzyFilter__SearchIcon__root__selected__light]:
                        selectedList.length > 0 && !systemClickAway && light,
                    },
                    {
                      [cs.FuzzyFilter__SearchIcon__root__active]: isActive,
                    }
                  ),
                }}
              />
            </InputAdornment>
          }
        />

        <div className={cs.DragDropDown__systemList__wrapper}>
          {systemClickAway ? (
            <div className={cs.DragDropDown__system__wrapper} style={{ width: dropdownWidth }}>
              <DropdownList
                options={options}
                setSelectedList={setSelectedList}
                selectedList={selectedList}
                setDropdownHeight={setDropdownHeight}
                setSearchValue={setSearchValue}
                userInput={searchValue}
                fieldToDisplay={fieldToDisplay}
                sort={sort}
                locked={locked}
                comingSoon={comingSoon}
              />
            </div>
          ) : null}
        </div>
      </div>
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedList: PropTypes.array.isRequired,
  setSelectedList: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  handleInputChange: PropTypes.func,
  search: PropTypes.bool,
  placeholder: PropTypes.string,
  dropdownState: PropTypes.func,
  sort: PropTypes.bool,
  fieldToDisplay: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  setDropdownHeight: PropTypes.func,
  system: PropTypes.bool,
  light: PropTypes.bool,
  system: PropTypes.bool,
  light: PropTypes.bool,
  locked: PropTypes.bool,
  comingSoon: PropTypes.bool,
};

export default Dropdown;
