import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import InjectedStripeForm from './InjectedStripeForm';
import { STRIPE_PUBLIC_KEY } from '../../constants';

const StripeCheckout = (props) => (
  <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
    <Elements>
      <InjectedStripeForm />
    </Elements>
  </StripeProvider>
);

StripeCheckout.propTypes = {};

export default StripeCheckout;
