import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaForward } from 'react-icons/fa';

import classnames from 'classnames';

import { Modal } from '../../../components/common';
import PresentationImg from '../../../components/icons/PresentationImg';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import { PRESENTATION_ICON_XLARGE, SYSTEM_COLORS } from '../../../constants';
// import '../case.scss';
import './style.scss';

/**
 * @desc Modal for submit
 * @param { * } props
 * - isToggle Boolean, true means show modal
 * - toggle function which change modal status to '' (hide modal)
 * - proceed function which shows Follow Questions
 * - presentation String of current case presentation
 * - system String which contains the name of current system
 */
const SubmitCaseModal = ({ isToggle, toggle, proceed, presentation, system, handleSubmit }) => {
  const [error, setError] = useState('');

  const handleCancel = () => {
    toggle();
    setError('');
  };

  return (
    <div>
      <Modal isToggle={isToggle} toggle={toggle} centered closable>
        <div className={classnames('case__modal')}>
          <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
            <div className="case__modal">
              <PresentationImg
                className="case__modal__img"
                presentation={presentation}
                size={PRESENTATION_ICON_XLARGE}
              />

              <div className="case__modal__title">
                <div className={classnames('title', 'case__modal__title-text')}>
                  <h5>Are you sure you have finished talking to your patient?</h5>
                </div>
              </div>
              <div className={classnames('note', 'case__modal__subtitle')}>
                <p>Proceed to formulate your clinical reasoning where you'll provide a case summary</p>
              </div>

              <div className={classnames('buttons', 'case__modal__btns')}>
                <ConfirmButton className={classnames('btn-submit-cancel', 'btn-round')} onClick={handleCancel}>
                  Cancel
                </ConfirmButton>
                <ConfirmButton
                  className={classnames('btn-submit-proceed', 'btn-round')}
                  onClick={() => {
                    handleSubmit();
                  }}
                  cy-id="single-player-proceed"
                >
                  Proceed <FaForward />
                </ConfirmButton>
              </div>
              <div className="case__modal__submit__error">{error}</div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

SubmitCaseModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  proceed: PropTypes.func,
  presentation: PropTypes.string,
  system: PropTypes.string,
};

export default SubmitCaseModal;
