import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Modal } from '../../common';
import errorImage from '../../../assets/common/hp-404-page-img.svg';
import useStyles from './style';

const ErrorModal = (props) => {
  const classes = useStyles();
  const {
    isToggle,
    toggle,
    title = 'Ops, something went wrong.',
    subtitle = 'Oscer feels sorry about it, if it continues happens, please contact us from intercom at right bottom corner',
    img = errorImage,
    error,
    link = '/dashboard/home',
  } = props;

  return (
    <Modal isToggle={isToggle} toggle={toggle} centered closable={false}>
      <div className={classes.ErrorModal}>
        <div className={classes.ErrorModal__content}>
          <img src={img} alt="image_error" className={classes.ErrorModal__image} />
          <div className={classes.ErrorModal__title}>{title}</div>
          <div className={classes.ErrorModal__subtitle}>{subtitle}</div>
          <div className={classes.ErrorModal__wrapper}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to={link}
              classes={{ contained: classes.ErrorModal__btn }}
            >
              Back To Dashboard
            </Button>
          </div>
          <div className={classes.ErrorModal__error}>{error}</div>
        </div>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  link: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  img: PropTypes.string,
  error: PropTypes.string,
};

export default ErrorModal;
