import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './levelBadge.scss';

/**
 * @desc The level badge on case card, level 1 -> green, 2 -> yellow, 3 -> red, 4 -> grey
 *
 * @param level: number of levels
 * @param mobile: boolean which shows whether the badge in mobile screen,
 * @param hide: hide the content and only show color circle
 * we don't use css media query because in mobile screen shows full size card after click
 */
const LevelBadge = ({ level, mobile = false, hide = false, className }) => {
  const colorSelector = () => {
    switch (level) {
      case 1:
        return 'var(--case-1-color)';

      case 2:
        return 'var(--case-2-color)';

      case 3:
        return 'var(--case-3-color)';

      case 4:
        return 'var(--case-4-color)';

      default:
        return 'var(--case-1-color)';
    }
  };

  return (
    <div
      className={cx('case__level__badge', { 'case__level__badge-mobile': mobile }, className)}
      style={{ backgroundColor: colorSelector() }}
    >
      {!hide && (
        <>
          <div className={cx('case__level__badge-header', { 'case__level__badge-header-mobile': mobile })}>Level</div>
          <div className={cx('case__level__badge-level', { 'case__level__badge-level-mobile': mobile })}>{level}</div>
        </>
      )}
    </div>
  );
};

LevelBadge.propTypes = {
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobile: PropTypes.bool,
  hide: PropTypes.bool,
  className: PropTypes.string,
};

export default LevelBadge;
