import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  MessageInput: {
    position: 'absolute',
    bottom: 10,
    left: '2%',
    width: '92%',
    [theme.breakpoints.down('sm')]: {
      left: '2%',
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: 1,
      left: 0,
      width: '100%',
    },
  },
  MessageInput__form: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px 15px',
    width: '100%',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.12)',
    position: 'relative',
    zIndex: 0,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#FBFBFB',
      padding: '3px 5px',
      borderRadius: 21.5,
    },
  },
  MessageInput__form__recommendation: {
    borderRadius: 0,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    boxShadow: 'unset',
    [theme.breakpoints.down('xs')]: {
      borderBottomLeftRadius: 21.5,
      borderBottomRightRadius: 21.5,
    },
  },
  MessageInput__btn__send: {
    width: 133,
    height: 43,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
    [theme.breakpoints.down('xs')]: {
      width: 44,
      height: 44,
      borderRadius: '50%',
      minWidth: 44,
      padding: 0,
      fontSize: 26,
    },
  },
  MessageInput__mic__wrapper: {
    position: 'relative',
    // top: -40,
    // left: -20,
    // [theme.breakpoints.down('xs')]: {
    //   position: 'relative',
    //   top: 0,
    //   left: 0,
    // },
  },
  MessageInput__send__text: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  MessageInput__send__text__mobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  MessageInput__reat__mic: {
    height: 0,
  },
  MessageInput__textArea: {
    flex: 1,
    borderRadius: 3,
    fontSize: 16,
    color: theme.palette.text.main,
    border: 'none',
    resize: 'none',
    padding: 8,
    zIndex: -2,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: `${theme.palette.text.main}99`,
    },
    [theme.breakpoints.down('xs')]: {
      // fontSize: 12,
    },
  },
}));

export default useStyles;
