import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Logout: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Logout__wrapper: {
    width: 440,
    height: 208,
    borderRadius: 16,
    background: 'white',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '85%',
      height: 180,
    },
  },
  Logout__img: {
    position: 'absolute',
    top: -54,
    left: -169,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 130,
      top: -120,
      left: -30,
    },
  },
  Logout__title: {
    fontSize: 24,
    fontWeight: 500,
    color: theme.palette.text.main,
    marginBottom: 7,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  Logout__subtitle: {
    color: theme.palette.text.main,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      marginBottom: 20,
    },
  },
  Logout__btns: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '90%',
  },
  Logout__btn__logout: {
    height: 43,
    width: 140,
    whiteSpace: 'nowrap',
    backgroundColor: `white`,
    border: '1.5px solid var(--primary-color-3-o8)',
    color: 'var(--primary-color-3-o8)',
    // borderWidth: 0,
    [theme.breakpoints.down('xs')]: {
      width: 130,
    },
    '&:hover': {
      // borderWidth: 0,
      // backgroundColor: `${theme.palette.text.main}4D`,
      // color: 'white',
    },
  },
  Logout__btn__cancel: {
    height: 43,
    width: 140,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      width: 130,
    },
  },
}));

export default useStyles;
