import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Hint from './Hint';
import IconHint from '../../../../components/icons/IconHint';
import iconOscerProfessor from '../../../../assets/character/oscer-professor.svg';
import useStyles from './style';

const HintsPanel = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.HintsPanel}>
      <img src={iconOscerProfessor} alt="professor" className={classes.HintsPanel__icon} />
      <div className={classes.HintsPanel__title}>Need Some Help?</div>
      <div className={classes.HintsPanel__label}>
        <IconHint color={theme.palette.primary.main} size={35} />
        Hints
      </div>
      <Hint />
    </div>
  );
};

HintsPanel.propTypes = {};

export default HintsPanel;
