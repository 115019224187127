import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import rootReducer from './reducers';

const initialState = {};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  process.env.REACT_APP_STAGE === 'development'
    ? composeWithDevTools(applyMiddleware(...middleware), sentryReduxEnhancer)
    : compose(applyMiddleware(...middleware), sentryReduxEnhancer)
);

export default store;
