import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  HintsPanelMobile__label: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 1,
    color: theme.palette.text.main,
  },
  HintsPanelMobile__hints__icon: {
    marginLeft: 4,
  },
  HintsPanelMobile__accordion__root: {
    position: 'absolute',
    top: 0,
    left: 'calc((100vw - 125px) * 0.75)',
    width: 'calc((100vw - 125px) * 0.25)',
    boxShadow: 'none',
    '&.Mui-expanded': {
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
      margin: 0,
    },
  },
  HintsPanelMobile__accordionSummary__root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    '&.Mui-expanded': {
      backgroundColor: '#ecf2ff',
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      minHeight: 60,
    },
  },
  HintsPanelMobile__accordionSummary__content: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  HintsPanelMobile__accordionDetails__root: {
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    position: 'absolute',
    width: 'auto',
    left: 'calc((125px - 100vw) * 0.75)',
    backgroundColor: 'white',
  },
}));

export default useStyles;
