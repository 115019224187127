import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';
import { Button, IconButton, Drawer, ClickAwayListener, Typography } from '@material-ui/core';
import Fuse from 'fuse.js';
import { useLocation } from 'react-router-dom';
import { ALL_SYSTEMS2, READABLE_SYSTEM_NAMES, LEVEL_NAME } from '../../constants';
import FuzzyFilter from './CaseFilter/FuzzyFilter';
import ClouseButton from '../buttons/CloseButton';
import { removeUnderscore } from '../../helpers/toTitleCase';
import { trackEvent } from '../../service/analytics';
import useStyles from './style';
import { useWidth } from '../../helpers/customHooks';
import { mapSystems, reverseSystems } from '../../helpers/mapSystems';
import Dropdown from '../Dropdowns/Dropdown';
import DropdownMulti from '../Dropdowns/DropdownMulti';
import { ALL_PRESENTATIONS } from 'components/icons/PresentationImg/constants';
import cx from 'classnames';
import FilterIcon from '../../assets/icons/filter-icon.svg';

const levelOptions = [1, 2, 3, 4];
const rotationOptions = [
  'Emergency Medicine',
  'Medicine',
  'Surgery',
  'Pediatrics',
  'Obstetrics and Gynecology',
  'Geriatrics',
  'Primary Healthcare',
  'Mental Health',
];

const MainboardFilter = (props) => {
  const screenSize = useWidth();
  const {
    cases,
    setFilteredCases,
    hasSystem = false,
    hasPresentation = false,
    hasLevel = false,
    hasRotation = false,
    hasIncomplete = false,
    hasSearch = false,
    triggerClear = false,
    presentationField = 'presenting_complaint',
    setTriggerClear = () => {},
    width = 150,
    searchRules = {
      threshold: 0.3,
      keys: ['patient_name', presentationField, 'label', 'system', 'gender', 'patient_age'],
    },
    // dashboard filter cases array is different from normal
    isDashboard,
  } = props;

  const location = useLocation();

  const [allPresentations, setAllPresentations] = useState([]);
  const [allSystems, setAllSystems] = useState([]);
  const [allLevels, setAllLevels] = useState([]);
  const [allRotations, setAllRotations] = useState([]);

  const [system, setSystem] = useState([]);
  const [level, setLevel] = useState([]);
  const [presentation, setPresentation] = useState([]);
  const [rotation, setRotation] = useState([]);

  // presentation values for mobile to be saved but not directly changed in parent
  const [tempPresentation, setTempPresentation] = useState([...presentation]);
  const [tempLevel, setTempLevel] = useState([...level]);
  const [tempSystem, setTempSystem] = useState([...system]);
  const [tempRotation, setTempRotation] = useState([...rotation]);

  // mobile drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [presentationDropdown, setPresentationDropdown] = useState(false);
  const [systemDropdown, setSystemDropdown] = useState(false);
  const [levelDropdown, setLevelDropdown] = useState(false);
  const [rotationDropdown, setRotationDropdown] = useState(false);

  const [presentationDropdownHeight, setPresentationDropdownHeight] = useState(0);
  const [systemDropdownHeight, setSystemDropdownHeight] = useState(0);
  const [levelDropdownHeight, setLevelDropdownHeight] = useState(0);
  const [rotationDropdownHeight, setRotationDropdownHeight] = useState(0);

  // Fuzzy search state
  const [searchValue, setSearchValue] = useState('');
  const [completeToggle, setCompleteToggle] = useState(false);

  const [searchFocus, setSearchFocus] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    initializeLists();
  }, [cases]);

  useEffect(() => {
    if (triggerClear) {
      setSearchValue('');
      setSystem([]);
      setLevel([]);
      setPresentation([]);
      setRotation([]);
      setTempLevel([]);
      setTempPresentation([]);
      setTempSystem([]);
      setTempRotation([]);
      setTriggerClear(false);
    }
  }, [triggerClear]);

  const initializeLists = () => {
    let presentations = [];
    let systems = [];
    let rotations = [];
    let levels = [];
    if (isDashboard) {
      for (let caseCollection of cases) {
        for (let curCase of caseCollection.cases) {
          if (curCase.random) {
            continue;
          }
          // presentations
          if (!presentations.includes(cleanPresentation(curCase.presentation))) {
            presentations.push(cleanPresentation(curCase.presentation));
          }
          // systems (Array)
          for (let system of curCase.systems) {
            if (!systems.includes(system.name.trim()) && ALL_SYSTEMS2.includes(system.name.trim())) {
              systems.push(system.name.trim());
            }
          }
          // rotations (Array)
          for (let rotation of curCase.rotations) {
            if (!rotations.includes(rotation.name.trim()) && rotationOptions.includes(rotation.name.trim())) {
              rotations.push(rotation.name.trim());
            }
          }
          // levels
          if (!levels.includes(curCase.case_level)) {
            levels.push(curCase.case_level);
          }
        }
      }
    } else {
      for (let curCase of cases) {
        // presentations
        if (hasPresentation) {
          if (!presentations.includes(cleanPresentation(curCase[presentationField]))) {
            presentations.push(cleanPresentation(curCase[presentationField]));
          }
        }
        // systems
        if (hasSystem) {
          for (let system of curCase.systems) {
            if (!systems.includes(system.name.trim()) && ALL_SYSTEMS2.includes(system.name.trim())) {
              systems.push(system.name.trim());
            }
          }
        }

        // rotations
        if (hasRotation) {
          for (let rotation of curCase.rotations) {
            if (!rotations.includes(rotation.name?.trim()) && rotationOptions.includes(rotation.name?.trim())) {
              rotations.push(rotation.name.trim());
            }
          }
        }

        // levels
        if (hasLevel) {
          if (!levels.includes(curCase.case_level)) {
            levels.push(curCase.case_level);
          }
        }
      }
    }

    // check if have icon
    setAllSystems([
      ...systems.map((opt) => ({
        name: READABLE_SYSTEM_NAMES[opt],
        oriName: opt,
      })),
    ]);
    setAllPresentations([
      ...presentations.map((opt) => ({
        name: cleanPresentation(opt),
        oriName: opt,
      })),
    ]);
    setAllRotations([...rotations]);
    setAllLevels([...levels.map((opt) => ({ name: LEVEL_NAME[opt - 1], oriName: opt }))]);
  };

  const cleanPresentation = (pres) =>
    removeUnderscore(pres)
      .split(' ')
      .map((opt) => capitalize(opt))
      .join(' ');

  useEffect(() => {
    filterCases();
    setTempSystem([...system]);
    setTempPresentation([...presentation]);
    setTempRotation([...rotation]);
    setTempLevel([...level]);
  }, [system, level, presentation, rotation, searchValue, completeToggle]);

  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const checkExistObj = (list, elem) => {
    for (let obj of list) {
      if (obj.oriName === elem || obj.name === elem) {
        return true;
      }
    }
    return false;
  };

  const checkSystemExist = (systemFilter, cases) => {
    if (cases.systems) {
      for (let filter of systemFilter) {
        if (
          cases.systems.some((d) => d.name.trim() === filter.oriName.trim()) ||
          cases.systems.some((d) => d.name.trim() === filter.name.trim())
        ) {
          return true;
        }
      }
      return checkExistObj(systemFilter, cases.system) || checkExistObj(systemFilter, reverseSystems(cases.system));
    } else {
      return checkExistObj(systemFilter, cases.system) || checkExistObj(systemFilter, reverseSystems(cases.system));
    }
  };

  const checkRotationExist = (rotationFilter, cases) => {
    if (cases.rotations) {
      for (let filter of rotationFilter) {
        for (let rotation of cases.rotations) {
          if (rotation.name.trim() === filter.oriName) {
            return true;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  };

  const filterCases = () => {
    if (isDashboard) {
      const dashboardFilterSearchRules = {
        threshold: 0.3,
        keys: ['patient_name', 'presentation', 'systems', 'gender', 'patient_age'],
      };

      try {
        // filter cases by system, level and presentation
        const filteredCases = cases.map((collection) => ({
          ...collection,
          cases: collection.cases.filter(
            (caseObj) =>
              (searchValue !== '' ? !caseObj.random : caseObj) &&
              (checkRotationExist(rotation, caseObj) || rotation.length === 0) &&
              (checkSystemExist(system, caseObj) || system.length === 0) &&
              (checkExistObj(level, caseObj.case_level) || level.length === 0) &&
              (checkExistObj(presentation, cleanPresentation(caseObj.presentation)) || presentation.length === 0)
          ),
        }));

        // filter by fuzzy search
        if (searchValue) {
          for (let i = 0; i < filteredCases.length; i += 1) {
            const fuse = new Fuse(filteredCases[i].cases, dashboardFilterSearchRules);
            const tempFilteredResult = fuse.search(searchValue).map((result) => result.item);
            filteredCases[i] = { ...filteredCases[i], cases: tempFilteredResult };
          }
        }
        setFilteredCases(filteredCases.filter((c) => c.cases.length > 0));
      } catch {
        console.log('Mainboard filter error [1]');
      }
    } else {
      try {
        // filter cases by system, level and presentation
        let filteredCases = cases.filter(
          (caseObj) =>
            (checkRotationExist(rotation, caseObj) || rotation.length === 0) &&
            (checkSystemExist(system, caseObj) || system.length === 0) &&
            (checkExistObj(level, caseObj.case_level) || level.length === 0) &&
            (checkExistObj(presentation, cleanPresentation(caseObj[presentationField])) || presentation.length === 0)
        );

        // filter by completed
        // if (completeToggle) {
        //   filteredCases = filteredCases.filter((caseObj) => caseObj.spm_best_stars === 0);
        // }

        // filter by fuzzy search
        if (searchValue) {
          const fuse = new Fuse(filteredCases, searchRules);
          filteredCases = fuse.search(searchValue).map((result) => result.item);
        }
        setFilteredCases(filteredCases);
      } catch {
        console.log('Mainboard filter error');
      }
    }
  };

  // System
  const handleSystemSelect = (opt) => {
    // update selected system
    setSystem([...opt]);
  };

  // Level
  const handleLevelSelect = (opt) => {
    setLevel([...opt]);
    // update selected system
    trackEvent({
      GAAction: 'Level Filter',
      FBAction: 'Level Filter',
      category: 'Filter',
      label: `User Clicks Level Filter ${opt} in ${location.pathname}`,
    });
  };

  // Presentation
  const handlePresentationSelect = (opt) => {
    setPresentation([...opt]);
    trackEvent({
      GAAction: 'Presentation Filter',
      FBAction: 'Presentation Filter',
      category: 'Filter',
      label: `User Clicks Presentation Filter ${opt} in ${location.pathname}`,
    });
  };

  const handleRotationSelect = (opt) => {
    setRotation([...opt]);
    // update selected system

    trackEvent({
      GAAction: 'Level Filter',
      FBAction: 'Level Filter',
      category: 'Filter',
      label: `User Clicks Level Filter ${opt} in ${location.pathname}`,
    });
  };

  const systemList = [
    ...ALL_SYSTEMS2.map((opt) => {
      return { name: READABLE_SYSTEM_NAMES[opt], oriName: opt };
    }),
  ];

  const handlePresentationClear = () => {
    setPresentation((draft) => {
      draft.clear();
    });
  };

  // Fuzzy search
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  // Complete switch
  const handleCompleteToggle = () => {
    trackEvent({
      GAAction: 'Complete Filter',
      FBAction: 'Complete Filter',
      category: 'Filter',
      label: `User toggles Complete Filter to ${completeToggle ? 'Incomplete' : 'All'} in ${location.pathname}`,
    });
    setCompleteToggle((toggle) => !toggle);
  };

  // Clear all
  const handleClearAll = () => {
    setTempLevel([]);
    setTempPresentation([]);
    setTempSystem([]);
    setTempRotation([]);
  };

  const handleDrawerSubmit = () => {
    setLevel([...tempLevel]);
    setPresentation([...tempPresentation]);
    setSystem([...tempSystem]);
    setRotation([...tempRotation]);
    setDrawerOpen(false);
  };

  return (
    <div className={classes.MainboardFilter}>
      <div className={classes.MainboardFilter__main}>
        {screenSize !== 'xs' && screenSize !== 'sm' && screenSize !== 'md' ? (
          <>
            {hasSearch && (
              <FuzzyFilter
                style={{ maxWidth: 180 }}
                searchValue={searchValue}
                handleInputChange={handleSearchInputChange}
                setFocus={setSearchFocus}
              />
            )}
            <div className={classes.FilterWrapper}>
              {hasSystem && (
                <DropdownMulti
                  options={allSystems}
                  search
                  style={{ width: searchFocus ? width - 25 : width }}
                  placeholder="System"
                  selectedList={system}
                  setSelectedList={handleSystemSelect}
                  system
                  tickEnd
                  seperator
                  clearIcon
                  sort
                />
              )}
            </div>
            <div className={classes.FilterWrapper}>
              {hasRotation && (
                <DropdownMulti
                  options={allRotations}
                  search
                  style={{ width: searchFocus ? width - 25 : width }}
                  placeholder="Rotation"
                  selectedList={rotation}
                  setSelectedList={handleRotationSelect}
                  tickEnd
                  seperator
                  clearIcon
                  sort
                />
              )}
            </div>
            <div className={classes.FilterWrapper}>
              {hasPresentation && (
                <DropdownMulti
                  options={allPresentations}
                  search
                  style={{ width: searchFocus ? width - 25 : width }}
                  placeholder="Presentation"
                  selectedList={presentation}
                  setSelectedList={handlePresentationSelect}
                  tickEnd
                  seperator
                  clearIcon
                  sort
                />
              )}
            </div>
            <div className={classes.FilterWrapper}>
              {hasLevel && (
                <DropdownMulti
                  options={allLevels}
                  search
                  style={{ width: searchFocus ? width - 25 : width }}
                  placeholder="Level"
                  selectedList={level}
                  setSelectedList={handleLevelSelect}
                  tickEnd
                  level
                  system={false}
                  seperator
                  sort
                  clearIcon
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div variant="contained" className={classes.linkButton} onClick={() => setDrawerOpen(true)}>
              <span className={classes.Filter__text}>Filter</span>
            </div>
            <Drawer
              style={{ zIndex: 999 }}
              anchor="bottom"
              // onBackdropClick={() => setDrawerOpen(false)}
              open={drawerOpen}
              ModalProps={{ onBackdropClick: () => setDrawerOpen(false) }}
              classes={{
                paper: cx(classes.SingleFilter__drawer),
              }}
            >
              <div className={classes.SingleFilter__drawer__wrapper}>
                <div className={classes.SingleFilter__drawer__header}>
                  <ClouseButton
                    className={classes.SingleFilter__drawer__cross}
                    onClick={() => setDrawerOpen(false)}
                    size={20}
                  />
                  <div
                    className={classes.SingleFilter__drawer__title}
                    style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', marginTop: '-8px' }}
                  >
                    <div className={classes.Filter__text}>Filters</div>
                  </div>
                </div>
                <div className={classes.SingleFilter__drawer__subtitle}></div>
                <div className={classes.SingleFilter__drawer__main}>
                  {hasSystem && (
                    <>
                      <div className={classes.MobileFilterTitle} style={{ marginBottom: '10px' }}>
                        <span>System</span>
                      </div>
                      <div
                        className={classes.MobileFilterRows}
                        style={{
                          marginBottom: systemDropdown
                            ? systemDropdownHeight > 250
                              ? 250
                              : systemDropdownHeight + 40
                            : '20px',
                        }}
                      >
                        <DropdownMulti
                          options={allSystems}
                          search
                          style={{ width: 'calc(100%)' }}
                          placeholder="System"
                          selectedList={tempSystem}
                          dropdownState={setSystemDropdown}
                          setSelectedList={setTempSystem}
                          setDropdownHeight={setSystemDropdownHeight}
                          system
                          tickEnd
                          seperator
                          sort
                          light
                        />
                      </div>
                    </>
                  )}

                  {hasRotation && (
                    <>
                      <div className={classes.MobileFilterTitle} style={{ marginBottom: '10px' }}>
                        <span>Rotation</span>
                      </div>
                      <div
                        className={classes.MobileFilterRows}
                        style={{
                          marginBottom: rotationDropdown
                            ? rotationDropdownHeight > 250
                              ? 250
                              : rotationDropdownHeight + 40
                            : '20px',
                        }}
                      >
                        <DropdownMulti
                          options={allRotations}
                          search
                          style={{ width: 'calc(100%)' }}
                          placeholder="Rotation"
                          selectedList={tempRotation}
                          dropdownState={setRotationDropdown}
                          setDropdownHeight={setRotationDropdownHeight}
                          setSelectedList={setTempRotation}
                          tickEnd
                          seperator
                          light
                          sort
                        />
                      </div>
                    </>
                  )}

                  {hasPresentation && (
                    <>
                      <div className={classes.MobileFilterTitle} style={{ marginBottom: '10px' }}>
                        <span>Presentation</span>
                      </div>
                      <div
                        className={classes.MobileFilterRows}
                        style={{
                          marginBottom: presentationDropdown
                            ? presentationDropdownHeight > 250
                              ? 250
                              : presentationDropdownHeight + 40
                            : '20px',
                        }}
                      >
                        <DropdownMulti
                          options={allPresentations}
                          search
                          style={{ width: 'calc(100%)' }}
                          placeholder="Presentation"
                          selectedList={tempPresentation}
                          dropdownState={setPresentationDropdown}
                          setDropdownHeight={setPresentationDropdownHeight}
                          setSelectedList={setTempPresentation}
                          tickEnd
                          seperator
                          light
                          sort
                        />
                      </div>
                    </>
                  )}

                  {hasLevel && (
                    <>
                      <div className={classes.MobileFilterTitle} style={{ marginBottom: '10px' }}>
                        <span>Level</span>
                      </div>
                      <div
                        className={classes.MobileFilterRows}
                        style={{
                          marginBottom: levelDropdown
                            ? levelDropdownHeight > 250
                              ? 250
                              : levelDropdownHeight + 40
                            : '20px',
                        }}
                      >
                        <DropdownMulti
                          options={allLevels}
                          search
                          style={{ width: 'calc(100%)' }}
                          placeholder="Level"
                          selectedList={tempLevel}
                          setSelectedList={setTempLevel}
                          setDropdownHeight={setLevelDropdownHeight}
                          dropdownState={setLevelDropdown}
                          tickEnd
                          seperator
                          level
                          light
                          sort
                        />
                      </div>
                    </>
                  )}

                  <div className={classes.SingleFilter__drawer__btns} style={{ marginBottom: '15px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      classes={{ root: classes.SingleFilter__drawer__btn__apply }}
                      onClick={handleDrawerSubmit}
                    >
                      Apply Filters
                    </Button>
                  </div>
                  <div onClick={handleClearAll} className={classes.SingleFilter__drawer__text__bottom}>
                    <Typography> Clear All </Typography>
                  </div>
                </div>
              </div>
            </Drawer>
          </>
        )}
      </div>
    </div>
  );
};

MainboardFilter.propTypes = {
  cases: PropTypes.array,
  setFilteredCases: PropTypes.func,
  hasSystem: PropTypes.bool,
  hasPresentation: PropTypes.bool,
  hasLevel: PropTypes.bool,
  hasSearch: PropTypes.bool,
  hasIncomplete: PropTypes.bool,
  searchRules: PropTypes.object,
  isDashboard: PropTypes.bool,
};

export default MainboardFilter;
