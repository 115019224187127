import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './levelBadgeLarge.scss';

/**
 * @desc The level badge on case card, level 1 -> green, 2 -> yellow, 3 -> red, 4 -> grey
 *
 * @param level: number of levels
 * @param mobile: boolean which shows whether the badge in mobile screen,
 * we don't use css media query because in mobile screen shows full size card after click
 */
const LevelBadgeLarge = ({ level, styles }) => {
  const colorSelector = () => {
    switch (level) {
      case 1:
        return 'var(--case-1-color)';

      case 2:
        return 'var(--case-2-color)';

      case 3:
        return 'var(--case-3-color)';

      case 4:
        return 'var(--case-4-color)';

      default:
        return 'var(--case-1-color)';
    }
  };

  return (
    <div className={classnames('case__level__badge__large', styles)} style={{ backgroundColor: colorSelector() }}>
      <div className={classnames('case__level__badge__large-header')}>Level</div>
      <div className={classnames('case__level__badge__large-level')}>{level}</div>
    </div>
  );
};

LevelBadgeLarge.propTypes = {
  level: PropTypes.number,
  styles: PropTypes.string,
};

export default LevelBadgeLarge;
