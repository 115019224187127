import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  UpgradeModal: {
    width: 660,
    height: 640,
    borderRadius: 15,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    backgroundColor: 'white',
    borderBottom: '20px solid #f6c89f',
    zIndex: 101,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: '90vh',
      borderRadius: 0,
    },
  },
  UpgradeModal__btn: {
    backgroundColor: theme.palette.prime.main,
    color: 'white',
    height: 43,
    '&:hover': {
      backgroundColor: theme.palette.prime.dark,
      boxShadow: 'none',
    },
  },
  UpgradeModal__img: {
    marginTop: -50,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      height: 300,
      marginTop: -80,
    },
  },
  UpgradeModal__img__2: {
    marginTop: 70,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90vw',
      marginTop: 0,
      marginBottom: 10,
    },
  },
  UpgradeModal__img__3: {
    marginTop: 20,
    marginBottom: 20,
    height: 260,
    [theme.breakpoints.down('xs')]: {
      height: 240,
      marginTop: 0,
      marginBottom: 10,
    },
  },
  UpgradeModal__cancel: {
    position: 'absolute',
    top: 15,
    right: 20,
    fontWeight: 300,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 400,
    },
  },
  UpgradeModal__content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '85%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      justifyContent: 'center',
    },
  },
  UpgradeModal__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  UpgradeModal__btns: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  UpgradeModal__title: {
    fontSize: 30,
    textAlign: 'center',
    color: theme.palette.text.main,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
      marginBottom: 10,
    },
  },
  UpgradeModal__subtitle: {
    fontSize: 20,
    textAlign: 'center',
    color: theme.palette.text.main,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: 20,
    },
  },
}));

export default useStyles;
