import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Switch from '@material-ui/core/Switch';
import Tour from 'reactour';
import { connect } from 'react-redux';
import { autoPlayAudioToggle } from '../../../actions/cases';
import { trackEvent } from '../../../service/analytics';
import PatientInfoModal from '../Modals/PatientInfoModal';

// import icons
import System from '../../../components/icons/System';
import PresentationImg from '../../../components/icons/PresentationImg';
import IconLevel from '../../../components/icons/IconLevel';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import SubmitCaseModal from '../Modals/SubmitCaseModal';
import logo from '../../../assets/logos/oscer-logo-blue.png';

// import style
import './styles.scss';
import { PRESENTATION_ICON_MEDIUM } from '../../../constants';

// This is a customized hook that will return previous value
function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * @desc the header in case section include level, restart, submit and a timer bar
 * @param { currentCase, submitCase, modalStatus, show, pause, timerComplete, wantingToNavigate } props
 * - currentCase Object which includes case details
 * - submitCase callback to submit case
 * - modalStatus String which describe modal types, default value is ""
 * - show Boolean default value true
 * - pause Boolean default value false
 * - timerComplete Boolean default value false
 * - wantingToNavigate Boolean default value false
 */
const TimerHeader = (props) => {
  const {
    isToggle,
    setToggle,
    isInfoToggle,
    setInfoToggle,
    currentCase,
    timeoutCase,
    modalStatus,
    pause,
    wantingToNavigate,
    proceed,
    stopSession,
    setTimerComplete,
    exitCase,
    sessionId,
    handleSubmit,
  } = props;
  const [currentTime, setCurrentTime] = useState(0);

  const [startStatus, setStartStatus] = useState(false);
  const [paused, setPaused] = useState(false);

  const [intervalObj, setIntervalObj] = useState();

  const prevStatus = usePrevious(modalStatus);

  /**
   * Disabled becase
   * This hook will detect modalStatus, if modal popup then stop timeInterval and resume when closed
   */
  useEffect(() => {
    let interval;
    if (modalStatus != '') {
      if (startStatus) {
        clearInterval(intervalObj);
      }
    } else if (startStatus) {
      interval = setInterval(() => {
        setCurrentTime((currentTime) => currentTime + 1);
      }, 1000);
      setIntervalObj(interval);
    } else if (prevStatus == 'BEGIN_CASE_MODAL') {
      interval = setInterval(() => {
        if (!pause) {
          setCurrentTime((currentTime) => currentTime + 1);
        }
      }, 1000);
      setIntervalObj(interval);
      setStartStatus(true);
    }

    // cleanup function when this component is unmount
    return function cleanup() {
      clearInterval(interval);
    };
  }, [modalStatus]);

  // This hook will detect currentTime, submit case when timeup
  useEffect(() => {
    if (currentTime >= currentCase.time_limit) {
      startStopTimer();
      setTimerComplete(true);
      timeoutCase();
    }
  }, [currentTime]);

  useEffect(() => {
    if (wantingToNavigate && startStatus) {
      setPaused(true);
      startStopTimer();
    }
    if (!wantingToNavigate && paused) {
      setPaused(false);
      startStopTimer();
    }
  }, [wantingToNavigate]);

  const startStopTimer = () => {
    if (startStatus) {
      clearInterval(intervalObj);
      setStartStatus(false);
    } else {
      const interval = setInterval(() => {
        setCurrentTime((currentTime) => currentTime + 1);
      }, 1000);
      setIntervalObj(interval);
      setStartStatus(true);
    }
  };

  const handleAudioSwitch = () => {
    trackEvent({
      GAAction: `turn_${props.autoPlayAudio ? 'off' : 'on'}_text_to_voice`,
      FBAction: `Turn ${props.autoPlayAudio ? 'Off' : 'On'} Text to Voice`,
      category: 'Single Player',
      label: `User ${props.id} Turn ${props.autoPlayAudio ? 'Off' : 'On'} Text to Voice`,
    });
    props.autoPlayAudioToggle();
  };

  return (
    <div className="timer">
      <div className="container-fluid">
        <div className="timer__body">
          <div className="timer__body__info">
            <img src={logo} alt="oscer logo" onClick={() => exitCase()} className="timer__body__info-logo" />
            <div className="timer__body__info-icon" onClick={() => setInfoToggle(true)}>
              <div className="timer__body__info-icon-wrap">
                <div className="icon__background">
                  <PresentationImg presentation={currentCase.presentation} size={PRESENTATION_ICON_MEDIUM} />
                </div>
              </div>
            </div>
            <div className="timer__body__info-person">
              <span>Chat to</span>
              <span className="timer__body__info-person-name">{currentCase.patient_name}</span>
              <div className="timer__body__info-switch">
                <span className="timer__body__info-switch-voice">VOICE</span>
                <Switch
                  checked={props.autoPlayAudio}
                  onChange={handleAudioSwitch}
                  color="primary"
                  name="audioSwitch"
                  size="small"
                  classes={{ root: 'timer__body__info-switch-audio' }}
                />
              </div>
            </div>
          </div>
          <div className="timer__body__btn">
            <ConfirmButton
              className="btn-primary-color"
              variant="extended"
              color="primary"
              onClick={() => setToggle(true)}
              data-tut="reactour__submit_case"
              cy-id="single-player-submit"
              // disabled={!sessionId}
            >
              Submit Case
            </ConfirmButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  autoPlayAudio: state.cases.autoPlayAudio,
  id: state.auth.user.id,
});

TimerHeader.propTypes = {
  currentCase: PropTypes.object.isRequired,
  setTimerComplete: PropTypes.func.isRequired,
  autoPlayAudioToggle: PropTypes.func,
  autoPlayAudio: PropTypes.bool,
  id: PropTypes.number,
  sessionId: PropTypes.string,
};

export default connect(mapStateToProps, { autoPlayAudioToggle })(TimerHeader);
