import React, { Component } from 'react';
import CommonModal from '../../../../components/common/CommonModal';
import './refLinkModal.scss';
import CharacterRef from '../../../../assets/character/character-referral.svg';
import { connect } from 'react-redux';
import { getReferralCode } from '../../../../actions/auth';

const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

class RefLinkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (!this.props.refCode) {
      this.props.dispatch(getReferralCode());
    }
  }

  copyClick = () => {
    copyToClipboard(`https://oscer.ai/#/ref/${this.props.refCode}`);
    this.setState({ clicked: true });
    setTimeout(() => {
      this.setState({ clicked: false });
    }, 1000);
  };

  render() {
    return (
      <CommonModal width={790} height={600} close={this.props.close}>
        <span className={'maybe-later-link'} onClick={() => this.props.close()}>
          Maybe Later
        </span>
        <div className={'wrapper'}>
          <div className={'characters'}>
            <img src={CharacterRef} alt="" />
          </div>
          <h1>Get Your Friends To Help Oscer</h1>
          <h6>SHARE YOUR LINK</h6>
          <div className={`link-block ${this.state.clicked ? 'clicked' : ''}`}>
            <div>{this.props.refCode}</div>
            <div onClick={this.copyClick}>copy</div>
          </div>
          <p>
            <span>Get 1 FREE Week of Oscer Prime</span>
            <br />
            when your friends join
          </p>
        </div>
      </CommonModal>
    );
  }
}

export default connect(
  (state) => ({ refCode: state.auth.refCode }),
  (dispatch) => ({ dispatch })
)(RefLinkModal);
