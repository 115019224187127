import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  TechnologyFeatures__container__outer: {
    backgroundColor: theme.palette.inherit.main,
    overflow: 'hidden',
  },
  TechnologyFeatures__container__inner: {
    paddingBottom: 0,
  },
  TechnologyFeatures__imageShowcase: {
    marginBottom: 120,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 60,
    },
  },
  TechnologyFeatures__overlay__1: {
    maxWidth: 250,
    maxHeight: 272,
    position: 'absolute',
    left: -162,
    top: 80,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  TechnologyFeatures__overlay__2: {
    maxWidth: 220,
    maxHeight: 277,
    position: 'absolute',
    right: 0,
    top: 500,
    transform: 'scale(-1, 1)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  TechnologyFeatures__overlay__3: {
    maxWidth: 208,
    maxHeight: 309,
    position: 'absolute',
    left: 0,
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
