import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  // todo: get the height from DOM it not ideal. Find better approach
  const moibleHeaderDOM = document.getElementById('learnmode-mobile-header');
  return {
    FloatingMessage__root: {
      position: 'fixed',
      top: 106,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '68%',
      zIndex: 0,
      [theme.breakpoints.down('xs')]: {
        top: moibleHeaderDOM?.offsetHeight || 72,
        width: '100%',
      },
    },
    FloatingMessage__wrapper: {
      borderRadius: 24,
      padding: '8px 34px',
      background: '#cdecf9',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0 2px 3px 0 rgba(27, 28, 29, 0.03), 0 2px 30px 0 rgba(27, 28, 29, 0.09)',
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
        marginLeft: 0,
      },
    },
    FloatingMessage__hr: {
      height: 1,
      background: '#ffffff',
      boxShadow: '0 2px 3px 0 rgba(27, 28, 29, 0.03)',
      border: 'solid 1px #ffffff',
      width: '100%',
      position: 'absolute',
    },
    FloatingMessage__message: {
      color: theme.palette.text.main,

      lineHeight: 1.5,
      letterSpacing: 'normal',
    },
    FloatingMessage__button: {
      padding: 0,
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
    FloatingMessage__button__icon: {
      color: theme.palette.text.main,
      background: '#cdecf9',
      borderRadius: '50%',
      padding: 5,
      marginRight: -10,
      fontSize: 36,
      marginLeft: 25,
    },

    PartiallyCompleteMessage__root: {
      width: '100%',
      position: 'relative',
      margin: '40px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    PartiallyCompleteMessage__hr: {
      height: 1,
      background: '#ffffff',
      boxShadow: '0 2px 3px 0 rgba(27, 28, 29, 0.03)',
      border: 'solid 1px #ffffff',
      width: '100%',
      position: 'absolute',
    },
    PartiallyCompleteMessage__message: {
      fontSize: 16,
      color: theme.palette.text.main,
      padding: 8,
      borderRadius: 30,
      background: '#cdecf9',
      lineHeight: 1.5,
      letterSpacing: 'normal',
    },
    PartiallyCompleteMessage__button: {
      padding: 0,
      marginLeft: 5,
      '&.Mui-disabled': {
        opacity: 0.4,
      },
    },
    PartiallyCompleteMessage__button__icon: {
      color: theme.palette.text.main,
      background: '#cdecf9',
      borderRadius: '50%',
      padding: 5,
      fontSize: 36,
    },
  };
});

export default useStyles;
