import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import useStyles from './style';

const CustomTooltip = (props) => {
  const classes = useStyles();
  const {
    children,
    title = 'This criteria is marked as a RED FLAG, which is an essential question you must ask to correctly diagnose the patient.',
    placement = 'bottom-start',
    arrow,
    styles = {},
    ...restProps
  } = props;

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow={arrow}
      classes={{
        tooltip: cx(classes.Tooltip, styles?.tooltip),
        arrow: cx(classes.Tooltip__arrow, styles?.arrow),
      }}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  arrow: PropTypes.bool,
  placement: PropTypes.string,
  styles: PropTypes.object,
};

export default CustomTooltip;
