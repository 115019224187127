import React from 'react';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { dumpCases } from 'helpers/Data/DumpCases';
import PropTypes from 'prop-types';

import HomeContainer from '../../../components/Container/HomeContainer';
import Slider from '../../../components/Slider';
import HoverCard from '../../../components/Card/HoverCard';

import CaseCard from './CaseCard';
import useStyles from './style';
import './style.scss';

const SwipeRight = (props) => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  const cs = useStyles();
  const handleClick = () => {
    props.history.push('/signup/');
  };

  return (
    <HomeContainer outerStyles={cx('SwipeRight', cs.SwipeRight__container__outer)}>
      <div className="col-lg-12 text-center">
        <Typography variant="h2" gutterBottom>
          Swipe Right
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Are you keen to focus on your cardiology diagnosis skills? Perhaps endocrinology? We’ve got all systems
          covered. Keep swiping until you find the patient you want - don’t worry, they won’t be offended!
        </Typography>
      </div>
      <div className={cs.SwipeRight__slide__wrapper}>
        <Slider settings={settings} isMobileArrowHide>
          {dumpCases.map((currentCase) => (
            <div key={currentCase.id}>
              <HoverCard
                className={cx('all-other-cases__hoverCard', cs.SwipeRight__caseCard__root)}
                key={`swipe-right-cases-${currentCase.id}`}
                // backCard={
                //   currentCase.result ? (
                //     <CompletedCard caseObj={currentCase} isSessionAvalible />
                //   ) : (
                //     <BasicCard case={currentCase} />
                //   )
                // }
              >
                <CaseCard case={currentCase} onClick={handleClick} classes={{ root: cs.SwipeRight__caseCard__root }} />
              </HoverCard>
            </div>
          ))}
        </Slider>
      </div>
    </HomeContainer>
  );
};

SwipeRight.propTypes = {
  history: PropTypes.object,
};

export default withRouter(SwipeRight);
