import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  FeedbackModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 790,
    height: 745,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    padding: 60,
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: 'auto',
      padding: '30px 15px 15px 15px',
    },
  },
  FeedbackModal__exit: {
    position: 'absolute',
    top: 15,
    right: 10,
    fontWeight: 300,
  },
  FeedbackModal__content: {
    // marginTop: -15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '85%',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      width: '90%',
    },
  },
  FeedbackModal__image: {
    maxHeight: 250,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 180,
    },
  },
  FeedbackModal__title: {
    fontSize: 30,
    color: theme.palette.text.main,
    marginBottom: 10,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 23,
    },
  },
  FeedbackModal__subtitle: {
    fontSize: 20,
    color: theme.palette.text.main,
    marginBottom: 15,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  FeedbackModal__faces: {
    display: 'flex',
    marginBottom: 30,
  },
  FeedbackModal__facces__emoji: {
    fontSize: 60,
    margin: '0 15px',
    cursor: 'pointer',
  },
  FeedbackModal__text: {
    fontSize: 20,
    color: theme.palette.text.main,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  FeedbackModal__emoji__tooltip: {
    borderColor: theme.palette.secondary.main,
    padding: '10px 16px',
  },
  FeedbackModal__emoji__tooltip__normal: {
    padding: '10px 16px',
  },
  FeedbackModal__emoji__arrow: {
    color: theme.palette.secondary.main,
  },
  FeedbackModal__textarea: {
    borderRadius: 10,
    color: theme.palette.text.main,
    resize: 'none',
    padding: '10px 16px',
    border: `1.5px solid ${theme.palette.text.main}4D`,
    marginBottom: 15,
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: `${theme.palette.text.main}99`,
    },
  },
  FeedbackModal__wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  FeedbackModal__error: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontSize: 14,
    marginTop: 10,
  },
  FeedbackModal__btn: {
    height: 43,
    fontWeight: 500,
  },
}));

export default useStyles;
