import * as Sentry from '@sentry/react';
import { findIndex } from 'lodash';
import { APIClient, FileClient } from '../settings';
import * as Api from '../service/api';
import { trackEvent, ecommerceTrack } from '../service/analytics';

import {
  USER_LOADED,
  USER_LOADING,
  LOGOUT_SUCCESS,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  CLEAR_AUTH_ERRORS,
  SET_MODAL_TO_SHOW,
  SET_REFERRAL_CODE,
  SET_INVITED_USERS,
  SET_RESTORE_EMAIL_SENT,
  SET_USER_DETAIL_ERRORS,
  SET_SEND_EMAIL_TO_BE_REMINDED,
  SET_VERIFIED,
  SET_SHOW_LOGOUT_MODAL,
  SET_INSENTIVE_MODAL,
  UPDATE_AUTH_USER,
  GET_ERRORS,
  SKIP_VERIFY,
  NOTIFICATION_SET,
} from './types';
import { SUBSCRIPTION_ENDS_IN_MODAL, TRIAL_FINISHED_MODAL, UPGRADE_TO_PRIME_MODAL } from '../reducers/dashboard';

// import store from '../store';
// import { fetchPaymentMethods } from './billing';

export const setUser = (user) => ({
  type: USER_LOADED,
  user,
});

export const setShowLogoutModal = (value) => ({
  type: SET_SHOW_LOGOUT_MODAL,
  value,
});

export const setLogout = () => ({
  type: LOGOUT_SUCCESS,
});

export const loginSuccess = (token) => ({
  type: LOGIN_SUCCESS,
  payload: { token },
});

export const returnErrors = (msg, status) => ({
  type: GET_ERRORS,
  payload: { msg, status },
});

export const setNofication = (notification) => ({
  type: NOTIFICATION_SET,
  notification,
});

export const getNotification = () => APIClient.get('/api/v1/notification/system/');

export const patchNotification = (notification_id, is_read) => (dispatch, getState) =>
  APIClient.patch('/api/v1/notification/system/', { notification_id, is_read })
    .then((notiRes) => {
      // This api only returns current notification, so need to immutably update
      // dispatch(setNofication(notiRes.data));
    })
    .catch((err) => {
      Sentry.captureException(err, {
        tags: {
          section: 'System Notification Error',
        },
      });
    })
    .then(() => {
      // If error then set is read to true to temporally close the modal
      const { notification } = getState().auth;
      const notiIndex = findIndex(notification, (n) => n.id === notification_id);
      if (notiIndex >= 0) {
        dispatch(
          setNofication(notification.map((noti, index) => (index === notiIndex ? { ...noti, is_read: true } : noti)))
        );
      }
    });

// CHECK TOKEN & LOAD USER
export const loadUser = (options) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // User Loading
    if (!getState().auth.token) {
      dispatch(setLogout());
      config.headers.Authorization = '';
      localStorage.removeItem('token');
      return;
    }
    if (!options || !options.dontDispatchLoading) {
      dispatch({ type: USER_LOADING });
    }
    const { token } = getState().auth;
    if (token) APIClient.defaults.headers.Authorization = `Token ${token}`;

    APIClient.get('/api/v1/auth/user/')
      .then((res) => {
        // update axios default header
        dispatch(setUser(res.data));
        getNotification()
          .then((notiRes) => {
            dispatch(setNofication(notiRes.data.filter((noti) => !noti.is_read)));
          })
          .catch((err) => {
            Sentry.captureException(err, {
              tags: {
                section: 'System Notification Error',
              },
            });
          });

        const user = res.data;

        resolve(user);
        // If not verified, set value to false, them the verified modal should display, for now we use exp instead
        // if (!user.experience) {
        //   dispatch({ type: SET_VERIFIED, value: false });
        // }
        if (user.is_trial && user.subscription_days_left < 0) {
          // user was on trial period, which has ended
          // show appropriate window in the dashboard
          dispatch({ type: SET_MODAL_TO_SHOW, value: TRIAL_FINISHED_MODAL });
          return;
        }
        if (!user.is_trial && (user.subscription_days_left === null || user.subscription_days_left < 0)) {
          // user is not on trial period, and his subscription has expired (or he has never had subscription)
          // show him `upgrade to Prime` invitation window
          dispatch({ type: SET_MODAL_TO_SHOW, value: UPGRADE_TO_PRIME_MODAL });
          return;
        }
        if (!user.is_trial && user.subscription_days_left >= 0 && user.subscription_days_left <= 7) {
          // user is not on trial period, and his subscrption will end soon
          // show him `you trial period will end in {} days
          dispatch({ type: SET_MODAL_TO_SHOW, value: SUBSCRIPTION_ENDS_IN_MODAL });
        }

        // When user loaded with 0 session, then popup get more session modal
        if (user.profile.available_session <= 0 && !user.prime_status) {
          dispatch({ type: SET_INSENTIVE_MODAL, value: true });
        }
      })
      .catch((err) => {
        dispatch(setLogout());
        config.headers.Authorization = '';
        localStorage.removeItem('token');
        window.location.href = '/';
        reject(err);
      });
    // Promise.all([getUserInfo])
    //   .then((values) => {
    //     resolve(values);
    //   })
    //   .catch((errs) => {
    //     reject(errs);
    //   });
  });

// Empty Headers
const config = { headers: {} };

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  APIClient.post('/api/v1/auth/logout/', null, tokenConfig(getState))
    .then(() => {
      window.location.href = '/';
      localStorage.removeItem('is_new_user');
      setTimeout(() => {
        dispatch(setLogout());
        config.headers.Authorization = '';
        localStorage.removeItem('token');
      }, 700);
    })
    .catch(() => {
      config.headers.Authorization = '';
      localStorage.removeItem('token');
      window.location.href = '/';
    });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const { token } = getState().auth;

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
};

// Setup log in process
export const signIn = (username, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIClient.post(`/api/v1/auth/login/`, { username, password })
      .then(async (res) => {
        await dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        const { token } = res.data;
        localStorage.setItem('token', token);
        APIClient.defaults.headers.Authorization = `Token ${token}`;
        FileClient.defaults.headers.Authorization = `Token ${token}`;
        dispatch(loadUser());
        resolve(res);

        // dispatch(fetchPaymentMethods());
      })
      .catch((err) => reject(err));
  });

// Setup sign up process
export const signUp = (email, password, promo_code, countryCode) => (dispatch) => {
  dispatch(clearAuthErrors());
  return new Promise((resolve, reject) => {
    Api.register(email, password, promo_code, countryCode)
      .then((res) => {
        dispatch({ type: REGISTER_SUCCESS, payload: res.data });
        setTimeout(() => {
          const { token } = res.data;
          localStorage.setItem('token', token);
          APIClient.defaults.headers.Authorization = `Token ${token}`;
          FileClient.defaults.headers.Authorization = `Token ${token}`;
          localStorage.setItem('is_new_user', true);
        }, 500);

        dispatch(loadUser());
        resolve(res);
        // history.push('/signup_success');
      })
      .catch((err) => {
        // Check if error response is empty or not
        if (err.response) {
          if (err.response?.status >= 400 && err.response?.status < 500) {
            dispatch({
              type: AUTH_ERROR,
              error: err.response.data,
            });
          } else {
            dispatch({
              type: AUTH_ERROR,
              error: { non_field_errors: [err.response.data] },
            });
          }
        } else {
          dispatch({
            type: AUTH_ERROR,
            error: `Sorry, there's something wrong with the network. Please try again later.`,
          });
        }
        reject(err);
      });
  });
};

export const googleSignUp = (token, promo_code, country) => (dispatch) => {
  dispatch(clearAuthErrors());
  Api.googleSignUp(token, promo_code, country)
    .then((res) => {
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      dispatch(loadUser());
      const isSignUp = res.data.created;
      trackEvent({
        GAAction: `${isSignUp ? 'sign_up' : 'login'}_google`,
        FBAction: `${isSignUp ? 'Signup' : 'Login'} Google`,
        category: `${isSignUp ? 'Signup' : 'Login'}`,
        label: `User ${res.data.user.id} ${isSignUp ? 'Signup' : 'Login'} By Google`,
      });
      if (isSignUp) {
        const currentDate = new Date();
        ecommerceTrack({
          transactionId: `${res.data.user.id}_${currentDate.getTime()}`,
          value: 0,
          id: 0,
          label: `User ${res.data.user.id} signup by Google`,
          displayName: 'signup',
          category: 'Free',
          price: 0,
          period: 0,
          time: currentDate,
        });
      }
      setTimeout(() => {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('is_new_user', true);
      }, 500);
    })
    .catch((err) => {
      // Check if error response is empty or not
      if (err.response) {
        if (err.response?.status >= 400 && err.response?.status < 500) {
          dispatch({
            type: AUTH_ERROR,
            error: err.response.data,
          });
        } else {
          dispatch({
            type: AUTH_ERROR,
            error: { non_field_errors: err.response.data.non_field_errors },
          });
        }
      } else {
        dispatch({
          type: AUTH_ERROR,
          error: `Sorry, there's something wrong with the network. Please try again later.`,
        });
      }
    });
};

export const facebookSignUp = (token, promo_code, country) => (dispatch) => {
  dispatch(clearAuthErrors());
  Api.facebookSignUp(token, promo_code, country)
    .then((res) => {
      dispatch({ type: REGISTER_SUCCESS, payload: res.data });
      dispatch(loadUser());
      const isSignUp = res.data.created;
      trackEvent({
        GAAction: `${isSignUp ? 'sign_up' : 'login'}_facebook`,
        FBAction: `${isSignUp ? 'Signup' : 'Login'} Facebook`,
        category: `${isSignUp ? 'Signup' : 'Login'}`,
        label: `User ${res.data.user.id} ${isSignUp ? 'Signup' : 'Login'} By Facebook`,
      });
      if (isSignUp) {
        const currentDate = new Date();
        ecommerceTrack({
          transactionId: `${res.data.user.id}_${currentDate.getTime()}`,
          value: 0,
          id: 0,
          label: `User ${res.data.user.id} signup by Facebook`,
          displayName: 'signup',
          category: 'Free',
          price: 0,
          period: 0,
          time: currentDate,
        });
      }
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('is_new_user', isSignUp);
    })
    .catch((err) => {
      // Check if error response is empty or not
      if (err.response) {
        if (err.response?.status >= 400 && err.response?.status < 500) {
          dispatch({
            type: AUTH_ERROR,
            error: err.response.data,
          });
        } else {
          dispatch({
            type: AUTH_ERROR,
            error: { non_field_errors: [err.response.data] },
          });
        }
      } else {
        dispatch({
          type: AUTH_ERROR,
          error: `Sorry, there's something wrong with the network. Please try again later.`,
        });
      }
    });
};

// Clean the auth errors which were occured in sign in and sign up process.
export const clearAuthErrors = () => ({
  type: CLEAR_AUTH_ERRORS,
});

export const getReferralCode = () => (dispatch, getState) => {
  if (!getState().auth.refCode) {
    APIClient.get('/referral/get_code/', tokenConfig(getState)).then((res) => {
      dispatch({
        type: SET_REFERRAL_CODE,
        code: res.data.referral_code,
      });
    });
  }
};
export const sendEmailToBeReminded = (email) => (dispatch) => {
  dispatch({
    type: SET_SEND_EMAIL_TO_BE_REMINDED,
    email,
  });
};

export const fetchInvitedUsers = () => (dispatch, getState) => {
  APIClient.get('/referral/invited_users/', tokenConfig(getState)).then((res) => {
    dispatch({
      type: SET_INVITED_USERS,
      users: res.data,
    });
  });
};

// forgot password
export const forgotPassword = (email) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(clearAuthErrors());
    Api.forgotPassword(email)
      .then(() => {
        dispatch({
          type: SET_RESTORE_EMAIL_SENT,
          value: true,
        });
        resolve();
      })
      .catch((err) => {
        // Check if error response is empty or not
        if (err.response) {
          if (err.response?.status >= 400 && err.response?.status < 500) {
            dispatch({
              type: AUTH_ERROR,
              error: err.response.data,
            });
          } else {
            dispatch({
              type: AUTH_ERROR,
              error: { non_field_errors: [err.response.data] },
            });
          }
        } else {
          dispatch({
            type: AUTH_ERROR,
            error: { non_field_errors: [err] },
          });
        }
        reject(err);
      });
  });

// reset password
export const resetPassword = (password, key) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(clearAuthErrors());
    Api.resetPassword(password, key)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // Check if error response is empty or not
        if (err.response) {
          if (err.response?.status >= 400 && err.response?.status < 500) {
            dispatch({
              type: AUTH_ERROR,
              error: err.response.data,
            });
          } else {
            dispatch({
              type: AUTH_ERROR,
              error: { non_field_errors: [err.response.data] },
            });
          }
        } else {
          dispatch({
            type: AUTH_ERROR,
            error: { non_field_errors: [err] },
          });
        }
      });
  });

export const patchUserDetails = (args) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/auth/user/`, args, tokenConfig(getState))
      .then((res) => {
        dispatch(setUser(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.status >= 400 && err.response?.status < 500) {
            dispatch({
              type: SET_USER_DETAIL_ERRORS,
              error: err.response.data,
            });
          } else {
            dispatch({
              type: SET_USER_DETAIL_ERRORS,
              error: { non_field_errors: [err.response.data] },
            });
          }
        } else {
          dispatch(
            returnErrors({ detail: `Sorry, there's something wrong with the network. Please try again later.` }, 404)
          );
        }
        reject(err);
      });
  });

export const postChangePassword = (password) => (_, getState) =>
  new Promise((resolve, reject) => {
    APIClient.post('/api/v1/auth/change_pass/', password)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getActivationEmail = () => (_, getState) => APIClient.get('/api/v1/auth/email/', tokenConfig(getState));

export const postVerifyEmailCode = (args) => (dispatch, getState) =>
  APIClient.post('/api/v1/auth/email/', args, tokenConfig(getState)).then((res) => {
    dispatch({
      type: USER_LOADED,
      user: res.data.user,
    });
  });

export const verifyQuestion = (verifyDetails, marketingProfile, isNewUser) => (dispatch, getState) => {
  const args = {
    profile: verifyDetails,
    marketingprofile: marketingProfile,
    is_first_time: isNewUser,
  };

  return new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/auth/user/`, args, tokenConfig(getState))
      .then((res) => {
        resolve(res);
        dispatch(setUser(res.data));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response?.status >= 400 && err.response?.status < 500) {
            dispatch({
              type: SET_USER_DETAIL_ERRORS,
              error: err.response.data,
            });
          } else {
            dispatch({
              type: SET_USER_DETAIL_ERRORS,
              error: { non_field_errors: [err.response.data] },
            });
          }
        } else {
          dispatch(
            returnErrors({ detail: `Sorry, there's something wrong with the network. Please try again later.` }, 404)
          );
        }
        reject(err);
      });
  });
};

export const setVerified = (value) => ({
  type: SET_VERIFIED,
  value,
});

export const setSkipVerify = () => ({
  type: SKIP_VERIFY,
});

export const patchPetition = () => (dispatch, getState) =>
  APIClient.patch('/api/v1/auth/petition/', null, tokenConfig(getState)).then((res) => {
    dispatch({ type: UPDATE_AUTH_USER, value: res.data });
  });

export const getUserProfile = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.get('/api/v1/auth/user/', tokenConfig(getState))
      .then((res) => {
        dispatch({ type: UPDATE_AUTH_USER, value: res.data });
        resolve(res);
      })
      .catch((err) => {
        reject(reject);
      });
  });

// This api should a patch request, so we name it patchEmailConfirm
export const patchEmailConfirm = (activationKey) => () =>
  new Promise((resolve, reject) => {
    APIClient.get(`/api/v1/auth/activation/${activationKey}/`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const getCustomisedSignupInfo = (params) => () =>
  new Promise((resolve, reject) => {
    APIClient.get(`/api/v1/customised-page/info/`, { params })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
