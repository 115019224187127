import produce from 'immer';
import {
  CREATE_MESSAGE,
  CLEAR_MESSAGES,
  DELETE_LOADING_MESSAGE,
  MESSAGES_SET,
  DELETE_COMPOUND_MESSAGE,
  COMPOUND_SELECTED_OPT_SET,
  MESSAGES_READ,
} from '../actions/types';

const initialState = {
  messages: [], // This state manages all the messages between agent and patient
};

const messageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const sessionId = localStorage.getItem('sessionId');
    const sessions = JSON.parse(localStorage.getItem('sessions')) || {};
    switch (action.type) {
      case CREATE_MESSAGE:
        draft.messages.push(action.message);
        if (sessions[sessionId]) {
          sessions[sessionId].messages = draft.messages;
          localStorage.setItem('sessions', JSON.stringify(sessions));
        }
        break;

      case CLEAR_MESSAGES:
        draft.messages = [];
        break;

      case DELETE_LOADING_MESSAGE:
        draft.messages.pop();
        break;

      case MESSAGES_SET:
        draft.messages = action.messages;
        break;

      case DELETE_COMPOUND_MESSAGE:
        draft.messages.pop();
        break;

      case COMPOUND_SELECTED_OPT_SET:
        draft.messages[draft.messages.length - 1].selectedOpt = action.option;
        break;

      case MESSAGES_READ:
        draft.messages[action.index].read = true;

      // no default
    }
  });

export default messageReducer;
