import produce from 'immer';
import {
  CONDTIION_SELECTED,
  ALL_QUIZZES_SET,
  RECOMMENDED_QUIZZES_SET,
  RECOMMENDED_QUIZ_SELECTED,
  FILTERED_QUIZZES_SET,
} from '../actions/types';

const initialState = {
  selectedConditionIndex: 0,
  allQuizzes: [],
  recommendedQuizzes: [],
  selectedRecommendedQuizIndex: 0,
  filteredQuizzes: [],
};

const authReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CONDTIION_SELECTED:
        draft.selectedConditionIndex = action.index;
        break;
      case ALL_QUIZZES_SET:
        draft.allQuizzes = action.allQuizzes;
        break;
      case RECOMMENDED_QUIZZES_SET:
        draft.recommendedQuizzes = action.recommendedQuizzes;
        break;
      case RECOMMENDED_QUIZ_SELECTED:
        draft.selectedRecommendedQuizIndex = action.index;
        break;
      case FILTERED_QUIZZES_SET:
        draft.filteredQuizzes = action.cases;
        break;
      default:
        break;
    }
  });

export default authReducer;
