import { makeStyles } from '@material-ui/core/styles';
import HomeHeroBG from '../../../assets/home/section-hero.svg';

const useStyles = makeStyles((theme) => ({
  HeroSection: {
    background: `url(${HomeHeroBG})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    maxWidth: 1920,
    margin: '0 auto',
    marginBottom: 120,
    [theme.breakpoints.down('md')]: {
      marginBottom: 60,
    },
  },
  HeroSection__contents: {
    paddingTop: 240,
    paddingBottom: 330,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: 240,
      paddingBottom: 30,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 80,
    },
  },
  HeroSection__text: {
    flex: 1,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: 10,
    },
  },
  HeroSection__video: {
    flex: 1,
    position: 'relative',
    maxWidth: '50%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
    },
  },
  HeroSection__video__player: {
    maxWidth: 540,
    '& video': {
      borderRadius: 16,
      boxShadow: '0 4px 15px 0 rgba(7, 42, 68, 0.1)',
    },
  },
  HeroSection__title: {
    marginBottom: 24,
    marginRight: 10,
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
      marginRight: 0,
      maxWidth: 'auto',
    },
  },

  HeroSection__subTitle: {
    marginBottom: 28,
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
      maxWidth: 'auto',
    },
  },
  HeroSection__description: {
    fontSize: 18,
    lineHeight: 1.44,
    marginBottom: 50,
    fontWeight: 'normal',
    maxWidth: 500,
    color: theme.palette.text.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 25,
      maxWidth: 'auto',
    },
  },
  HeroSection__signUpBtn: {
    padding: '21px 45px',
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 400,
    '&:hover': {
      color: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  HeroSection__oscerCharacter: {
    position: 'absolute',
    width: '55%',
    maxWidth: 280,
    bottom: -240,
    left: -208,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  HeroSection__signin: {
    marginLeft: 40,
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      margin: '-10px 0 10px 0',
    },
  },
  HeroSection__signin__link: {
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
}));

export default useStyles;
