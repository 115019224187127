export const dumpCases = [
  {
    id: 2,
    patient_name: 'Alex Whitaker',
    max_score: 20,
    gender: 'Male',
    patient_age: 56,
    system: 'respiratory',
    presentation: 'Shortness_Of_Breath',
    diagnosis: 'COPD',
    case_level: 2,
    categories: [{ category_name: 'recommended' }],
    result: { max_score: 20, stars: 0, score: 1 },
  },
  {
    id: 11,
    patient_name: 'Grace Conroy',
    max_score: 20,
    gender: 'Female',
    patient_age: 20,
    system: 'neuro',
    presentation: 'Headache',
    diagnosis: 'Migraine',
    case_level: 2,
    categories: [],
    result: null,
  },
  {
    id: 21,
    patient_name: 'Larry McMurray',
    max_score: 20,
    gender: 'Male',
    patient_age: 70,
    system: 'gastro',
    presentation: 'Rectal_Bleeding',
    diagnosis: 'Peptic_Ulcer_Disease',
    case_level: 1,
    categories: [],
    result: null,
  },
  {
    id: 22,
    patient_name: 'Charles Reed',
    max_score: 20,
    gender: 'Male',
    patient_age: 55,
    system: 'cardio',
    presentation: 'Shortness_Of_Breath',
    diagnosis: 'Aortic_Stenosis Sclerosis',
    case_level: 3,
    categories: [],
    result: null,
  },
  {
    id: 23,
    patient_name: 'Anne Buckley',
    max_score: 20,
    gender: 'Female',
    patient_age: 67,
    system: 'gastro',
    presentation: 'Vomiting',
    diagnosis: 'Ischaemic_colitis',
    case_level: 4,
    categories: [],
    result: null,
  },
  {
    id: 29,
    patient_name: 'Michelle Sned',
    max_score: 20,
    gender: 'Female',
    patient_age: 46,
    system: 'gastro',
    presentation: 'Rectal_Bleeding',
    diagnosis: 'Haemorrhoids',
    case_level: 2,
    categories: [],
    result: null,
  },
  {
    id: 30,
    patient_name: 'Jim Orson',
    max_score: 20,
    gender: 'Male',
    patient_age: 55,
    system: 'gastro',
    presentation: 'Abdominal_Pain',
    diagnosis: 'Small_Bowel_Obstruction',
    case_level: 3,
    categories: [{ category_name: 'recommended' }],
    result: { max_score: 20, stars: 0, score: 2 },
  },
  {
    id: 39,
    patient_name: 'Amira Romero',
    max_score: 20,
    gender: 'Female',
    patient_age: 15,
    system: 'endocrine',
    presentation: 'Fatigue',
    diagnosis: 'Diabetes',
    case_level: 3,
    categories: [],
    result: null,
  },
];
