import produce from 'immer';
import {
  SET_INVITED_REFERRAL_CODE_VALID,
  SET_INVITED_REFERRAL_CODE,
  JOBS_SET,
  JOBS_QUANTITY_SET,
} from '../actions/types';

const initialState = {
  invitedReferralCode: '',
  invitedReferralCodeValid: false, // todo: check if ref code is valid, display in UI
  jobs: [],
  jobsQuantity: 0,
};

const home = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_INVITED_REFERRAL_CODE:
        return {
          ...state,
          invitedReferralCode: action.value,
        };
      case SET_INVITED_REFERRAL_CODE_VALID:
        return {
          ...state,
          invitedReferralCodeValid: action.value,
        };
      case JOBS_SET:
        draft.jobs = action.jobs;
        break;
      case JOBS_QUANTITY_SET:
        draft.jobsQuantity = action.quantity;
        break;
      default:
        break;
    }
  });

export default home;
