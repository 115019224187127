import * as Sentry from '@sentry/react';
import { findIndex } from 'lodash';
import { APIClient, FileClient } from '../settings';
import * as Api from '../service/api';
import { trackEvent, ecommerceTrack } from '../service/analytics';
import store from '../store';

// Empty Headers
const config = { headers: {} };

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const { token } = getState().auth;

  // If token, add to headers config
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
};

const isTracked = (state) =>
  // if (whiteList.includes(window.location.href)) return true;
  !state.auth.user.is_staff &&
  typeof window !== 'undefined' &&
  process.env.REACT_APP_STAGE === 'production' &&
  !state.auth.user.email.includes('+test');

// Send tracking event to backend
export const postTrackEvent = (data) => (dispatch, getState) => {
  const state = store.getState();
  if (isTracked(state)) {
    new Promise((resolve, reject) => {
      APIClient.post(`/api/v1/tracking/event/`, data, tokenConfig(getState))
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }
};
