import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  FuzzyFilter: {
    transition: 'all 0.3s ease',
    position: 'relative',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  FuzzyFilter__InputBase__root: {
    height: 40,
    backgroundColor: 'white',
    borderRadius: 21.5,
    fontSize: 14,
    padding: '0 15px 0 10px',
    color: theme.palette.text.main,
    width: '100%',
    border: 'solid 1px rgba(18, 19, 19, 0.05)',
    '&::placeholder': {
      color: `${theme.palette.text.main}66`,
    },
    '&:hover': {
      border: `solid 2px ${theme.palette.primary.main}`,
    },
  },
  FuzzyFilter__InputBase__root__active: {
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  FuzzyFilter__dropdown: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    backgroundColor: 'white',
    borderRadius: 16,
    maxWidth: 400,
    boxShadow: '0 13px 20px 4px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: 47,
    right: 0,
    left: 0,
    zIndex: 1,
  },
  FuzzyFilter__SearchIcon__root: {
    opacity: 0.4,
  },
  FuzzyFilter__SearchIcon__root__active: {
    color: theme.palette.primary.dark,
  },
}));

export default useStyles;
