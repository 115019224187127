import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Container, Avatar, MenuItem, MenuList } from '@material-ui/core';
import { connect } from 'react-redux';
import { setShowLogoutModal } from '../../../../actions/auth';
import { SYSTEM_COLORS, READABLE_SYSTEM_NAMES, ALL_SYSTEMS2 } from '../../../../constants';
import imgOscer from '../../../../assets/logos/oscer-icon-solid.svg';
import iconCrownGold from '../../../../assets/icons/prime-crown-icon-gold.svg';
import userNameGenerator from '../../../../helpers/userNameGenerator';
import MobileMenuPopover from '../../../MenuPopover/MobileMenuPopover';
import iconStreak from '../../../../assets/icons/streak-stat-icon.svg';
import iconStreakBlank from '../../../../assets/icons/streak-base-stat-icon.svg';
import iconSpecialty from '../../../../assets/icons/specialty-stat-icon.svg';
import iconRanking from '../../../../assets/icons/ranking-stats-icon.svg';
import ordinalSuffix from '../../../../helpers/ordinalSuffix';
import System from '../../../icons/System';
import Logout from '../../Logout';
import useStyles from './style';

const DashboardMobileHeaderNav = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { user, systemStats, casesCompleted, percentile = 80 } = props;
  const [sortedSpecialties, setSortedSpecialties] = useState([]);

  useEffect(() => {
    let array = [];
    let currentSystems = [];
    for (let system of systemStats) {
      currentSystems.push(system.system);
      array.push([system.system, system.mastery]);
    }
    for (let system of ALL_SYSTEMS2) {
      if (!currentSystems.includes(system)) {
        array.push([system, '0']);
      }
    }
    const sorted = sortSpecialties(JSON.parse(JSON.stringify(array)));
    setSortedSpecialties([...sorted]);
  }, [systemStats]);

  const sortSpecialties = (arr) => arr.sort((a, b) => b[1] - a[1]);

  const profileMenu = [
    {
      title: 'Your Profile',
      subtitle: `Achievements, stats & more`,
      link: '/profile',
    },
    // {
    //   title: 'Skills Hub',
    //   subtitle: `Improve your history`,
    //   link: '/dashboard/learn',
    // },
    {
      title: 'Case Results',
      subtitle: `Your completed case results`,
      link: '/case-history',
    },
    {
      title: 'Roadmap',
      subtitle: `Discover new features`,
      link: 'https://oscer.notion.site/beb891e0d6594789a815f9f787e9a614?v=713bf90ee6a44b198cd6e09c075851f1',
    },
    {
      title: 'Logout',
      link: null,
      subtitle: `Bailing on us?`,
      function: () => props.setShowLogoutModal(true),
    },
  ];

  const handleProfile = (link, func) => {
    // if this is a http link then open new tab, othervise redirect
    if (link) {
      if (link.includes('http')) {
        window.open(link, '_blank').focus();
      } else {
        history.push(link);
      }
    } else if (func) {
      func();
    }
  };

  const [menuOpen, setMenuOpen] = useState(null);

  return (
    <div className={classes.DashboardMobileHeaderNav}>
      <Logout />
      <Container
        maxWidth="xs"
        disableGutters
        classes={{
          root: classes.DashboardMobileHeaderNav__Container__root,
        }}
      >
        <div className={classes.DashboardMobileHeaderNav__main}>
          <img src={imgOscer} alt="oscer_logo" className="" onClick={() => history.push('/dashboard/home/')} />
          {props.isPrime ? (
            <>
              <MobileMenuPopover
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                menuIcon={<img src={iconStreak} alt="crown" className={classes.DashboardMobileHeaderNav__menu__icon} />}
                styles={{ paper: classes.DashboardMobileHeaderNav__popover__paper }}
              >
                <div className={classes.DashboardMobileHeaderNav__Menu}>
                  <div className={classes.DashboardMobileHeaderNav__Menu__streak}>
                    <img
                      src={iconStreakBlank}
                      alt="sretak_icon"
                      className={classes.DashboardMobileHeaderNav__Menu__streak__icon}
                    />
                    <span className={classes.DashboardMobileHeaderNav__Menu__streak__num}>3</span>
                    <div className={classes.DashboardMobileHeaderNav__Menu__streak__text}>DAYS</div>
                  </div>
                  <div className={classes.DashboardMobileHeaderNav__Menu__main}>
                    <div>
                      <div className={classes.DashboardMobileHeaderNav__Menu__title}>Your Streak</div>
                      <div className={classes.DashboardMobileHeaderNav__Menu__subtitle}>
                        Let’s see how many days you can complete an Oscer case.
                      </div>
                    </div>
                    <Link
                      onClick={() => setMenuOpen('')}
                      to={props.isPrime ? '/profile' : '/upgrade'}
                      className={classes.DashboardMobileHeaderNav__free__link}
                    >
                      VIEW
                    </Link>
                  </div>
                </div>
              </MobileMenuPopover>
              {props.isPrime && (
                <MobileMenuPopover
                  menuOpen={menuOpen}
                  setMenuOpen={setMenuOpen}
                  menuIcon={
                    <img src={iconSpecialty} alt="crown" className={classes.DashboardMobileHeaderNav__menu__icon} />
                  }
                  styles={{ paper: classes.DashboardMobileHeaderNav__popover__paper }}
                >
                  <div className={classes.DashboardMobileHeaderNav__Menu}>
                    <div className={classes.DashboardMobileHeaderNav__Menu__streak}>
                      {sortedSpecialties.length > 0 && casesCompleted >= 4 ? (
                        <>
                          <System
                            system={sortedSpecialties[0][0]}
                            size={50}
                            className={classes.DashboardMobileHeaderNav__Menu__streak__icon}
                          />
                          <div
                            className={classes.DashboardMobileHeaderNav__Menu__streak__text}
                            style={{ color: SYSTEM_COLORS[sortedSpecialties[0][0]] }}
                          >
                            {READABLE_SYSTEM_NAMES[sortedSpecialties[0][0]]}
                          </div>
                        </>
                      ) : (
                        <>
                          <img
                            src={iconSpecialty}
                            alt="iconSpecialty"
                            className={classes.DashboardHeaderNav__Menu__streak__icon}
                          />
                          <div className={classes.DashboardHeaderNav__Menu__streak__text}></div>
                        </>
                      )}
                    </div>
                    <div className={classes.DashboardMobileHeaderNav__Menu__main}>
                      <div>
                        <div className={classes.DashboardMobileHeaderNav__Menu__title}>Your Specialty</div>
                        <div className={classes.DashboardMobileHeaderNav__Menu__subtitle}>
                          {sortedSpecialties.length > 0 && casesCompleted >= 4
                            ? 'Your best system, where you’ve covered the most cases + scored the best!'
                            : 'You need to complete 4 cases to determine your best Oscer system.'}
                        </div>
                      </div>
                      {casesCompleted < 4 ? (
                        <span
                          className={classes.DashboardHeaderNav__free__link}
                          style={{ color: casesCompleted < 4 && '#00234880' }}
                        >
                          VIEW
                        </span>
                      ) : (
                        <Link
                          onClick={() => setMenuOpen('')}
                          to={props.isPrime ? '/profile' : '/upgrade'}
                          className={classes.DashboardMobileHeaderNav__free__link}
                        >
                          VIEW
                        </Link>
                      )}
                    </div>
                  </div>
                </MobileMenuPopover>
              )}
              <MobileMenuPopover
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                menuIcon={
                  <img src={iconRanking} alt="crown" className={classes.DashboardMobileHeaderNav__menu__icon} />
                }
                styles={{ paper: classes.DashboardMobileHeaderNav__popover__paper }}
              >
                <div className={classes.DashboardMobileHeaderNav__Menu}>
                  <div className={classes.DashboardMobileHeaderNav__Menu__streak}>
                    {percentile < 10 || props.casesCompleted < 4 ? (
                      <img
                        src={iconRanking}
                        alt="oscer_logo"
                        className={classes.DashboardMobileHeaderNav__iconRanking}
                      />
                    ) : (
                      <div className={classes.DashboardMobileHeaderNav__Menu__percentile}>
                        <div className={classes.DashboardMobileHeaderNav__Menu__percentile__num}>
                          {user.statisticprofile
                            ? Math.round(user.statisticprofile?.oscer_percentile_history)
                            : percentile}
                        </div>
                        <div className={classes.DashboardMobileHeaderNav__Menu__percentile__h}>
                          {ordinalSuffix(
                            user.statisticprofile
                              ? Math.round(user.statisticprofile?.oscer_percentile_history)
                              : percentile
                          )}
                        </div>
                      </div>
                    )}
                    <div className={classes.DashboardMobileHeaderNav__Menu__streak__text}>Percentile</div>
                  </div>
                  <div className={classes.DashboardMobileHeaderNav__Menu__main}>
                    <div>
                      <div className={classes.DashboardMobileHeaderNav__Menu__title}>Your Percentile</div>
                      <div className={classes.DashboardMobileHeaderNav__Menu__subtitle}>
                        {props.casesCompleted < 4
                          ? 'You need to complete 4 cases to determine your percentile.'
                          : `You are in the top ${
                              user.statisticprofile
                                ? 100 - Math.round(user.statisticprofile?.oscer_percentile_history)
                                : percentile
                            }% of all Oscer users!`}
                      </div>
                    </div>
                    {casesCompleted < 4 ? (
                      <span
                        className={classes.DashboardHeaderNav__free__link}
                        style={{ color: casesCompleted < 4 && '#00234880' }}
                      >
                        VIEW
                      </span>
                    ) : (
                      <Link
                        onClick={() => setMenuOpen('')}
                        to={props.isPrime ? '/profile' : '/upgrade'}
                        className={classes.DashboardMobileHeaderNav__free__link}
                      >
                        VIEW
                      </Link>
                    )}
                  </div>
                </div>
              </MobileMenuPopover>
            </>
          ) : (
            <MobileMenuPopover
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              menuIcon={
                <div className={classes.DashboardMobileHeaderNav__free__icon__wrapper}>
                  <img src={iconCrownGold} alt="crown" className={classes.DashboardMobileHeaderNav__free__icon} />
                </div>
              }
              styles={{ paper: classes.DashboardMobileHeaderNav__popover__paper }}
            >
              <div className={classes.DashboardMobileHeaderNav__Menu}>
                <img src={iconCrownGold} alt="crown" className={classes.DashboardMobileHeaderNav__free__img} />
                <div className={classes.DashboardMobileHeaderNav__Menu__main}>
                  <div className={classes.DashboardMobileHeaderNav__Menu__title}>Prime Upgrade</div>
                  <div className={classes.DashboardMobileHeaderNav__Menu__subtitle}>
                    Get full access to all cases and all modes with Prime.
                  </div>
                  <Link
                    onClick={() => setMenuOpen('')}
                    to="/upgrade"
                    className={classes.DashboardMobileHeaderNav__free__link}
                  >
                    UPGRADE
                  </Link>
                </div>
              </div>
            </MobileMenuPopover>
          )}
          <MobileMenuPopover
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            menuIcon={
              <Avatar className={classes.DashboardMobileHeaderNav__avatar}>
                {userNameGenerator(user)
                  .split(' ')
                  .map((nameCap) => (
                    <span key={nameCap} className={classes.DashboardMobileHeaderNav__avatar__name}>
                      {nameCap.charAt(0).toUpperCase()}
                    </span>
                  ))}
                {props.isPrime && (
                  <img src={iconCrownGold} alt="crown" className={classes.DashboardMobileHeaderNav__avatar__crown} />
                )}
              </Avatar>
            }
            styles={{ paper: classes.DashboardMobileHeaderNav__popover__paper }}
          >
            <div className={classes.DashboardHeaderNav__profile__wrapper}>
              <MenuList id="menu-list-grow">
                {profileMenu.map((menu) => (
                  <MenuItem
                    key={menu.title}
                    classes={{ root: classes.HomeHeader__profile__MenuItem }}
                    onClick={() => {
                      handleProfile(menu.link, menu.function);
                      setMenuOpen('');
                    }}
                  >
                    <div
                      className={cx(
                        { [classes.HomeHeader__btn__active]: menu.link === 0 },
                        classes.HomeHeader__profile__title
                      )}
                    >
                      <span>{menu.title}</span>
                      {/* <span className={classes.HomeHeader__profile__subtitle}> {menu.subtitle} </span> */}
                      {menu.number && <div className={classes.HomeHeader__profile__num}>{menu.number}</div>}
                    </div>
                    <div className={classes.HomeHeader__profile__subtitle}>{menu.subtitle}</div>
                  </MenuItem>
                ))}
              </MenuList>
            </div>
          </MobileMenuPopover>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  systemStats: state.auth.user.systems,
  casesCompleted: state.auth.user.profile?.num_attempted_cases,
  isPrime: state.auth.user.prime_status,
});

DashboardMobileHeaderNav.propTypes = {
  user: PropTypes.object,
  isPrime: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, { setShowLogoutModal })(DashboardMobileHeaderNav));
