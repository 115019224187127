import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RoundButton } from '../../components/buttons';
import FacebookIcon from '../../assets/common/facebook-signin-icon.svg';
import { facebookSignUp, googleSignUp } from '../../actions/auth';
import { AUTH_ERROR } from '../../actions/types';
import * as style from './style.module.scss';

const SocialAuthPanel = (props) => {
  const { country = 'au' } = props;
  const [backDropOpen, setBackDropOpen] = useState(false);

  let isGoogleClicked = false;

  const promoCode = new URLSearchParams(props.location?.search).get('promo_code') ?? '';

  const handleGoogleResponse = (response) => {
    // send authentication request to the backend
    props.dispatch(googleSignUp(response.credential, promoCode, country));
    setBackDropOpen(false);
  };

  const handleGoogleFailResponse = (response) => {
    // show user a error
    if (isGoogleClicked) {
      props.dispatch({
        type: AUTH_ERROR,
        error: 'Failed to authenticate with Google',
      });
      setBackDropOpen(false);
    }

    // log details to the console
    // console.log('Failed to authenticate with Google');
  };

  // });

  const handleGoogleClick = () => {
    isGoogleClicked = true;
    setBackDropOpen(true);
  };

  const handleFacebookLogin = () => {
    if (window.FB) {
      setBackDropOpen(true);
      window.FB.login((response) => {
        if (response?.status === 'connected') {
          // Logged into your webpage and Facebook.
          props.dispatch(facebookSignUp(response.authResponse.accessToken, promoCode, country));
        } else {
          // The person is not logged into your webpage or we are unable to tell.
          props.dispatch({
            type: AUTH_ERROR,
            error: 'Failed to authenticate with Facebook',
          });
          setBackDropOpen(false);
        }
      });
    } else {
      props.dispatch({
        type: AUTH_ERROR,
        error: 'Facebook not initialised',
      });
      setBackDropOpen(false);
    }
  };

  return (
    <div className={[style['social-wrapper']]} style={{ display: 'flex', alignItems: 'center' }}>
      <RoundButton onClick={handleFacebookLogin} style={{ width: 40, height: 40, marginRight: 15 }}>
        <img src={FacebookIcon} alt="facebook_icon" />
      </RoundButton>

      <div onClick={handleGoogleClick}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            handleGoogleResponse(credentialResponse);
          }}
          onError={handleGoogleFailResponse}
          intermediate_iframe_close_callback={() => setBackDropOpen(false)}
          type="icon"
          shape="circle"
          theme="filled_blue"
        />
      </div>

      <Backdrop
        open={backDropOpen}
        style={{
          zIndex: 100,
          color: '#fff',
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

SocialAuthPanel.propTypes = {
  dispatch: PropTypes.func,
  country: PropTypes.string,
};

export default withRouter(connect(null, (dispatch) => ({ dispatch }))(SocialAuthPanel));
