import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ReferralModal: {
    height: 728,
    width: 790,
    background: 'white',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    position: 'relative',
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.down('xs')]: {
      height: '90vh',
      borderRadius: 0,
      padding: 25,
    },
  },
  ReferralModal__exit: {
    position: 'absolute',
    top: 30,
    right: 30,
    fontWeight: 300,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  ReferralModal__exit__icon: {
    display: 'none',
    position: 'absolute',
    top: 30,
    right: 30,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  ReferralModal__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  ReferralModal__img: {
    margin: '30px 0',
    maxHeight: 250,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 200,
      maxWidth: 'calc(100vw * 0.8)',
      margin: '10px 0',
    },
  },
  ReferralModal__title: {
    fontSize: 30,
    color: theme.palette.text.main,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
      marginBottom: 15,
    },
  },
  ReferralModal__subtitle: {
    fontSize: 20,
    color: theme.palette.text.main,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginBottom: 15,
    },
  },
  ReferralModal__share: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 300,
    marginBottom: 5,
  },
  ReferralModal__btn: {
    display: 'flex',
    borderRadius: 44,
    border: 'solid 2px #4a84ff',
    backgroundColor: 'rgba(74, 132, 255, 0.1)',
    height: 50,
    width: 300,
    overflow: 'hidden',
    marginBottom: 20,
  },
  ReferralModal__code: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '66.6%',
    fontSize: 35,
    color: theme.palette.primary.main,
    alignSelf: 'center',
  },
  ReferralModal__copy: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '34%',
    alignSelf: 'center',
    fontSize: 18,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
  },
  ReferralModal__share__btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '80%',
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  ReferralModal__share__email: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 43,
    width: 140,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  ReferralModal__share__facebook: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 43,
    width: 140,
    backgroundColor: '#3b5998',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  ReferralModal__share__twitter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 43,
    width: 140,
    backgroundColor: '#1da1f2',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  ReferralModal__share__icon: {
    marginRight: 5,
    maxWidth: 26,
    maxHeight: 27,
  },
  ReferralModal__share__fb__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ReferralModal__fb__text__wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: '#f6c89f',
    lineHeight: 1,
    marginTop: 5,
  },
  ReferralModal__fb__text: {
    fontSize: 10,
  },
}));

export default useStyles;
