import { useState, useEffect } from 'react';
import axios from 'axios';

const useGeoLocation = (countryCode) => {
  const [country, setCountry] = useState(countryCode);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const api = 'https://api.country.is';

  useEffect(() => {
    let isCancelled = false;

    // if (country || country === false) return;
    const fetchAPI = async () => {
      setIsLoading(true);
      await axios
        .get(api)
        .then((res) => {
          if (res.data?.country && !isCancelled) {
            setCountry(res.data.country === 'GB' ? 'uk' : res.data.country.toLowerCase());
          } else {
            setError(true);
          }
        })
        .catch((err) => setError(err))
        .then(() => setIsLoading(false));
    };
    fetchAPI();
    return () => {
      isCancelled = true;
    };
  }, []);

  return { country, error, isLoading };
};

export default useGeoLocation;
