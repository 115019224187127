import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import img from '../../../assets/character/oscer-tour-welcome.svg';
import doneImg from '../../../assets/character/oscer-tour-start-diagnosing.svg';

const useStyles = makeStyles((theme) => ({
  UserTour__welcome: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  UserTour__welcome__img: {
    maxWidth: 400,
    marginBottom: 20,
  },
  UserTour__welcome__marginBottom: {
    marginBottom: 20,
  },
  UserTour__welcome__subtitle: {
    marginBottom: 30,
    color: theme.palette.text.main,
    width: '80%',
    textAlign: 'center',
  },
  UserTour__welcome__btn: {
    height: 40,
  },
  UserTour__content__1: {
    display: 'flex',
    flexDirection: 'column',
  },
  UserTour__title__1: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__subtitle__1: {
    fontSize: 15,
    lineHeight: 1.33,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__btn__1: {
    height: 34,
    width: 78,
    fontSize: 14,
    fontWeight: 500,
    alignSelf: 'flex-end',
    padding: 0,
  },
}));

const WelcomePage = (handleUserTourClose, setTourPage, setCompleted, isPrime) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  return [
    {
      content: () => (
        <div className={classes.UserTour__welcome}>
          <img src={img} alt="img" className={classes.UserTour__welcome__img} />
          <Typography variant="h4" className={classes.UserTour__welcome__marginBottom}>
            Welcome to Oscer!
          </Typography>
          <div className={classes.UserTour__welcome__subtitle}>
            Here you'll find the freedom to master diagnosis anywhere with hundreds of intriguing cases where you get
            detailed instant feedback.
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCompleted('welcome_flag');
            }}
            classes={{ root: classes.UserTour__welcome__btn }}
          >
            Let's do it
          </Button>
        </div>
      ),
      style: {
        maxWidth: 660,
        minWidth: 350,
        height: 600,
        borderRadius: 16,
        boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        borderBottom: '19px solid #4a84ff',
        display: 'flex',
        borderTop: 'none',
      },
    },
  ];
};

WelcomePage.propTypes = {
  handleUserTourClose: PropTypes.func,
};

export default WelcomePage;
