// user groups
export const UNIMELB_STUDENTS = 'Unimelb Students';
export const UNIMELB_TUTORS = 'Unimelb Tutors';
export const UNIMELB_USERS = 'Unimelb Users';
export const CASE_WRITERS = 'Case Writers';
export const CASE_STAFFS = 'Case Staff';
export const MARKETING = 'Marketing';
export const DAILY_TASK = 'Daily Task';
export const UK_USERS = 'UK User';
export const NORMAL_USERS = 'Normal Users';
export const CONTENT_TEAM_STAFFS = 'Content Team Staff';
export const GOOGLE_USERS = 'Google Users';

// MESSAGE TYPES
export const INCOMING_MESSAGE = 'INCOMING_MESSAGE';
export const OUTGOING_MESSAGE = 'OUTGOING_MESSAGE';
export const LOADING_MESSAGE = 'LOADING_MESSAGE';
export const SEPERATOR_MESSAGE = 'SEPERATOR_MESSAGE';
export const PARTIALLY_COMPLETE_MESSAGE = 'PARTIALLY_COMPLETE_MESSAGE';

// Session Mode Type
export const OSCE_HISOTRY = 'OSCE_HISOTRY';
export const LEARN_MODE = 'LEARN_MODE';

// external app IDs/client IDs
// export const PAYPAL_CLIENT_ID = 'AayH29RSmuVQc4ZKBnmwrqp9F377YinHZmFWFsLxYZW31vgYiroyqHH4TqE1ACyQ32OdI57rOTd6dPo8';
// export const STRIPE_PUBLIC_KEY = 'pk_test_ShNV7vCkzOHCPCygs9ATfwpT';
// export const GOOGLE_CLIENT_ID = '80643761443-5j6t0ierlavqn1pv40ubefvvo9ghoqrm.apps.googleusercontent.com';
// export const FACEBOOK_APP_ID = '700933547108597';

export const PAYPAL_CLIENT_ID = 'AZsOj4FGCkWM948gykKGMY_wcdirQ6MC58qEA3XupL5J_q2YK4lzXGh2aacGuPPuRbF17Wh7FfR6Dw44';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const GOOGLE_CLIENT_ID = '1025187075574-leg6tbhd47q98nguhe33cpf68it5qmpg.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '2155349584599222';
export const SYSTEM_CARDIO = 'cardio';
export const SYSTEM_CARDIO2 = 'Cardiovascular';
export const SYSTEM_DERMATOLOGY = 'dermatology';
export const SYSTEM_DERMATOLOGY2 = 'Dermatology';
export const SYSTEM_ENDOCRINE = 'endocrine';
export const SYSTEM_ENDOCRINE2 = 'Endocrine';
export const SYSTEM_GASTRO = 'gastro';
export const SYSTEM_GASTRO2 = 'Gastrointestinal';
export const SYSTEM_MUSCULO = 'musculo';
export const SYSTEM_MUSCULO2 = 'Musculoskeletal';
export const SYSTEM_NEURO = 'neuro';
export const SYSTEM_NEURO2 = 'Neurological';
export const SYSTEM_ONC = 'onc';
export const SYSTEM_ONC2 = 'Hematology/Oncology';
export const SYSTEM_RENAL = 'renal';
export const SYSTEM_RENAL2 = 'Renal';
export const SYSTEM_RESPIRATORY = 'respiratory';
export const SYSTEM_RESPIRATORY2 = 'Respiratory';
export const SYSTEM_PSYCHIATRIC = 'mental';
export const SYSTEM_PSYCHIATRIC2 = 'Psychiatric';
export const SYSTEM_GYNECOLOGY = 'gyn';
export const SYSTEM_GYNECOLOGY2 = "Women's Health";
// new no icons
export const SYSTEM_GENITOURINARY = 'Genitourinary';
export const SYSTEM_RHEUMATOLOGICAL = 'Rheumatological';
export const SYSTEM_OPHTHALMOLOGICAL = 'Ophthalmological';
export const SYSTEM_ENT = 'Ent';

export const ALL_SYSTEMS = [
  SYSTEM_CARDIO,
  SYSTEM_DERMATOLOGY,
  SYSTEM_ENDOCRINE,
  SYSTEM_GASTRO,
  SYSTEM_GYNECOLOGY,
  // SYSTEM_ONC = Haematology
  SYSTEM_ONC,
  SYSTEM_RESPIRATORY,
  SYSTEM_MUSCULO,
  SYSTEM_NEURO,
  SYSTEM_PSYCHIATRIC,
  SYSTEM_RENAL,
];

export const ALL_SYSTEMS2 = [
  SYSTEM_CARDIO2,
  SYSTEM_DERMATOLOGY2,
  SYSTEM_ENDOCRINE2,
  SYSTEM_GASTRO2,
  SYSTEM_GYNECOLOGY2,
  SYSTEM_ONC2,
  SYSTEM_RESPIRATORY2,
  SYSTEM_NEURO2,
  SYSTEM_PSYCHIATRIC2,
  SYSTEM_RENAL2,
  SYSTEM_MUSCULO2,
  SYSTEM_GENITOURINARY,
  SYSTEM_RHEUMATOLOGICAL,
  SYSTEM_OPHTHALMOLOGICAL,
  SYSTEM_ENT,
];

export const READABLE_SYSTEM_NAMES = {
  [SYSTEM_CARDIO]: 'Cardiology',
  [SYSTEM_DERMATOLOGY]: 'Dermatology',
  [SYSTEM_ENDOCRINE]: 'Endocrine',
  [SYSTEM_GASTRO]: 'Gastrointestinal',
  [SYSTEM_MUSCULO]: 'Musculoskeletal',
  [SYSTEM_NEURO]: 'Neurology',
  [SYSTEM_ONC]: 'Haematology',
  [SYSTEM_RENAL]: 'Renal',
  [SYSTEM_RESPIRATORY]: 'Respiratory',
  [SYSTEM_PSYCHIATRIC]: 'Psychiatric',
  [SYSTEM_GYNECOLOGY]: 'Gynecology',
  // new
  [SYSTEM_CARDIO2]: 'Cardiology',
  [SYSTEM_DERMATOLOGY2]: 'Dermatology',
  [SYSTEM_ENDOCRINE2]: 'Endocrine',
  [SYSTEM_GASTRO2]: 'Gastrointestinal',
  [SYSTEM_NEURO2]: 'Neurological',
  [SYSTEM_ONC2]: 'Hematology/Oncology',
  [SYSTEM_RENAL2]: 'Renal',
  [SYSTEM_RESPIRATORY2]: 'Respiratory',
  [SYSTEM_PSYCHIATRIC2]: 'Psychiatric',
  [SYSTEM_GYNECOLOGY2]: "Women's Health",
  [SYSTEM_MUSCULO2]: 'Musculoskeletal',
  [SYSTEM_GENITOURINARY]: 'Genitourinary',
  [SYSTEM_RHEUMATOLOGICAL]: 'Rheumatology',
  [SYSTEM_OPHTHALMOLOGICAL]: 'Ophthalmology',
  [SYSTEM_ENT]: 'Ear, Nose & Throat',
};

export const MAP_NAMES_TO_ABBR = {
  Cardiology: SYSTEM_CARDIO,
  Dermatology: SYSTEM_DERMATOLOGY,
  Endocrine: SYSTEM_ENDOCRINE,
  Gastrointestinal: SYSTEM_GASTRO,
  Musculoskeletal: SYSTEM_MUSCULO,
  Neurology: SYSTEM_NEURO,
  Haematology: SYSTEM_ONC,
  Renal: SYSTEM_RENAL,
  Respiratory: SYSTEM_RESPIRATORY,
  Psychiatric: SYSTEM_PSYCHIATRIC,
  Gynecology: SYSTEM_GYNECOLOGY,
};

export const SYSTEM_COLORS = {
  // [SYSTEM_CARDIO]: 'var(--cardiology-color)',
  // [SYSTEM_DERMATOLOGY]: 'var(--dermatology-color)',
  // [SYSTEM_ENDOCRINE]: 'var(--endocrine-color)',
  // [SYSTEM_GASTRO]: 'var(--gastrointestinal-color)',
  // [SYSTEM_MUSCULO]: 'var(--musculoskeletal-color)',
  // [SYSTEM_NEURO]: 'var(--neurology-color)',
  // [SYSTEM_ONC]: 'var(--heme-color)',
  // [SYSTEM_RENAL]: 'var(--renal-color)',
  // [SYSTEM_RESPIRATORY]: 'var(--respiratory-color)',
  [SYSTEM_PSYCHIATRIC]: '#FBCF99',
  [SYSTEM_GYNECOLOGY]: '#FFCCDC',
  [SYSTEM_CARDIO]: '#fba799',
  [SYSTEM_DERMATOLOGY]: '#fce2cc',
  [SYSTEM_ENDOCRINE]: '#b1e3c6',
  [SYSTEM_GASTRO]: '#f4e3ad',
  [SYSTEM_MUSCULO]: '#fbc9c2',
  [SYSTEM_NEURO]: '#d1d1d1',
  [SYSTEM_ONC]: '#726e89',
  [SYSTEM_RENAL]: '#a99df6',
  [SYSTEM_RESPIRATORY]: '#bbd9f9',

  [SYSTEM_CARDIO2]: '#fba799',
  [SYSTEM_DERMATOLOGY2]: '#fce2cc',
  [SYSTEM_ENDOCRINE2]: '#b1e3c6',
  [SYSTEM_GASTRO2]: '#f4e3ad',
  [SYSTEM_NEURO2]: '#d1d1d1',
  [SYSTEM_ONC2]: '#726e89',
  [SYSTEM_RENAL2]: '#a99df6',
  [SYSTEM_RESPIRATORY2]: '#bbd9f9',
  [SYSTEM_PSYCHIATRIC2]: '#FBCF99',
  [SYSTEM_GYNECOLOGY2]: '#FFCCDC',
  [SYSTEM_MUSCULO2]: '#fbc9c2',
  [SYSTEM_RHEUMATOLOGICAL]: '#D1BEA7',
  [SYSTEM_GENITOURINARY]: '#D3ADF9',
  [SYSTEM_OPHTHALMOLOGICAL]: '#B1E3DD',
  [SYSTEM_ENT]: '#A3BDFB',
};

export const SYSTEM_OPTIONS = [
  'Cardiology',
  'Dermatology',
  'Endocrine',
  'Gastrointestinal',
  'Gynecology',
  'Haematology',
  'Musculoskeletal',
  'Neurology',
  'Psychiatric',
  'Renal',
  'Respiratory',
];

export const PRESENTATION_OPTIONS = [
  'Abdominal Distension',
  'Abdominal Pain',
  'Chest Pain',
  'Cough',
  'Fatigue',
  'Fever',
  'Headache',
  'Leg Pain',
  'Rash',
  'Sore Throat',
];

// Level names to display on filter
export const LEVEL_NAME = [
  'Level 1 - Preclinical',
  'Level 2 - Clinical',
  'Level 3 - Doctor',
  'Level 4 - Senior Doctor',
];

// case system icon size
export const SYSTEM_ICON_MEDIUM = 62;
export const SYSTEM_ICON_SMALL = 32;

// case presentation icon size
export const PRESENTATION_ICON_XLARGE = 200;
export const PRESENTATION_ICON_MEDIUM = 50;
export const PRESENTATION_ICON_SMALL = 32;

// chat avator size
export const CHAT_AVATAR_SIZE = 35;

// exam mode
export const EXAM_MODE = 'EXAM MODE';
export const EXAM_LEARN_MODE = 'LEARN MODE';

// permissions
export const CASE_CARD_SCORE_BLACK_LIST = [UNIMELB_USERS];
export const PROFILE_PAGE_BLACK_LIST = [UNIMELB_USERS];
export const DIFFERENTIAL_DIAGNOSIS_BLACK_LIST = [UNIMELB_USERS];
export const TASK_BLACK_LIST = [UNIMELB_USERS];
export const FEATURED_CASES_BLACK_LIST = [UNIMELB_USERS, DAILY_TASK];
export const USER_TOUR_BLACK_LIST = [UNIMELB_USERS, DAILY_TASK];
export const NOTIFICATION_BLACK_LIST = [UNIMELB_USERS, DAILY_TASK];

// learn mode
export const LEANR_MODE_WHITE_LIST = [CASE_WRITERS, CASE_STAFFS, CONTENT_TEAM_STAFFS];

export const EXIT_CASE_MODAL = 'EXIT_CASE_MODAL';
export const HINT_MODAL = 'HINT_MODAL';
export const TIMEOUT_CASE_MODAL = 'TIMEOUT_CASE_MODAL';
export const SESSION_EXPIRED_MODAL = 'SESSION_EXPIRED_MODAL';
export const COMPLETE_MODAL = 'SESSION_COMPLETE_MODAL';
export const ASSIST_MODE_MODAL = 'ASSIST_MODE_MODAL';

// Tab names
export const TAB_NAME_HISTORY = 'History';
export const TAB_NAME_TEMPLATE = 'OSCE Template';
export const TAB_NAME_QUIZZES = 'Quizzes (Beta)';
export const TAB_NAME_LEARN = 'Learn';

export const COLLECTION_CAPTIONS = {
  'Custom Collections':
    "Here's an assortment of cases relevant to your current rotation. We have curated these cases just for you.",
  Ambulatory:
    'These cases are often seen in outpatient settings. Practice your routine medical assessments and follow-up care here.',
  'Emergency Medicine':
    'Focus on emergency presentations and conditions that will require immediate intervention here.',
  'Free Cases':
    "A collection of Oscer's best cases, which are available for free. Feel free to attempt them as many times as you'd like!",
  Geriatrics: 'Here are cases focused on presentations and conditions commonly found in the elderly.',
  'Jump back in': "The cases you've most recently attempted are here. You can give them a go again at anytime!",
  'Jump Back In': "The cases you've most recently attempted are here. You can give them a go again at anytime!",
  'Jump Back': "The cases you've most recently attempted are here. You can give them a go again at anytime!",
  Masquerades:
    "Put your detective skills to the test with this set of challenging cases. Remember, the patient's complaint may not be their primary presentations. Dig deep!",
  Medicine:
    'Practice the most important presentations and conditions in internal medicine. This is a vital collection to master.',
  'Mental Health':
    'This is one of our newest collections, allowing you to diagnose mental health conditions. Practice these cases before/after patient encounters!',
  Paediatrics:
    'Practice your history taking for your paediatric rotation here. This collection contains the highest yield presentations and conditions.',
  'Primary Healthcare':
    'Here you can tackle common presentations and conditions seen in primary care/general practice settings.',
  'Rare Diagnoses':
    'Put your diagnostic skills to the test through tackling some of our most challenging diagnosises. Remember, think broad!',
  'Red Flag':
    'Critical and life-threatening conditions are a must-know for medical students. Practice the vital skills of recognising red-flag conditions here.',
  'Red Flags':
    'Critical and life-threatening conditions are a must-know for medical students. Practice the vital skills of recognising red-flag conditions here.',
  'Study gap':
    'Target your weaker topics here with a set of cases curated just for you. Attempt them to improve your clinical skills. ',
  'Study Gap':
    'Target your weaker topics here with a set of cases curated just for you. Attempt them to improve your clinical skills. ',
  Surgery:
    'We have curated a collection of cases relevant to your surgical rotation. Practice them now to improve your clinical skills.',
  'Surgical Emergencies':
    'These cases are common emergency presentations that may warrant immediate surgical intervention. Try them now!',
  "Women's health":
    'Practice presentations and conditions related to obstetrics and gynaecology. Practice them now to get on top of your skills.',
  'Obstetrics Gynaecology':
    'Practice presentations and conditions related to obstetrics and gynaecology. Practice them now to get on top of your skills.',
  'Other cases': 'Here are some other Oscer cases you can try to improve your skills',
  'Other Cases': 'Here are some other Oscer cases you can try to improve your skills',
};
