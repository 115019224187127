/**
 * @desc generate user name based on either first name and last name or email
 * @param {user} object from Redux
 */
export default (user) => {
  if (user.first_name || user.last_name) {
    return `${user.first_name} ${user.last_name}`;
  }
  if (user.email) {
    return user.email;
  }
  return 'Oscer';
};
