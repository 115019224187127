import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  LinearProgressBar: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  LinearProgressBar__info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 3,
  },
  LinearProgressBar__info__name: {
    color: theme.palette.text.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  LinearProgressBar__info__result: {
    color: theme.palette.primary.light,
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  LinearProgressBar__LinearProgress__root: {
    borderRadius: 16,
  },
  LinearProgressBar__LinearProgress__colorPrimary: {
    backgroundColor: 'rgba(18, 19, 19, 0.1)',
  },
  LinearProgressBar__LinearProgress__barColorPrimary: {
    borderRadius: 16,
    backgroundImage: 'linear-gradient(to right, #4a84ff, #83d0f0, #fcb9ad)',
  },
}));

export default useStyles;
