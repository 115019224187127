import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  Card,
  Icon,
  Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { signIn, clearAuthErrors } from '../../actions/auth';
import { trackPage, trackEvent } from '../../service/analytics';
import DividerWithText from '../../components/common/Divider';
import SocialAuthPanel from '../../containers/SocialAuthPanel';
import iconLogo from '../../assets/logos/oscer-full-logo.svg';
import EmailIcon from '../../assets/login/email-icon-dark.svg';
import PasswordIcon from '../../assets/login/password-icon-dark.svg';
import PasswordErrorIcon from '../../assets/login/flag-mark-icon.svg';
import EmailErrorIcon from '../../assets/login/flag-mark-icon-blue.svg';
import iconOscerSignIn from '../../assets/character/character-signup-lookdown.svg';
import { emailRegx } from '../../helpers/validation';
import useGeoLocation from '../../hook/useGeoLocation';
import useStyles from './style';

const EMAIL_ERROR = 1;
const PASSWORD_ERROR = 2;
const NETWORK_ERROR = 'Network error, please contact support(500)';

const SignIn = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [pwd, setPwd] = useState('');
  const [pwdHelperText, setPwdHelperText] = useState('');
  const [isRemeberChecked, setRemeberChecked] = useState(false);
  const [errors, setErrors] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);

  // Detect whether user from UK
  const supportLocale = ['uk'];
  const { country, error } = useGeoLocation('');
  const locale = window.location.pathname.split('/')[1];
  const baseName = supportLocale.includes(locale);
  if (supportLocale.includes(country) && !baseName) {
    window.location.href = `/${country.toLowerCase()}${window.location.pathname || ''}${window.location.search || ''}`;
  }

  useEffect(() => {
    trackPage(history.location.pathname);
  }, []);

  useEffect(() => {
    if (typeof props.authError === 'string') {
      setErrors(props.authError);
    } else {
      props.clearAuthErrors();
    }
  }, [props.authError]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.code === EMAIL_ERROR) {
      setErrors('');
    }
  };

  // Email validation after textfield lose focus
  const handleEmailBlur = (e) => {
    if (errors.code === EMAIL_ERROR) {
      setErrors('');
    }
    emailValidation(e.target.value);
  };

  const emailValidation = (inputEmail) => {
    if (inputEmail && inputEmail.trim()) {
      if (!emailRegx.test(inputEmail.toLowerCase())) {
        setEmailHelperText('Invalid Email');
        return false;
      }
      setEmailHelperText('');
      return true;
    }
    setEmailHelperText('Required');
    return false;
  };

  const handlePwdChange = (e) => {
    if (errors.code === PASSWORD_ERROR) {
      setErrors('');
    }
    setPwd(e.target.value);
  };

  // Password validation after textfield lose focus
  const handlePwdBlur = (e) => {
    if (errors.code === PASSWORD_ERROR) {
      setErrors('');
    }
    pwdValidation(e.target.value);
  };

  const pwdValidation = (inputPwd) => {
    if (inputPwd) {
      if (inputPwd.length < 6) {
        setPwdHelperText('At least 6 characters');
        return false;
      }
      setPwdHelperText('');
      return true;
    }
    setPwdHelperText('Required');
    return false;
  };

  // Terms and conditions checkbox stage toggle
  const handleRemeberChange = () => {
    setRemeberChecked((checked) => !checked);
  };

  // Todo: decouple the animated signin/singup button
  // Submit button for sign up
  const handleSubmit = () => {
    // Final frontend fields validation
    setIsWaiting(true);
    if (emailValidation(email) && pwdValidation(pwd)) {
      props
        .signIn(email, pwd)
        .then((res) => {
          trackEvent({
            GAAction: 'login',
            FBAction: 'Login',
            label: `User ${props.userId} Login`,
          });
        })
        .catch((err) => {
          codeToText(err);
        })
        .then(() => {
          setIsWaiting(false);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const codeToText = (err) => {
    switch (err.code) {
      case 1:
        setErrors(err);
        return;
      case 2:
        setErrors(err);
        return;
      default:
        setErrors(NETWORK_ERROR);
    }
  };

  return (
    <>
      <Link to="/">
        <img src={iconLogo} alt="oscer__icon__logo" className={classes.SignIn__icon__logo} />
      </Link>
      <div className={classes.SignIn__title}>WELCOME BACK!</div>
      <DividerWithText marginTop="25px">sign in with one click</DividerWithText>
      <div className={classes.SignIn__social__login}>
        <SocialAuthPanel country={country} />
      </div>
      <DividerWithText marginTop="20px">or use your email</DividerWithText>
      <form className={classes.SignIn__form} autoComplete="off">
        <TextField
          className={classes.SignIn__textField}
          error={!!emailHelperText.length}
          classes={{
            root: cx(classes.SignIn__textField__root, {
              [classes.SignIn__texfField__error__offset]: !!emailHelperText.length,
            }),
          }}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          value={email}
          required
          id="email"
          autoComplete="off"
          placeholder="Email Address"
          helperText={emailHelperText}
          InputProps={{
            startAdornment: <img src={EmailIcon} alt="email_icon" className={classes.SignIn__textField__icon} />,
            classes: { input: classes.SignIn__textField__input },
          }}
        />
        {errors.code === EMAIL_ERROR && (
          <Card className={classes.Email__error}>
            <div className={classes.Left__side__message}>
              <div className={classes.icon__wrapper}>
                <Icon classes={{ root: classes.iconRoot }}>
                  <img className={classes.imageIcon} alt="dangerIcon" src={EmailErrorIcon} />
                </Icon>
              </div>
              <div className={classes.info__wrapper}>
                <span>{errors.message}</span>
              </div>
            </div>
            <div className={classes.Signup__message}>
              <span>
                <Link to={`/signup${props.location.search}`} className={classes.Link__message}>
                  SIGN UP NOW
                </Link>
              </span>
            </div>
          </Card>
        )}

        <TextField
          className={classes.SignIn__textField}
          error={!!pwdHelperText.length}
          classes={{
            root: cx(classes.SignIn__textField__root, {
              [classes.SignIn__texfField__error__offset]: !!pwdHelperText.length,
            }),
          }}
          onChange={handlePwdChange}
          onBlur={handlePwdBlur}
          type="password"
          autoComplete="off"
          value={pwd}
          onKeyPress={handleKeyPress}
          required
          id="password"
          placeholder="Password"
          helperText={pwdHelperText}
          InputProps={{
            startAdornment: <img src={PasswordIcon} alt="pwd_icon" className={classes.SignIn__textField__icon} />,
            classes: { input: classes.SignIn__textField__input },
          }}
        />

        {errors.code === PASSWORD_ERROR && (
          <Card className={classes.Password__error}>
            <div className={classes.icon__wrapper}>
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} alt="dangerIcon" src={PasswordErrorIcon} />
              </Icon>
            </div>
            <div className={classes.info__wrapper}>
              <span>{errors.message}</span>
            </div>
          </Card>
        )}

        <div className={classes.SignIn__checkbox}>
          <FormControlLabel
            classes={{ root: classes.SignIn__FormControlLabel__root }}
            control={
              <Checkbox checked={isRemeberChecked} onChange={handleRemeberChange} name="checkedA" color="primary" />
            }
            label="Keep me logged in"
          />
          <Link to="/forgot_password" className={classes.SignIn__forgot__pwd}>
            Forgot Password?
          </Link>
        </div>
        <Button
          variant="contained"
          color="primary"
          classes={{
            root: classes.SignIn__buttom__root,
            contained: classes.SignIn__button__contained,
            label: classes.SignIn__button__label,
            disabled: isWaiting ? classes.SignIn__button__isLoading : classes.SignIn__button__disabled,
          }}
          onClick={handleSubmit}
          disabled={isWaiting || !!emailHelperText || !!pwdHelperText}
        >
          {isWaiting ? <CircularProgress color="secondary" size={16} /> : 'Sign in'}
        </Button>
        {errors === NETWORK_ERROR && <div className={classes.SignIn__error}>{errors}</div>}
      </form>
      <div className={cx(classes.SignIn__have__account, { [classes.SignIn__error__show]: !!errors })}>
        Don&apos;t have an account yet?{' '}
        {props.setSignUp ? (
          // send to prime account sign up page
          <span onClick={() => props.setSignUp(true)} className={classes.SignIn__signup} aria-hidden="true">
            Sign up
          </span>
        ) : (
          <Link to={`/signup${props.location?.search}`} className={classes.SignIn__signup}>
            Sign up
          </Link>
        )}
      </div>
    </>
  );
};

SignIn.propTypes = {
  location: PropTypes.object,
  signIn: PropTypes.func,
  history: PropTypes.object,
  setSignUp: PropTypes.func,
  userId: PropTypes.number,
  clearAuthErrors: PropTypes.func,
  authError: PropTypes.string,
};

export default SignIn;
