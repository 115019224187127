export const mapSystems = (system) => {
  if (system === 'Cardiovascular') {
    return 'cardio';
  } else if (system === 'Dermatology') {
    return 'dermatology';
  } else if (system === 'Endocrine') {
    return 'endocrine';
  } else if (system === 'Endocrine') {
    return 'endocrine';
  } else if (system === 'Gastrointestinal') {
    return 'gastro';
  } else if (system === 'Neurological') {
    return 'neuro';
  } else if (system === 'Hematology/Oncology') {
    return 'onc';
  } else if (system === 'Renal') {
    return 'renal';
  } else if (system === 'Respiratory') {
    return 'respiratory';
  } else if (system === 'Psychiatric') {
    return 'mental';
  } else if (system === "Women's Health") {
    return 'gyn';
  } else {
    return 'none';
  }
};

export const reverseSystems = (system) => {
  if (system === 'cardio') {
    return 'Cardiovascular';
  } else if (system === 'dermatology') {
    return 'Dermatology';
  } else if (system === 'endocrine') {
    return 'Endocrine';
  } else if (system === 'gastro') {
    return 'Gastrointestinal';
  } else if (system === 'neuro') {
    return 'Neurological';
  } else if (system === 'onc') {
    return 'Hematology/Oncology';
  } else if (system === 'renal') {
    return 'Renal';
  } else if (system === 'respiratory') {
    return 'Respiratory';
  } else if (system === 'mental') {
    return 'Psychiatric';
  } else if (system === 'gyn') {
    return "Women's Health";
  } else {
    return 'none';
  }
};
