import React from 'react';
import { Typography } from '@material-ui/core';
import HomeContainer from '../../../components/Container/HomeContainer';
import useStyles from './style';

const TechnologyStatistics = () => {
  const classes = useStyles();

  const statistics = [
    { statistic: '90%+', name: 'Accuracy' },
    { statistic: '200+', name: 'Conditions' },
    { statistic: '1,000,000s', name: 'Unique Inquiries' },
  ];

  return (
    <HomeContainer innerStyles={classes.TechnologyStatistics__container__inner}>
      <Typography variant="h2" className={classes.TechnologyStatistics__title} gutterBottom>
        Oscer processes a lot of information at once…
      </Typography>
      <div className={classes.TechnologyStatistics__statistic}>
        {statistics.map((statistic) => (
          <div key={statistic.name} className={classes.TechnologyStatistics__statisticCard}>
            <div className={classes.TechnologyStatistics__statisticCard__statistic}>{statistic.statistic}</div>
            <div className={classes.TechnologyStatistics__statisticCard__name}>{statistic.name}</div>
          </div>
        ))}
      </div>
    </HomeContainer>
  );
};

export default TechnologyStatistics;
