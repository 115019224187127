import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { animateScroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Typography, Container, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { getJobs } from '../../actions/home';

import { trackPage } from '../../service/analytics';
import useStyles from './style';
import ImageShowcase from '../../components/Showcase/ImageShowcase';
import HomeContainer from '../../components/Container/HomeContainer';
import ValueCard from '../../components/Card/ValueCard';

import OverlayShape7 from '../../assets/home/shape-7.svg';
import imgValuePositive from '../../assets/home/jobs-value-relentlessly-positive.svg';
import imgValueJoy from '../../assets/home/jobs-value-design-for-joy.svg';
import imgValueDeliver from '../../assets/home/jobs-value-deliver-fast.svg';
import imgValueTruth from '../../assets/home/jobs-value-pursue-the-truth.svg';
import imgValueBack from '../../assets/home/jobs-value-back-ourselves.svg';
import shape1 from '../../assets/home/jobs-hero-shape-1.svg';
import shape2 from '../../assets/home/jobs-hero-shape-2.svg';
import shape6 from '../../assets/home/jobs-values-shape.svg';
import shape7 from '../../assets/home/untiring-patients-overlay.svg';
import benefitsFlexible from '../../assets/home/jobs-benefits-family-photo.png';
import benefitsQuality from '../../assets/home/jobs-benefits-quality-equipment.png';
import benefitsEnergy from '../../assets/home/jobs-benefits-small-team.png';
import arrowIcon from '../../assets/common/arrow-icon.svg';
import patientsImg from '../../assets/home/jobs-benefits-team-photo.png';
import JoinTeam from '../../assets/home/jobs-hero-img.svg';
import Benefits from '../../assets/home/hp-benefits-img.svg';

const Jobs = (props) => {
  const classes = useStyles();

  useEffect(() => {
    trackPage('/careers');
  }, []);

  const handleScrollTop = () => {
    animateScroll.scrollToTop();
  };

  const values = [
    {
      title: 'We Deliver Fast',
      detail:
        'Achieving our mission will include hundreds of incremental ideas, designs, demos, lines of code. The faster we deliver, the more our product takes the shape of what our customers want.',
      image: imgValueDeliver,
    },
    {
      title: 'We Pursue the Truth',
      detail:
        'A relentless pursuit to objectively guide our decision making. We face the brutal facts early and chart our course, no spin, just the truth.',
      image: imgValueTruth,
    },
    {
      title: 'We Design for Joy',
      detail:
        'When we make decisions in our products we overweight user joy. We create products that are so damn delightful that people want to use them strictly because of their design.',
      image: imgValueJoy,
    },
    {
      title: 'We Back Ourselves',
      detail: `We only hire people that can become world class in their role, we'll give you what you need to succeed and the freedom to execute. Own your domain and back yourself.`,
      image: imgValueBack,
    },
    {
      title: `We're Warm and Open`,
      detail:
        'In our teams, with our students, doctors and patients we are warm, caring and transparent, approaching every situation with optimism and positivity.',
      image: imgValuePositive,
    },
  ];

  const benefits = [
    {
      title: 'Flexible Work-Life Balance',
      subtitle:
        'We all have our life at work and our life outside of work, we’re flexible with working hours and working from home and when you do come into the office our Fitzroy office is in prime position.',
      image: benefitsFlexible,
      isReverse: false,
    },
    {
      title: 'Quality Equipment & Snacks',
      subtitle: `We’ve got those fresh start-up vibes with the latest tech equipment (pick the set-up you want), free snacks and drinks (kombucha 🍹), sit-stand desks and a focus on getting better.`,
      image: benefitsQuality,
      isReverse: true,
    },
    {
      title: 'Small Team Energy',
      subtitle:
        'No corporate bullshit, we like to keep it simple with flat hierarchy, casual clothes and just an all round chill environment where everyone is included.',
      image: benefitsEnergy,
      isReverse: false,
    },
  ];

  // hashlink offset
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const colorSelector = (category) => {
    switch (category) {
      case 'Design':
        return '#a99df6';
      case 'Engineering':
        return '#fba799';
      case 'Growth & Marketing':
        return '#b1e3c6';
      case 'Content':
        return '#fbcf99';
      default:
        return '#a99df6';
    }
  };

  return (
    <main className={classes.Jobs__section__main}>
      <section className={cx(classes.Jobs__section, classes.Jobs__oddSection)}>
        <Container className={cx(classes.Jobs__container, classes.Jobs__firstContainer)} maxWidth="lg">
          <p className={classes.Jobs__tag}>CAREERS</p>
          <Typography variant="h1" className={classes.Jobs__joinus}>
            Work with us
          </Typography>
          <Typography variant="h3" className={classes.Jobs__title}>
            Our mission is to give the world the tools to eliminate missed diagnosis, and we’d love your help.
          </Typography>
          <div className={classes.Jobs__firstContainer__bottom}>
            <img
              className={classes.Jobs__img}
              src={JoinTeam}
              alt="Join Oscer in our mission to give the world the tools
              to eliminate missed diagnosis"
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            component={HashLink}
            to="careers#positions"
            smooth
            scroll={(el) => scrollWithOffset(el)}
            classes={{ root: classes.Jobs__firstContainer__btn }}
          >
            See Open Positions
          </Button>
        </Container>
        <img className={classes.Jobs__overlay1} src={shape2} alt="Oscer overlay 2" />
        <img className={classes.Jobs__overlay2} src={shape1} alt="Oscer overlay 1" />
      </section>
      <HomeContainer
        outerStyles={cx(classes.Jobs__container__outer, classes.Jobs__showCaseWrapper)}
        innerStyles={classes.Jobs__container__inner}
      >
        <Typography variant="h2" classes={{ root: classes.Jobs__job__title }}>
          Patients deserve better
        </Typography>
        <div className={classes.Jobs__join}>
          <div className={classes.Jobs__join__text}>
            We all make mistakes in our jobs - even doctors. We think of them as our invincible best and brightest that
            care for us in our times of greatest need. Oscer is about these doctors. It is about offering them a steady
            hand on the shoulder. Oscer is building a medical knowledge graph and second opinion tool that will help
            doctors to make fewer mistakes and save millions of lives, in the form of an intelligent mobile app that
            will (one day) sit inconspicuously on the desk of every doctor, in every medical setting.
          </div>
          <img src={patientsImg} alt="img" className={classes.Jobs__join__img} />
        </div>
      </HomeContainer>

      <section className={classes.Jobs__section}>
        <Container className={cx(classes.Jobs__container, classes.Jobs__team)} maxWidth="lg">
          <Typography variant="h2" className={classes.Jobs__title__values}>
            Oscer's values
          </Typography>
          <Typography variant="subtitle1" className={classes.Jobs__subtitle}>
            These are our bread and butter with everything we do
          </Typography>
          <div className={classes.Jobs__values__main}>
            {values.map((value) => (
              <ValueCard key={value.title} image={value.image} title={value.title} detail={value.detail} />
            ))}
          </div>
        </Container>
        <img className={classes.Jobs__overlay6} src={shape6} alt="Oscer overlay 6" />
        <img className={classes.Jobs__overlay7} src={shape7} alt="Oscer overlay 7" />
      </section>

      <section className={cx(classes.Jobs__section, classes.Jobs__benefits)} id="jobs">
        <Container className={cx(classes.Jobs__container, classes.Jobs__team__benefits)} maxWidth="lg">
          <Typography variant="h2" className={classes.Jobs__title}>
            Life at Oscer
          </Typography>
          <Typography variant="subtitle1" gutterBottom className={classes.Jobs__subtitle}>
            We care just as much for ourselves as we do for our students and (future) patients. We want everyone to be
            healthy, enjoy work wherever they are, take holidays and have the financial resources and support they need.
            Along with a competitive salary and shares in the business, we also offer great benefits and perks.
          </Typography>
          {benefits.map((feat) => (
            <ImageShowcase
              key={feat.title}
              title={feat.title}
              subtitle={feat.subtitle}
              image={feat.image}
              isReverse={feat.isReverse}
              styles={{
                root: classes.Jobs__showcase__root,
                title: classes.Jobs__showcase__title,
                subtitle: classes.Jobs__showcase__subtitle,
                image: classes.Jobs__showcase__image,
              }}
            />
          ))}
        </Container>
      </section>

      <section className={classes.Jobs__section} id="positions">
        <Container className={cx(classes.Jobs__container, classes.Jobs__team)} maxWidth="lg">
          <Typography variant="h2" className={classes.Jobs__title}>
            Open Roles
          </Typography>
          <Typography variant="subtitle1" gutterBottom className={classes.Jobs__subtitle}>
            We're hiring world-class people for all our different teams.
          </Typography>
          <div className={classes.Jobs__opportunities}>
            {props.jobs.map((job) => (
              <div className={classes.Jobs__job} key={job?.sub_category}>
                <div className={classes.Jobs__job__category}>{job?.sub_category}</div>
                {job?.pages.map((page) => (
                  <div key={page.name} className={classes.Jobs__job__wrapper}>
                    <Button
                      className={classes.Jobs__btn}
                      endIcon={<img src={arrowIcon} alt="end_icon" />}
                      component={Link}
                      style={{ backgroundColor: `${colorSelector(job?.sub_category)}33` }}
                      to={`careers/${page.name}`}
                      rel="noopener noreferrer"
                      onClick={handleScrollTop}
                    >
                      <div className={classes.Jobs__btn__label}>
                        <span>{page.name}</span>
                        <span className={classes.Jobs__btn__location}>{page.meta}</span>
                      </div>
                      <div
                        className={classes.Jobs__btn__slice}
                        style={{ backgroundColor: colorSelector(job?.sub_category) }}
                      />
                    </Button>
                  </div>
                ))}
              </div>
            ))}
            <div className={classes.Jobs__opportunities__helperText}>Nothing quite fits your skill set?</div>
            <div className={classes.Jobs__opportunities__helperText}>
              <a className={classes.Jobs__email} href="mailto:tom@oscer.ai" target="_blank" rel="noopener noreferrer">
                Reach out
              </a>
              &nbsp;to us, we’ll always find a place for awesome people.
            </div>
          </div>
        </Container>
        <img className={classes.Jobs__overlay3} src={OverlayShape7} alt="Oscer overlay shape" />
      </section>
    </main>
  );
};

const mapStateToProps = (state) => ({
  jobs: state.home.jobs,
});

Jobs.propTypes = {
  jobs: PropTypes.array,
};

export default connect(mapStateToProps, { getJobs })(Jobs);
