import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ValueCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 320,
    marginBottom: 40,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
    },
  },
  ValueCard__iamge: {
    maxWidth: 265,
    maxHeight: 180,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 200,
      marginBottom: 20,
    },
  },
  ValueCard__name: {
    fontSize: 30,
    fontWeight: 500,
    color: theme.palette.text.main,
    textAlign: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  },
  ValueCard__detail: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

export default useStyles;
