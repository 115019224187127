import { combineReducers } from 'redux';
import errors from './errors';
import auth from './auth';
import cases from './cases';
import messages from './messages';
import checkout from './checkout';
import dashboard from './dashboard';
import account from './account';
import home from './home';
import pwf from './pwf';
import task from './task';
import condition from './condition';
import learn from './learn';
import diagnosis from './diagnosis';

export default combineReducers({
  errors,
  auth,
  cases,
  messages,
  checkout,
  dashboard,
  account,
  home,
  pwf,
  condition,
  task,
  learn,
  diagnosis,
});
