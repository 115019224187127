import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { autoPlayAudioToggle, setSessionId } from '../../../actions/cases';
import { Modal } from '../../../components/common';
import PresentationImg from '../../../components/icons/PresentationImg';
import LevelBadge from '../../../components/badge/LevelBadge';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import GeneralSwitch from '../../../components/Switch/GeneralSwitch';
import SystemBadge from '../../../components/badge/systemBadge';
import { trackEvent } from '../../../service/analytics';
import '../case.scss';
import './style.scss';
import { PRESENTATION_ICON_XLARGE, SYSTEM_COLORS } from '../../../constants';
import { toTitle } from '../../../helpers/toTitleCase';

/**
 * @desc Patient Info modal
 * @param {*} props
 * - currentCase an object of current case details
 * - isToggle a boolean which represents the modal is popup or not
 * - toggle a function to control modal
 * - setMode a function to set mode, either 'EXAM MODE' or 'LEARN MODE'
 */
const PatientInfoModal = (props) => {
  const { patient_name, gender, patient_age, system, presentation, case_level, description } = props.currentCase;
  const { isInfoToggle, infoToggle } = props;

  const handleAudioSwitch = () => {
    trackEvent({
      GAAction: `turn_${props.autoPlayAudio ? 'off' : 'on'}_text_to_voice`,
      FBAction: `Turn ${props.autoPlayAudio ? 'Off' : 'On'} Text to Voice`,
      category: 'Single Player',
      label: `User ${props.id} Turn ${props.autoPlayAudio ? 'Off' : 'On'} Text to Voice`,
    });
    props.autoPlayAudioToggle();
  };

  const handleExit = () => {
    trackEvent({
      GAAction: 'exit_single_player_case',
      FBAction: 'Exit Single Player Case',
      category: 'Single Player',
    });
    props.setSessionId(null);
    props.history.push('/dashboard/home/');
  };

  return (
    <Modal isToggle={isInfoToggle} toggle={infoToggle} centered closable>
      <div className={classnames('case__modal')}>
        <div className={classnames('case__modal__icons', 'case__modal__icons-patient')}>
          <div className={classnames('icon__background', 'icon__background-sm')}>
            <LevelBadge level={case_level} />
          </div>
          <SystemBadge system={system} />
        </div>
        <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
          <div className="case__modal">
            <PresentationImg
              className={classnames('case__modal__img', 'case__modal__img-patient')}
              presentation={presentation}
              size={PRESENTATION_ICON_XLARGE}
            />

            <div className="case__modal__title">
              <div className={classnames('title')}>
                <h5>Here’s what you need to know about your patient…</h5>
              </div>
            </div>
            <div className={classnames('note', 'case__modal__subtitle')}>
              <p>
                {description ||
                  `${patient_name} is a ${patient_age} y.o ${
                    gender === 'Female' ? 'female' : 'male'
                  } who is presenting with 
                ${toTitle(presentation).toLowerCase()}.`}
              </p>
            </div>
            <div className="case__modal__begin-switch">
              <span className="case__modal__begin-switch-voice">
                Turn on your patient’s voice to hear their responses
              </span>
              <GeneralSwitch size="medium" toggle={handleAudioSwitch} isToggle={props.autoPlayAudio} />
            </div>

            <div className={classnames('buttons', 'case__modal__btns', 'case__modal__btns-patient')}>
              <ConfirmButton className={classnames('btn-cancel', 'case__modal__btns-exit')} onClick={handleExit}>
                Exit
              </ConfirmButton>
              <ConfirmButton className={classnames('btn-learn', 'btn-round')} onClick={infoToggle}>
                Ok, got it!
              </ConfirmButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  autoPlayAudio: state.cases.autoPlayAudio,
  id: state.auth.user.id,
});

PatientInfoModal.propTypes = {
  currentCase: PropTypes.object.isRequired,
  autoPlayAudioToggle: PropTypes.func,
  autoPlayAudio: PropTypes.bool,
  id: PropTypes.number,
  history: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, { autoPlayAudioToggle, setSessionId })(PatientInfoModal));
