import React from 'react';
import PropTypes from 'prop-types';
import { FaForward } from 'react-icons/fa';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { Modal } from '../../../components/common';
import PresentationImg from '../../../components/icons/PresentationImg';
import { PRESENTATION_ICON_XLARGE, SYSTEM_COLORS } from '../../../constants';
import './style.scss';

/**
 * @desc Modal for timeout
 * @param { * } props
 * - isToggle Boolean, true means show modal
 * - toggle function which change modal status to '' (hide modal)
 * - proceed function which shows Follow Questions
 * - stopSession function which will call stop session api
 */
const TimeoutModal = ({ isToggle, toggle, proceed, stopSession, system }) => (
  <div>
    <Modal isToggle={isToggle} toggle={toggle} centered closable={false}>
      <div className={classnames('case__modal')}>
        <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
          <div className="case__modal">
            <PresentationImg className="case__modal__img" presentation="time_up" size={PRESENTATION_ICON_XLARGE} />

            <div className="case__modal__title">
              <div className={classnames('title', 'case__modal__title-text')}>
                <h5>Sorry your time is up!</h5>
                <h5>Do you know the diagnosis?</h5>
              </div>
            </div>
            <div className={classnames('note', 'case__modal__subtitle')}>
              <p>Proceed to formulate your clinical reasoning where you'll provide a case summary</p>
            </div>

            <div className={classnames('buttons', 'case__modal__btns')}>
              <Button
                className={classnames('btn-submit-cancel', 'btn-round')}
                variant="outlined"
                color="primary"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
              <Fab
                className={classnames('btn-submit-proceed', 'btn-round')}
                variant="extended"
                color="primary"
                onClick={() => {
                  // stopSession();
                  proceed();
                }}
              >
                Proceed <FaForward />
              </Fab>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
);

TimeoutModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  proceed: PropTypes.func,
  stopSession: PropTypes.func,
  system: PropTypes.string,
};

export default TimeoutModal;
