import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.07)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes wave': {
    '0%': {
      transform: 'scale(1.4)',
      opacity: 1,
      transformOrigin: 'center',
    },
    '100%': {
      transform: 'scale(2.7)',
      opacity: 0,
      transformOrigin: 'center',
    },
  },
  MicButton: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.text.main,
    cursor: 'pointer',
    color: 'white',
    fontSize: 30,
    transition: 'background-color 0.5s linear',
    zIndex: 1,
    border: 'none',
    [theme.breakpoints.down('xs')]: {
      width: 44,
      height: 44,
      fontSize: 26,
    },
    '&:disabled': {
      // backgroundColor: '#ffffff91',
      cursor: 'not-allowed',
    },
  },
  MicButton__animation: {
    // animation: '$pulsate 1s infinite',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      animation: '$pulsate 1s infinite',
    },
  },
  MicButton__wrapper: {
    position: 'relative',
  },
  MicButton__wave: {
    position: 'absolute',
    background: 'grey',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 15,
    height: 15,
    borderRadius: '50%',
    backfaceVisibility: 'hidden',
    zIndex: -1,
    '&:before': {
      position: 'absolute',
      background: 'grey',
      marginLeft: -10,
      marginTop: -10,
      width: 35,
      height: 35,
      content: '""',
      display: 'block',
      borderRadius: '50%',
      backfaceVisibility: 'hidden',
      animation: '$wave 2s infinite ease-out',
    },
    '&:after': {
      position: 'absolute',
      background: 'grey',
      marginLeft: -10,
      marginTop: -10,
      width: 35,
      height: 35,
      content: '""',
      display: 'block',
      borderRadius: '50%',
      backfaceVisibility: 'hidden',
      opacity: 0,
      animation: '$wave 2s 1s infinite ease-out',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
