import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DashboardHome__Container__root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0px',
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: 0,
    },
  },
  CaseHistory__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: -15,
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100%)',
      height: 38,
      margin: 0,
      top: 0,
      fontSize: 14,
      position: 'sticky',
      color: theme.palette.primary.dark,
      // backgroundColor: 'rgba(255, 255, 255, 0.5)',
    },
  },
  CaseHistory__header__scroll: {
    [theme.breakpoints.down('xs')]: {
      backgroundColor: `${theme.palette.text.main}BF`,
      color: 'white',
      zIndex: 302,
      boxShadow: '0 2px 14px 0 rgba(0, 35, 72, 0.04)',
      '& $CaseHistory__MobileTitle': {
        color: 'white',
      },
    },
  },
  CaseHistory__sideLeft: {
    display: 'none',
    alignItems: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flex: '1',
      fontSize: 14,
      padding: '0 5px',
    },
  },
  CaseHistory__sideRight: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flex: '1',
      padding: '0 5px',
    },
  },
  CaseHistory__MobileTitle: {
    color: theme.palette.primary.dark,
    fontSize: 14,
  },
  CaseHistory: {
    minHeight: '100vh',
    width: '100%',
    marginBottom: 30,
  },
  CaseHistory__main: {
    marginTop: 45,
    marginBottom: 100,
    padding: '0 60px',
    [theme.breakpoints.down('md')]: {
      padding: '0 31px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      marginTop: 0,
    },
  },
  CaseHistory__title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 'normal',
    color: theme.palette.text.main,
    lineHeight: 'normal',
    marginBottom: 20,
  },
  CaseHistory__cases: {
    margin: '25px -5px',
    marginBottom: 40,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },

  CaseHistory__caseCard: {
    margin: '10px 5px',
    flex: '0 1 calc(33% - 10px)',
    [theme.breakpoints.down('md')]: {
      flex: '0 1 calc(33% - 10px)',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 1 calc(50% - 10px)',
      margin: 5,
    },
    [theme.breakpoints.down('xs')]: {
      flex: '0 1 calc(80% - 10px)',
      margin: 5,
    },
  },
  CaseHistory__caseCardModal: {
    '&.important': {
      flex: 1,
      borderRadius: '16px 16px 0 0',
      boxShadow: 'none',
    },
  },
  CaseHistory__pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  CaseHistory__paginationItem: {
    fontSize: 14,
    fontWeight: 500,
    border: 'solid 2px #9dc9f6',
    color: '#9dc9f6',
    '&.Mui-selected': {
      color: theme.palette.text.main,
      background: '#9dc9f6',
    },
  },
  CaseHistory__filterDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  CaseHistory__filterMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  CaseHistory__modal: {
    '& .important': {
      cursor: 'auto',
    },
  },
  CaseHistory__noHistory: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  CaseHistory__noHistory__image: {
    marginBottom: 15,
    maxWidth: '100%',
  },
  CaseHistory__noHistory__paragraph: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.text.main,
    marginTop: 15,
  },

  ExpandedCase: {
    display: 'flex',
    flexDirection: 'column',
    width: 375,
    [theme.breakpoints.down('xs')]: {
      width: 280,
    },
  },
  ExpandedCase__detail: {
    background: '#ffffff',
    padding: '7px 15px 25px 15px',
    borderRadius: '0 0 16px 16px',
  },
  ExpandedCase__tableHead: {
    display: 'flex',
    padding: 4,
    fontSize: 14.8,
    lineHeight: 1.22,
    color: theme.palette.text.main,
    textAlign: 'center',
    borderBottom: `solid 1px ${theme.palette.primary.dark}`,
  },
  ExpandedCase__tableHead_item: {
    flex: 1,
    textAlign: 'center',
  },
  ExpandedCase__tableHead_date: {
    flex: 1,
  },
  ExpandedCase__tableRow: {
    display: 'flex',
    padding: 5,
    fontSize: 17,
    lineHeight: 1,
    color: theme.palette.text.main,
    borderBottom: `solid 1px rgba(0, 35, 72, 0.3)`,
  },
  ExpandedCase__tableRow__item: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  ExpandedCase__tableRow__date: {
    flex: 1,
  },
  ExpandedCase__player: {
    background: '#e6f6fc',
    borderRadius: '50%',
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,
    marginRight: 5,
  },
  ExpandedCase__role: {
    background: '#e6f6fc',
    borderRadius: '50%',
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.light,
  },
  ExpandedCase__viewBtn: {
    lineHeight: 1,
    borderRadius: 14,
    background: '#e6f6fc',
    fontSize: 16.8,
    padding: '5px 16px',
    color: theme.palette.primary.light,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ExpandedCase__mode: {
    justifyContent: 'flex-start',
  },
  OscerLoader__wrapper: {
    margin: '0 auto',
    marginTop: '5vh',
  },
  FilterBtn: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.text.light,
    height: 27,
    fontSize: 11,
    border: `1px solid #f5f5f5`,
  },
}));

export default useStyles;
