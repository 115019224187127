import produce from 'immer';
import {
  SHOW_CHECKOUT_MODAL,
  HIDE_CHECKOUT_MODAL,
  CHECKOUT_SET_PLANS,
  SET_SELECTED_PLAN,
  SET_CHECKOUT_BUTTON_STATE,
  SET_CHECKOUT_COMPLETE,
  DEFAULT_PAYMENT_SET,
  PLAN_UPDATED,
} from '../actions/types';

const initialState = {
  hidden: true,
  plans: [],
  selectedPlan: '',
  stripeCheckoutButtonEnabled: false,
  checkoutComplete: false,
  defaultPayment: [],
};

const updatePlan = (draft, { plan }) => {
  const targetIndex = draft.plans.findIndex((p) => p.id === plan.id);
  draft.plans[targetIndex] = plan;
};

const checkoutReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SHOW_CHECKOUT_MODAL:
        return { ...state, hidden: false };
      case HIDE_CHECKOUT_MODAL:
        return { ...state, hidden: true };
      case CHECKOUT_SET_PLANS:
        return { ...state, plans: action.plans };
      case SET_SELECTED_PLAN:
        return { ...state, selectedPlan: action.plan };
      case SET_CHECKOUT_BUTTON_STATE:
        return { ...state, stripeCheckoutButtonEnabled: action.value };
      case SET_CHECKOUT_COMPLETE:
        return { ...state, checkoutComplete: action.value };
      case DEFAULT_PAYMENT_SET:
        return { ...state, defaultPayment: action.payload };
      case PLAN_UPDATED:
        updatePlan(draft, action);
        break;
      default:
        return state;
    }
  });

export default checkoutReducer;
