import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { IconButton } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { PARTIALLY_COMPLETE_MESSAGE } from 'helpers/constants';
import useStyles from './style';

const PartiallyCompleteMessage = (props) => {
  const { message, handleNextModule, disabled } = props;
  const cs = useStyles();
  const canNextModule = useSelector((state) => state.learn.canNextModule);
  const lastResponseMessage = useSelector((state) => state.learn.lastResponseMessage);
  const messageList = useSelector((state) => state.messages.messages);

  const shouldFloating = messageList[messageList.length - 1]?.type !== PARTIALLY_COMPLETE_MESSAGE;

  return (
    canNextModule &&
    lastResponseMessage.category_fully_complete_flag === false &&
    (shouldFloating ? (
      <div className={cs.FloatingMessage__root}>
        <div className={cs.FloatingMessage__wrapper}>
          <div className={cs.FloatingMessage__message}>
            Must ask questions are completed…you can proceed to {message?.text?.response}
          </div>
          <IconButton
            color="primary"
            aria-label="go to next module"
            className={cs.FloatingMessage__button}
            onClick={handleNextModule}
            disabled={
              !canNextModule || lastResponseMessage.current_category_index !== message.text.current_category_index
            }
          >
            <ArrowDownwardIcon className={cs.FloatingMessage__button__icon} />
          </IconButton>
        </div>
      </div>
    ) : (
      <div className={cs.PartiallyCompleteMessage__root}>
        <div className={cs.PartiallyCompleteMessage__message}>
          You’ve completed all must ask questions, so you can proceed to {message.text.response} or keep chatting to get
          ALL marks
        </div>
        <IconButton
          color="primary"
          aria-label="go to next module"
          className={cs.PartiallyCompleteMessage__button}
          onClick={handleNextModule}
          disabled={
            !canNextModule || lastResponseMessage.current_category_index !== message.text.current_category_index
          }
        >
          <ArrowDownwardIcon className={cs.PartiallyCompleteMessage__button__icon} />
        </IconButton>
      </div>
    ))
  );
};

PartiallyCompleteMessage.propTypes = {
  message: PropTypes.object,
  handleNextModule: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default PartiallyCompleteMessage;
