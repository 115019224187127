import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DragDropDown: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 5,
    maxWidth: 300,
    width: 240,
    marginRight: '0.5%',
  },
  DragDropDown__autocompleteRoot: {
    width: '100%',
    marginBottom: 5,
    position: 'relative',
  },
  DragDropDown__paper: {
    borderRadius: 16,
  },
  DragDropDown__popper__disabled: {
    display: 'none',
  },
  DragDropDown__systemList__wrapper: {
    position: 'relative',
    // zIndex: 300,
  },
  DragDropDown__system__wrapper: {
    position: 'absolute',
    top: 4,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: 240,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 2px 2px rgba(0, 35, 72, 0.12)',
    borderRadius: 16,
    padding: '7px 0',
    zIndex: 100,
  },
  FuzzyFilter: {
    position: 'relative',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  FuzzyFilter__InputBase__root: {
    transition: 'width 0.3s ease',
    height: 40,
    backgroundColor: 'white',
    borderRadius: 21.5,
    fontSize: 14,
    padding: '0 15px',
    color: theme.palette.text.main,
    width: '100%',
    // zIndex: 300,
    border: `solid 1px rgba(18, 19, 19, 0.05)`,
    '& .Mui-focused': {
      textOverflow: 'ellipsis',
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&::placeholder': {
      color: `${theme.palette.text.main}66`,
    },
    '&:hover': {
      cursor: 'pointer',
      border: `solid 2px ${theme.palette.primary.main}`,
    },
    '& .MuiInputBase-input': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  inputRoot: {
    textOverflow: 'ellipsis',
  },
  FuzzyFilter__InputBase__root__required: {
    backgroundColor: '#edf3ff',
    border: `solid 2px ${theme.palette.primary.main}`,
    color: theme.palette.primary.dark,
  },
  FuzzyFilter__InputBase__root__selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: `solid 1px ${theme.palette.info.main}`,
    '&::placeholder': {
      color: `${theme.palette.text.main}66`,
    },
    '&:hover': {
      border: `solid 1px ${theme.palette.info.main}`,
    },
  },
  FuzzyFilter__InputBase__root__selected__light: {
    backgroundColor: theme.palette.inherit.main,
    color: theme.palette.primary.dark,
    border: `solid 1px #f1f1f1`,
    '&::placeholder': {
      color: `${theme.palette.text.main}66`,
    },
    '&:hover': {
      border: `solid 1px ${theme.palette.info.main}`,
    },
  },
  FuzzyFilter__dropdown: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 16,
    backgroundColor: 'white',
    borderRadius: 16,
    maxWidth: 400,
    boxShadow: '0 13px 20px 4px rgba(0, 0, 0, 0.08)',
    position: 'absolute',
    top: 47,
    right: 0,
    left: 0,
    zIndex: 1,
  },
  FuzzyFilter__SearchIcon__root: {
    color: theme.palette.primary.dark,
  },
  FuzzyFilter__SearchIcon__root__selected: {
    color: 'white',
  },
  FuzzyFilter__SearchIcon__root__selected__light: {
    color: theme.palette.primary.dark,
  },
  FuzzyFilter__SearchIcon__root__active: {
    color: theme.palette.primary.main,
    '-webkit-transform': 'rotate(180deg)',
    '-moz-transform': 'rotate(180deg)',
    '-o-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
    transform: 'rotate(180deg)',
  },
  multiSelections: {
    marginTop: '-10px',
    padding: '5px 10px 0px 8px',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 200,
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'block',
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '10px',
    },
  },
  shadow: {
    height: '7px',
    backgroundColor: '#f1f1f1' /* For browsers that do not support gradients */,
    backgroundImage: 'linear-gradient(#f1f1f1, white)',
  },
  selectedWrapper: {
    marginTop: '5px',
    minWidth: '40px',
    padding: '6px 10px',
    margin: '0 2px',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    borderRadius: '20px',
    background: '#4a84ff',
    color: 'white',
    fontSize: 14,
  },
  selectedWrapperSm: {
    fontSize: 11,
  },
  RoundedIcon: {
    height: '12px',
    width: '12px',
    '&:hover': {
      opacity: '0.8',
      cursor: 'pointer',
    },
  },
  Input__RoundedIcon: {
    height: '17px',
    width: '17px',
    '&:hover': {
      opacity: '0.8',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
