import {
  CREATE_MESSAGE,
  GET_ERRORS,
  CLEAR_MESSAGES,
  DELETE_LOADING_MESSAGE,
  MESSAGES_SET,
  DELETE_COMPOUND_MESSAGE,
  COMPOUND_SELECTED_OPT_SET,
  MESSAGES_READ,
} from './types';
import { APIClient, FileClient } from '../settings';

// CREATE MESSAGE
// export const createMessage = (type, text, date, status, options, response_type = 'normal') => ({
//   type: CREATE_MESSAGE,
//   message: { type, text, date, status, options, response_type },
// });

export const createMessage = (type, text, date, status, options, response_type = 'normal') => ({
  type: CREATE_MESSAGE,
  message: { type, text, date, status, options, response_type },
});

export const compoundSelectedOptSet = (option) => ({
  type: COMPOUND_SELECTED_OPT_SET,
  option,
});

export const deleteCompoundMessage = () => ({
  type: DELETE_COMPOUND_MESSAGE,
});

// RETURN ERRORS
export const returnErrors = (msg, status) => ({
  type: GET_ERRORS,
  payload: { msg, status },
});

// CLEAR MESSAGES IN THE GLOBAL STATE
export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});

// REMOVE LOADING MESSAGE
export const deleteLoadingMessage = () => ({
  type: DELETE_LOADING_MESSAGE,
});

export const postMessageRecord = (arg) =>
  new Promise((resolve, reject) =>
    FileClient.post('api/v1/auth/user/message/', arg)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err))
  );

// load messages from local stroage
export const setMessages = (messages) => ({
  type: MESSAGES_SET,
  messages,
});

// To identify if the message is new or not, if old the voice to text function may not auto play
export const setMessageRead = (index) => ({
  type: MESSAGES_READ,
  index,
});
