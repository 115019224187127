/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Wistia Doc https://wistia.com/support/developers/async-embeds
// Embed Options:
//  videoFoam: this will make the video auto fit

const WistiaPlayer = (props) => {
  const { videoId, videoLink } = props;
  const [swatchOpacity, setSwatchOpacity] = useState(0);

  const handleLoad = (e) => {
    setSwatchOpacity(1);
  };

  useEffect(() => {
    // append JS that WistiaPlayer requires
    const script3 = document.createElement('script');
    script3.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
    script3.async = true;
    document.body.appendChild(script3);

    return () => {
      document.body.removeChild(script3);
    };
  }, []);
  return videoId ? (
    <div
      className={`wistia_embed wistia_async_${videoId} videoFoam=true autoPlay=true muted=true playlistLoop=true`}
      // popover needs to set withd and height
      // className={`wistia_embed wistia_async_${videoId} videoFoam=true popover=true`}
      style={{
        position: 'relative',
        borderRadius: 16,
        overflow: 'hidden',
        // width: 600,
        // height: 300,
      }}
    >
      <div
        className="wistia_swatch"
        style={{
          height: '100%',
          left: 0,
          opacity: swatchOpacity,
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          transition: 'opacity 200ms',
          width: '100%',
        }}
      >
        <img
          src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
          // style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
          alt="Oscer Wistia Video"
          aria-hidden="true"
          onLoad={handleLoad}
          style={{
            filter: 'blur(5px)',
            minWidth: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
      </div>
    </div>
  ) : (
    <div className="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <div
        className="wistia_responsive_wrapper"
        style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%', zIndex: 1 }}
      >
        <iframe
          src={videoLink}
          title="Oscer Inital Explainer Video"
          allow="autoplay; fullscreen"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          className="wistia_embed"
          name="wistia_embed"
          allowFullScreen
          msallowfullscreen="msallowfullscreen"
          width="100%"
          height="100%"
          style={{ borderRadius: 16, zIndex: 1 }}
        ></iframe>
      </div>
    </div>
  );
};

WistiaPlayer.propTypes = {
  videoId: PropTypes.string,
  videoLink: PropTypes.string,
};

export default WistiaPlayer;
