import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';

const SeperatorMessage = (props) => {
  const { message } = props;
  const cs = useStyles();

  return (
    <div className={cs.SeperatorMessage__root}>
      <hr className={cs.SeperatorMessage__hr} />
      <div className={cs.SeperatorMessage__message}>{message.text}</div>
    </div>
  );
};

SeperatorMessage.propTypes = {
  message: PropTypes.object,
};

export default SeperatorMessage;
