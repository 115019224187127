import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import cx from 'classnames';
import imgOscerCrown from '../../../assets/home/hp-plans-oscer-crown.svg';

import useStyles from './style';

/**
 * @desc Oscer Plan card
 *
 */
const PlanCard = (props) => {
  const classes = useStyles();

  const {
    plan: { display_name, original_price, price, billingType, currency_symbol, currency },
    link,
    bestValue = false,
  } = props;

  return (
    <div className={classes.PlanCard}>
      {bestValue && <img src={imgOscerCrown} alt="oscer_crown" className={classes.PlanCard__best__img} />}
      <div className={cx(classes.PlanCard__duration, { [classes.PlanCard__duration__best]: bestValue })}>
        <span>{display_name}</span>
        {bestValue && <span className={classes.PlanCard__duration__value}>BEST VALUE</span>}
      </div>
      <div className={classes.PlanCard__main}>
        {original_price > price && (
          <div className={classes.PlanCard__originalPrice}>{`${currency_symbol ?? '$'}${original_price}`}</div>
        )}
        <div className={classes.PlanCard__currentPrice}>
          {currency_symbol}
          <span className={classes.PlanCard__currentPrice__price}>{price.toString().split('.')[0]}</span>
          {price.toString().split('.')[1]}
          <span className={classes.PlanCard__currentPrice__usd}>{currency ?? 'USD'}</span>
        </div>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={link}
          classes={{ root: cx(classes.PlanCard__btn__root, { [classes.PlanCard__btn__best__root]: bestValue }) }}
        >
          Buy Now
        </Button>
        <div className={classes.PlanCard__desc}>{billingType}</div>
      </div>
    </div>
  );
};
PlanCard.propTypes = {
  plan: PropTypes.object,
  link: PropTypes.string,
};

export default PlanCard;
