import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CheckIcon from '@material-ui/icons/Check';

import { SYSTEM_COLORS } from '../../../../constants';
import LevelBadge from '../../../../components/badge/LevelBadge';
import PresentationImage from '../../../../components/icons/PresentationImg';
import { removeUnderscore } from '../../../../helpers/toTitleCase';
import useStyles from './style';

/**
 * @desc Oscer Case card
 *
 * @param case case object
 */
const CaseCard = (props) => {
  const {
    case: { case_level, system, presentation, patient_name, patient_age, gender, result },
    onClick,

    classes = {
      root: '',
    },
  } = props;

  const isDiagnosed = result ? result.stars > 0 : false;

  const handlePress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const cs = useStyles();
  return (
    <div
      className={cx(cs.CaseCard, classes.root)}
      onClick={onClick}
      role="button"
      onKeyDown={handlePress}
      tabIndex="-1"
    >
      <div className={cs.CaseCard__borderWrapper} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
        <div className={cs.CaseCard__presentationImageWrapper}>
          <PresentationImage presentation={presentation} className={cs.CaseCard__presetationImage} />
        </div>
        <div className={cs.CaseCard__patientName}>{`${patient_name}, ${patient_age}${
          gender === 'Male' ? 'M' : 'F'
        }`}</div>
        <span className={cs.CaseCard__presentation}>PRESENTING WITH</span>
        <div className={cs.CaseCard__presentationName}>{removeUnderscore(presentation)}</div>
        <LevelBadge styles={cs.CaseCard__badge} level={case_level} />

        {isDiagnosed && (
          <div className="card__back__comp__tick" style={{ backgroundColor: SYSTEM_COLORS[system] }}>
            <CheckIcon />
          </div>
        )}
      </div>
    </div>
  );
};

CaseCard.propTypes = {
  case: PropTypes.object,
  onClick: PropTypes.func,
  case_level: PropTypes.string,
  system: PropTypes.string,
  presentation: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  classes: PropTypes.object,
};

export default CaseCard;
