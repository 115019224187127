import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imgDiagnosis from '../../../assets/userTour/mobile-tour-diagnosis-img.svg';
import imgGoal from '../../../assets/userTour/mobile-tour-goal-img.svg';
import imgHistory from '../../../assets/userTour/mobile-tour-history-img.svg';
import imglearn from '../../../assets/userTour/mobile-tour-learn-img.svg';
import imgTemplates from '../../../assets/userTour/mobile-tour-templates-img.svg';
import MobileTourSlide from './MobileTourSlide';
import useStyles from './style';

const MobileUserTour = (props) => {
  const classes = useStyles();

  const { isOpen, onRequestClose } = props;

  const tourSlider = useRef(null);

  const carousel = [
    {
      image: imgGoal,
      tag: 'GOAL',
      title: 'Master Diagnosis',
      subtitle:
        'We designed Oscer for those of you who want to master diagnosis, with advanced features to enhance your clinical reasoning.',
    },
    {
      image: imgHistory,
      tag: 'History Mode',
      title: 'OSCE History',
      subtitle:
        'Practice history taking with realistic OSCE rubrics and our virtual patients powered by AI, it’s less fun but we all need to pass exams.',
    },
  ];

  if (Number(process.env.REACT_APP_LEARN_ON)) {
    carousel.push({
      image: imglearn,
      tag: 'Skills Hub',
      title: 'Learn Mode',
      subtitle:
        'Take a guided history from patients presenting with every symptom, in a safe space where you can make mistakes to learn the basics.',
    });
  }
  if (Number(process.env.REACT_APP_DIAGNOSIS_ON)) {
    carousel.push({
      image: imgDiagnosis,
      tag: 'Diagnosis Mode',
      title: 'Race to Diagnosis',
      subtitle:
        'Make your diagnosis as quickly and safely as possible. You’ll be rewarded for the right answer and remembering red flags.',
    });
  }
  if (Number(process.env.REACT_APP_PWF_ON)) {
    carousel.push({
      image: imgTemplates,
      tag: 'Scripts',
      title: 'OSCE Scripts',
      subtitle:
        'Every case is also a template to practice with friends. It’s a bank of history and examination cases for old fashioned study.',
    });
  }

  const slickSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    swipe: false,
  };

  const handleSlideNext = () => {
    tourSlider.current.slickNext();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onRequestClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.MobileUserTour__main}>
          <Slider {...slickSetting} ref={tourSlider} className={classes.Signup__slider}>
            {carousel.map((car, idx) => (
              <MobileTourSlide
                key={car.tag}
                tag={car.tag}
                img={car.image}
                title={car.title}
                index={idx}
                carLength={carousel.length}
                subtitle={car.subtitle}
                handleNext={handleSlideNext}
                onRequestClose={onRequestClose}
              />
            ))}
          </Slider>
        </div>
      </Fade>
    </Modal>
  );
};

MobileUserTour.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default MobileUserTour;
