import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import oscerLogo from '../../../../assets/logos/oscer-full-logo-dark.svg';
import facebookIcon from '../../../../assets/common/facebook-icon.svg';
import twitterIcon from '../../../../assets/common/twitter-icon.svg';
import privacyPDF from '../../../../assets/policy/Oscer-Privacy-Policy.pdf';
import termPDF from '../../../../assets/policy/Oscer-Terms&Conditions.pdf';
import insIcon from '../../../../assets/common/instagram-icon.svg';
import useStyles from './style';

const HomeFooter = (props) => {
  const classes = useStyles();

  const footerLinks = [
    {
      title: 'Company',
      navigation: [
        { name: 'About', link: '/about' },
        { name: `Careers${props.jobsQuantity ? ` (${props.jobsQuantity})` : ''}`, link: '/careers' },
        { name: 'Contact', link: '/about#contact' },
      ],
    },
    {
      title: 'Product',
      navigation: [
        { name: 'Plans', link: '/plans' },
        { name: 'Technology', link: '/technology' },
        { name: 'Institutions', link: '/institutions' },
      ],
    },
  ];

  const handleScrollTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <div className={classes.HomeFooter}>
      <Container maxWidth="lg" classes={{ root: classes.HomeFooter__container__root }}>
        <div className={classes.HomeFooter__main}>
          <div className={classes.HomeFooter__col}>
            <img src={oscerLogo} alt="oscer_logo" className={classes.HomeFooter__logo} />
            <div className={classes.HomeFooter__copyright}>
              © Copyright {new Date().getFullYear()} Oscer ai. All rights reserved.
            </div>
          </div>
          {footerLinks.map((footer) => (
            <div key={footer.title} className={classes.HomeFooter__col}>
              <div className={classes.HomeFooter__text}>{footer.title}</div>
              {footer.navigation.map((nav) => (
                <Link key={nav.name} to={nav.link} className={classes.HomeFooter__link} onClick={handleScrollTop}>
                  {nav.name}
                </Link>
              ))}
            </div>
          ))}
          <div className={classes.HomeFooter__col}>
            <div className={classes.HomeFooter__text}>Legal</div>
            <a href={termPDF} target="_blank" rel="noopener noreferrer" className={classes.HomeFooter__link}>
              Terms & Conditions
            </a>
            <a href={privacyPDF} target="_blank" rel="noopener noreferrer" className={classes.HomeFooter__link}>
              Privacy Policy
            </a>
          </div>
          <div className={classes.HomeFooter__col}>
            <div className={classes.HomeFooter__text}>Social</div>
            <div className={classes.HomeFooter__social}>
              <a href="https://www.facebook.com/oscerai/" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon} alt="facebook" className={classes.HomeFooter__social__icon} />
              </a>
              <a href="https://twitter.com/oscer_ai" target="_blank" rel="noopener noreferrer">
                <img src={twitterIcon} alt="twitter" className={classes.HomeFooter__social__icon} />
              </a>
              <a href="https://www.instagram.com/oscer.ai/" target="_blank" rel="noopener noreferrer">
                <img src={insIcon} alt="instagram" className={classes.HomeFooter__social__icon} />
              </a>
            </div>
            <div className={classes.HomeFooter__copyright__mobile}>
              © Copyright {new Date().getFullYear()} Oscer ai. All rights reserved.
            </div>
          </div>
          <Button
            variant="contained"
            disableElevation
            classes={{
              root: classes.ScrollTopBtn,
              label: classes.ScrollTopBtn__label,
            }}
            onClick={handleScrollTop}
          >
            <KeyboardArrowUpIcon />
            <div className={classes.ScrollTopBtn__text}>TOP</div>
          </Button>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jobsQuantity: state.home.jobsQuantity,
});

HomeFooter.propTypes = {
  jobsQuantity: PropTypes.number,
};

export default connect(mapStateToProps)(HomeFooter);
