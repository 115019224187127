import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as Sentry from '@sentry/react';
import { Link, withRouter } from 'react-router-dom';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
  Card,
  Icon,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { connect } from 'react-redux';
import { clearAuthErrors, signUp } from '../../actions/auth';
import DividerWithText from '../../components/common/Divider';
import { trackEvent, ecommerceTrack } from '../../service/analytics';
import SocialAuthPanel from '../../containers/SocialAuthPanel';
import iconLogo from '../../assets/logos/oscer-full-logo.svg';
import EmailIcon from '../../assets/login/email-icon-dark.svg';
import PasswordIcon from '../../assets/login/password-icon-dark.svg';
import iconOscerSignup from '../../assets/character/character-signup-lookdown.svg';
import iconReferral from '../../assets/login/referral-icon.svg';
import iconPromo from '../../assets/login/promo-icon.svg';
import freeSession from '../../assets/login/free-sessions-icon.svg';
import termPDF from '../../assets/policy/Oscer-Terms&Conditions.pdf';
import EmailErrorIcon from '../../assets/login/flag-mark-icon-blue.svg';
import { emailRegx } from '../../helpers/validation';
import { useWidth } from '../../helpers/customHooks';
import useGeoLocation from '../../hook/useGeoLocation';
import useStyles from './style';

const EMAIL_ERROR = 2;
const MISSING_FIELDS_ERROR = 1;

const SignUp = (props) => {
  const classes = useStyles();
  const { history, location, referCode = '' } = props;
  const [email, setEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [pwd, setPwd] = useState('');
  const [showPwd, setShowPwd] = useState(false);
  const [pwdHelperText, setPwdHelperText] = useState('');
  // const [confirmPwd, setConfirmPwd] = useState('');
  // const [confirmPwdHelperText, setConfirmPwdHelperText] = useState('');
  // const [referralCode, setReferralCode] = useState(referCode);
  const [promoCode, setPromoCode] = useState(new URLSearchParams(location?.search).get('promo_code') ?? '');
  const [isTermsChecked, setTermsChecked] = useState(true);
  const [errors, setErrors] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);

  const screenSize = useWidth();

  // Detect whether user from UK
  const supportLocale = ['uk'];
  const { country, error } = useGeoLocation('');
  const locale = window.location.pathname.split('/')[1];
  const baseName = supportLocale.includes(locale);
  if (supportLocale.includes(country) && !baseName) {
    window.location.href = `/${country.toLowerCase()}${window.location.pathname || ''}${window.location.search || ''}`;
  }

  // Used to find any errors from server
  // useEffect(() => {
  //   if (props.authError && typeof props.authError === 'object' && props.authError !== null) {
  //     codeToText(Object.keys(props.authError)[0]);
  //   } else if (typeof props.authError === 'string') {
  //     setErrors(props.authError);
  //   } else {
  //     props.clearAuthErrors();
  //   }
  // }, [props.authError]);

  const handleEmailChange = (e) => {
    if (errors.code === EMAIL_ERROR) {
      setErrors('');
    }
    emailValidation(e.target.value);
    setEmail(e.target.value);
  };

  // Email validation after textfield lose focus
  const handleEmailBlur = (e) => {
    emailValidation(e.target.value);
  };

  const emailValidation = (inputEmail) => {
    if (inputEmail && inputEmail.trim()) {
      if (!emailRegx.test(inputEmail.toLowerCase())) {
        setEmailHelperText('Invalid Email');
        return false;
      }
      setEmailHelperText('');
      return true;
    }
    setEmailHelperText('Required');
    return false;
  };

  const handlePwdChange = (e) => {
    setPwd(e.target.value);
    pwdValidation(e.target.value);
  };

  // Password validation after textfield lose focus
  const handlePwdBlur = (e) => {
    pwdValidation(e.target.value);
  };

  const handlePress = (e) => {
    if (formInputCheck() && !isWaiting && e.key === 'Enter') {
      handleSubmit();
    }
  };

  const pwdValidation = (inputPwd) => {
    if (inputPwd) {
      if (inputPwd.length < 6) {
        setPwdHelperText('At least 6 characters');
        return false;
      }
      setPwdHelperText('');
      return true;
    }
    setPwdHelperText('Required');
    return false;
  };

  // const handlePwdConfirmChange = (e) => {
  //   setConfirmPwd(e.target.value);
  // };

  // // Password confirm validation after after textfield lose focus
  // const handlePwdConfirmBlur = (e) => {
  //   pwdConfirmValidation(e.target.value);
  // };

  // const pwdConfirmValidation = (inputConfirmedPwd) => {
  //   if (pwd) {
  //     if (inputConfirmedPwd) {
  //       if (inputConfirmedPwd !== pwd) {
  //         setConfirmPwdHelperText('Password do not match.');
  //         return false;
  //       }
  //       setConfirmPwdHelperText('');
  //       return true;
  //     }
  //     setConfirmPwdHelperText('Password is required.');
  //     return false;
  //   }
  //   setPwdHelperText('Password is required.');
  //   return false;
  // };

  const handleReferralChange = (e) => {
    setPromoCode(e.target.value);
  };

  // Terms and conditions checkbox stage toggle
  const handleTermsChange = () => {
    setTermsChecked((checked) => !checked);
  };

  // Submit button for sign up
  const handleSubmit = () => {
    // Final frontend fields validation
    if (emailValidation(email) && pwdValidation(pwd)) {
      // if (emailValidation(email) && pwdValidation(pwd) && pwdConfirmValidation(confirmPwd)) {
      setIsWaiting(true);
      props
        .signUp(email, pwd, promoCode, country)
        .then((res) => {
          if (promoCode) {
            trackEvent({
              GAAction: 'signup_promotion',
              FBAction: 'Signup promotion',
              category: 'Signup',
              label: `User ${res.data.user.id} signup by promotion ${promoCode}`,
            });
          } else if (res.data.user.profile.promo_code) {
            trackEvent({
              GAAction: 'signup_promo_code',
              FBAction: 'Signup Promo Code',
              category: 'Signup',
              label: `User ${res.data.user.id} signup with promo code ${res.data.user.profile.promo_code}`,
            });
          } else {
            trackEvent({
              GAAction: 'sign_up',
              FBAction: 'Signup',
              category: 'Signup',
              label: `User ${res.data.user.id} signup`,
            });
          }
          const currentDate = new Date();
          ecommerceTrack({
            transactionId: `${res.data.user.id}_${currentDate.getTime()}`,
            value: 0,
            id: 0,
            label: `User ${res.data.user.id} signup`,
            displayName: 'signup',
            category: 'Free',
            price: 0,
            period: 0,
            time: currentDate,
          });

          const urlSearch = history.location.search;
          const query = new URLSearchParams(urlSearch);
          query.delete('promo_code');
          // Redirect mobile user to spm, make user user don't have specific url
          if (
            screenSize === 'xs' &&
            `${history.location.pathname}${!!query.toString() && `?${query.toString()}`}` === '/signup'
          ) {
            history.push('/cases/60/spm');
          } else {
            // history.push(`${history.location.pathname}${!!query.toString() && `?${query.toString()}`}`);
          }
        })
        .catch((err) => {
          // missing fields
          if (err?.code === 1 || err?.code === 2) {
            setErrors(err);
          } else if (err?.response?.status >= 500 || err?.response?.status === 404) {
            setErrors('Network error, please contact support(500).');
            Sentry.captureException(err, {
              tags: {
                section: 'Purchase Error',
              },
            });
          } else {
            setErrors('Network error, please contact support (500).');
          }
        })
        .then(() => {
          setIsWaiting(false);
        });
    }
  };

  // Check if all fields are valid
  const formInputCheck = () => email && !emailHelperText && pwd && !pwdHelperText && isTermsChecked;
  // email && !emailHelperText && pwd && !pwdHelperText && confirmPwd && !confirmPwdHelperText && isTermsChecked;

  const codeToText = (code) => {
    switch (code) {
      case 'email':
        setEmailHelperText(renderErrors('email'));
        return;
      case 'password':
        setPwdHelperText(renderErrors('password'));
        return;
      // case 'password2':
      //   setConfirmPwdHelperText(renderErrors('password2'));
      //   return;
      case 'agreed':
        setErrors('You must agree with Terms and Conditions');
        return;
      case 'non_field_errors':
        setErrors(renderErrors('non_field_errors'));
        return;
      default:
        setErrors('Network error, please contact support(500).');
    }
  };

  const renderErrors = (field) => !!props.authError[field] && props.authError[field][0];

  return (
    <>
      <Link to="/">
        <img src={iconLogo} alt="oscer__icon__logo" className={classes.Signup__icon__logo} />
      </Link>
      <div className={classes.Signup__title}>SIGN UP FOR FREE ACCESS</div>
      <DividerWithText marginTop="25px">sign up with one click</DividerWithText>
      <div className={classes.Signup__social__login}>
        <SocialAuthPanel country={country} />
      </div>
      <DividerWithText marginTop="20px">or use your email</DividerWithText>
      <form className={classes.Signup__form} autoComplete="off" onKeyDown={handlePress}>
        <TextField
          className={classes.Signup__textField}
          error={!!emailHelperText.length}
          classes={{
            root: cx(classes.Signup__textField__root, {
              [classes.Signup__texfField__error__offset]: !!emailHelperText.length,
            }),
          }}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          value={email}
          required
          id="email"
          autoComplete="off"
          placeholder="Email Address"
          helperText={emailHelperText}
          InputProps={{
            startAdornment: <img src={EmailIcon} alt="email_icon" className={classes.Signup__textField__icon} />,
            classes: { input: classes.Signup__textField__input },
          }}
        />
        {errors.code === EMAIL_ERROR && (
          <Card className={classes.Email__error}>
            <div className={classes.Left__side__message}>
              <div className={classes.icon__wrapper}>
                <Icon classes={{ root: classes.iconRoot }}>
                  <img className={classes.imageIcon} alt="dangerIcon" src={EmailErrorIcon} />
                </Icon>
              </div>
              <div className={classes.info__wrapper}>
                <span>{errors.message}</span>
              </div>
            </div>
            <div className={classes.Signup__message}>
              <span>
                <Link to={`/signin${props.location.search}`} className={classes.Link__message}>
                  SIGN IN NOW
                </Link>
              </span>
            </div>
          </Card>
        )}

        <TextField
          className={classes.Signup__textField}
          error={!!pwdHelperText.length}
          classes={{
            root: cx(classes.Signup__textField__root, {
              [classes.Signup__texfField__error__offset]: !!pwdHelperText.length,
            }),
          }}
          onChange={handlePwdChange}
          onBlur={handlePwdBlur}
          type={showPwd ? 'text' : 'password'}
          autoComplete="off"
          value={pwd}
          required
          id="password"
          placeholder="Password"
          helperText={pwdHelperText}
          InputProps={{
            startAdornment: <img src={PasswordIcon} alt="pwd_icon" className={classes.Signup__textField__icon} />,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPwd((show) => !show)}
                  // onMouseDown={handleMouseDownPassword}
                >
                  {showPwd ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                </IconButton>
              </InputAdornment>
            ),
            classes: { input: classes.Signup__textField__input },
          }}
        />

        {/* <TextField
            className={classes.Signup__textField}
            error={!!confirmPwdHelperText.length}
            classes={{
              root: cx(classes.Signup__textField__root, {
                [classes.Signup__texfField__error__offset]: !!confirmPwdHelperText.length,
              }),
            }}
            onChange={handlePwdConfirmChange}
            value={confirmPwd}
            onBlur={handlePwdConfirmBlur}
            type="password"
            required
            id="password2"
            autoComplete="off"
            placeholder="Confirm Password"
            helperText={confirmPwdHelperText}
            InputProps={{
              startAdornment: <img src={PasswordIcon} alt="pwd_icon" className={classes.Signup__textField__icon} />,
              classes: { input: classes.Signup__textField__input },
            }}
          /> */}
        {/* <TextField
            className={classes.Signup__textField}
            // error={!!helperText.length}
            classes={{ root: classes.Signup__textField__root }}
            onChange={handleReferralChange}
            value={referralCode}
            id="referral code"
            placeholder="Referral Code (Optional)"
            // helperText={helperText}
            InputProps={{
              startAdornment: (
                <img src={iconReferral} alt="referral_icon" className={classes.Signup__textField__icon} />
              ),
              endAdornment: (
                <img
                  src={freeSession}
                  alt="freeSession"
                  className={cx(classes.Signup__freeSession__icon, {
                    [classes.Signup__freeSession__icon__show]: referralCode.length === 6,
                  })}
                />
              ),
              classes: { input: classes.Signup__textField__input },
            }}
          /> */}
        {!!promoCode && (
          <TextField
            className={classes.Signup__textField}
            // error={!!helperText.length}
            classes={{ root: classes.Signup__textField__root }}
            onChange={handleReferralChange}
            value={promoCode}
            id="promo code"
            placeholder="Promo Code (Optional)"
            // helperText={helperText}
            InputProps={{
              startAdornment: <img src={iconPromo} alt="promoCode_icon" className={classes.Signup__textField__icon} />,
              // endAdornment: (
              //   <img
              //     src={freeSession}
              //     alt="freeSession"
              //     className={cx(classes.Signup__freeSession__icon, {
              //       [classes.Signup__freeSession__icon__show]: promoCode.length === 6,
              //     })}
              //   />
              // ),
              classes: { input: classes.Signup__textField__input },
            }}
          />
        )}
        <div className={classes.Signup__checkbox}>
          <FormControlLabel
            classes={{ root: classes.Signup__FormControlLabel__root }}
            control={<Checkbox checked={isTermsChecked} onChange={handleTermsChange} name="checkedA" color="primary" />}
            label="I agree to the Oscer"
          />
          <a href={termPDF} target="_blank" rel="noopener noreferrer" className={classes.Signup__terms}>
            Terms and Conditions
          </a>
        </div>
        <Button
          variant="contained"
          color="primary"
          classes={{
            root: classes.Signup__buttom__root,
            contained: classes.Signup__button__contained,
            label: classes.Signup__button__label,
            disabled: isWaiting ? classes.Signup__button__isLoading : classes.Signup__button__disabled,
          }}
          disabled={!formInputCheck() || isWaiting}
          onClick={handleSubmit}
        >
          {isWaiting ? <CircularProgress color="secondary" size={16} /> : 'Sign Up'}
        </Button>
        {errors.code === MISSING_FIELDS_ERROR ? (
          // missing fields
          <div className={classes.Signup__error}>{errors.code !== EMAIL_ERROR && errors.message}</div>
        ) : (
          <div className={classes.Signup__error}>{errors.code !== EMAIL_ERROR && errors}</div>
        )}
      </form>
      <div className={classes.Signup__have__account}>
        Already have an account?{' '}
        {props.setSignUp ? (
          // send to prime account sign in
          <span onClick={() => props.setSignUp(false)} className={classes.Signup__signin} aria-hidden="true">
            Sign in
          </span>
        ) : (
          <Link to={`/signin${props.location?.search}`} className={classes.Signup__signin}>
            Sign in{' '}
          </Link>
        )}
      </div>
    </>
  );
};

SignUp.propTypes = {
  location: PropTypes.object,
  signUp: PropTypes.func,
  setSignUp: PropTypes.func,
  authError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  clearAuthErrors: PropTypes.func,
  history: PropTypes.object,
  referCode: PropTypes.string,
};

export default SignUp;
