import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ProfilePanelMobile: {
    backgroundColor: 'white',
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderRadius: '20px 0 0 20px',
    width: 60,
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ProfilePanelMobile__icon__wrapper: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: '#f2fafd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ProfilePanelMobile__icon: {},
}));

export default useStyles;
