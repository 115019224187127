import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Provider as AlertProvider } from 'react-alert';
import { ThemeProvider } from '@material-ui/core/styles';
import AlertTemplate from 'react-alert-template-basic';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { enableMapSet } from 'immer';
import * as Sentry from '@sentry/react';
import Mixpanel from 'mixpanel-browser';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import store from './store';
import theme from './theme';
import OscerLoader from './components/loader/OscerLoader';
import App from './containers/App';

import Gleap from 'gleap';
import { GOOGLE_CLIENT_ID } from 'helpers/constants';

// Please make sure to call this method only once!
Gleap.initialize('kC1ZvYxGOhDdkkjPzme8RJq3vp6ktxEO');
const history = createBrowserHistory();
// init sentry as early as possible, before initializing React
if (process.env.REACT_APP_STAGE !== 'development') {
  Sentry.init({
    dsn: 'https://194284dd19a842df8d7290798a8b02b7@o506358.ingest.sentry.io/5595949',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: process.env.REACT_APP_STAGE,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1,
    ignoreErrors: [
      // Facebook borked
      'fb_xd_fragment',
      'this.onready is not a function',
      'cancelled',
      'cancelado',
      'annulé',
      // todo: some error from the audio player, we may want to fix it
      'Requested device not found',
      `Cannot read property 'scale' of null`,
      'interact with the document first',
      // Loading chunk XX failed.
      'Loading chunk',
      // request response code 0
      'Network Error',
      'parentNode',
      // Non-Error promise rejection captured with value: Object Not Found Matching Id:2 (in verification)
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
      // Wistia video error
      `Cannot read property 'embedOptions' of null`,
      `POST https://pipedream.wistia.com/mput?topic=metrics - Got an unsuccessful status code: 0.`,
      `Cannot read property 'url' of undefined`,
      `_this.updateVideoThumbnail is not a function`,
      `Cannot read property 'webkitFullscreenElement' of null`,
      `AbortError: The fetching process for the media resource was aborted by the user agent at the user's request.`,
      `Cannot read property 'trigger' of null`,

      // Logout
      't.hls.levels[e] is undefined',
      'Illegal invocation',

      // hotjar
      'NotSupportedError: Failed to load because no supported source was found.',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
      /wistia\.com/i,
    ],
    beforeSend(event, hint) {
      if (hint) {
        const error = hint.originalException;
        let errorMessage = '';
        if (typeof error === 'string') {
          errorMessage = error;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }
        if (errorMessage.includes('401')) {
          return null;
        }
      }

      return event;
    },
  });
}

// init Mixpanel
Mixpanel.init('fcdb127a646663d5f6481558fdc82c40');

// export const hlp = initHelpHero('UNISf9fAkCl');

// Alert Options
const alertOptions = {
  timeout: 3000,
  position: 'top center',
};

const oscerLoader = (
  <div className="application__loader">
    <OscerLoader />
  </div>
);

// enable set and map in immer
enableMapSet();

ReactDOM.render(
  <div className="application">
    <Suspense fallback={oscerLoader}>
      <Provider store={store}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <ThemeProvider theme={theme}>
            <AlertProvider template={AlertTemplate} {...alertOptions}>
              <App />
            </AlertProvider>
          </ThemeProvider>
        </GoogleOAuthProvider>
      </Provider>
    </Suspense>
  </div>,
  document.getElementById('app')
);
