import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Modal } from '../../../../components/common';
import imgUpgrade from '../../../../assets/payment/prime-offer-img.svg';
import imgUpgrade2 from '../../../../assets/payment/prime-offer-2-cases-img.svg';
import imgUpgrade3 from '../../../../assets/payment/prime-offer-4-cases-img.svg';
import imgUpgradeExplorer from '../../../../assets/payment/new-feature-condition-explorer.svg';
import useStyles from './style';

/**
 * @desc Mode selection modal
 * @param {*} param0
 */
const UpgradeExplorerModal = (props) => {
  const classes = useStyles();
  const { isToggle, toggle, type = 'explorer' } = props;

  // Handle upgrade modal text
  let contentObj = {};
  switch (type) {
    case 'explorer':
      contentObj = {
        title: `Upgrade to Oscer Prime to access Condition Explorer`,
        subtitle:
          'Condition Explorer allows you to compare different conditions. Access Condition Explorer and more cases with Oscer Prime.',
        image: imgUpgradeExplorer,
        imageStyle: classes.UpgradeExplorerModal__img__2,
      };
      break;
    default:
      contentObj = {
        title: `Upgrade to Oscer Prime to access Condition Explorer`,
        subtitle:
          'Condition Explorer allows you to compare different conditions. Access Condition Explorer and more cases with Oscer Prime.',
        image: imgUpgradeExplorer,
        imageStyle: classes.UpgradeExplorerModal__img__2,
      };
      break;
  }

  const location = useLocation();

  return (
    <Modal isToggle={isToggle} toggle={() => toggle(false, type)} centered zIndex={9999} closable>
      <div className={classes.UpgradeExplorerModal}>
        <div className={classes.UpgradeExplorerModal__cancel} onClick={() => toggle(false, type)} aria-hidden="true">
          Exit
        </div>
        <div className={classes.UpgradeExplorerModal__content}>
          <div className={classes.UpgradeExplorerModal__wrapper}>
            <img src={contentObj.image} alt="upgrade_image" className={contentObj.imageStyle} />
            <div className={classes.UpgradeExplorerModal__title}>{contentObj.title}</div>
            <div className={classes.UpgradeExplorerModal__subtitle}>{contentObj.subtitle}</div>
          </div>
          <div className={classes.UpgradeExplorerModal__btns}>
            <Button
              variant="contained"
              classes={{ root: classes.UpgradeExplorerModal__btn }}
              component={Link}
              to={{
                pathname: '/compare',
                state: { from: location.pathname, search: location.search },
              }}
              onClick={() => toggle(false, type)}
            >
              {contentObj.button || 'Learn More'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

UpgradeExplorerModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  type: PropTypes.string,
};

export default UpgradeExplorerModal;
