import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import HomeHeader from '../../components/Layout/Header/HomeHeader';
import HomeFooter from '../../components/Layout/Footer/HomeFooter';
import DashboardHelper from '../DashboardHelper';
import DashboardHeaderNav from '../../components/Layout/Header/DashboardHeaderNav';
import DashboardNavMobile from '../../components/Layout/Header/DashboardNavMobile';
import DashboardFooterNav from '../../components/Layout/Footer/DashboardFooterNav';
import UpgradeHeader from '../../components/Layout/Header/UpgradeHeader';
import { useWidth } from '../../helpers/customHooks';
import useStyles from './style';

/**
 * @desc This route used by home pages that need footer and header
 * @param {*} param0
 */
//! must full match the subdomain and domain to allow SAML2 Login for safety
// ! Need refactor to follow redirect match url parameter
// todo: change to ==== unimelb.oscer.ai
const isSubdomain = window.location.hostname.includes('unimelb');

const getSearchParams = (searchQuery) => {
  const query = new URLSearchParams(searchQuery);
  return query;
};

const HomeRouter = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isSubdomain ? (
        <Redirect to="/saml2/login" />
      ) : (
        <>
          <HomeHeader isAuthenticated={isAuthenticated} />
          <Component {...props} />
          <HomeFooter />
        </>
      )
    }
  />
);

HomeRouter.propTypes = {
  auth: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  component: PropTypes.any,
};

/**
 * @desc This route used by pages exclusive to unauthorised users
 * @param {*} param0
 */
const NonAuthOnlyRouter = ({ component: Component, isAuthenticated, ...rest }) => {
  const newSearchQuery = getSearchParams(rest.location.search);
  // Remove promo_code because this has been used in signin/up
  newSearchQuery.delete('promo_code');
  // console.log('NonAuthOnlyRouter', newSearchQuery.toString());

  // Force signup user to take the tour
  if (rest.location.pathname.includes('/signup')) {
    newSearchQuery.delete('redirect');
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        // If authenticate and not verify page, redirect or got to dashboard.
        // If authenticate and verify page, render verify
        // If not authenticate, render component
        isAuthenticated && !rest.location.pathname.includes('/verify/') ? (
          <Redirect
            to={
              newSearchQuery.get('redirect') ||
              `/dashboard/home${newSearchQuery.toString() ? `?${newSearchQuery.toString()}` : ''}`
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
NonAuthOnlyRouter.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
};

/**
 * @desc This route used by dashboard that need a side bar
 * @param {*} param0
 */
const DashboardRouter = ({
  component: Component,
  isAuthenticated,
  isEmailVerified,
  isVerifySkiped,
  isVerifyQuizCompleted,
  subscriptionDaysLeft,
  isPrime,
  user,
  ...rest
}) => {
  const classes = useStyles();

  // console.log('DashboardRouter', rest.location.search, isAuthenticated);
  const newSearchQuery = getSearchParams(rest.location.search);
  const screenSize = useWidth();

  if (screenSize === 'xs' || screenSize === 'sm') {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // If authenticate, and if verified -> render component, if not redirect verify
        if (isAuthenticated) {
          // Render component if quizs are answered or skipped or this is verify page it self
          if (
            (isVerifyQuizCompleted && isEmailVerified) ||
            isVerifySkiped ||
            rest.location.pathname.includes('/verify/')
          ) {
            return (
              <div className={classes.App__dasboard}>
                <DashboardHelper />
                {screenSize === 'xs' || screenSize === 'sm' ? (
                  <DashboardNavMobile user={user} />
                ) : (
                  <>
                    <DashboardHeaderNav user={user} />
                    <UpgradeHeader subscriptionDaysLeft={subscriptionDaysLeft} isPrime={isPrime} />
                  </>
                )}

                <div className={classes.App__dasboard__content} id="mobile-cases-dashboard-scroll-id">
                  <Component {...props} />
                </div>
                {(screenSize === 'xs' || screenSize === 'sm') && <DashboardFooterNav />}
              </div>
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/verify',
                search: `?redirect=${props.location.pathname}${
                  newSearchQuery.toString() ? `?${newSearchQuery.toString()}` : ''
                }`,
              }}
            />
          );
        }
        // If not authenticate, redirect to signin, unimelb users need to redirect them to saml login
        return (
          <Redirect
            to={
              isSubdomain
                ? '/saml2/login'
                : {
                    pathname: '/signin',
                    search: `?redirect=${props.location.pathname}${
                      newSearchQuery.toString() ? `?${newSearchQuery.toString()}` : ''
                    }`,
                  }
            }
          />
        );
      }}
    />
  );
};

DashboardRouter.propTypes = {
  isAuthenticated: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  isEmailVerified: PropTypes.bool,
  isVerifySkiped: PropTypes.bool,
  isVerifyQuizCompleted: PropTypes.bool,
  subscriptionDaysLeft: PropTypes.number,
  isPrime: PropTypes.bool,
  user: PropTypes.object,
};

/**
 * @desc This route used by pages exclusive to authorised users
 * @param {*} param0
 */
const AuthOnlyRouter = ({
  component: Component,
  isAuthenticated,
  isEmailVerified,
  isVerifySkiped,
  isVerifyQuizCompleted,
  ...rest
}) => {
  const newSearchQuery = getSearchParams(rest.location.search);
  // console.log('AuthOnlyRouter', rest.location.pathname, newSearchQuery.toString());

  return (
    <Route
      {...rest}
      render={(props) => {
        localStorage.setItem('saml2_redirect_url', props.match.url);
        // If not authenticate, redirect to signin
        if (isAuthenticated) {
          // If verified, render component. If not check if it's in verify
          if ((isVerifyQuizCompleted && isEmailVerified) || isVerifySkiped) {
            // If pathname has verify, go to dashboard or redirect to presigned url.
            if (props.location.pathname === '/verify') {
              let url = '/dashboard/home';
              if (rest.location.search) {
                url = newSearchQuery.get('redirect');
              }
              return (
                <Redirect
                  to={{
                    pathname: url,
                  }}
                />
              );
            }
            return <Component {...props} />;
          }
          if (props.location.pathname === '/verify') {
            return <Component {...props} />;
          }
          // If not verified, redirect to verify and save predefined url
          return (
            <Redirect
              to={{
                pathname: isSubdomain ? '/saml2/login' : '/verify',
                search: `?redirect=${rest.location.pathname}${
                  newSearchQuery.toString() ? `&${newSearchQuery.toString()}` : ''
                }`,
              }}
            />
          );
        }
        // If not authenticate, redirect to signin
        return (
          <Redirect
            to={{
              pathname: isSubdomain ? '/saml2/login' : '/signin',
              search: `?redirect=${rest.location.pathname}${
                newSearchQuery.toString() ? `&${newSearchQuery.toString()}` : ''
              }`,
            }}
          />
        );
      }}
    />
  );
};

AuthOnlyRouter.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  isEmailVerified: PropTypes.bool,
  isVerifySkiped: PropTypes.bool,
  isVerifyQuizCompleted: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
  isEmailVerified: state.auth.user.profile.is_email_verified,
  isVerifySkiped: state.auth.isVerifySkiped,
  // Google signup has default verified but no qs
  // isVerifyQuizCompleted: !!state.auth.user.profile.study_field || !!state.auth.user.profile.university,
  isVerifyQuizCompleted: !!state.auth.user.profile.experience_level,
  isPrime: state.auth.user.prime_status,
  subscriptionDaysLeft: state.auth.user.subscription_days_left,
  user: state.auth.user,
});

/**
 * @desc This route used by pages to SAML2 Login and Verification
 * @param {*} param0
 */
const SAMLRouter = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (isAuthenticated ? <Redirect to="/dashboard/home" /> : <Component {...props} />)}
  />
);

SAMLRouter.propTypes = {
  component: PropTypes.any,
  isAuthenticated: PropTypes.bool,
};

export const AuthOnlyRoute = connect(mapStateToProps)(AuthOnlyRouter);
export const NonAuthOnlyRoute = connect(mapStateToProps)(NonAuthOnlyRouter);
export const HomeRoute = connect(mapStateToProps)(HomeRouter);
export const DashboardRoute = connect(mapStateToProps)(DashboardRouter);
export const SAMLRoute = connect(mapStateToProps)(SAMLRouter);
