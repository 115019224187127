import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Modal } from '../../common';
import PresentationImg from '../../icons/PresentationImg';
import ConfirmButton from '../../buttons/ConfirmButton';
import { PRESENTATION_ICON_XLARGE, SYSTEM_COLORS } from '../../../constants';
import './style.scss';

/**
 * @desc Case begin modal
 * @param {*} props
 * - isToggle is a boolean which represents the modal is popup or not
 * - toggle is a function to control modal
 * - history is props from react router
 * - wantToNavigateTo an action that accept a string as url, if the string is valid (e.g. '/dashboard/home/') then navigatingToPage is true, if string is empty then navigatingToPage is false
 * - navigatingToPage is a boolean that controls whether user can navigate to new page, this is useful when you are in page /case but want to navigate to other pages such as /profile
 * - system is a string of current system
 */
const ExitModal = (props) => {
  const {
    isToggle,
    toggle = () => {},
    history,
    navigatingToPage,
    system = 'respiratory',
    title = 'Are you sure you want to exit the case?',
    message = 'You will lose the progress you’ve made in the current history section',
    leftBtnText = 'Exit',
    leftBtnFunc = null,
    rightBtnText = 'Cancel',
    rightBtnFunc = null,
    image,
  } = props;
  const handleExit = () => {
    if (leftBtnFunc) {
      leftBtnFunc();
    } else {
      toggle();
      if (navigatingToPage) {
        history.push(navigatingToPage);
      }
    }
  };

  const handleRightBtnClick = () => {
    if (rightBtnFunc) {
      rightBtnFunc();
    } else {
      toggle();
    }
  };

  return (
    <div className="exit-modal">
      <Modal isToggle={isToggle} toggle={toggle} centered closable={false}>
        <div className={classnames('case__modal')}>
          <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
            <div className="case__modal">
              {image ? (
                <img className="case__modal__img--custom" src={image} alt="oscer-character"></img>
              ) : (
                <PresentationImg className="case__modal__img" presentation="exit" size={PRESENTATION_ICON_XLARGE} />
              )}

              <div className="case__modal__title">
                <div className={classnames('title', 'case__modal__title-text')}>
                  <h5 className="case__modal__title-text">{title}</h5>
                </div>
              </div>
              <div className={classnames('note', 'case__modal__subtitle')}>{message}</div>

              <div className={classnames('buttons', 'case__modal__btns')}>
                <ConfirmButton className={classnames('btn-exit', 'btn-round')} onClick={() => handleExit()}>
                  {leftBtnText}
                </ConfirmButton>
                <ConfirmButton className={classnames('btn-exit-cancel', 'btn-round')} onClick={handleRightBtnClick}>
                  {rightBtnText}
                </ConfirmButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ExitModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  navigatingToPage: PropTypes.string,
  system: PropTypes.string,
  history: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  rightBtnText: PropTypes.string,
  leftBtnText: PropTypes.string,
  rightBtnFunc: PropTypes.func,
  leftBtnFunc: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default withRouter(ExitModal);
