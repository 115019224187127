import { makeStyles } from '@material-ui/core/styles';
import HomeHeroBG from '../../assets/home/section-hero.svg';

const useStyles = makeStyles((theme) => ({
  NotFound: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  NotFound__container__outer: {
    background: `url(${HomeHeroBG})`,
    overflow: 'hidden',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  NotFound__container__inner: {
    marginTop: 140,
    [theme.breakpoints.down('sm')]: {
      marginTop: 80,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 30,
    },
  },
  NotFound__main: {
    display: 'flex',
    width: '40%',
    flexDirection: 'column',
    color: theme.palette.text.main,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  NotFound__title: {
    marginBottom: 24,
    marginTop: 50,
    fontSize: 56,
    lineHeight: 1.11,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      marginTop: 0,
      textAlign: 'center',
    },
  },
  NotFound__subtitle: {
    marginBottom: 28,
    fontSize: 30,
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 26,
      textAlign: 'center',
    },
  },
  NotFound__text: {
    marginBottom: 50,
    fontSize: 18,
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      textAlign: 'center',
    },
  },
  NotFound__btn__contained: {
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  NotFound__image: {
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
}));

export default useStyles;
