import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // You can manually send the error to Sentry
      // Sentry.captureException(this.state.error);
      Sentry.captureException(this.state.error, {
        tags: {
          section: 'error boundary',
        },
      });
      // console.log('ErrorBoundary', this.state.error);
      if (this.state.error.name === 'ChunkLoadError') {
        window.location.reload();
      }

      // Error path
      return (
        <div>
          <h2>Loading...</h2>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
export default ErrorBoundary;
