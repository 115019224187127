import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import cx from 'classnames';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';
import { findIndex } from 'lodash';
import LevelBadge from '../../icons/LevelBadge/LevelBadge';
import { READABLE_SYSTEM_NAMES } from '../../../constants';
import System from '../../icons/System';
import LockedIcon from '../../../assets/icons/locked-icon.svg';

import useStyles from './style';

/**
 * @desc Dropdown list
 * @param {*} props
 * - options: dropdown values (string array/obj array)
 * - selectedList: list of values selected
 * - setSelectedList: function to set selectedList
 * - sort: boolean -- whether sort is true in dropdown
 * - setSearchValue: function to set search value in parent
 * - multi: boolean whether many can be selected
 * - fieldToDisplay: name of field to be displayed if array is obj
 */
const DropdownList = (props) => {
  const classes = useStyles();
  const {
    options = [],
    selectedList = [],
    setSelectedList = () => {},
    sort = false,
    userInput = '',
    setSearchValue = () => {},
    setInputFocus = () => {},
    setDropdownHeight = () => {},
    multi = false,
    fieldToDisplay = 'name',
    system = false,
    level = false,
    tickEnd = false,
    locked = false,
    comingSoon = false,
    icon,
  } = props;

  const [dropdownList, setDropdownList] = useState([]);
  const widthRef = useRef(null);

  const compareObj = (a, b) => {
    if (locked) {
      if (a.locked && !b.locked) {
        return 1;
      } else if (!a.locked && b.locked) {
        return -1;
      }
      // if both locked by name
    }
    if (a[fieldToDisplay] < b[fieldToDisplay]) {
      return -1;
    }
    if (a[fieldToDisplay] > b[fieldToDisplay]) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    let tempOptions = options;
    // if array string
    if ((options.length > 0 && typeof options[0] === 'string') || typeof options[0] === 'number') {
      tempOptions = transfromStringToObj(options);
    }
    // if array object
    if (sort) {
      setDropdownList(tempOptions.sort(compareObj));
    } else {
      setDropdownList(tempOptions);
    }
  }, []);

  const transfromStringToObj = (arr) => {
    return arr.map((a) => ({ name: a, oriName: a }));
  };

  const handleListReturn = () => {
    setSearchValue('');
  };

  const handleItemClick = (event, dia) => {
    if (!multi) {
      if (checkExist(selectedList, dia)) {
        setSelectedList([]);
        return;
      }
      setSelectedList([...[dia]]);
    } else {
      if (checkExist(selectedList, dia)) {
        let newList = selectedList.filter((elem) => elem[fieldToDisplay] !== dia[fieldToDisplay]);
        setSelectedList([...newList]);
      } else {
        setSelectedList([...selectedList, dia]);
      }
      setSearchValue('');
      setInputFocus();
    }
  };

  const checkExist = (list, elem) => {
    if (list instanceof Array) {
      if (list.some((e) => e[fieldToDisplay] === elem[fieldToDisplay])) {
        return true;
      }
      return;
    }
    // set
    return list.has(elem);
  };

  useEffect(() => {
    if (widthRef.current) {
      setDropdownHeight(widthRef.current.offsetHeight);
    }
  }, [widthRef.current, userInput]);

  const fuseSettings = {
    threshold: 0.2,
    keys: [fieldToDisplay],
  };

  const fuse = new Fuse(dropdownList, fuseSettings);

  const searchResult = fuse.search(userInput).map((result) => result.item);

  // When system is selected and search conditions under current system
  return userInput.length > 0 ? (
    <List ref={widthRef} className={classes.systemList__list} style={{ marginTop: '-7px', overflow: 'hidden' }}>
      <li className={classes.systemList__list__li}>
        <ul className={classes.systemList__list__ul}>
          <ListSubheader
            className={cx(classes.systemList__list__subheader, classes.systemList__list__subheader__results)}
            onClick={handleListReturn}
          >
            <ArrowBackIosIcon classes={{ root: classes.systemList__back__icon }} />
            {`${searchResult.length} RESULTS`}
          </ListSubheader>
          <div style={{ maxHeight: '250px', overflow: 'scroll' }}>
            {dropdownList.length === 0 && <div className={classes.loading__message}> Loading... </div>}
            {searchResult.map((dia, index) => (
              <ListItem
                key={index}
                button
                disabled={dia.locked}
                selected={checkExist(selectedList, dia)}
                onClick={(event) => handleItemClick(event, dia)}
                className={classes.systemList__list__listItem}
                style={{
                  padding:
                    system || level
                      ? widthRef.current.offsetWidth < 200
                        ? '3px 5px 3px 13px'
                        : '3px 5px 3px 16px'
                      : widthRef.current.offsetWidth < 200
                      ? '3px 5px 3px 20px'
                      : '3px 16px 3px 28px',
                  textOverflow: 'ellipsis',
                  maxWidth: dia.coming_soon ? 'calc(100% - 77px)' : 'calc(100% - 23px)',
                  overflow: 'hidden',
                  marginRight: '2px',
                }}
              >
                {system && (
                  <System
                    system={dia.oriName}
                    size={32}
                    className={cx(classes.systemList__system__icon, {
                      [classes.systemList__system__icon__sm]: widthRef.current.offsetWidth < 200,
                    })}
                  />
                )}
                {level && <LevelBadge level={dia.oriName} styles="levelModal-badge" hide mobile />}
                {locked && dia.locked && (
                  <img className={classes.systemList__locked__icon} src={LockedIcon} alt="filterIcon" />
                )}
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize:
                        widthRef.current.offsetWidth < 200
                          ? '11px'
                          : widthRef.current.offsetWidth < 245
                          ? '13px'
                          : '95%',
                      textOverflow: 'ellipsis',
                      maxWidth: dia.coming_soon ? 'calc(100% - 77px)' : 'calc(100% - 23px)',
                      overflow: 'hidden',
                      marginRight: '2px',
                    },
                  }}
                  primary={dia[fieldToDisplay]}
                  className={classes.systemList__list__text}
                />
                {checkExist(selectedList, dia) && <CheckIcon classes={{ root: classes.systemList__list__tick }} />}
              </ListItem>
            ))}
          </div>
        </ul>
      </li>
    </List>
  ) : (
    <List ref={widthRef} className={classes.systemList__list} subheader={<li />}>
      {userInput.length === 0 && (
        <li className={classes.systemList__list__li}>
          <ul className={classes.systemList__list__ul}>
            {dropdownList.length === 0 && <div className={classes.loading__message}> Loading... </div>}
            {dropdownList.map((option, index) => (
              <ListItem
                key={index}
                button
                selected={checkExist(selectedList, option)}
                onClick={(event) => handleItemClick(event, option)}
                className={classes.systemList__list__listItem}
                style={{
                  padding:
                    system || level || ((locked || comingSoon) && option.locked)
                      ? widthRef.current.offsetWidth < 200
                        ? '3px 5px 3px 13px'
                        : '3px 5px 3px 16px'
                      : widthRef.current.offsetWidth < 200
                      ? '3px 5px 3px 20px'
                      : '3px 16px 3px 28px',
                }}
                disabled={option.locked}
              >
                {!tickEnd && checkExist(selectedList, option) && (
                  <CheckIcon classes={{ root: classes.systemList__list__tick }} />
                )}
                {system && (
                  <System
                    system={option.oriName}
                    size={32}
                    className={cx(classes.systemList__system__icon, {
                      [classes.systemList__system__icon__sm]: widthRef.current.offsetWidth < 200,
                    })}
                  />
                )}
                {level && (
                  <LevelBadge
                    level={option.oriName}
                    className={cx(classes.systemList__level__icon, {
                      [classes.systemList__level__icon__sm]: widthRef.current.offsetWidth < 200,
                    })}
                    styles="levelModal-badge"
                    hide
                    mobile
                  />
                )}
                {locked && option.locked && (
                  <img className={classes.systemList__locked__icon} src={LockedIcon} alt="filterIcon" />
                )}
                <ListItemText
                  primaryTypographyProps={{
                    style: {
                      fontSize:
                        widthRef.current.offsetWidth < 200
                          ? '11px'
                          : widthRef.current.offsetWidth < 245
                          ? '13px'
                          : '95%',
                      textOverflow: 'ellipsis',
                      // if coming soon cut margin
                      maxWidth: option.coming_soon ? 'calc(100% - 77px)' : 'calc(100% - 23px)',
                      overflow: 'hidden',
                      marginRight: '2px',
                      // not coming soon and not locked
                      marginLeft: (locked || comingSoon) && !option.coming_soon && !option.locked ? 0 : 0,
                    },
                  }}
                  primary={option[fieldToDisplay]}
                  className={classes.systemList__list__text}
                />
                {tickEnd && checkExist(selectedList, option) && (
                  <CheckIcon classes={{ root: classes.systemList__list__tick__right }} />
                )}
                {comingSoon && option.coming_soon && (
                  // if not disabled
                  <div
                    className={cx(classes.systemList__coming_soon, {
                      [classes.systemList__coming_soon__lg]: widthRef.current.offsetWidth > 245,
                    })}
                  >
                    <span> COMING SOON </span>
                  </div>
                )}
              </ListItem>
            ))}
          </ul>
        </li>
      )}
    </List>
  );
};

DropdownList.propTypes = {
  options: PropTypes.array,
  setSelectedList: PropTypes.func,
  selectedList: PropTypes.array,
  userInput: PropTypes.string,
  setSearchValue: PropTypes.func,
  setInputFocus: PropTypes.func,
  sort: PropTypes.bool,
  multi: PropTypes.bool,
  fieldToDisplay: PropTypes.string,
  system: PropTypes.bool,
  level: PropTypes.bool,
  tickEnd: PropTypes.bool,
  icon: PropTypes.bool,
  setDropdownHeight: PropTypes.func,
  locked: PropTypes.bool,
  comingSoon: PropTypes.bool,
};

export default DropdownList;
