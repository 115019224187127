import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import HomeContainer from '../../../components/Container/HomeContainer';
import WistiaPlayer from '../../../components/WistiaPlayer';
import sectionHeroOverlay1 from '../../../assets/home/section-hero-overlay-1.svg';
import sectionHeroOverlay2 from '../../../assets/home/section-hero-overlay-2.svg';
import oscerCharacter from '../../../assets/home/oscer-character.svg';
import untiringPatientsOverlay from '../../../assets/home/untiring-patients-overlay.svg';
import './style.scss';
import useStyles from './style';

const HeroSection = () => {
  const classes = useStyles();

  return (
    <HomeContainer outerStyles={classes.HeroSection} innerStyles={classes.HeroSection__contents}>
      <img className="section-hero-overlay-1" src={sectionHeroOverlay1} alt="" />
      <img className="section-hero-overlay-2" src={sectionHeroOverlay2} alt="" />

      <div className={classes.HeroSection__text}>
        <Typography variant="h1" className={classes.HeroSection__title}>
          The next step in clinical training
        </Typography>

        <Typography variant="h3" className={classes.HeroSection__subTitle}>
          Master diagnosis with our virtual patients
        </Typography>
        <Typography className={classes.HeroSection__description}>
          Learn faster with the freedom of practicing OSCEs whenever, wherever
        </Typography>
        <Button
          className={classes.HeroSection__signUpBtn}
          variant="contained"
          color="primary"
          component={Link}
          to="/signup"
        >
          Sign Up
        </Button>
        <div className={classes.HeroSection__signin}>
          or{' '}
          <Link to="/signin" className={classes.HeroSection__signin__link}>
            Sign In
          </Link>
        </div>
      </div>
      <div className={classes.HeroSection__video}>
        <WistiaPlayer videoId="jrmogk8sl9" />
        <img className={classes.HeroSection__oscerCharacter} src={oscerCharacter} alt="oscer" />
      </div>

      <img className="untiring-patients-overlay-1" src={untiringPatientsOverlay} alt="" />
    </HomeContainer>
  );
};

export default HeroSection;
