import Mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/react';
import store from '../store';

// Always allow home page tracking
// const whiteList = [
//   'https://www.oscer.ai/',
//   'https://www.oscer.ai/about',
//   'https://www.oscer.ai/plans',
//   'https://www.oscer.ai/institutions',
//   'https://www.oscer.ai/technology',
//   'https://www.oscer.ai/jobs',
// ];

const isTracked = (state) =>
  // if (whiteList.includes(window.location.href)) return true;

  !state.auth.user.is_staff &&
  typeof window !== 'undefined' &&
  process.env.REACT_APP_STAGE === 'production' &&
  !state.auth.user.email.includes('+test');
export const trackPage = (page) => {
  const state = store.getState();
  if (isTracked(state)) {
    if (window.gtag != null) {
      window.gtag('event', 'page_view', {
        page_path: page,
        send_to: 'UA-122458144-1',
      });
    }
    Mixpanel.track('Page View', {
      'page path': page,
      'Prime Status': state?.auth?.user?.prime_status,
      'CE Tour Completed': state?.auth?.user?.profile?.user_tour_flags?.condition_explorer_flag,
      'New Dashboard Tour Completed': state?.auth?.user?.profile?.user_tour_flags?.new_onboarding_flag,
    });
    if (window.userpilot) {
      window.userpilot.track('Page Event', {
        'page path': page,
        'Prime Status': state?.auth?.user?.prime_status,
        'CE Tour Completed': state?.auth?.user?.profile?.user_tour_flags?.condition_explorer_flag,
        'New Dashboard Tour Completed': state?.auth?.user?.profile?.user_tour_flags?.new_onboarding_flag,
      });
    }
  }
};

export const trackEvent = (payload) => {
  const state = store.getState();
  const userId = state?.auth?.user?.id || 'NA';

  // console.log(payload);
  if (isTracked(state)) {
    if (payload.GAAction && window.gtag != null) {
      window.gtag('event', payload.GAAction, {
        event_label: payload.label,
        event_category: payload.category,
        value: payload.value,
        user: userId,
        onboardingTour: state?.auth?.user?.profile?.user_tour_flags?.onboarding_flag,
      });
    }

    if (payload.FBAction && window.fbq != null) {
      window.fbq('track', payload.FBAction, {
        value: payload.value,
        currency: payload.currency,
        content_name: payload.label,
        content_category: payload.category,
        user: userId,
        onboardingTour: state?.auth?.user?.profile?.user_tour_flags?.onboarding_flag,
      });
    }
    Mixpanel.track(payload.category, {
      label: payload.label,
      price: payload.value,
      action: payload.FBAction,
      user: userId,
      onboardingTour: state?.auth?.user?.profile?.user_tour_flags?.onboarding_flag,
      ...payload.others,
    });
  }
};

export const ecommerceTrack = (payload) => {
  const state = store.getState();
  if (isTracked(state)) {
    if (window.gtag != null) {
      window.gtag('event', 'purchase', {
        transaction_id: payload.transactionId,
        affiliation: 'Oscer',
        value: payload.value,
        currency: 'USD',
        event_label: payload.label,
        items: [
          {
            id: payload.id,
            name: payload.displayName,
            category: payload.category,
            quantity: 1,
            price: payload.price,
            period: payload.period,
          },
        ],
      });

      window.gtag('event', 'conversion', { send_to: 'AW-428250314/ZqgmCMPlg_YBEMqpmswB' });
    }

    if (window.fbq != null && payload.isFBTrack) {
      window.fbq('track', 'Purchase', {
        transaction_id: payload.transactionId,
        value: payload.value,
        currency: 'USD',
        content_name: payload.displayName,
        content_type: payload.category,
        contents: [
          {
            id: payload.id,
            quantity: 1,
          },
        ],
      });
    }

    Mixpanel.people.track_charge(payload.value, {
      $time: payload.time,
      currency: 'USD',
      transactionId: payload.transactionId,
      plan: payload.displayName,
    });
  }
};

export const trackUserId = (user) => {
  if (!user.is_staff && process.env.REACT_APP_STAGE === 'production') {
    if (window.gtag != null) {
      window.gtag('set', { user_id: user.id, send_to: 'UA-122458144-1' });
    }
    Mixpanel.identify(user.id);
    Mixpanel.people.set({
      $email: user.email, // only reserved properties need the $
      username: user.username,
      'prime status': user.prime_status,
      'day streak': user.day_streak,
    });
    Sentry.setUser({ id: user.id, username: user.username });
  }
};

// export const unTrackUser = (user) => {
//   if (!user.is_staff && process.env.REACT_APP_STAGE === 'production') {
//     Sentry.configureScope((scope) => scope.setUser(null));
//   }
// };
