import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  GeneralSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  GeneralSwitch__text: {
    color: `${theme.palette.text.main}33`,
    fontSize: 12,
    fontWeight: 500,
  },
  GeneralSwitch__text__active: {
    color: `${theme.palette.primary.main}CC`,
  },
  GeneralSwitch__switch__root: {},
}));

export default useStyles;
