import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Modal } from '../../../components/common';
import PresentationImg from '../../../components/icons/PresentationImg';
import ConfirmButton from '../../../components/buttons/ConfirmButton';
import { PRESENTATION_ICON_XLARGE, SYSTEM_COLORS } from '../../../constants';
import '../case.scss';

/**
 * @desc Hint modal
 * @param {*} props
 * - currentCase an object of current case details
 * - isToggle a boolean which represents the modal is popup or not
 * - toggle a function to control modal
 */
const HintModal = ({ isToggle, toggle, hint, system }) => (
  <div className="hint-modal">
    <Modal isToggle={isToggle} toggle={toggle} centered closable>
      {hint ? (
        <div className={classnames('case__modal')}>
          <div className={classnames('case__modal__large')} style={{ borderBottomColor: SYSTEM_COLORS[system] }}>
            <div className="case__modal">
              <PresentationImg
                className="case__modal__img"
                presentation={`Hint_${hint.hintsCount + 1}`}
                size={PRESENTATION_ICON_XLARGE}
              />

              <div className="case__modal__title">
                <div className={classnames('title', 'case__modal__title-text', 'case__modal__title-text-hint')}>
                  <h5>
                    hint {hint.hintsCount + 1} of {hint.hintsLength}
                  </h5>
                </div>
              </div>
              <div className={classnames('note', 'case__modal__subtitle', 'case__modal__subtitle-hint')}>
                <p>{hint.text}</p>
              </div>

              <div className={classnames('buttons', 'case__modal__btns')}>
                <ConfirmButton className={classnames('btn-round', 'btn-hint')} onClick={toggle}>
                  Continue
                </ConfirmButton>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Modal>
  </div>
);

HintModal.propTypes = {
  isToggle: PropTypes.bool,
  toggle: PropTypes.func,
  hint: PropTypes.object,
  system: PropTypes.string,
};

export default HintModal;
