import { makeStyles } from '@material-ui/core/styles';
import HomeHeroBG from '../../assets/home/section-hero.svg';

const useStyles = makeStyles((theme) => ({
  HomePlans: {
    width: '100%',
    marginTop: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  HomePlans__title: {
    marginBottom: 30,
  },
  HomePlans__tag: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.6,
  },
  HomePlans__joinOscer__outer: {
    background: `url(${HomeHeroBG})`,
    overflow: 'hidden',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  HomePlans__joinOscer__subtitle: {
    position: 'relative',
    marginBottom: 100,
    textAlign: 'center',
    maxWidth: 950,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: 10,
    },
  },
  HomePlans__joinOscer__img: {
    width: 154,
    position: 'absolute',
    top: -100,
    left: -150,
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HomePlans__imageShowcase: {
    marginBottom: 120,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 60,
    },
  },
  HomePlans__container__outer: {
    overflow: 'hidden',
  },
  HomePlans__container__inner: {
    paddingBottom: 0,
  },
  HomePlans__plans: {
    display: 'flex',
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  HomePlans__oscerCrownImage: {
    position: 'absolute',
    zIndex: 1,
    top: '-19%',
    right: '25%',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HomePlans__seperator: {
    display: 'flex',
    alignSelf: 'normal',
    alignItems: 'center',
    marginBottom: 15,
  },
  HomePlans__seperator__line: {
    background: theme.palette.text.light,
    height: 2,
    flex: 1,
  },
  HomePlans__seperator__text: {
    fontSize: 22,
    lineHeight: 1.64,
    color: theme.palette.text.light,
    padding: '0 18px',
  },
  HomePlans__tryFreeContainer: {
    paddingBottom: 80,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingBottom: 40,
    },
  },
  HomePlans__benefitsContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      paddingBottom: 40,
      paddingTop: 0,
    },
  },
  HomePlans__tryFreeText: {
    fontSize: 22,
    lineHeight: 1.64,
    color: theme.palette.text.light,
    marginBottom: 15,
  },
  HomePlans__signUpBtn: {
    fontWeight: 500,
  },
  HomePlans__benefits: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px 30px -10px',
  },
  HomePlans__benefits__item: {
    display: 'flex',
    width: '31%',
    alignItems: 'center',
    margin: '0 10px 30px 10px',
    color: theme.palette.text.main,
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      width: '47%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0px 0px 15px 0px',
      fontSize: 16,
    },
  },
  HomePlans__benefits__icon: {
    width: 50,
    marginRight: 12,
    [theme.breakpoints.down('xs')]: {
      width: 46,
    },
  },
  HomePlans__benefits__comming: {
    opacity: 0.6,
  },
  HomePlans__showCaseWrapper: {
    background: theme.palette.inherit.main,
    position: 'relative',
  },
  HomePlans__overlay__1: {
    top: '54em',
    left: '-12em',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  HomePlans__overlay__2: {
    transform: 'scaleX(-1)',
    position: 'absolute',
    top: '31em',
    right: '-5em',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  HomePlans__bannerContainer__outer: {
    overflow: 'visible',
  },
  HomePlans__bannerContainer__inner: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  HomePlans__banner: {
    display: 'flex',
    maxWidth: '61%',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  HomePlans__banner__title: {
    fontSize: 30,
    color: theme.palette.text.main,
    lineHeight: 1.5,
    marginRight: 90,
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      fontSize: 17,
      marginBottom: 10,
    },
  },
  HomePlans__banner__btn: {
    background: '#f6c89f',
    color: '#ffffff',
    maxHeight: 60,
    padding: '20px 40px',
    minWidth: 153,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  HomePlans__overlay__3: {
    position: 'absolute',
    left: 0,
    width: 208,
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
