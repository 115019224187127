import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, Link, withRouter, useHistory } from 'react-router-dom';
import { Container, Avatar, MenuItem, MenuList, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { setShowLogoutModal } from '../../../../actions/auth';
import imgOscer from '../../../../assets/logos/oscer-icon-solid.svg';
import iconCrownGold from '../../../../assets/icons/prime-crown-icon-gold.svg';
import userNameGenerator from '../../../../helpers/userNameGenerator';
import MenuPopover from '../../../MenuPopover';
import iconStreak from '../../../../assets/icons/streak-stat-icon.svg';
import iconStreakBlank from '../../../../assets/icons/streak-base-stat-icon.svg';
import iconSpecialty from '../../../../assets/icons/specialty-stat-icon.svg';
import iconRanking from '../../../../assets/icons/ranking-stats-icon.svg';
import { SYSTEM_COLORS, READABLE_SYSTEM_NAMES, ALL_SYSTEMS2 } from '../../../../constants';
import System from '../../../icons/System';
import iconCases from '../../../../assets/icons/cases-tab-icon.svg';
import iconHome from '../../../../assets/icons/home-tab-icon.svg';
import iconScripts from '../../../../assets/icons/scripts-tab-icon.svg';
import iconConExp from '../../../../assets/icons/condition-explorer-tab-icon.svg';
import iconSkillsHub from '../../../../assets/icons/skills-hub-icon.svg';
import ordinalSuffix from '../../../../helpers/ordinalSuffix';
import SearchIcon from '@material-ui/icons/Search';
import Logout from '../../Logout';
import { fetchCaseSystemStats } from '../../../../actions/cases';
import useStyles from './style';
import { setUpgradeExplorerModalToggle } from '../../../../actions/dashboard';

const DashboardHeaderNav = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const { user, percentile = 80, systemStats, cases, dashboardOnboarding, casesCompleted } = props;
  const [sortedSpecialties, setSortedSpecialties] = useState([]);

  useEffect(() => {
    let array = [];
    let currentSystems = [];
    for (let system of systemStats) {
      currentSystems.push(system.system);
      array.push([system.system, system.mastery]);
    }
    for (let system of ALL_SYSTEMS2) {
      if (!currentSystems.includes(system)) {
        array.push([system, '0']);
      }
    }
    const sorted = sortSpecialties(JSON.parse(JSON.stringify(array)));
    setSortedSpecialties([...sorted]);
  }, [systemStats]);

  const sortSpecialties = (arr) => arr.sort((a, b) => b[1] - a[1]);

  useEffect(() => {
    if (!systemStats || systemStats.length === 0) {
      props.fetchCaseSystemStats();
    }
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const [menuElement, setMenuElement] = useState(null);

  const navigations = [
    {
      image: iconHome,
      name: 'HOME',
      link: '/dashboard/home',
      dataTut: 'reactour__tab__home',
    },
    // {
    //   image: iconCases,
    //   name: 'CASES',
    //   link: '/dashboard/cases,
    //   dataTut: 'reactour__tab__cases',
    // },
    {
      image: iconSkillsHub,
      name: 'LEARN',
      link: '/dashboard/learn',
    },
    {
      image: iconScripts,
      name: 'SCRIPTS',
      link: '/dashboard/scripts',
      dataTut: 'reactour__tab__scripts',
    },
    {
      image: iconConExp,
      name: 'CONDITION EXPLORER',
      link: '/dashboard/explorer',
      beta: true,
      dataTut: 'reactour__tab__explorer',
    },
  ];

  const profileMenu = [
    {
      title: 'Your Profile',
      subtitle: `Achievements, stats & more`,
      link: '/profile',
      dataTut: 'reactour__tab__profile',
    },
    // {
    //   title: 'Skills Hub',
    //   subtitle: `Improve your history`,
    //   link: '/dashboard/learn',
    //   dataTut: 'reactour__tab__Learn',
    // },
    {
      title: 'Case Results',
      subtitle: `Your completed case results`,
      link: '/case-history',
    },
    {
      title: 'Roadmap',
      subtitle: `Discover new features`,
      link: 'https://oscer.notion.site/beb891e0d6594789a815f9f787e9a614?v=713bf90ee6a44b198cd6e09c075851f1',
    },
    {
      title: 'Logout',
      link: null,
      subtitle: `Bailing on us?`,
      function: () => props.setShowLogoutModal(true),
    },
  ];

  const handleProfile = (link, func) => {
    // if this is a http link then open new tab, othervise redirect
    if (link) {
      if (link.includes('http')) {
        window.open(link, '_blank').focus();
      } else {
        history.push(link);
      }
    } else if (func) {
      func();
    }
  };

  const handleMouseOver = (e) => {
    setMenuElement(e.target);
  };

  return (
    <div className={classes.DashboardHeaderNav}>
      <Logout />
      <Container
        maxWidth="lg"
        disableGutters
        data-tut="reacttour__tab__nav"
        classes={{
          root: classes.DashboardHeaderNav__Container__root,
        }}
      >
        <div className={classes.DashboardHeaderNav__main}>
          <img
            src={imgOscer}
            alt="oscer_logo"
            className={classes.DashboardHeaderNav__OscerLogo}
            onClick={() => history.push('/dashboard/home')}
          />

          {navigations.map((nav) => (
            <NavLink
              to={!nav.beta ? nav.link : '/dashboard/explorer'}
              onClick={() => nav.beta && !props.isPrime && props.setUpgradeExplorerModalToggle(true)}
              key={nav.name}
              data-tut={nav.dataTut}
              className={classes.DashboardHeaderNav__nav}
              activeClassName={classes.DashboardHeaderNav__nav__active}
            >
              {/* <div data-tut = {nav.dataTut}> */}
              <img src={nav.image} alt={`${nav.name}__image`} className={classes.DashboardHeaderNav__nav__logo} />
              <div className={classes.DashboardHeaderNav__nav__text}>{nav.name}</div>
              <div className={classes.DashboardHeaderNav__nav__text__beta}>
                {' '}
                {nav.beta ? <span>(BETA)</span> : <span></span>}{' '}
              </div>
              {/* </div> */}
            </NavLink>
          ))}
          <div />
        </div>

        <div
          className={cx(classes.DashboardHeaderNav__user, { [classes.DashboardHeaderNav__user__free]: !props.isPrime })}
        >
          <NavLink
            to="/search"
            key="search"
            data-tut="reactour__tab__search"
            className={classes.DashboardHeaderNav__nav}
            activeClassName={classes.DashboardHeaderNav__nav__active}
          >
            <SearchIcon
              classes={{
                root: cx(classes.DashboardHeaderNav__SearchIcon__root),
              }}
            />
          </NavLink>
          {!props.isPrime && (
            <MenuPopover
              mouseOverElement={menuElement}
              menuIcon={
                <div className={classes.DashboardHeaderNav__free__icon__wrapper}>
                  <img src={iconCrownGold} alt="crown" className={classes.DashboardHeaderNav__free__icon} />
                </div>
              }
              handleMouseOver={handleMouseOver}
            >
              <div className={classes.DashboardHeaderNav__Menu}>
                <img src={iconCrownGold} alt="crown" className={classes.DashboardHeaderNav__free__img} />
                <div className={classes.DashboardHeaderNav__Menu__main}>
                  <div className={classes.DashboardHeaderNav__Menu__title}>Prime Upgrade</div>
                  <div className={classes.DashboardHeaderNav__Menu__subtitle}>
                    Get full access to all cases and all modes with Prime.
                  </div>
                  <Link to="/compare" className={classes.DashboardHeaderNav__free__link}>
                    UPGRADE
                  </Link>
                </div>
              </div>
            </MenuPopover>
          )}
          <MenuPopover
            mouseOverElement={menuElement}
            menuIcon={<img src={iconStreak} alt="oscer_logo" className="" />}
            handleMouseOver={handleMouseOver}
          >
            <div className={classes.DashboardHeaderNav__Menu}>
              <div className={classes.DashboardHeaderNav__Menu__streak}>
                <img
                  src={iconStreakBlank}
                  alt="sretak_icon"
                  className={classes.DashboardHeaderNav__Menu__streak__icon}
                />
                <span
                  className={cx(classes.DashboardHeaderNav__Menu__streak__num, {
                    [classes.DashboardHeaderNav__Menu__streak__num__two]: props.user.day_streak.toString().length === 2,
                  })}
                >
                  {props.user.day_streak}
                </span>
                <div className={classes.DashboardHeaderNav__Menu__streak__text}>DAYS</div>
              </div>
              <div className={classes.DashboardHeaderNav__Menu__main}>
                <div>
                  <div className={classes.DashboardHeaderNav__Menu__title}>Your Streak</div>
                  <div className={classes.DashboardHeaderNav__Menu__subtitle}>
                    Let’s see how many days you can complete an Oscer case.
                  </div>
                </div>
                <Link
                  onClick={() => {
                    setMenuElement('');
                  }}
                  to={'/profile'}
                  className={classes.DashboardHeaderNav__free__link}
                >
                  VIEW
                </Link>
              </div>
            </div>
          </MenuPopover>
          {props.isPrime && (
            <MenuPopover
              mouseOverElement={menuElement}
              menuIcon={<img src={iconSpecialty} alt="oscer_logo" className="" />}
              handleMouseOver={handleMouseOver}
            >
              <div className={classes.DashboardHeaderNav__Menu}>
                <div className={classes.DashboardHeaderNav__Menu__streak}>
                  {sortedSpecialties.length > 0 && casesCompleted >= 4 ? (
                    <>
                      <System
                        system={sortedSpecialties[0][0]}
                        size={50}
                        className={classes.DashboardHeaderNav__Menu__streak__icon}
                      />
                      <div
                        className={classes.DashboardHeaderNav__Menu__streak__text}
                        style={{ color: SYSTEM_COLORS[sortedSpecialties[0][0]] }}
                      >
                        {READABLE_SYSTEM_NAMES[sortedSpecialties[0][0]]}
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={iconSpecialty}
                        alt="iconSpecialty"
                        className={classes.DashboardHeaderNav__Menu__streak__icon}
                      />
                      <div className={classes.DashboardHeaderNav__Menu__streak__text}></div>
                    </>
                  )}
                </div>
                <div className={classes.DashboardHeaderNav__Menu__main}>
                  <div>
                    <div className={classes.DashboardHeaderNav__Menu__title}>Your Specialty</div>
                    <div className={classes.DashboardHeaderNav__Menu__subtitle}>
                      {sortedSpecialties.length > 0 && casesCompleted >= 4
                        ? 'Your best system, where you’ve covered the most cases + scored the best!'
                        : 'You need to complete 4 cases to determine your best Oscer system.'}
                    </div>
                  </div>
                  {casesCompleted < 4 ? (
                    <span
                      className={classes.DashboardHeaderNav__free__link}
                      style={{ color: casesCompleted < 4 && '#00234880' }}
                    >
                      VIEW
                    </span>
                  ) : (
                    <Link
                      onClick={() => {
                        setMenuElement('');
                      }}
                      to={'/profile'}
                      className={classes.DashboardHeaderNav__free__link}
                    >
                      VIEW
                    </Link>
                  )}
                </div>
              </div>
            </MenuPopover>
          )}
          {props.isPrime && (
            <MenuPopover
              // open={dashboardOnboarding}
              mouseOverElement={menuElement}
              menuIcon={<img src={iconRanking} alt="oscer_logo" className="" />}
              handleMouseOver={handleMouseOver}
              placement={width < 1290 ? 'bottom-end' : 'bottom'}
            >
              <div className={classes.DashboardHeaderNav__Menu}>
                <div className={classes.DashboardHeaderNav__Menu__streak}>
                  {percentile < 10 || props.casesCompleted < 4 ? (
                    <img src={iconRanking} alt="oscer_logo" className={classes.DashboardHeaderNav__iconRanking} />
                  ) : (
                    <div className={classes.DashboardHeaderNav__Menu__percentile}>
                      <div className={classes.DashboardHeaderNav__Menu__percentile__num}>
                        {user.statisticprofile
                          ? Math.round(user.statisticprofile?.oscer_percentile_history)
                          : percentile}
                      </div>
                      <div className={classes.DashboardHeaderNav__Menu__percentile__h}>
                        {user.statisticprofile
                          ? ordinalSuffix(Math.round(user.statisticprofile?.oscer_percentile_history))
                          : ordinalSuffix(percentile)}
                      </div>
                    </div>
                  )}
                  <div className={classes.DashboardHeaderNav__Menu__streak__text}>Percentile</div>
                </div>
                <div className={classes.DashboardHeaderNav__Menu__main}>
                  <div>
                    <div className={classes.DashboardHeaderNav__Menu__title}>Your Percentile</div>
                    <div className={classes.DashboardHeaderNav__Menu__subtitle}>
                      {props.casesCompleted < 4
                        ? 'You need to complete 4 cases to determine your percentile.'
                        : `You are in the top ${
                            user.statisticprofile
                              ? 100 - Math.round(user.statisticprofile?.oscer_percentile_history)
                              : percentile
                          }% of all Oscer users!`}
                    </div>
                  </div>
                  {casesCompleted < 4 ? (
                    <span
                      className={classes.DashboardHeaderNav__free__link}
                      style={{ color: casesCompleted < 4 && '#00234880' }}
                    >
                      VIEW
                    </span>
                  ) : (
                    <Link
                      onClick={() => {
                        setMenuElement('');
                      }}
                      to={props.isPrime ? '/profile' : '/compare'}
                      className={classes.DashboardHeaderNav__free__link}
                    >
                      VIEW
                    </Link>
                  )}
                </div>
              </div>
            </MenuPopover>
          )}
          <MenuPopover
            keepOpen={props.profileOpened}
            mouseOverElement={menuElement}
            menuIcon={
              <Avatar className={classes.DashboardHeaderNav__avatar}>
                {userNameGenerator(user)
                  .split(' ')
                  .map((nameCap) => (
                    <span key={nameCap} className={classes.DashboardHeaderNav__avatar__name}>
                      {nameCap.charAt(0).toUpperCase()}
                    </span>
                  ))}
                {props.isPrime && (
                  <img src={iconCrownGold} alt="crown" className={classes.DashboardHeaderNav__avatar__crown} />
                )}
              </Avatar>
            }
            handleMouseOver={handleMouseOver}
            styles={{
              arrow: classes.DashboardHeaderNav__profile__arrow,
              wrapper: classes.DashboardHeaderNav__profile__menu,
            }}
            placement="bottom-end"
          >
            <div className={classes.DashboardHeaderNav__profile__wrapper}>
              <MenuList id="menu-list-grow">
                {profileMenu.map((menu) => (
                  <MenuItem
                    key={menu.title}
                    data-tut={menu.dataTut}
                    classes={{ root: classes.HomeHeader__profile__MenuItem }}
                    onClick={() => {
                      setMenuElement('');
                      handleProfile(menu.link, menu.function);
                    }}
                  >
                    <div
                      className={cx(
                        { [classes.HomeHeader__btn__active]: menu.link === 0 },
                        classes.HomeHeader__profile__title
                      )}
                    >
                      {menu.title}
                      {menu.number && <div className={classes.HomeHeader__profile__num}>{menu.number}</div>}
                    </div>
                    <div className={classes.HomeHeader__profile__subtitle}>{menu.subtitle}</div>
                  </MenuItem>
                ))}
              </MenuList>
            </div>
          </MenuPopover>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPrime: state.auth.user.prime_status,
  user: state.auth.user,
  cases: state.cases.casesCollection,
  systemStats: state.auth.user.systems,
  casesCompleted: state.auth.user.profile?.num_attempted_cases,
  profileOpened: state.dashboard.profilePopoverOpen,
});

DashboardHeaderNav.propTypes = {
  user: PropTypes.object,
  systemStats: PropTypes.array,
  setShowLogoutModal: PropTypes.func,
  isPrime: PropTypes.bool,
  fetchCaseSystemStats: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, { setUpgradeExplorerModalToggle, setShowLogoutModal, fetchCaseSystemStats })(
    DashboardHeaderNav
  )
);
