import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  SignIn: {
    position: 'relative',
    width: 510,
    backgroundColor: 'var(--neutral-color-2)',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 var(--black-3-color)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '45px 60px',
    borderBottom: '19px solid var(--primary-color-2)',
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      height: 'auto',
      minHeight: '100vh',
      borderRadius: 0,
      padding: '30px 45px',
    },
  },
  SignIn__wrapper: {
    width: '100%',
    // height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  SignIn__icon__signIn: {
    position: 'absolute',
    // zIndex: 100,
    top: -69,
    right: -215,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  SignIn__title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.palette.text.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  SignIn__icon__logo: {
    marginBottom: 40,
    maxWidth: 150,
  },
  SignIn__social__login: {
    marginTop: 25,
  },
  SignIn__form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginTop: 25,
  },
  SignIn__textField__root: {
    '& .MuiInput-underline:before': {
      borderBottomColor: 'var(--secondary-color-2-o1)',
    },
    '& .MuiInputBase-root': {
      padding: '0 0 5px 0',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 34,
    },
  },
  SignIn__textField: {
    flex: 1,
    width: '100%',
    marginBottom: 25,
  },
  SignIn__texfField__error__offset: {
    marginBottom: 3,
  },
  SignIn__textField__icon: {
    maxWidth: 25,
    maxHeight: 25,
  },
  SignIn__textField__input: {
    marginLeft: 10,
  },
  SignIn__checkbox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      marginTop: -10,
    },
  },
  SignIn__FormControlLabel__root: {
    margin: 0,
    marginRight: 5,
    marginLeft: -11,
    color: theme.palette.text.main,
    '& .MuiIconButton-label': {
      color: theme.palette.primary.light,
      borderRadius: 5,
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
  SignIn__buttom__root: {
    width: '100%',
  },
  SignIn__button__contained: {
    padding: '13px 45px',
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  SignIn__button__label: {
    fontWeight: 'bold',
  },
  SignIn__button__disabled: {
    opacity: 0.2,
  },
  SignIn__forgot__pwd: {
    fontSize: 14,
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      textShadow: '0px 0px 0.2px #83d0f0',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  SignIn__have__account: {
    fontSize: 14,
    color: theme.palette.text.main,
    marginTop: 25,
  },
  SignIn__error__show: {
    marginTop: 4,
  },
  SignIn__error: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 5,
  },
  SignIn__signup: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
