import produce from 'immer';
import { DIAGNOSIS_LIST_SET, DIAGNOSIS_SESSION_SET, DIAGNOSIS_START_SET } from 'actions/types';

const initialState = {
  diagnosisList: [],
  session: {},
  start: false,
};

const diagnosisReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case DIAGNOSIS_LIST_SET:
        draft.diagnosisList = action.diagnosisList;
        break;
      case DIAGNOSIS_SESSION_SET:
        draft.session = action.session;
        break;
      case DIAGNOSIS_START_SET:
        draft.start = action.payload;
        break;
      default:
        break;
    }
  });

export default diagnosisReducer;
