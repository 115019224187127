import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  TeamCard: {
    boxShadow: '0 2px 4px 0 rgba(0, 35, 72, 0.3)',
    width: 210,
    height: 210,
    borderRadius: 16,
    backgroundColor: 'white',
    overflow: 'hidden',
    margin: '15px 5px',
    [theme.breakpoints.down('xs')]: {
      width: 160,
      height: 160,
      margin: '10px 5px',
    },
  },
  TeamCard__to: {
    cursor: 'pointer',
  },
  TeamCard__wrapper: {
    borderBottom: `8px solid ${theme.palette.primary.light}`,
    padding: '10px 10px 1px 10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 1px 5px',
      borderBottom: `4px solid ${theme.palette.primary.light}`,
    },
  },
  TeamCard__img: {
    width: 95,
    height: 95,
    [theme.breakpoints.down('xs')]: {
      width: 85,
      height: 85,
    },
  },
  TeamCard__title: {
    fontSize: 15,
    color: theme.palette.primary.light,
    fontWeight: 500,
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    textAlign: 'center',
  },
  TeamCard__name: {
    fontWeight: 500,
    color: theme.palette.text.main,
    marginBottom: 5,
  },
  TeamCard__star: {
    opacity: 0.6,
    fontWeight: 500,
    fontSize: 9,
  },
}));

export default useStyles;
