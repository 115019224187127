import React from 'react';
import PropTypes from 'prop-types';

function IconMicrophone(props) {
  const { color = 'black', size = 20 } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} viewBox="0 0 58 58">
      <g>
        <path
          d="M44,28c-0.552,0-1,0.447-1,1v6c0,7.72-6.28,14-14,14s-14-6.28-14-14v-6c0-0.553-0.448-1-1-1s-1,0.447-1,1v6
        c0,8.485,6.644,15.429,15,15.949V56h-5c-0.552,0-1,0.447-1,1s0.448,1,1,1h12c0.552,0,1-0.447,1-1s-0.448-1-1-1h-5v-5.051
        c8.356-0.52,15-7.465,15-15.949v-6C45,28.447,44.552,28,44,28z"
          fillRule="nonzero"
        />
        <path
          d="M29,46c6.065,0,11-4.935,11-11V11c0-6.065-4.935-11-11-11S18,4.935,18,11v24C18,41.065,22.935,46,29,46z M20,11
        c0-4.963,4.038-9,9-9s9,4.037,9,9v24c0,4.963-4.038,9-9,9s-9-4.037-9-9V11z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

IconMicrophone.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconMicrophone;
