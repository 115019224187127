import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  TechnologyStatistics__container__inner: {
    paddingBottom: 120,
    paddingTop: 120,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
      paddingTop: 60,
    },
  },
  TechnologyStatistics__title: {
    textAlign: 'center',
    marginBottom: 60,
  },
  TechnologyStatistics__img: {
    maxWidth: '80%',
  },
  TechnologyStatistics__statistic: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  TechnologyStatistics__statisticCard: {
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  TechnologyStatistics__statisticCard__statistic: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: 30,
    lineHeight: 1.5,
    color: theme.palette.text.main,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
      fontWeight: 'normal',
    },
  },
  TechnologyStatistics__statisticCard__name: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      fontWeight: 'normal',
    },
  },
}));

export default useStyles;
