import React from 'react';
import PropTypes from 'prop-types';

import {
  OUTGOING_MESSAGE,
  SEPERATOR_MESSAGE,
  OSCE_HISTORY_SESSION,
  PARTIALLY_COMPLETE_MESSAGE,
} from 'helpers/constants';
import MessageOut from './MessageOut';
import MessageIn from './MessageIn';
import SeperatorMessage from './SeperatorMessage';
import PartiallyCompleteMessage from './PartiallyCompleteMessage';

import './style.scss';

/**
 * @desc Chatting panel which include MessageIn (bot response) and MessageOut (user input)
 */
const MessageList = ({ messages, currentCase, user, onMessageSent, type = OSCE_HISTORY_SESSION, handleNextModule }) => {
  const MessageSelector = (message, idx) => {
    switch (message.type) {
      case OUTGOING_MESSAGE:
        return (
          <MessageOut
            key={`message-${idx}-${message.text?.guide_msg || message.text}`}
            message={message}
            user={user}
            msgIndex={idx}
            onMessageSent={onMessageSent}
          />
        );
      case SEPERATOR_MESSAGE:
        return <SeperatorMessage key={`message-${idx}-${message.date}`} message={message} user={user} msgIndex={idx} />;
      case PARTIALLY_COMPLETE_MESSAGE:
        return (
          <PartiallyCompleteMessage
            key={`message-${idx}-${message.date}`}
            message={message}
            user={user}
            msgIndex={idx}
            handleNextModule={() => handleNextModule(message?.text?.current_category_index)}
          />
        );

      default:
        return (
          <MessageIn
            key={`message-${idx}-${message.date}`}
            message={message}
            messages={messages}
            currentCase={currentCase}
            type={type}
            msgIndex={idx}
          />
        );
    }
  };

  return (
    <div className="message-list" id="messageListId">
      {messages.map((message, idx) => MessageSelector(message, idx))}
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.array.isRequired,
  currentCase: PropTypes.object,
  user: PropTypes.object,
  type: PropTypes.string,
  handleNextModule: PropTypes.func,
  onMessageSent: PropTypes.func,
};

export default MessageList;
