import { TASK_SET, TASK_MODAL_SET, EASTER_EGG_UPDATED, GENERAL_TASK_UPDATED, ALL_TASK_COMPLETED_SET } from './types';
import { APIClient } from '../settings';
import { tokenConfig, loadUser } from './auth';

export const taskSet = (tasks) => ({
  type: TASK_SET,
  tasks,
});

export const taskModalSet = (value) => ({
  type: TASK_MODAL_SET,
  value,
});

export const easterEggUpdated = (value, index) => ({
  type: EASTER_EGG_UPDATED,
  value,
  index,
});

export const generalTaskUpdated = (value, index) => ({
  type: GENERAL_TASK_UPDATED,
  value,
  index,
});

export const taskCompletedSet = (index) => ({
  type: ALL_TASK_COMPLETED_SET,
  index,
});

// id: task id, index: index in all tasks array
export const patchTaskComplete = (id, index) => (dispatch, getState) =>
  new Promise((resolve, reject) =>
    APIClient.patch(`api/v1/tasks/${id}/complete/`, tokenConfig(getState))
      .then((res) => {
        dispatch(taskCompletedSet(index));
        resolve(res.data);
      })
      .catch((err) => reject(err))
  );

export const getTasks = () => (dispatch, getState) =>
  new Promise((resolve, reject) =>
    APIClient.get('api/v1/tasks/list/', tokenConfig(getState))
      .then((res) => {
        dispatch(taskSet(res.data));
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  );

export const patchRewardClaim = (id, type, taskIndex) => (dispatch, getState) =>
  new Promise((resolve, reject) =>
    APIClient.patch(`api/v1/tasks/${id}/claim-reward/`, tokenConfig(getState))
      .then((res) => {
        if (type === 'DailyTask' || type === 'CaseTask' || type === 'GeneralTask') {
          // Easter egg
          // Set task is_reward_claimed to true
          // dispatch(easterEggUpdated(true, taskIndex));
        } else {
          dispatch(taskModalSet({ ...res.data, isToggle: true }));
          // dispatch(loadUser());
        }
        dispatch(loadUser());

        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      })
  );
