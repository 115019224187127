import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';

import { fetchPlans } from '../../actions/checkout';
import { trackPage } from '../../service/analytics';
import useGeoLocation from '../../hook/useGeoLocation';
import HomeContainer from '../../components/Container/HomeContainer';
import ImageShowcase from '../../components/Showcase/ImageShowcase';
import imgOverflow1 from '../../assets/home/shape-artificial-2.svg';
import PartyPopper from '../../assets/home/hp-plans-party-popper.svg';
import imgOverflow2 from '../../assets/home/section-hero-overlay-1.svg';
import imgOverflow3 from '../../assets/home/shape-7.svg';
import imgFeature1 from '../../assets/home/hp-plans-context-snippet-img.svg';
import imgFeature2 from '../../assets/home/hp-plans-practice-w-friends-img.svg';
import imgFeature3 from '../../assets/home/hp-plans-guided-histories-img.svg';
import imgFeature4 from '../../assets/home/hp-plans-condition-explorer-img.svg';
import Plan1 from '../../assets/home/hp-plans-plan1.svg';
import Plan2 from '../../assets/home/hp-plans-plan2.svg';
import Plan3 from '../../assets/home/hp-plans-plan3.svg';
import PlanCard from '../../components/Card/PlanCard';
import GuidedHistories from '../../assets/icons/guided-histories-icon.svg';
import AIChat from '../../assets/icons/ai-chat-icon.svg';
import CompairIcon from '../../assets/icons/compare-icon.svg';
import CompairePerformance from '../../assets/icons/compare-performance-icon.svg';
import ConditionsIcon from '../../assets/icons/conditions-icon.svg';
import DetailedRubrics from '../../assets/icons/detailed-rubrics-icon.svg';
import ExaminationChecklists from '../../assets/icons/examination-checklists-icon.svg';
import FocusConditions from '../../assets/icons/focus-conditions-icon.svg';
import IntergrateIcon from '../../assets/icons/integrate-icon.svg';
import LearnClinical from '../../assets/icons/learn-clinical-icon.svg';
import LongCases from '../../assets/icons/long-cases-icon.svg';
import MasterIcon from '../../assets/icons/master-icon.svg';
import MedicinCases from '../../assets/icons/medicine-cases-icon.svg';
import OrganiseSystem from '../../assets/icons/organise-system-icon.svg';
import PracticeQuiz from '../../assets/icons/practice-quiz-icon.svg';
import PWFICON from '../../assets/icons/practice-w-friends-icon.svg';
import TrackImprovement from '../../assets/icons/track-improvement-icon.svg';
import UploadNotes from '../../assets/icons/upload-notes-icon.svg';

import useStyles from './style';

const Plans = (props) => {
  const classes = useStyles();
  const { country, _error, isLoading } = useGeoLocation('au');

  useEffect(() => {
    if (!isLoading) {
      props.fetchPlans(country);
    }
  }, [isLoading]);

  useEffect(() => {
    trackPage('/plans');
  }, []);

  const features = [
    {
      title: 'Chat with patients powered by AI',
      subtitle:
        'Our patients are just like a real people (they even have a memory), so you can ask your questions naturally, and our patients will get you!',
      image: imgFeature1,
      isReverse: false,
    },
    {
      title: 'Practice with friends',
      subtitle: `With Oscer there’s hundreds of cases for 2, 3 or more players. So, get your friends together, choose which player you want to be and get diagnosing!`,
      image: imgFeature2,
      isReverse: true,
    },
    {
      title: 'Study with condition explorer',
      subtitle:
        'Oscer’s condition explorer allows you to generate an unlimited number of quizzes tailored to more than 300 conditions.',
      image: imgFeature4,
      isReverse: false,
    },
    {
      title: 'Guided histories and presentation explorer',
      subtitle:
        'Oscer will take you through step-by-step to help you learn how to take a history; for both general and specific patient presentations - coming soon.',
      image: imgFeature3,
      isReverse: true,
    },
  ];

  const osceStudyIcons = [
    {
      name: '100s of adult medicine cases',
      icon: MedicinCases,
    },
    {
      name: 'AI powered single player chats',
      icon: AIChat,
    },
    {
      name: 'Practice cases with friends',
      icon: PWFICON,
    },
    {
      name: 'Detailed rubrics for every case',
      icon: DetailedRubrics,
    },
    {
      name: 'Track your improvement',
      icon: TrackImprovement,
    },
    {
      name: 'Compare your performance to peers',
      icon: CompairePerformance,
    },
    {
      name: 'Guided histories (coming soon)',
      icon: GuidedHistories,
      isComing: true,
    },
    {
      name: 'Examination checklists (coming soon)',
      icon: ExaminationChecklists,
      isComing: true,
    },
    {
      name: 'Long cases (coming soon)',
      icon: LongCases,
      isComing: true,
    },
  ];

  const conditionStudyIcons = [
    {
      name: '300+ conditions and more coming',
      icon: ConditionsIcon,
    },
    {
      name: 'Master every condition',
      icon: MasterIcon,
    },
    {
      name: 'Focus with condition levels',
      icon: FocusConditions,
    },

    {
      name: 'Learn the clinical features',
      icon: LearnClinical,
    },
    {
      name: 'Unlimited practice quizzes',
      icon: PracticeQuiz,
    },
    {
      name: 'Organise your study by system',
      icon: OrganiseSystem,
    },
    {
      name: 'Upload your notes (coming soon)',
      icon: UploadNotes,
      isComing: true,
    },
    {
      name: 'Integrate with Anki & others (coming soon)',
      icon: IntergrateIcon,
      isComing: true,
    },
    {
      name: 'Compare condition features (coming soon)',
      icon: CompairIcon,
      isComing: true,
    },
  ];
  // plan period
  const visiblePlans = [31, 180, 365];
  const filteredPlan = props.plans.filter((plan) => visiblePlans.includes(plan.period));

  return (
    <div className={classes.HomePlans}>
      <Helmet>
        <title>Plans</title>
        <meta name="description" content="Join Oscer Prime today" />
      </Helmet>
      <HomeContainer
        outerStyles={classes.HomePlans__joinOscer__outer}
        innerStyles={classes.HomePlans__joinOscer__inner}
      >
        <p className={classes.HomePlans__tag}>PLANS</p>
        <Typography variant="h2" className={classes.HomePlans__title} gutterBottom>
          Join Oscer Prime today
        </Typography>
        <Typography variant="subtitle1" className={classes.HomePlans__joinOscer__subtitle} gutterBottom>
          <img
            className={classes.HomePlans__joinOscer__img}
            src={PartyPopper}
            alt="Celebrate our beta launch with 50% off"
          />
          Get unlimited access to all our cases and new features.
        </Typography>
        <div className={classes.HomePlans__plans}>
          {filteredPlan.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              link={`/plans/create-prime-account?plan=${escape(plan.name)}`}
              bestValue={plan?.period === 365}
            />
          ))}
        </div>
      </HomeContainer>
      <HomeContainer
        outerStyles={classes.HomePlans__container__outer}
        innerStyles={cx(classes.HomePlans__container__inner, classes.HomePlans__tryFreeContainer)}
      >
        <div className={classes.HomePlans__seperator}>
          <div className={classes.HomePlans__seperator__line} />
          <div className={classes.HomePlans__seperator__text}>OR</div>
          <div className={classes.HomePlans__seperator__line} />
        </div>
        <div className={classes.HomePlans__tryFreeText}>Try Oscer for free. No card required.</div>
        <Button
          className={classes.HomePlans__signUpBtn}
          color="primary"
          variant="contained"
          component={Link}
          to="/signup"
        >
          Sign Up
        </Button>
      </HomeContainer>
      <HomeContainer
        outerStyles={classes.HomePlans__container__outer}
        innerStyles={cx(classes.HomePlans__container__inner, classes.HomePlans__benefitsContainer)}
      >
        <Typography variant="h2" gutterBottom>
          Your Oscer Prime membership includes:
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Clinical Reasoning & OSCE Study
        </Typography>
        <div className={classes.HomePlans__benefits}>
          {osceStudyIcons.map((item) => (
            <div key={item.name} className={classes.HomePlans__benefits__item}>
              <img className={classes.HomePlans__benefits__icon} src={item.icon} alt={item.name} />
              <div className={item.isComing && classes.HomePlans__benefits__comming}>{item.name}</div>
            </div>
          ))}
        </div>
        <Typography variant="subtitle1" gutterBottom>
          Condition Study
        </Typography>
        <div className={classes.HomePlans__benefits}>
          {conditionStudyIcons.map((item) => (
            <div key={item.name} className={classes.HomePlans__benefits__item}>
              <img className={classes.HomePlans__benefits__icon} src={item.icon} alt={item.name} />
              <div className={item.isComing && classes.HomePlans__benefits__comming}>{item.name}</div>
            </div>
          ))}
        </div>
      </HomeContainer>
      <HomeContainer
        outerStyles={cx(classes.HomePlans__container__outer, classes.HomePlans__showCaseWrapper)}
        innerStyles={classes.HomePlans__container__inner}
      >
        {features.map((feat) => (
          <ImageShowcase
            key={feat.title}
            title={feat.title}
            subtitle={feat.subtitle}
            image={feat.image}
            isReverse={feat.isReverse}
            styles={{ root: classes.HomePlans__imageShowcase }}
          />
        ))}
        <img src={imgOverflow1} alt="hero_overlay_1" className={classes.HomePlans__overlay__1} />
        <img src={imgOverflow2} alt="hero_overlay_2" className={classes.HomePlans__overlay__2} />
      </HomeContainer>
      <HomeContainer
        outerStyles={cx(classes.HomePlans__container__outer, classes.HomePlans__bannerContainer__outer)}
        innerStyles={cx(classes.HomePlans__container__inner, classes.HomePlans__bannerContainer__inner)}
      >
        <div className={classes.HomePlans__banner}>
          <div className={classes.HomePlans__banner__title}>
            Lock in access to all our cases and score higher on your OSCEs
          </div>
          <Button
            className={classes.HomePlans__banner__btn}
            variant="contained"
            // component={Link}
            // to="/plans/create-prime-account?plan=1-year"
            onClick={() => {
              animateScroll.scrollToTop();
            }}
          >
            Buy Now
          </Button>
        </div>
        <img src={imgOverflow3} alt="hero_overlay_3" className={classes.HomePlans__overlay__3} />
      </HomeContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  plans: state.checkout.plans,
});

Plans.propTypes = {
  fetchPlans: PropTypes.func,
  plans: PropTypes.array,
};

export default connect(mapStateToProps, { fetchPlans })(Plans);
