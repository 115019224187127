import React from 'react';
import { Typography, Button } from '@material-ui/core';
import imgInstitution from '../../../assets/home/hp-institutions-img.svg';
import imgOverlayLeft from '../../../assets/home/shape-7.svg';
import imgOverlayRight from '../../../assets/home/institutions-shape-2.svg';
import HomeContainer from '../../../components/Container/HomeContainer';
import insDetailed from '../../../assets/home/detailed-results-icon.svg';
import insOsce from '../../../assets/home/osce-assessment-icon.svg';
import insIncrease from '../../../assets/home/increase-clinical-interaction-icon.svg';
import insCustomise from '../../../assets/home/customise-reporting-icon.svg';
import insCurriculum from '../../../assets/home/curriculum-virtual-patients-icon.svg';
import insCustom from '../../../assets/home/custom-patients-icon.svg';
import useStyles from './style';

const SpeakToTeam = () => {
  const classes = useStyles();

  const institution = [
    {
      icon: insCurriculum,
      desc: 'Standardise clinical exposure with a set of curriculum matched virtual patients and cases',
    },
    {
      icon: insCustomise,
      desc: 'Supports flipping the classroom every clinical tutorial customised to your students',
    },
    {
      icon: insCustom,
      desc: 'Create custom virtual patients with open chat (voice & text) with over 95% accuracy',
    },
    {
      icon: insOsce,
      desc: 'OSCE style assessments can be performed across the school at low cost',
    },
    {
      icon: insIncrease,
      desc: 'Increase clinical interaction at no logistical or operational cost',
    },
    {
      icon: insDetailed,
      desc: 'Detailed results and reporting for tutors and students on all cases',
    },
  ];

  return (
    <HomeContainer outerStyles={classes.SpeakToTeam__continer__outer}>
      <Typography variant="h2" className={classes.SpeakToTeam__title} gutterBottom>
        What sets us apart?
      </Typography>
      <Typography variant="subtitle1" className={classes.SpeakToTeam__subtitle} gutterBottom>
        The most interactive clinical reasoning platform ever created
      </Typography>
      <div className={classes.SpeakToTeam__institutions}>
        {institution.map((ins) => (
          <div key={ins.desc} className={classes.SpeakToTeam__institutions__wrapper}>
            <img src={ins.icon} alt="icon" className="" />
            <div className={classes.SpeakToTeam__institutions__text}>{ins.desc}</div>
          </div>
        ))}
      </div>
      <Button variant="contained" color="primary" className={classes.SpeakToTeam__btn} href="mailto:tom@oscer.ai">
        Speak To Our Team
      </Button>
      <img src={imgInstitution} alt="institution_overlay_left" className={classes.SpeakToTeam__img} />
      <img src={imgOverlayLeft} alt="institution_overlay_left" className={classes.SpeakToTeam__overlay__left} />
      <img src={imgOverlayRight} alt="institution_overlay_right" className={classes.SpeakToTeam__overlay__right} />
    </HomeContainer>
  );
};

SpeakToTeam.propTypes = {};

export default SpeakToTeam;
