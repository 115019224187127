import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeContainer from '../../../components/Container/HomeContainer';
import oscerProductImg from '../../../assets/home/InstantGraphication/case-interface-new.jpg';
import ScoresSnapShot from '../../../assets/home/InstantGraphication/scores-snapshot.png';

import CaseInfoModal from '../../../assets/home/InstantGraphication/case-info-modal.png';
import oscerProductScore from '../../../assets/home/InstantGraphication/oscer-product-score.png';
import oscerProductHint from '../../../assets/home/InstantGraphication/hint-snapshot.png';
import HintModal from '../../../assets/home/InstantGraphication/hint-modal.png';
import instantGratificationOverlay from '../../../assets/home/InstantGraphication/instant-gratification-overlay.svg';
import MobileStatsBar from '../../../assets/home/InstantGraphication/mobile-stats-bar.png';

import useStyles from './style';
import './style.scss';

const InstantGraphication = (props) => {
  const classes = useStyles();

  return (
    <HomeContainer outerStyles={classes.InstantGraphication} innerStyles={classes.InstantGraphication__container}>
      <div className="text-center">
        <Typography variant="h2" gutterBottom>
          Immediate feedback
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Every case is timed and scored, giving you real-time and objective diagnostic feedback. If you get stuck, no
          judgement here! We’ve got you covered with hints you can reveal along the way.
        </Typography>
        <div className="oscer-screenshot">
          <img className="oscer-product" src={oscerProductImg} alt="OscerProduct" />
          <Link to="/signup">
            <img className="oscer-info-modal feature" src={CaseInfoModal} alt="Oscer Case Info Modal" />
          </Link>
          <Link to="/signup">
            <img
              className={`${'oscer-product-score'} ${'feature'}`}
              src={oscerProductScore}
              alt="Oscer Product Score"
            />
          </Link>
          <Link to="/signup">
            <img className={`${'oscer-product-hint'} ${'feature'}`} src={oscerProductHint} alt="Oscer Reveal Hint" />
          </Link>
          <Link to="/signup">
            <img className={`${'oscer-product-questions'} ${'feature'}`} src={ScoresSnapShot} alt="" />
          </Link>
          <Link to="/signup">
            <img className="oscer-mobile-stats-bar feature" src={MobileStatsBar} alt="Oscer Mobile Status Bar" />
          </Link>

          <Link to="/signup">
            <img className="oscer-hint-modal feature" src={HintModal} alt="Oscer Hint Modal" />
          </Link>
        </div>
        <Button
          className={classes.InstantGraphication__signUpBtn}
          variant="contained"
          color="primary"
          component={Link}
          to="/signup"
        >
          Sign Up
        </Button>
      </div>

      <img className="instant-gratification-overlay-1" src={instantGratificationOverlay} alt="" />
    </HomeContainer>
  );
};

InstantGraphication.propTypes = {};

export default withRouter(InstantGraphication);
