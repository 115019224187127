import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  PracticeWithFriend: {},
  PracticeWithFriend__container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  PracticeWithFriend__title: {
    textAlign: 'center',
  },
  PracticeWithFriend__left: {
    flex: 1,
    marginRight: 60,
  },
  PracticeWithFriend__right: {
    flex: 1,
  },
  PracticeWithFriend__chatWindow: {
    maxWidth: '100%',
    marginBottom: 80,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  PracticeWithFriend__overlay1: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: '14%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  PracticeWithFriend__subtitle: {
    textAlign: 'center',
    maxWidth: 1100,
  },
}));

export default useStyles;
