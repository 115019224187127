import { makeStyles } from '@material-ui/core/styles';
import SectionBG from '../../assets/home/section-hero.svg';
import TeamBG from '../../assets/home/swipe-right-bg.svg';

const useStyles = makeStyles((theme) => ({
  About__section: {
    maxWidth: 1920,
    margin: '106px  auto 120px auto',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: 39,
      marginBottom: 60,
    },
  },
  About__section__team: {
    backgroundColor: '#ecf2ff',
    padding: 60,
    [theme.breakpoints.down('xs')]: {
      padding: '50px 15px',
    },
  },
  About__subtitle: {
    fontSize: 30,
    lineHeight: 1.5,
    textAlign: 'center',
    maxWidth: 720,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 17,
    },
  },
  About__section__main: {
    overflow: 'hidden',
  },
  About__oddSection: {
    background: `url(${SectionBG})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  About__values__main: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  About__mission__title: {
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  About__mission__subTitle: {
    marginBottom: 28,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
    },
  },
  About__mission__description: {
    marginBottom: 40,
    maxWidth: 1100,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  About__tag: {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.6,
  },
  About__video__player: {
    flex: 1,
    position: 'relative',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  About__TeamSection: {
    background: `url(${TeamBG})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
  },

  About__container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  About__container__team: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  About__container__left: {
    flex: 1,
    marginRight: 60,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  About__container__right: {
    flex: 1,
  },
  About__firstContainer: {
    paddingTop: 85,
    paddingBottom: 45,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 45,
    },
  },
  About__firstContainer__bottom: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  About__title: {
    marginBottom: 60,
    margin: '0 7%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      marginBottom: 30,
    },
  },

  About__overylay1: {
    position: 'absolute',
    left: 0,
    bottom: -170,
    maxWidth: 170,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  About__overylay2: {
    position: 'absolute',
    right: 0,
    top: '13%',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  About__overylay3: {
    position: 'absolute',
    right: -141,
    bottom: 0,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  About__overylay4: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    maxWidth: 170,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  About__overylay5: {
    position: 'absolute',
    top: -230,
    right: 0,
    width: 106,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  About__mission__oscerCharacter: {
    position: 'absolute',
    width: '55%',
    maxWidth: 194,
    bottom: 0,
    left: -210,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  About__systems: {
    marginBottom: 45,
  },
  About__systemBar: {
    marginBottom: 9,
  },
  About__img: {
    width: '100%',
  },
  About__team: {
    paddingTop: 60,
    paddingBottom: 120,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
    },
  },
  About__team__imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  About__team__subtitle: {
    textAlign: 'center',
  },
  About__team__image: {
    maxWidth: 300,
    margin: 'auto',
  },
  About__contact: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  About__contact__container: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  About__contact__question: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      marginBotton: 10,
    },
  },
  About__lastSection: {
    marginBottom: 0,
    paddingBottom: 60,
  },
  About__contact__image: {
    maxWidth: 1100,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  About__team__btn: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  About__contact__wrapper: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
    },
  },
  About__info__wrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: 'white',
    position: 'absolute',
    top: 30,
    left: 30,
    width: 250,
    boxShadow: '0 8px 16px 0 rgba(0, 35, 72, 0.16)',
    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      top: -40,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  About__map__info: {
    padding: '30px 20px',
    borderTop: `5px solid ${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
  },
  About__map__title: {
    fontSize: 18,
    lineHeight: 1.44,
    color: '#919aa3',
    marginBottom: 5,
  },
  About__map__address: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    marginBottom: 15,
  },
  About__map__qs: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    textDecoration: 'underline',
    marginBottom: 15,
  },
  About__map__partner: {
    fontSize: 18,
    lineHeight: 1.44,
    color: theme.palette.text.main,
    textDecoration: 'underline',
  },
  About__mission__wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  About__mission__icon__mobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 120,
      maxWidth: 120,
      display: 'flex',
      transform: 'scaleX(-1)',
    },
  },
}));

export default useStyles;
