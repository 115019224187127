import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DashboardFooterNav: {
    width: '100%',
    backgroundColor: 'white',
    // position: 'sticky',
    position: 'absolute',
    bottom: 0,
    boxShadow: '0 2px 12px 0 rgba(0, 35, 72, 0.04)',
    // zIndex: 1000,
  },
  DashboardFooterNav__Container__maxWidthLg: {
    maxWidth: 1080,
  },
  DashboardFooterNav__Container__root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px',
    height: 70,
  },
  DashboardFooterNav__main: {
    flex: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  DashboardFooterNav__free__icon: {
    height: 20,
    width: 24,
  },
  DashboardFooterNav__user: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  DashboardFooterNav__nav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: `${theme.palette.text.main}B3`,
    transition: 'all 0.2s',
    filter: 'opacity(50%) grayscale(100%)',
    '&:hover': {
      filter: 'opacity(70%) grayscale(100%)',
    },
  },
  DashboardFooterNav__nav__active: {
    color: theme.palette.primary.main,
    filter: 'grayscale(0)',
    '&:hover': {
      filter: 'grayscale(0)',
    },
  },
  DashboardFooterNav__ItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  DashboardFooterNav__nav__logo: {
    maxHeight: 36,
    maxWidth: 36,
  },
  DashboardFooterNav__nav__logo__beta: {
    marginBottom: 15,
  },
  DashboardFooterNav__nav__text: {
    fontSize: 15,
    letterSpacing: 0.3,
    fontWeight: 500,
    marginLeft: 10,
  },
  DashboardFooterNav__nav__text__beta: {
    fontSize: 12,
    marginLeft: -2,
    letterSpacing: 0.3,
    fontWeight: 500,
  },
  DashboardFooterNav__free__icon__wrapper: {
    borderRadius: '50%',
    backgroundColor: theme.palette.text.main,
    height: 36,
    width: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DashboardFooterNav__icon__placeholder: {
    height: 36,
    width: 36,
  },
  DashboardFooterNav__avatar: {
    backgroundColor: theme.palette.primary.light,
    width: 36,
    height: 36,
    overflow: 'initial',
    cursor: 'pointer',
    position: 'relative',
  },
  DashboardFooterNav__avatar__crown: {
    position: 'absolute',
    height: 15,
    top: -7,
    right: -7,
    transform: 'rotate(45deg)',
  },
  DashboardFooterNav__avatar__name: {
    fontSize: 15,
    fontWeight: 500,
  },
  DashboardFooterNav__profile__arrow: {
    right: -7,
  },
  DashboardFooterNav__free__img: {
    height: 71,
    width: 85,
  },
  DashboardFooterNav__Menu__img: {
    height: 85,
    width: 85,
    borderRadius: '50%',
    backgroundColor: `${theme.palette.text.main}1A`,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DashboardFooterNav__btn__view: {
    position: 'absolute',
    top: 10,
    right: 0,
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.text.main}33`,
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'white',
      color: `${theme.palette.text.main}99`,
    },
  },
  HomeHeader__profile__num: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 24,
    width: 24,
    lineHeight: 1.33,
    fontSize: 15,
    paddingTop: 2,
  },
  HomeHeader__profile__subtitle: {
    fontSize: 12,
    color: `${theme.palette.text.main}99`,
    lineHeight: 1.33,
    fontWeight: 300,
  },
  DashboardFooterNav__free__link: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    alignSelf: 'flex-end',
  },
  HomeHeader__profile__MenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 20px',
  },
  HomeHeader__btn__active: {
    color: theme.palette.primary.main,
  },
  HomeHeader__profile__title: {
    fontSize: 18,
    color: theme.palette.text.main,
    lineHeight: 1.33,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  DashboardFooterNav__profile__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    minWidth: 200,
  },
  DashboardFooterNav__profile__menu: {
    borderRadius: '11px 0 11px 11px',
  },
  DashboardFooterNav__Menu__icon: {
    height: 36,
    width: 36,
    backgroundColor: `${theme.palette.text.main}33`,
    color: `${theme.palette.text.main}33`,
    fontWeight: 500,
    fontSize: 9,
    letterSpacing: -0.06,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  DashboardFooterNav__Menu: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
    position: 'relative',
  },
  DashboardFooterNav__Menu__wrapper: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between',
  },
  DashboardFooterNav__Menu__main: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
    flex: 1,
  },
  DashboardFooterNav__Menu__title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: theme.palette.text.main,
    marginBottom: 5,
  },
  DashboardFooterNav__Menu__subtitle: {
    fontSize: 14,
    lineHeight: 1.3,
    color: `${theme.palette.text.main}99`,
    marginBottom: 10,
  },
  DashboardFooterNav__Menu__foot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
  },
  DashboardFooterNav__Menu__foot__icon: {
    height: 24,
    width: 24,
    filter: 'opacity(0.5)',
    backgroundColor: theme.palette.primary.main,
  },
  DashboardFooterNav__Menu__foot__icon__active: {
    filter: 'opacity(1)',
  },
  DashboardFooterNav__Menu__streak: {
    position: 'relative',
  },
  DashboardFooterNav__Menu__streak__icon: {},
  DashboardFooterNav__Menu__streak__num: {
    position: 'absolute',
    top: 51,
    left: 52,
    fontWeight: 500,
    fontSize: 38,
  },
}));

export default useStyles;
