import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  Institutions: {},
  Institutions__container__inner: {
    paddingTop: 120,
    paddingBottom: 120,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
      paddingTop: 60,
    },
  },
  Institutions__title: {
    textAlign: 'center',
    marginBottom: 40,
  },
  Institutions__subtitle: {
    textAlign: 'center',
    marginBottom: 60,
  },
  Institutions__overlay: {
    position: 'absolute',
    right: 0,
    top: -80,
    maxWidth: 247,
    maxHeight: 296,
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  Institutions__slider: {
    width: '100%',
  },
}));

export default useStyles;
