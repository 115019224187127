import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Popover, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import useStyles from './style';

const MobileMenuPopover = (props) => {
  const classes = useStyles();

  const {
    menuOpen,
    setMenuOpen,
    children,
    menuIcon,
    handleMouseOver,
    mouseOverElement,
    placement = 'bottom',
    styles = {},
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (menuOpen === '') {
      setAnchorEl(null);
    }
  }, [menuOpen]);

  const handleClose = () => {
    setMenuOpen('');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePopoverOpen = (event) => {
    if (menuOpen !== event.currentTarget) {
      setMenuOpen(event.currentTarget);
      handleClick(event);
    }
  };

  return (
    <>
      <div className={classes.MobileMenuPopover__icon__wrapper} onClick={handlePopoverOpen} aria-hidden="true">
        {menuIcon}
        <ArrowDropUpIcon
          classes={{
            root: cx(classes.MobileMenuPopover__icon, styles.arrow, {
              [classes.MobileMenuPopover__icon__active]: anchorEl,
            }),
          }}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: styles.paper }}
        anchorReference="none"
        anchorPosition={{ left: 0, top: 56 }}
        style={{ left: 0 }}
      >
        {children}
      </Popover>
      <div
        className={cx(classes.MobileMenuPopover__dropback, {
          [classes.MobileMenuPopover__dropback__on]: anchorEl,
        })}
      />
    </>
  );
};

MobileMenuPopover.propTypes = {
  children: PropTypes.node,
  menuIcon: PropTypes.node,
  styles: PropTypes.object,
  handleMouseOver: PropTypes.func,
  mouseOverElement: PropTypes.object,
  placement: PropTypes.string,
};

export default MobileMenuPopover;
