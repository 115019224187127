import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DragDropDown: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 5,
    maxWidth: 300,
    width: 240,
    marginRight: '0.5%',
    '& .MuiInputLabel-outlined': {
      // lineHeight: 0.5,
    },
  },
  DragDropDown__autocompleteRoot: {
    width: '100%',
    marginBottom: 5,
    position: 'relative',
  },
  DragDropDown__paper: {
    // boxShadow: '0 2px 2px 2px rgba(0, 35, 72, 0.12)',
    borderRadius: 16,
  },
  DragDropDown__popper__disabled: {
    display: 'none',
  },
  DragDropDown__systemList__wrapper: {
    position: 'relative',
  },
  DragDropDown__popupIndicator: {
    display: 'none',
  },
  DragDropDown__inputRoot: {
    '&.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      borderRadius: '100px',
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  DragDropDown__textfield__icon: {
    color: `${theme.palette.text.main}66`,
    display: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.main,
    },
  },
  DragDropDown__textfield__icon__active: {
    display: 'flex',
  },
  DragDropDown__system__wrapper: {
    position: 'absolute',
    top: 4,
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: 240,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 2px 2px rgba(0, 35, 72, 0.12)',
    borderRadius: 16,
    padding: '7px 0',
    zIndex: 100,
  },
  FuzzyFilter: {
    position: 'relative',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  FuzzyFilter__InputBase__root: {
    transition: 'width 0.3s ease',
    height: 40,
    backgroundColor: 'white',
    borderRadius: 21.5,
    fontSize: 14,
    padding: '0 15px',
    color: theme.palette.text.main,
    width: '100%',
    border: `solid 1px rgba(18, 19, 19, 0.05)`,
    '&.Mui-focused': {
      border: 'solid 2px #4a84ff',
    },
    '&::placeholder': {
      color: `${theme.palette.text.main}66`,
    },
    '&:hover': {
      cursor: 'pointer',
      border: 'solid 2px #4a84ff',
    },
    '& .MuiInputBase-input': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  FuzzyFilter__InputBase__root__selected: {
    backgroundColor: theme.palette.info.main,
    color: 'white',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      border: `solid 1px ${theme.palette.info.main}`,
    },
  },
  FuzzyFilter__InputBase__root__selected__light: {
    backgroundColor: theme.palette.inherit.main,
    color: theme.palette.primary.dark,
    border: `solid 1px #f1f1f1`,
    '&::placeholder': {
      color: `${theme.palette.text.main}66`,
    },
    '&:hover': {
      border: `solid 1px ${theme.palette.info.main}`,
    },
  },
  FuzzyFilter__SearchIcon__root: {
    color: `${theme.palette.primary.dark}`,
  },
  FuzzyFilter__SearchIcon__root__selected__light: {
    color: theme.palette.primary.dark,
  },
  FuzzyFilter__SearchIcon__root__selected: {
    color: 'white',
  },
  FuzzyFilter__SearchIcon__root__active: {
    color: theme.palette.primary.main,
    '-webkit-transform': 'rotate(180deg)',
    '-moz-transform': 'rotate(180deg)',
    '-o-transform': 'rotate(180deg)',
    '-ms-transform': 'rotate(180deg)',
    transform: 'rotate(180deg)',
  },
}));

export default useStyles;
