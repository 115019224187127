import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ErrorModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 594,
    height: 569,
    backgroundColor: 'white',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    borderBottom: `20px solid ${theme.palette.primary.light}`,
    padding: 60,
    [theme.breakpoints.down('xs')]: {
      width: '95vw',
      height: 'auto',
      padding: '30px 15px',
    },
  },
  ErrorModal__content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  ErrorModal__image: {
    maxHeight: 250,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      maxHeight: 180,
    },
  },
  ErrorModal__title: {
    fontSize: 21,
    color: theme.palette.text.main,
    marginBottom: 20,
    textAlign: 'center',
  },
  ErrorModal__subtitle: {
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.text.main,
    marginBottom: 40,
    textAlign: 'center',
  },
  ErrorModal__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  ErrorModal__error: {
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontSize: 14,
    marginTop: 10,
  },
  ErrorModal__btn: {
    height: 43,
  },
}));

export default useStyles;
