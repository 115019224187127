import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  MenuPopover: {},
  MenuPopover__arrow: {
    fontSize: 50,
    position: 'absolute',
    bottom: -41,
    marginLeft: 'auto',
    marginRight: 'auto',
    opacity: 0,
    height: 0,
    transition: 'opacity 0.4s',
  },
  MenuPopover__arrow__active: {
    opacity: 1,
    height: 'auto',
  },
  MenuPopover__wrapper: {
    borderRadius: 11,
    overflow: 'hidden',
    boxShadow: '0 5px 20px 0 rgba(7, 42, 68, 0.1)',
    marginTop: 20,
  },
  MenuPopover__Paper: {
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  MenuPopover__menu__icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
}));

export default useStyles;
