import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { patchUserDetails } from '../actions/auth';
import useUserGroup from './useUserGroup';
import { trackEvent } from '../service/analytics';
import { useWidth } from '../helpers/customHooks';
import { USER_TOUR_BLACK_LIST } from '../constants';

/**
 * We currently have three onboarding type: onboarding_flag, osce_history_flag, osce_template_flag
 * @param {*} tourName
 * @param {*} type
 * @returns
 */
export const useUserTour = (tourName, type = 'onboarding_flag') => {
  /**
   * If user quit from a tour, force them redo all tour
   *
   */
  const tourFlag = useSelector((state) => state?.auth?.user?.profile?.user_tour_flags);
  const dispatch = useDispatch();

  /**
   * User tour status
   * If this is not in localstorage or any of the key/values not match, then user tour should show
   *
   * If user quit without finish all process, the userTour local storage will be delete in App?.js,
   * so they will be required to start from begining.
   *
   * TODO: could change to state if needed in future
   */
  const userTour = {
    dashboard: { type: 'onboarding_flag' },
    case: { type: 'osce_history_flag' },
    case_submit: { type: 'osce_history_flag', prerequisite: 'case' },
    summary: { type: 'osce_history_flag' },
    questions: { type: 'osce_history_flag' },
    single_player_rubric: { type: 'osce_history_flag' },
    osce_template_script: { type: 'osce_template_flag' },
    osce_template_rubric: { type: 'osce_template_flag' },
    diagnosis: { type: 'diagnosis_flag' },
    condition_explorer: { type: 'condition_explorer_flag' },
    new_onboarding: { type: 'new_onboarding_flag' },
    welcome: { type: 'welcome_flag' },
  };

  const [userGroup, isAuthenticate, isTourBlocked] = useUserGroup({ blackList: USER_TOUR_BLACK_LIST });
  const screenSize = useWidth();

  const getTourState = () => {
    /**
     * This tag only attached when user has active onboarding_flag (new users)
     * onboardingTour
     * NOT_IN_SCOPE
     * TODO
     * CONTROL_GROUP
     * DONE
     */
    // return true;
    // return true;

    // if (type === 'diagnosis_flag') return true;

    // If local storage has userTour, then check  flag status
    if (tourFlag[type] !== 'TODO') {
      return false;
    }

    if (userTour[tourName].type === 'condition_explorer_flag') {
      return true;
    }

    if (userTour[tourName].type === 'new_onboarding_flag') {
      return true;
    }

    if (userTour[tourName].type === 'welcome_flag') {
      return true;
    }

    // !Need redo logic when we recover feature tour
    if (userTour[tourName].type === 'diagnosis_flag' && !isMobileOnly) {
      return true;
    }

    // !Temporally turn off feature tour
    if (userTour[tourName].type === 'onboarding_flag') {
      return false;
    }

    // Check prerequisite, return false if no prereq and prereq is not finish
    if (userTour[tourName]?.prerequisite) {
      return false;
    }

    if (isTourBlocked) {
      return false;
    }

    if (isMobileOnly && type !== 'onboarding_flag') {
      return false;
    }

    return true;
  };

  const [isTourOpen, setTourOpen] = useState(false);

  useEffect(() => {
    const newState = getTourState();
    setTourOpen(newState);
  }, [isTourBlocked, screenSize]);

  const handleTourfinished = (tour) => {
    setTourOpen(false);
    trackEvent({
      GAAction: `complete_user_tour_${type}`,
      FBAction: `Complete User Tour ${type}`,
      category: `User Tour`,
      label: `Complete ${type} tour in section ${tourName} by ${isMobileOnly ? 'mobile' : 'desktop'}`,
    });
    // For mobile users, once they finished onboarding tour, set other tour finished as well
    if (isMobileOnly) {
      dispatch(
        patchUserDetails({
          profile: {
            user_tour_flags: {
              welcome_flag: 'DONE',
              onboarding_flag: 'DONE',
              new_onboarding_flag: 'DONE',
              osce_history_flag: 'DONE',
              osce_template_flag: 'DONE',
              condition_explorer_flag: 'DONE',
            },
          },
        })
      );
    } else {
      dispatch(patchUserDetails({ profile: { user_tour_flags: { ...tourFlag, [tour]: 'DONE' } } }));
    }
  };

  // Currently not in use because we forced user take the tour
  const handleTourClosed = (tour) => {
    setTourOpen(false);
    trackEvent({
      GAAction: `closed_user_tour_${tourName}`,
      FBAction: `Closed User Tour ${tourName}`,
      category: `User Tour`,
      label: `Closed ${type} tour in section ${tourName}`,
    });
    dispatch(patchUserDetails({ profile: { user_tour_flags: { ...tourFlag, [tour]: 'DONE' } } }));
  };

  /**
   * isTourOpen: a state to control tour open
   * handleTourClosed: user close tour by clicking cross
   * setTourFinished: user finish the tour
   * setTourOpen: set isTourOpen state
   *
   */
  return [isTourOpen, setTourOpen, handleTourClosed, handleTourfinished];
};

useUserTour.propTypes = {
  tourName: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default useUserTour;
