import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import useStyles from './style';

/**
 * @desc this is the showcase in /technology
 * @param {*} param0
 */
const ImageShowcase = ({ title, subtitle, image, isReverse, styles = {} }) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.ImageShowcase, { [classes.ImageShowcase__reverse]: isReverse }, styles.root)}>
      <div className={classes.ImageShowcase__left}>
        <Typography variant="h2" gutterBottom className={styles.title}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={styles.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.ImageShowcase__right}>
        <img src={image} className={cx(classes.ImageShowcase__chatWindow, styles.image)} alt={`image__${title}`} />
      </div>
    </div>
  );
};

ImageShowcase.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  isReverse: PropTypes.bool,
  styles: PropTypes.object,
};

export default ImageShowcase;
