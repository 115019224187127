import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CaseInterfaceProfile from '../../../../components/Avatar/CaseInterfaceProfile';
import PatientInfoModal from '../../Modals/PatientInfoModal';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import NavigateNextArrow from '../../../../assets/home/navigate-arrow.svg';

import useStyles from './style';

const BEGIN_CASE_MODAL = 'BEGIN_CASE_MODAL';
const ProfilePanel = (props) => {
  const classes = useStyles();
  const { user, isPrime, modalState, currentCase, progress, second } = props;

  // Patient info toggle
  const [isInfoToggle, setInfoToggle] = useState(false);

  const patientInfoToggle = () => {
    setInfoToggle((toggle) => !toggle);
  };

  const secondToTime = (seconds) => {
    const format = (val) => `0${Math.floor(val)}`.slice(-2);
    // const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60;

    return [minutes, seconds % 60].map(format).join(':');
  };

  const reminderSelector = (seconds) => {
    if (seconds === 0) {
      return { text: "Time's up!", color: '#FBA799' };
    }
    if (seconds <= 120) {
      return { text: 'Wrap up', color: '#FBA799' };
    }
    if (seconds <= 240) {
      return { text: 'Almost there!', color: '#EDBD66' };
    }
    if (seconds <= 360) {
      return { text: 'Keep going!', color: '#83D0F0' };
    }
    return { text: 'Great!', color: '#4A84FF' };
  };

  return (
    <div className={classes.ProfilePanel}>
      <div
        className={classes.ProfilePanel__CollapseButton}
        onClick={() => {
          props.togglePanel();
        }}
      >
        <img src={NavigateNextArrow} height={30} width={30} alt="next" />
        <div className={classes.Tooltip__arrow}> </div>
        <div className={classes.Tooltip}>
          <div className={classes.Tooltip__borderTop}></div>
          <span className={classes.Tooltip__text}>Collapse side bar</span>
        </div>
      </div>
      <PatientInfoModal currentCase={currentCase} isInfoToggle={isInfoToggle} infoToggle={patientInfoToggle} />
      {modalState !== BEGIN_CASE_MODAL ? (
        <div className={classes.ProfilePanel__progress}>
          <div className={classes.ProfilePanel__progress__wrapper}>
            <CircularProgress
              variant="determinate"
              value={100}
              size={75}
              thickness={6}
              className={classes.ProfilePanel__CircularProgress}
            />
            <CircularProgress
              variant="determinate"
              value={progress}
              size={75}
              thickness={6}
              classes={{
                root: cx(
                  classes.ProfilePanel__CircularProgress__root,
                  { [classes.ProfilePanel__CircularProgress__color__1]: second === 0 },
                  { [classes.ProfilePanel__CircularProgress__color__2]: second <= 120 && second > 0 },
                  { [classes.ProfilePanel__CircularProgress__color__3]: second <= 240 && second > 120 },
                  { [classes.ProfilePanel__CircularProgress__color__4]: second <= 360 && second > 240 },
                  { [classes.ProfilePanel__CircularProgress__color__5]: second > 360 }
                ),
              }}
            />
          </div>
          <div className={classes.ProfilePanel__details}>
            <div
              className={classes.ProfilePanel__details__reminder}
              style={{ color: `${reminderSelector(second).color}` }}
            >
              {reminderSelector(second).text}
            </div>
            <div className={classes.ProfilePanel__details__time}>
              <span className={classes.ProfilePanel__timer__text}>{secondToTime(second)}</span>
            </div>
            <div className={classes.ProfilePanel__details__text}>The case will not end</div>
          </div>
        </div>
      ) : (
        <CaseInterfaceProfile user={user} isPrime={isPrime} setInfoToggle={patientInfoToggle} />
      )}

      {/* will be used for read only mode later */}
      {/* <PatientInfoModal currentCase={currentCase} isInfoToggle={isInfoToggle} infoToggle={patientInfoToggle} />
      <CaseInterfaceProfile user={user} isPrime={isPrime} setInfoToggle={patientInfoToggle} /> */}
    </div>
  );
};

ProfilePanel.propTypes = {
  user: PropTypes.object,
  isPrime: PropTypes.bool,
  currentCase: PropTypes.object,
  modalState: PropTypes.string,
  progress: PropTypes.number,
  second: PropTypes.number,
};

export default ProfilePanel;
