import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import img from '../../../assets/character/oscer-tour-welcome.svg';
import doneImg from '../../../assets/character/oscer-tour-start-diagnosing.svg';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  UserTour__welcome: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  UserTour__welcome__img: {
    maxWidth: 400,
    marginBottom: 20,
  },
  UserTour__welcome__marginBottom: {
    marginBottom: 20,
  },
  UserTour__welcome__subtitle: {
    marginBottom: 30,
    color: theme.palette.text.main,
    width: '80%',
    textAlign: 'center',
  },
  UserTour__welcome__btn: {
    height: 40,
  },
  UserTour__content__1: {
    display: 'flex',
    flexDirection: 'column',
  },
  UserTour__title__1: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__subtitle__1: {
    fontSize: 15,
    lineHeight: 1.33,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__btn__1: {
    height: 34,
    width: 78,
    fontSize: 14,
    fontWeight: 500,
    alignSelf: 'flex-end',
    padding: 0,
  },
}));

const UserTourSteps = (
  handleUserTourClose,
  setTourPage,
  setCompleted,
  isPrime,
  setProfilePopover,
  firstCase,
  isOpen
) => {
  const classes = useStyles();
  const history = useHistory();
  const isNewUser = localStorage.getItem('is_new_user') || false;
  const dispatch = useDispatch();
  // const filteredCases = useSelector((state) => state.cases.filteredCases);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setProfilePopover(true);
      }, 200);
    } else {
      setProfilePopover(false);
    }
  }, [isOpen]);

  return [
    {
      selector: '[data-tut="reacttour__tab__nav"]',
      position: 'bottom',
      content: () => <div className={classes.UserTour__content__1}></div>,
    },
    {
      selector: '[data-tut="reacttour__tab__nav"]',
      position: 'bottom',
      content: () => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Welcome to to your brand new Oscer Home!</div>
          <div className={classes.UserTour__subtitle__1}>
            This is where you can access cases, scripts (templates) and our newest feature Condition Explorer.{' '}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProfilePopover(true);
              // Set the first case as selected
              setTourPage(2);
            }}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Next
          </Button>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__tab__profile"]',
      position: 'bottom',
      content: () => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Profile</div>
          <div className={classes.UserTour__subtitle__1}>
            You can access your profile, account details and percentile here. View your specialty and system progress as
            well.
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setProfilePopover(false);
              setTourPage(3);
            }}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Next
          </Button>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__freeCases"]',
      position: 'bottom',
      content: () => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Collections</div>
          <div className={classes.UserTour__subtitle__1}>
            This is where you can find our cases organised into Collections. Each Collection contains a number of
            assorted cases based on rotations.
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setTourPage(4);
            }}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Done
          </Button>
        </div>
      ),
    },
    {
      selector: '[data-tut="reactour__randomCase"]',
      position: 'bottom',
      content: () => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Random Case</div>
          <div className={classes.UserTour__subtitle__1}>This case card will take you to a random Oscer case.</div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setTourPage(5);
            }}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Done
          </Button>
        </div>
      ),
    },
    {
      content: () => (
        <div className={classes.UserTour__welcome}>
          <img src={doneImg} alt="done_img" className={classes.UserTour__welcome__img} />
          <Typography variant="h4" className={classes.UserTour__welcome__marginBottom}>
            Start Mastering Diagnosis
          </Typography>
          <div className={classes.UserTour__welcome__subtitle}>
            {isPrime
              ? "We've created some personalized collections for your current studies. Let's get started and jump into your first case!"
              : "We've chosen 20 free cases that match your current studies. Let's get started and jump into your first case!"}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setCompleted('new_onboarding_flag');
              if (isNewUser && firstCase) {
                history.push(`/cases/${firstCase}/spm`);
              }
            }}
            classes={{ root: classes.UserTour__welcome__btn }}
          >
            Let's Go!
          </Button>
        </div>
      ),
      style: {
        maxWidth: 660,
        minWidth: 350,
        height: 600,
        borderRadius: 16,
        boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
        backgroundColor: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        borderBottom: '19px solid #4a84ff',
        display: 'flex',
        borderTop: 'none',
      },
    },
  ];
};

UserTourSteps.propTypes = {
  handleUserTourClose: PropTypes.func,
};

export default UserTourSteps;
