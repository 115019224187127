import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Hint from '../HintsPanel/Hint';
import IconHint from '../../../../components/icons/IconHint';
import useStyles from './style';

const HintsPanelMobile = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { accordionExpanded, accordionOnChange } = props;

  return (
    <Accordion
      square
      classes={{ root: classes.HintsPanelMobile__accordion__root }}
      expanded={accordionExpanded}
      onChange={accordionOnChange}
    >
      <AccordionSummary
        classes={{
          root: classes.HintsPanelMobile__accordionSummary__root,
          content: classes.HintsPanelMobile__accordionSummary__content,
        }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.HintsPanelMobile__label}>
          <IconHint color={theme.palette.primary.main} size={30} className={classes.HintsPanelMobile__hints__icon} />
          Hints
        </div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.HintsPanelMobile__accordionDetails__root }}>
        <Hint />
      </AccordionDetails>
    </Accordion>
  );
};

HintsPanelMobile.propTypes = {
  accordionExpanded: PropTypes.bool,
  accordionOnChange: PropTypes.func,
};

export default HintsPanelMobile;
