import React from 'react';
import PropTypes from 'prop-types';
import ReactSlick from 'react-slick';
import Fab from '@material-ui/core/Fab';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

import cx from 'classnames';
import useStyles from './style';

const CarouselNextArrow = (props) => {
  const { classes, onClick } = props;

  return (
    <Fab classes={classes} onClick={onClick}>
      <MdArrowForward size={12} />
    </Fab>
  );
};

CarouselNextArrow.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
};

const CarouselPrevArrow = (props) => {
  const { classes, onClick } = props;
  return (
    <Fab classes={classes} onClick={onClick}>
      <MdArrowBack size={12} />
    </Fab>
  );
};

CarouselPrevArrow.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
};

const Slider = (props) => {
  const cs = useStyles();
  const { isMobileArrowHide, className } = props;

  const defaultSetting = {
    nextArrow: (
      <CarouselNextArrow
        classes={{
          root: cx(cs.Slider_arrow, cs.Slider__forwardArrow, { [cs.Slider__arrow__mobile]: isMobileArrowHide }),
        }}
      />
    ),
    prevArrow: (
      <CarouselPrevArrow
        classes={{
          root: cx(cs.Slider_arrow, cs.Slider__backwardArrow, { [cs.Slider__arrow__mobile]: isMobileArrowHide }),
        }}
      />
    ),
  };

  const { settings, children } = props;

  return (
    <ReactSlick {...defaultSetting} {...settings} className={className}>
      {children}
    </ReactSlick>
  );
};

Slider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object,
  isMobileArrowHide: PropTypes.bool,
  className: PropTypes.string,
};

export default Slider;
