import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './style';

const DividerWithText = (props) => {
  const { marginTop, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.DividerWithText} style={{ marginTop: marginTop || '20px' }}>
      <span>{children}</span>
    </div>
  );
};

DividerWithText.propTypes = {
  marginTop: PropTypes.string,
  children: PropTypes.node,
};

export default DividerWithText;
