import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DashboardMobileHeaderNav: {
    width: '100%',
    backgroundColor: 'white',
    height: 56,
    // position: 'sticky',
    position: 'absolute',
    top: 0,
    zIndex: 1000,
    boxShadow: '0 2px 12px 0 rgba(0, 35, 72, 0.04)',
  },
  DashboardMobileHeaderNav__Container__root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    padding: '0 10px',
  },
  DashboardHeaderNav__free__link: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    alignSelf: 'flex-end',
  },
  DashboardHeaderNav__Menu__streak__text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
    color: theme.palette.primary.light,
    letterSpacing: 0.17,
    textTransform: 'uppercase',
  },
  DashboardHeaderNav__Menu__streak__icon: {
    width: 76,
    marginBottom: 8,
  },
  DashboardMobileHeaderNav__main: {
    flex: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  DashboardMobileHeaderNav__free__icon: {
    height: 20,
    width: 24,
  },
  DashboardMobileHeaderNav__free__icon__wrapper: {
    borderRadius: '50%',
    backgroundColor: theme.palette.text.main,
    height: 36,
    width: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DashboardMobileHeaderNav__iconRanking: {
    height: 50,
  },
  DashboardMobileHeaderNav__avatar: {
    backgroundColor: theme.palette.primary.light,
    width: 36,
    height: 36,
    overflow: 'initial',
    cursor: 'pointer',
    position: 'relative',
  },
  DashboardMobileHeaderNav__avatar__crown: {
    position: 'absolute',
    height: 15,
    top: -7,
    right: -7,
    transform: 'rotate(45deg)',
  },
  DashboardMobileHeaderNav__avatar__name: {
    fontSize: 15,
    fontWeight: 500,
  },
  DashboardMobileHeaderNav__menu__icon: {
    height: 36,
    width: 36,
  },
  DashboardMobileHeaderNav__Menu: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
  },
  DashboardMobileHeaderNav__Menu__main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 20,
    flex: 1,
  },
  DashboardMobileHeaderNav__Menu__title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: theme.palette.text.main,
    marginBottom: 5,
  },
  DashboardMobileHeaderNav__Menu__subtitle: {
    fontSize: 14,
    lineHeight: 1.3,
    color: `${theme.palette.text.main}99`,
    marginBottom: 10,
    maxWidth: '95%',
  },
  DashboardMobileHeaderNav__free__img: {
    height: 71,
    width: 85,
  },
  DashboardMobileHeaderNav__popover__paper: {
    left: 0,
    top: 55,
    width: '100%',
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '0 0 16px 16px',
    boxShadow: 'none',
    maxWidth: '100%',
  },
  DashboardMobileHeaderNav__free__link: {
    alignSelf: 'flex-end',
    fontSize: 12,
  },
  DashboardMobileHeaderNav__Menu__streak: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  DashboardMobileHeaderNav__Menu__streak__icon: {
    width: 76,
    marginBottom: 8,
  },
  DashboardMobileHeaderNav__Menu__streak__num: {
    position: 'absolute',
    top: 24,
    left: 29,
    fontWeight: 500,
    fontSize: 38,
  },
  DashboardMobileHeaderNav__Menu__streak__text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
    color: theme.palette.primary.light,
    letterSpacing: 0.17,
    textTransform: 'uppercase',
  },
  DashboardMobileHeaderNav__Menu__percentile: {
    display: 'flex',
    marginBottom: 8,
  },
  DashboardMobileHeaderNav__Menu__percentile__num: {
    fontSize: 62,
    color: theme.palette.primary.light,
    fontWeight: 500,
    lineHeight: 0.8,
  },
  DashboardMobileHeaderNav__Menu__percentile__h: {
    color: theme.palette.primary.light,
    alignSelf: 'flex-end',
  },
  DashboardHeaderNav__profile__wrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    minWidth: 200,
  },
  HomeHeader__profile__MenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 20px',
  },
  HomeHeader__btn__active: {
    color: theme.palette.primary.main,
  },
  HomeHeader__profile__title: {
    fontSize: 18,
    color: theme.palette.text.main,
    lineHeight: 1.33,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  HomeHeader__profile__num: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 24,
    width: 24,
    lineHeight: 1.33,
    fontSize: 15,
    paddingTop: 2,
  },
  HomeHeader__profile__subtitle: {
    fontSize: 12,
    color: `${theme.palette.text.main}99`,
    lineHeight: 1.33,
    fontWeight: 300,
  },
}));

export default useStyles;
