import React from 'react';
import { SyncLoader } from 'react-spinners';
import iconOscerLoader from '../../../assets/common/oscer-loader.svg';
import useStyles from './style';

const OscerLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.OscerLoader}>
      <div className={classes.OscerLoader__spinner}>
        <SyncLoader
          size={7}
          color="white"
          css={{
            textAlign: 'center',
            backgroundColor: '#83D0F0',
            padding: '5px 15px',
            borderRadius: '15px',
            boxShadow: '0px 2px 3px rgba(27, 28, 29, 0.03)',
            width: '80px',
          }}
        />
      </div>
      <img src={iconOscerLoader} alt="loader" className={classes.OscerLoader__icon} />
    </div>
  );
};

OscerLoader.propTypes = {};

export default OscerLoader;
