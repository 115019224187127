import { isMobileOnly, deviceType, browserName } from 'react-device-detect';
import { APIClient } from '../settings';

const headers = (token) => {
  const config = { headers: {} };

  if (token) {
    config.headers.Authorization = `token ${token}`;
  }

  return config;
};

const get = (path, token = null) => APIClient.get(path, headers(token));

const post = (path, token = null, data = {}) => APIClient.post(path, data, headers(token));

// get url parameters
const getURLParam = (key) => {
  const urlSearch = window.location.search;
  const query = new URLSearchParams(urlSearch);
  return query.get(key);
};

export const postSessionId = (token, case_id, case_journey_id, mode) =>
  post('/api/v1/agent/session/', token, { case_id, is_test_session: false, case_journey_id, mode });

export const getResponse = (token, session_id, message) =>
  post(`/api/agent/${session_id}/`, token, { session_id, message });

export const getCase = (token, caseId) => get(`/api/v1/cases/${caseId}/`, token);

export const getUnlockedCase = (token) => get(`/api/v1/cases/unlocked/list/`, token);

export const getAllCases = (token) => get(`/api/v1/cases/list/`, token);

export const getPwfCases = (token) => get(`/api/v1/cases/?search=GENERAL_CASE`, token);

export const getSearchCases = (token) => get(`/api/v1/cases/`, token);

export const getHints = (token, caseId) => get(`/api/v1/cases/${caseId}/hints/`, token);

export const getFUQuestions = (token, caseId) => get(`/api/v1/cases/${caseId}/questions/`, token);

export const getStats = (token) => get(`/api/v1/stats/completion/`, token);

export const getRubricStats = (token, session_id) => get(`/api/v1/cases/session/${session_id}/`, token);

export const getPlans = (params) => {
  let param = '';
  if (params?.region) {
    param += `?region=${params.region}`;
    if (params?.voucher) {
      param += `&promo_code=${params.voucher}`;
    }
  } else if (params?.voucher) {
    param += `?promo_code=${params.voucher}`;
  }
  return get(`/api/v1/payment/plan/${param}`, params?.token);
  // const para = new URLSearchParams({ voucher: params?.voucher, region: params?.region });
  // console.log(para, para.toString());
  // return get(`/api/v1/payment/plan/?${para.toString()}`, params?.token);
};

export const payPalPostCheckout = (token, orderID, subscriptionID) =>
  post('/payment/paypal_post_checkout/', token, { order_id: orderID, subscription_id: subscriptionID });

export const sendAnswer = (token, session_id, question, selected_answer) =>
  post(`/api/agent/${session_id}/question/`, token, { question, selected_answer });

export const postQuestions = (token, session_id, questions) =>
  post(`/api/agent/${session_id}/question/`, token, questions);

export const postTrackingEvent = (token, event) => post(`/api/v1/tracking/event/`, token, event);

export const register = (email, password, promo_code, countryCode) =>
  // const promo_code = getURLParam('promo_code') || '';
  post(`/api/v1/auth/register/`, null, {
    email,
    password,
    referral: '',
    promo_code,
    country_code: countryCode,
    is_mobile: isMobileOnly,
    device: deviceType,
    browser: browserName,
  });

// used both for sign up and log in as technically it's the same.
export const googleSignUp = (token, promo_code, country) =>
  // const promo_code = getURLParam('promo_code') || '';
  post(`/api/v1/auth/google/`, null, {
    token,
    referral: '',
    promo_code,
    country_code: country,
    is_mobile: isMobileOnly,
    device: deviceType,
    browser: browserName,
  });

export const facebookSignUp = (token, promo_code, country) =>
  // const promo_code = getURLParam('promo_code') || '';
  post(`/api/v1/auth/facebook/`, null, {
    token,
    referral: '',
    promo_code,
    country_code: country,
    is_mobile: isMobileOnly,
    device: deviceType,
    browser: browserName,
  });

// forgot password
export const forgotPassword = (email) => post(`/api/v1/auth/forgot_pass/`, null, { email });
export const resetPassword = (password, key) => post(`/api/v1/auth/reset_pass/`, null, { key, password });
