import React from 'react';
import PropTypes from 'prop-types';

const IconEmail = props => {
  const { color = 'var(--secondary-color-1)', size = 20, ...restProps } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 50 35" version="1.1" {...restProps}>
      <g id="assets" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="new-icons-light-dark" transform="translate(-303.000000, -740.000000)">
          <g id="email-icon-dark" transform="translate(303.000000, 740.000000)">
            <path d="M24.8349,19.0251 L48.1599,1.2971 C47.2609,0.5001 46.0899,0.0001 44.7939,0.0001 L5.0969,0.0001 C3.7509,0.0001 2.5349,0.5321 1.6239,1.3841 L24.8349,19.0251 Z" id="Fill-1" fill={color}></path>
            <g id="Group-5" transform="translate(0.000000, 1.923000)">
              <path d="M25.4404,19.1538 C25.2614,19.2898 25.0474,19.3578 24.8354,19.3578 C24.6224,19.3578 24.4084,19.2898 24.2294,19.1538 L0.4574,1.0868 C0.1694,1.7238 0.0004,2.4278 0.0004,3.1738 L0.0004,27.9038 C0.0004,30.7178 2.2824,33.0008 5.0964,33.0008 L44.7944,33.0008 C47.6084,33.0008 49.8894,30.7178 49.8894,27.9038 L49.8894,24.8388 L49.8894,15.8118 L49.8894,3.1738 C49.8894,2.3798 49.6924,1.6368 49.3694,0.9668 L25.4404,19.1538 Z" id="Fill-3" fill={color} mask="url(#mask-2)"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

IconEmail.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

export default IconEmail;
