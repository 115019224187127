import * as Sentry from '@sentry/react';
import {
  SET_PRESENTATION_FILTER,
  SET_SYSTEM_FILTER,
  SET_SELECTED_CASE_INDEX,
  SET_SELECTED_CASE_SECTION,
  SET_SYSTEM_AVERAGE_SELECTED,
  SET_SYSTEM_AVERAGE_FILTER,
  SET_LEVEL_FILTER,
  SET_INSENTIVE_MODAL,
  SET_MODE_SELECTION_MODAL,
  SET_EXPLORER_UPGRADE_MODAL,
  SET_SELECTED_CASE,
  SET_UPGRADE_MODAL,
  SET_PROFILE_POPOVER,
  UPGRADE_MODAL_STEP_FLAG_SET,
} from './types';
import { setFilteredCases } from './cases';
import { APIClient } from '../settings';
import { tokenConfig } from './auth';

export const setSelectedPresentationFilter = (value) => ({
  type: SET_PRESENTATION_FILTER,
  value,
});

export const setProfilePopover = (toggle) => ({
  type: SET_PROFILE_POPOVER,
  toggle,
});

export const setSelectedSystemFilter = (value) => ({
  type: SET_SYSTEM_FILTER,
  value,
});

export const setSelectedLevelFilter = (value) => ({
  type: SET_LEVEL_FILTER,
  value,
});

export const applyDashboardCaseFilter = () => (dispatch, getState) => {
  const { presentationFilter, systemFilter } = getState().dashboard;
  const { cases } = getState().cases;
  const filteredCases = cases.filter((c) => {
    let ok = true;
    if (presentationFilter) {
      ok = presentationFilter.value.toLowerCase() === c.presentation.toLowerCase();
    }
    if (systemFilter) {
      ok = ok && systemFilter.value.toLowerCase() === c.system.toLowerCase();
    }
    return ok;
  });
  dispatch(setFilteredCases(filteredCases));
};

export const setSelectedCaseIndex = (idx) => ({
  type: SET_SELECTED_CASE_INDEX,
  idx,
});

export const setSelectedCaseSection = (section) => ({
  type: SET_SELECTED_CASE_SECTION,
  section,
});

export const setSystemAverageFilter = (value) => ({
  type: SET_SYSTEM_AVERAGE_FILTER,
  value,
});

export const setSystemAverageSelected = (value) => ({
  type: SET_SYSTEM_AVERAGE_SELECTED,
  value,
});

export const setInsentiveModal = (value) => ({
  type: SET_INSENTIVE_MODAL,
  value,
});

export const setModeSelectionModal = (value) => ({
  type: SET_MODE_SELECTION_MODAL,
  value,
});

export const setSelectedCase = (caseObj) => ({
  type: SET_SELECTED_CASE,
  caseObj,
});

export const setUpgradeExplorerModalToggle = (toggle) => ({
  type: SET_EXPLORER_UPGRADE_MODAL,
  toggle,
});

export const setUpgradeModalToggle = (toggle) => ({
  type: SET_UPGRADE_MODAL,
  toggle,
});

export const setUpgradeModalStepFlag = (step) => ({
  type: UPGRADE_MODAL_STEP_FLAG_SET,
  step,
});

export const postFeedback = (caseId, comment, rate, session_type, session_uuid) => () =>
  new Promise((resolve, reject) => {
    APIClient.post('/api/v1/cases/feedback/', { case: caseId, comment, rate, session_type, session_uuid })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const postPrimeFeedback = (reasons, score) => {
  const args = { reasons, recommmend_score: score };
  return new Promise((resolve, reject) => {
    APIClient.post('/api/v1/feedback/prime-feedback/', args)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        Sentry.captureException(err, {
          tags: {
            section: '/api/v1/feedback/prime-feedback/',
            args,
          },
        });
        reject(err);
      });
  });
};
