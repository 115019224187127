import {
  SET_MODE,
  SET_EXIT_MODAL,
  SET_CASE,
  SELECTED_ROLE_SET,
  OCCUPIED_ROLES_SET,
  PWF_INFO_SET,
  MARKING_RUBRIC_SET,
  MARKING_RUBRIC_UPDATED,
  MARKER_STEP_UPDATED,
  MARKER_QUESTION_UPDATED,
  MARKER_OVERALL_UPDATED,
  SET_SUBMIT_CASE_MODAL,
  SET_MARKER_NOT_SUBMIT_MODAL,
  SET_HOST_NOT_START_MODAL,
  SET_ERROR_MODAL,
} from './types';
import { APIClient } from '../settings';
import { tokenConfig } from './auth';
import { range } from 'lodash';

export const setSelectedRole = (selectedRole) => ({
  type: SELECTED_ROLE_SET,
  selectedRole,
});

export const setOccupiedRoles = (occupiedRoles) => ({
  type: OCCUPIED_ROLES_SET,
  occupiedRoles,
});

export const setMode = (mode) => ({
  type: SET_MODE,
  mode,
});

export const setExitModal = (value) => ({
  type: SET_EXIT_MODAL,
  value,
});

export const setSubmitCaseModal = (value) => ({
  type: SET_SUBMIT_CASE_MODAL,
  value,
});

export const setMarkerNotSubmitModal = (value) => ({
  type: SET_MARKER_NOT_SUBMIT_MODAL,
  value,
});

export const setHostNotStartModal = (value) => ({
  type: SET_HOST_NOT_START_MODAL,
  value,
});

export const setErrorModal = (value) => ({
  type: SET_ERROR_MODAL,
  value,
});

export const setCase = (value) => ({
  type: SET_CASE,
  value,
});

export const pwfInfoSet = (value) => ({
  type: PWF_INFO_SET,
  value,
});

export const markingRubricSet = (markingRubric) => ({
  type: MARKING_RUBRIC_SET,
  markingRubric,
});

export const markingRubricUpdated = (marking) => ({
  type: MARKING_RUBRIC_UPDATED,
  marking,
});

export const markerStepUpdated = (step) => ({
  type: MARKER_STEP_UPDATED,
  step,
});

export const markerQuestionUpdated = (answer) => ({
  type: MARKER_QUESTION_UPDATED,
  answer,
});

export const markerOverallUpdated = (answer) => ({
  type: MARKER_OVERALL_UPDATED,
  answer,
});

export const postPWFInit = (modeData) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.post('/api/v1/session_pwf/init/', modeData, tokenConfig(getState))
      .then((res) => {
        dispatch(pwfInfoSet(res.data));
        dispatch(markerStepUpdated('Proceed to Questions'));

        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

// Todo: set mode if success
export const getPWFSession = (session) => (_, getState) =>
  APIClient.get(`/api/v1/stats/case/${session}/`, tokenConfig(getState));

export const patchRoleSelect = (session, role) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/session_pwf/${session}/role_select/`, role, tokenConfig(getState))
      .then((res) => {
        dispatch(setOccupiedRoles(res.data.occupied_roles));
        resolve(res.data);
      })
      .catch((err) => {
        // Todo: need backend return correct error
        if (err.response.data.occupied_roles && err.response.data.occupied_roles.length) {
          dispatch(setOccupiedRoles(err.response.data.occupied_roles));
          dispatch(setSelectedRole(''));
        }
        reject(err.response.data);
      });
  });

export const getPWFInfo = (session, param) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.post(`/api/v1/session_pwf/${session}/info/`, param, tokenConfig(getState))
      .then((res) => {
        dispatch(pwfInfoSet(res.data));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const patchPWFStart = (session) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/session_pwf/${session}/start/`, null, tokenConfig(getState))
      .then((res) => {
        dispatch(markingRubricSet(res.data));
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });

export const patchPWFCancel = (session) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/session_pwf/${session}/cancel/`, null, tokenConfig(getState))
      .then((res) => {
        // Todo: remove console log
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });

export const patchPWFSubmit = (session, markingRubric) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    APIClient.patch(`/api/v1/session_pwf/${session}/submit/`, markingRubric, tokenConfig(getState))
      .then((res) => {
        dispatch(markingRubricSet(res.data));
        resolve(res);
      })
      .catch((err) => {
        // Todo: remove console log
        console.log(err);
        reject(err);
      });
  });

export const getDailyTaskCases = (dailytask_case) => (dispatch) =>
  new Promise((resolve, reject) => {
    APIClient.get(`/api/v1/daily-task/case/${dailytask_case}/`)
      .then((res) => {
        const questions = res.data.questions.map((ques) => ({
          description: ques.answer,
          earned_marks: null,
          index: ques.id,
          options: range(ques.marks + 1).map((mark) => ({
            mark,
            text: mark.toString(),
          })),
          title: ques.question,
        }));
        const dailytaskData = {
          session_question_answers: questions,
          summary: res.data.summary,
        };
        dispatch(pwfInfoSet(dailytaskData));
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
