import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { EXAM_LEARN_MODE } from '../../../constants';

const useStyles = makeStyles((theme) => ({
  UserTour__content__1: {
    display: 'flex',
    flexDirection: 'column',
  },
  UserTour__title__1: {
    fontSize: 20,
    lineHeight: 1.4,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__subtitle__1: {
    fontSize: 15,
    lineHeight: 1.33,
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  UserTour__btn__1: {
    height: 34,
    width: 78,
    fontSize: 14,
    fontWeight: 500,
    alignSelf: 'flex-end',
    padding: 0,
  },
}));

const UserTourSteps = (setModes, handleUserTourClose, currentCase) => {
  const classes = useStyles();

  return [
    {
      selector: '[data-tut="reactour__start_case"]',
      position: 'right',
      content: ({ goTo }) => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Let’s Start!</div>
          <div className={classes.UserTour__subtitle__1}>
            {`Read through ${currentCase.patient_name}’s information and then let’s begin chatting to ${
              currentCase.gender === 'Male' ? 'him' : 'her'
            }.`}
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModes(EXAM_LEARN_MODE);
              goTo(1);
            }}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Next
          </Button>
        </div>
      ),
      style: {
        maxWidth: 320,
        borderTop: '4px solid #83d0f0',
        borderRadius: '0 16px 16px 16px',
        padding: 16,
      },
    },
    {
      selector: '[data-tut="reactour__time_to_chat"]',
      position: 'top',
      stepInteraction: false,
      content: () => (
        <div className={classes.UserTour__content__1}>
          <div className={classes.UserTour__title__1}>Time to chat</div>
          <div className={classes.UserTour__subtitle__1}>
            Use your voice or type here to ask your patient a few questions.
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUserTourClose}
            classes={{ root: classes.UserTour__btn__1 }}
          >
            Got it!
          </Button>
        </div>
      ),
      style: {
        maxWidth: 320,
        borderTop: '4px solid #83d0f0',
        borderRadius: 16,
        padding: 16,
      },
    },
  ];
};

UserTourSteps.propTypes = {
  handleUserTourClose: PropTypes.func,
  setModes: PropTypes.func,
};

export default UserTourSteps;
