import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  DashboardHeaderNav: {
    width: '100%',
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    boxShadow: '0 2px 12px 0 rgb(0 35 72 / 4%)',
  },
  DashboardHeaderNav__Container__root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 84,
    padding: '0 70px',
    [theme.breakpoints.down('md')]: {
      padding: '0 50px',
    },
  },
  DashboardHeaderNav__OscerLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  DashboardHeaderNav__iconRanking: {
    height: 60,
  },
  DashboardHeaderNav__main: {
    width: '75%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  DashboardHeaderNav__free__icon: {
    height: 20,
    width: 24,
  },
  DashboardHeaderNav__user: {
    width: '25%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  DashboardHeaderNav__user__free: {
    width: '20%',
  },
  DashboardHeaderNav__nav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: `${theme.palette.text.main}B3`,
    paddingRight: 0,
    marginRight: -10,
    transition: 'all 0.2s',
    filter: 'opacity(50%) grayscale(100%)',
    '&:hover': {
      filter: 'opacity(70%) grayscale(100%)',
    },
  },
  DashboardHeaderNav__nav__active: {
    color: theme.palette.primary.main,
    filter: 'grayscale(0)',
    '&:hover': {
      filter: 'grayscale(0)',
    },
  },
  DashboardHeaderNav__nav__logo: {
    maxHeight: 36,
    maxWidth: 36,
  },
  DashboardHeaderNav__nav__text: {
    fontSize: 15,
    letterSpacing: 0.3,
    fontWeight: 500,
    marginLeft: 10,
    maxWidth: 100,
    lineHeight: 1.1,
  },
  DashboardHeaderNav__nav__text__beta: {
    fontSize: 13,
    letterSpacing: 0.3,
    fontWeight: 500,
    marginLeft: -10,
    maxWidth: 100,
    lineHeight: 1.1,
  },

  DashboardHeaderNav__free__icon__wrapper: {
    borderRadius: '50%',
    backgroundColor: theme.palette.text.main,
    height: 36,
    width: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DashboardHeaderNav__icon__placeholder: {
    height: 36,
    width: 36,
  },
  DashboardHeaderNav__SearchIcon__root: {
    height: 48,
    width: 48,
    color: theme.palette.primary.main,
  },
  DashboardHeaderNav__avatar: {
    backgroundColor: theme.palette.primary.light,
    width: 36,
    height: 36,
    overflow: 'initial',
    cursor: 'pointer',
    position: 'relative',
  },
  DashboardHeaderNav__avatar__crown: {
    position: 'absolute',
    height: 15,
    top: -7,
    right: -7,
    transform: 'rotate(45deg)',
  },
  DashboardHeaderNav__avatar__name: {
    fontSize: 15,
    fontWeight: 500,
  },
  DashboardHeaderNav__profile__arrow: {
    right: -7,
  },
  DashboardHeaderNav__free__img: {
    height: 71,
    width: 85,
  },
  DashboardHeaderNav__Menu__img: {
    height: 85,
    width: 85,
    borderRadius: '50%',
    backgroundColor: `${theme.palette.text.main}1A`,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DashboardHeaderNav__btn__view: {
    position: 'absolute',
    top: 10,
    right: 0,
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.text.main}33`,
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'white',
      color: `${theme.palette.text.main}99`,
    },
  },
  HomeHeader__profile__num: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 24,
    width: 24,
    lineHeight: 1.33,
    fontSize: 15,
    paddingTop: 2,
  },
  HomeHeader__profile__subtitle: {
    fontSize: 12,
    color: `${theme.palette.text.main}99`,
    lineHeight: 1.33,
    fontWeight: 300,
  },
  DashboardHeaderNav__free__link: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1,
    alignSelf: 'flex-end',
  },
  HomeHeader__profile__MenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 20px',
  },
  HomeHeader__btn__active: {
    color: theme.palette.primary.main,
  },
  HomeHeader__profile__title: {
    fontSize: 18,
    color: theme.palette.text.main,
    lineHeight: 1.33,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  DashboardHeaderNav__profile__wrapper: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    minWidth: 200,
  },
  DashboardHeaderNav__profile__menu: {
    borderRadius: '11px 0 11px 11px',
  },
  DashboardHeaderNav__Menu: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 15px',
  },
  DashboardHeaderNav__Menu__main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 20,
    flex: 1,
  },
  DashboardHeaderNav__Menu__title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    color: theme.palette.text.main,
    marginBottom: 5,
  },
  DashboardHeaderNav__Menu__subtitle: {
    fontSize: 14,
    lineHeight: 1.3,
    color: `${theme.palette.text.main}99`,
    marginBottom: 10,
  },
  DashboardHeaderNav__Menu__streak: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  DashboardHeaderNav__Menu__streak__icon: {
    width: 76,
    marginBottom: 8,
  },
  DashboardHeaderNav__Menu__streak__num: {
    position: 'absolute',
    top: 24,
    left: 29,
    fontWeight: 500,
    fontSize: 38,
  },
  DashboardHeaderNav__Menu__streak__num__two: {
    position: 'absolute',
    top: 24,
    left: 18,
    fontWeight: 500,
    fontSize: 38,
  },
  DashboardHeaderNav__Menu__streak__text: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.4,
    color: theme.palette.primary.light,
    letterSpacing: 0.17,
    textTransform: 'uppercase',
  },
  DashboardHeaderNav__Menu__percentile: {
    display: 'flex',
    marginBottom: 8,
  },
  DashboardHeaderNav__Menu__percentile__num: {
    fontSize: 62,
    color: theme.palette.primary.light,
    fontWeight: 500,
    lineHeight: 0.8,
  },
  DashboardHeaderNav__Menu__percentile__h: {
    color: theme.palette.primary.light,
    alignSelf: 'flex-end',
  },
}));

export default useStyles;
