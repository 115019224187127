import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  HintsPanel: {
    position: 'relative',
    borderRadius: 16,
    boxShadow: '0 2px 30px 0 rgba(27, 28, 29, 0.03)',
    padding: '30px 20px 20px 20px',
    backgroundColor: '#fbfbfb',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HintsPanel__icon: {
    position: 'absolute',
    right: 20,
    top: -25,
    width: 48,
    height: 72,
  },
  HintsPanel__title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.text.main,
    marginBottom: 10,
  },
  HintsPanel__label: {
    marginBottom: 10,
  },
}));

export default useStyles;
