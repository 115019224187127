import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import starIcon from '../../assets/icons/star-outlined-icon.svg';
import starIconFull from '../../assets/icons/star-icon.svg';
import useStyles from './style';

/**
 * @desc a rating componet with stars
 * @param {*} param0
 * - rate is the number of full star, default 1
 * - total is the number of total star
 * - starClass is the styles for stars
 * - groupClass is the styles for grouping div
 */
const StarRating = ({ rate = 0, total = 3, starClass, groupClass }) => {
  const classes = useStyles();
  const rating = () => {
    const stars = [];
    for (let i = 0; i < total; i += 1) {
      if (i < rate) {
        stars.push(<img key={i} src={starIconFull} className={cx(classes.StarRating, starClass)} alt="star-full" />);
      } else {
        stars.push(<img key={i} src={starIcon} className={cx(classes.StarRating, starClass)} alt="star" />);
      }
    }
    return stars;
  };

  return <div className={groupClass}>{rating()}</div>;
};

StarRating.propTypes = {
  rate: PropTypes.number,
  total: PropTypes.number,
  starClass: PropTypes.string,
  groupClass: PropTypes.string,
};

export default StarRating;
