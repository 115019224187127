import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  HomeHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 120,
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    [theme.breakpoints.down('xs')]: {
      height: 39,
      boxShadow: '0 2px 23px 0 rgba(27, 28, 29, 0.03)',
    },
  },
  HomeHeader__main: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  HomeHeader__about__MenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 20px',
  },
  HomeHeader__wrapper: {
    borderRadius: 16,
    overflow: 'hidden',
    boxShadow: '0 5px 20px 0 rgba(7, 42, 68, 0.1)',
    marginTop: 10,
  },
  HomeHeader__about__main: {
    position: 'relative',
  },
  HomeHeader__arrow: {
    position: 'absolute',
    top: -14,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  HomeHeader__Paper: {
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  HomeHeader__about__title: {
    fontSize: 18,
    color: theme.palette.text.main,
    lineHeight: 1.33,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      marginBottom: 5,
    },
  },
  HomeHeader__about__subtitle: {
    fontSize: 14,
    color: theme.palette.text.main,
    lineHeight: 1.43,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  HomeHeader__nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
  },
  HomeHeader__nav__logo: {
    marginRight: '4%',
    [theme.breakpoints.down('sm')]: {
      width: 120,
      marginRight: 0,
    },
  },
  HomeHeader__nav__link: {
    color: theme.palette.text.main,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '&:hover': {
      textDecoration: 'none',
      color: 'black',
      textShadow: '0px 0px 0.2px #000000;',
    },
  },
  HomeHeader__link__active: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
      textShadow: 'none',
      cursor: 'default',
    },
  },
  HomeHeader__btn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      marginRight: 20,
    },
  },
  HomeHeader__dashboard__contained: {
    padding: '15px 35px',
    borderRadius: 40,
    [theme.breakpoints.down('xs')]: {
      padding: '5px 15px',
      fontSize: 14,
      marginRight: 5,
    },
  },
  HomeHeader__dashboard__icon: {
    marginLeft: 10,
    maxWidth: 25,
  },
  HomeHeader__signIn: {
    display: 'flex',
    alignItems: 'center',
    width: 200,
    height: 60,
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    borderRadius: 30,
    boxShadow: '0 5px 20px 0 rgba(7, 42, 68, 0.1)',
    fontSize: 16,
    fontWeight: 500,
    paddingLeft: 40,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 150,
      paddingLeft: 20,
    },
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 120,
      paddingLeft: 15,
    },
  },
  HomeHeader__signUp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 150,
    height: 60,
    borderRadius: 30,
    boxShadow: '0 5px 20px 0 rgba(7, 42, 68, 0.1)',
    fontSize: 16,
    fontWeight: 500,
    marginLeft: -80,
    '&:hover': {
      textDecoration: 'none',
      color: 'white',
      fontWeight: 600,
    },
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 120,
      marginLeft: -70,
    },
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 90,
      marginLeft: -50,
    },
  },
  HomeHeader__main__mobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  HomeHeader__logo__mobile: {
    maxHeight: 25,
  },
  HomeHeader__drawer__paper: {
    width: '100%',
  },
  HomeHeader__drawer__main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    // padding: '50px 0 0 40px',
  },
  HomeHeader__drawer__link: {
    color: 'white',
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    borderTop: '1px solid #ffffff',
    borderBottom: '1px solid #ffffff',
    borderLeft: '1px solid #ffffff',
    width: '100%',
    marginBottom: 30,
    fontSize: 18,
    padding: '10px 15px',
    textAlign: 'center',
  },
  HomeHeader__link__sign: {
    fontWeight: 500,
  },
  HomeHeader__link__mobile__active: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    fontWeight: 'bold',
    border: 'none',
  },
  HomeHeader__btn__active: {
    color: theme.palette.primary.main,
  },
  HomeHeader__jobs__num: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: 20,
    width: 20,
    marginLeft: 5,
    fontSize: 12,
    marginTop: 1,
  },
  HomeHeader__logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  HomeHeader__hire: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 8,
  },
  HomeHeader__hiring: {
    fontSize: 14,
    color: theme.palette.text.main,
    backgroundColor: '#ecf2ff',
    borderRadius: 11.5,
    padding: '2px 30px',
    '&:hover': {
      backgroundColor: '#d3e1ff',
      boxShadow: '0 2px 23px 0 rgba(27, 28, 29, 0.1)',
      transition: 'linear 0.1s',
    },
  },
  HomeHeader__num: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -15,
    fontSize: 14,
    paddingTop: 1,
  },
  HomeHeader__mobile__header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 0 20px',
    marginBottom: 20,
    boxShadow: '0 2px 23px 0 rgba(27, 28, 29, 0.03)',
  },
  HomeHeader__mobile__wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  HomeHeader__mobile__link__active: {
    color: theme.palette.primary.primary,
  },
  HomeHeader__mobile__links: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 30,
  },
  HomeHeader__mobile__link: {
    fontSize: 22,
    fontWeight: 500,
    color: theme.palette.text.main,
    width: '100%',
    borderBottom: `2px solid ${theme.palette.text.main}`,
    marginBottom: 20,
  },
  HomeHeader__mobile__about: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
}));

export default useStyles;
