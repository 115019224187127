import axios from 'axios';

const onRequest = (req) => req;

const onRequestError = (err) => Promise.reject(err);

const onResponse = (res) => Promise.resolve(res);

const onResponseError = (err) => {
  if (err?.response?.status === 500) {
    return Promise.reject('Network Error(500), please contact support');
  }
  // todo: change all backend response strucutre
  //! notice that 401 eror is an exception, which needs proper error handling. We reject the error mssage as a short term fix
  if (err?.response?.status === 401) {
    APIClient.defaults.headers.Authorization = '';
    return Promise.reject(err.message);
  }
  try {
    if (typeof err.response.data.code === 'number') {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(Number(err.response.data.code[0]));
  } catch {
    try {
      return Promise.reject(err.response.data.non_field_errors[0]);
    } catch {
      return Promise.reject(err);
    }
  }
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export const APIClient = axios.create();
export const FileClient = axios.create();

const token = localStorage.getItem('token');
APIClient.interceptors.request.use(onRequest, onRequestError);
APIClient.interceptors.response.use(onResponse, onResponseError);
FileClient.interceptors.request.use(onRequest, onRequestError);
FileClient.interceptors.response.use(onResponse, onResponseError);
// APIClient.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
if (token) {
  APIClient.defaults.headers.Authorization = `Token ${token}`;
  FileClient.defaults.headers.Authorization = `Token ${token}`;
}

export const emailConfirmationUrl = (key) => `/api/v1/auth/activation/${key}/`;
